import React from "react";
import ManualContent from "../components/ManualContent";

const Welcome = () => {
  return (
    <ManualContent title="Welcome to Speqqle">
      <h2 id="intro">Revolutionizing Spectroscopic Data Analysis</h2>
      <p>
        Speqqle is designed to{" "}
        <strong>simplify, accelerate, and enhance</strong> spectroscopic data
        analysis. Building upon the foundation of <strong>RefFIT</strong>,
        Speqqle is more than just a fitting tool—it&apos;s a powerful,
        intuitive, and user-friendly platform that brings cutting-edge analysis
        to researchers, engineers, and scientists across multiple fields.
      </p>

      <h2 id="what-is-speqqle">What is Speqqle?</h2>
      <p>
        Speqqle is a <strong>web-based spectroscopic analysis platform</strong>{" "}
        that enables users to efficiently fit and interpret optical, infrared,
        and Raman spectra. Whether you&apos;re modeling reflectivity,
        transmission, ellipsometry, or analyzing complex datasets, Speqqle
        provides an{" "}
        <strong>interactive, visual, and algorithmically robust</strong>{" "}
        environment to handle your data with precision.
      </p>

      <h2 id="who-uses-speqqle">Who Uses Speqqle?</h2>
      <p>
        Speqqle is built for{" "}
        <strong>scientists, engineers, and industry professionals</strong>{" "}
        working with optical and spectroscopic data. Typical users include:
      </p>
      <ul>
        <li>
          <strong>Materials Scientists</strong> analyzing thin films,
          multilayers, and new materials.
        </li>
        <li>
          <strong>Physicists & Chemists</strong> studying optical properties of
          complex systems.
        </li>
        <li>
          <strong>Industry Experts</strong> optimizing coatings, sensors, and
          optical devices.
        </li>
        <li>
          <strong>Data Scientists</strong> leveraging machine learning for
          pattern recognition in spectral data.
        </li>
      </ul>

      <h2 id="features">What Can Speqqle Do?</h2>
      <ul>
        <li>
          <strong>Data Handling & Fitting:</strong> Load, visualize, and fit
          spectra in an intuitive interface.
        </li>
        <li>
          <strong>Advanced Modeling:</strong> Use predefined models or build
          your own for dielectric functions.
        </li>
        <li>
          <strong>Simultaneous Multi-Dataset Fitting:</strong> Fit multiple
          datasets together for higher accuracy.
        </li>
        <li>
          <strong>Flexible Parameter Control:</strong> Adjust and constrain
          parameters dynamically.
        </li>
        <li>
          <strong>Integration with Machine Learning:</strong> Utilize AI-driven
          insights for enhanced analysis.
        </li>
        <li>
          <strong>Real-Time Graphical Feedback:</strong> See instant results
          while adjusting parameters.
        </li>
      </ul>

      <h2 id="how-to-use-manual">How This Manual Works</h2>
      <p>
        This manual is designed to be <strong>modular and interactive</strong>.
        You don&apos;t need to read it from start to finish—just navigate using
        the <strong>sidebar</strong> and jump to the section you need, such as:
      </p>
      <ul>
        <li>
          <a href="general/quickstart">Getting Started</a> – Learn how to load
          and fit your first dataset.
        </li>
        <li>
          <a href="general/interface">Understanding the Interface</a> – Explore
          the{" "}
          <strong>
            Data Loading, Experiments, Parameters, Fit, Results, Outputs, and
            Loop
          </strong>{" "}
          tabs.
        </li>
        <li>
          <a href="fit/vdf-fit">Advanced Features</a> – Learn about{" "}
          <strong>
            VDF fitting, chisq minimization, and multi-dataset handling
          </strong>
          .
        </li>
        <li>
          <a href="packages/overview">Package-Specific Guides</a> – Find
          explanations for current and future packages such as{" "}
          <strong>Gasfit, Ramanfit, Nearfit, Hyperfit</strong>.
        </li>
        <li>
          <a href="troubleshooting/overview">Troubleshooting & FAQs</a> – Get
          quick answers to common issues.
        </li>
      </ul>

      {/*<p>
        💡 <strong>Tip:</strong> Wherever you see the{" "}
        <strong>❔ help icon</strong> in Speqqle, clicking it will bring you
        directly to the relevant section in this manual.
      </p>*/}

      <h2 id="start-now">Let&apos;s Get Started 🚀</h2>
      <p>
        Click on a topic in the sidebar to begin exploring{" "}
        <strong>Speqqle</strong>. If you&apos;re new, start with{" "}
        <a href="general/quickstart">Getting Started</a> to learn how to load
        and analyze your first dataset.
      </p>
    </ManualContent>
  );
};

export default Welcome;
