import React, { useState, useEffect } from "react";
import deleteIcon from "../../../res/icons/delete.png";
import addIcon from "../../../res/icons/add.png";
import linkIcon from "../../../res/icons/link.png";
import unlinkIcon from "../../../res/icons/unlink.png";
import "./../leftSide.scss";

function ModelEntry(params) {
  const {
    modelName,
    setModelName,
    canAdd,
    linked,
    unlink,
    deleteEntry,
    ID,
    addSubModel,
    startLink,
    isLinkActive,
    // canLink,
    canBeDeleted,
    // subModelIDs,
    speqqleID,
    indexChain,
    linkingID,
  } = params;

  const [name, setName] = useState(modelName);

  const handleRename = (event) => {
    event.preventDefault();
    if (!isLinkActive()) {
      const newName = prompt("Enter new model name", name);
      if (newName !== null) {
        setName(newName);
        setModelName(newName, ID);
      }
    }
  };

  useEffect(() => {
    if (name !== modelName) {
      setName(modelName);
    }
  }, [modelName]);

  // const handleClick = () => {
  //   if (isLinkActive()) {
  //     startLink(ID, speqqleID, indexChain);
  //   }
  // };

  const handleUnlink = () => {
    unlink(indexChain);
  };

  const handleDelete = () => {
    if (linked) {
      unlink(indexChain);
      deleteEntry(0, true);
    } else {
      deleteEntry(ID, false);
    }
  };

  // const style = isLinkActive()
  //   ? canLink(ID, speqqleID)
  //     ? { cursor: "pointer" }
  //     : { color: "gray" }
  //   : {};

  return (
    <div className="modelEntry">
      <div
        className={`modelEntryName${ID === linkingID ? " beingLinked" : ""}`}
        id="model-entry-name"
        onContextMenu={handleRename}
        // onClick={() => handleClick()}
        // style={style}
      >
        {name}
      </div>
      <div>
        {canAdd && !isLinkActive() ? (
          <img
            className="modelEntryIcon"
            src={addIcon}
            alt={"add model icon"}
            onClick={() => {
              addSubModel(ID, linked);
            }}
          />
        ) : (
          <></>
        )}
        {linked && !isLinkActive() ? (
          <img
            className="modelEntryIcon"
            src={unlinkIcon}
            alt={"unlink icon"}
            onClick={() => handleUnlink()}
          />
        ) : (
          <></>
        )}
        {!linked && !isLinkActive() ? (
          <img
            className="modelEntryIcon"
            src={linkIcon}
            alt={"link icon"}
            onClick={() => startLink(ID, speqqleID, indexChain)}
          />
        ) : (
          <></>
        )}
        {canBeDeleted && !isLinkActive() ? (
          <img
            className="modelEntryIcon"
            src={deleteIcon}
            alt={"detele icon"}
            onClick={() => {
              handleDelete();
            }}
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default ModelEntry;
