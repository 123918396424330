import React from "react";
import ManualContent from "../../components/ManualContent";
import image1 from "../../images/Legend_modelcurve.jpg";
import image2 from "../../images/Legend_datacurve.jpg";

const Interactive = () => {
  return (
    <ManualContent title="Interactive Legend">
      <p>
        The graph legend in Speqqle allows you to quickly access key features
        for both data and model curves.
      </p>

      <h2>Accessing Model Parameters from the Legend</h2>
      <p>
        Clicking on a <strong>model curve</strong> in the legend automatically
        opens the corresponding model in the <strong>Parameters</strong> tab,
        allowing you to view and adjust its parameters.
      </p>

      <img
        src={image1}
        alt="Opening Model Parameters from the Legend"
        width="800"
      />

      <h2>Modifying Data Curves from the Legend</h2>
      <p>
        Clicking on a <strong>data curve</strong> in the legend opens a settings
        window, where you can adjust various parameters:
      </p>

      <ul>
        <li>
          <strong>Change the x-axis unit:</strong> Modify the original unit of
          the dataset.
        </li>
        <li>
          <strong>Set x-axis limits:</strong> Define the data range to focus on
          relevant sections.
        </li>
        <li>
          <strong>Add exclusion ranges:</strong> Remove specific data ranges,
          for example, to exclude noisy regions or unwanted artifacts.
        </li>
        <li>
          <strong>Manage exclusions:</strong> Use the{" "}
          <strong>green plus (+) icon</strong> to add an exclusion range, and
          the <strong>red cross (X) icon</strong> to remove an exclusion range.
        </li>
      </ul>

      <p>
        The total number of remaining data points after exclusions is displayed
        under <strong>&quot;Points&quot;</strong>.
      </p>

      <img
        src={image2}
        alt="Dataset Window with Exclusion Ranges"
        width="800"
      />

      <h2>Expanded Dataset Manager</h2>
      <p>
        The dataset settings window displays the dataset file name as the title.
        Clicking the title opens the <strong>expanded dataset manager</strong>,
        which provides additional functions such as:
      </p>

      <ul>
        <li>
          <strong>Data scaling:</strong> Adjust intensity values.
        </li>
        <li>
          <strong>Shifting:</strong> Apply offsets to align datasets.
        </li>
      </ul>

      <p>
        To close the dataset window, click the{" "}
        <strong>red cross (X) icon</strong> in the top right corner.
      </p>
    </ManualContent>
  );
};

export default Interactive;
