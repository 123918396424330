import React from "react";
import ManualContent from "../../components/ManualContent";

const Relationships = () => {
  return (
    <ManualContent title="Parameter Relationships">
      <p>Coming Soon</p>
    </ManualContent>
  );
};

export default Relationships;
