/**
 * @category LeftSide
 * @component
 * @module Files/fileProcessing/FileProcessingWindow
 * @description This component is a window that pops up when user tries to upload some files and has *no preset*
 * selected in loading options. This component then lets user select options for the file and then process it.
 * User can also save the options as a preset for later use or as an automatic loading option.
 */
import React, { useState, useEffect, useContext, useRef } from "react";
import RawAndSolved from "./RawAndSolved";
import ProcessingOptions from "./ProcessingOptions";
import { DashboardContext } from "../../../../context/DashboardContext";
import { GeneralContext } from "../../../../context/GeneralContext";
import FileList from "./FileList";
import "./fileProcessing.scss";
// import { Button } from "@mui/material";
// import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { generateWarningObject, hasProperty } from "../../../../utils/helpers";
import {
  deepCopyFiles,
  getNextAvailableIndex,
  readFile,
} from "./processingLogic";
import { processFileWithOptions } from "./processingLogic";
import { deepCopy } from "../../Models/modelLogic";
import CloseButton from "../../../commonComponents/CloseButton";
import CustomButton from "../../../commonComponents/CustomButton";

function FileProcessingWindow(props) {
  const { cancelProcessing, solveProcessing, modalId } = props;
  const {
    filePresets,
    setFilePresets,
    fileID,
    setFileID,
    setWarnings,
    setNewWarningCount,
  } = useContext(DashboardContext);
  const { limitedToast, recordedErrorLog } = useContext(GeneralContext);
  const fileInputRef = useRef(null);
  const folderInputRef = useRef(null);
  const [currentFileIndex, setCurrentFileIndex] = useState(0);
  const [currentFileContent, setCurrentFileContent] = useState(null);
  const [processedContent, setProcessedContent] = useState("");
  const [idForFile, setIdForFile] = useState(fileID);
  const [solvedList, setSolvedList] = useState([]);
  const [done, setDone] = useState(false);
  const [filesToProcess, setFilesToProcess] = useState([]);

  useEffect(() => {
    if (
      done &&
      processedContent === "" &&
      solvedList.every((file) => file !== null) &&
      filesToProcess.length > 0
    ) {
      handleFinishLoad();
    }
  }, [done, processedContent, solvedList]);

  useEffect(() => {
    setIdForFile(fileID);
  }, [fileID]);

  useEffect(() => {
    try {
      if (currentFileIndex <= filesToProcess.length - 1) {
        readFile(
          filesToProcess[currentFileIndex],
          generateWarningObject,
          setWarnings,
          setNewWarningCount,
          limitedToast,
          recordedErrorLog
        )
          .then((readContent) => {
            setCurrentFileContent(readContent);
          })
          .catch(() => {
            setCurrentFileContent(null);
            setCurrentFileIndex((old) => old + 1);
          });
      } else {
        setDone(true);
      }
    } catch (error) {
      recordedErrorLog("currentFileIndex useEffect failure: ", error);
    }
  }, [currentFileIndex]);

  const handleSave = (optionObject) => {
    try {
      const saveName = prompt("Name your option set:");
      if (saveName !== null) {
        const largestIdObj = filePresets.reduce((acc, curr) => {
          return acc.id > curr.id ? acc : curr;
        }, filePresets[0]);

        setFilePresets((old) => [
          ...old,
          {
            presetName: saveName,
            id: largestIdObj.id + 1,
            presetOptions: optionObject,
          },
        ]);
      }
    } catch (error) {
      recordedErrorLog("Save handler failure: ", error);
    }
  };

  const handleApply = (optionObject) => {
    try {
      const dataPoints = processFileWithOptions(
        currentFileContent,
        optionObject,
        recordedErrorLog
      );

      if (dataPoints !== null) {
        const maxX = dataPoints.reduce(function (max, p) {
          const x = p.x > 0 ? Math.ceil(p.x) : Math.floor(p.x);
          return x > max ? x : max;
        }, Math.ceil(dataPoints[0].x));

        const minX = dataPoints.reduce(function (min, p) {
          const x = p.x > 0 ? Math.ceil(p.x) : Math.floor(p.x);
          return x < min ? x : min;
        }, Math.floor(dataPoints[0].x));

        const file = filesToProcess[currentFileIndex];

        let filesToProcessCopy = deepCopyFiles(filesToProcess);

        filesToProcessCopy[currentFileIndex].solved = true;

        const fileData = {
          name: file.name,
          size: file.size,
          type: file.type,
          lastModified: file.lastModified,
          content: currentFileContent,
          dataPoints: dataPoints,
          npoints: dataPoints.length,
          edges: [{ min: "", max: "" }],
          dataRangeMin: minX,
          dataRangeMax: maxX,
          xUnit: optionObject.xUnit,
          ID: idForFile,
          options: optionObject,
        };
        setIdForFile((old) => old + 1);
        setFileID((old) => old + 1);

        setFilesToProcess(filesToProcessCopy);
        const updatedSolved = deepCopy(solvedList);
        updatedSolved[currentFileIndex] = fileData;
        setSolvedList(updatedSolved);
        const nextFreeIndex = getNextAvailableIndex(filesToProcessCopy);
        if (nextFreeIndex !== null) {
          setCurrentFileIndex(nextFreeIndex);
        } else {
          setDone(true);
        }
        setProcessedContent("");
      } else {
        setProcessedContent(dataPoints);
      }
    } catch (error) {
      recordedErrorLog("Apply handler failure: ", error);
    }
  };

  const handleApplyToAll = async (optionObject) => {
    try {
      const filesToProcessCopy = deepCopyFiles(filesToProcess);
      let newId = idForFile;
      let newFileID = fileID;
      let updatedSolved = deepCopy(solvedList);

      // Create an array of promises for unsolved files
      const processingPromises = filesToProcess.map(async (file, index) => {
        if (file.solved) {
          // If the file is already solved, we resolve immediately.
          return Promise.resolve();
        }
        try {
          const readContent = await readFile(
            file,
            generateWarningObject,
            setWarnings,
            setNewWarningCount,
            limitedToast,
            recordedErrorLog
          );
          const dataPoints = processFileWithOptions(
            readContent,
            optionObject,
            recordedErrorLog
          );

          if (dataPoints !== null) {
            const maxX = dataPoints.reduce((max, p) => {
              const x = p.x > 0 ? Math.ceil(p.x) : Math.floor(p.x);
              return x > max ? x : max;
            }, Math.ceil(dataPoints[0].x));
            const minX = dataPoints.reduce((min, p) => {
              const x = p.x > 0 ? Math.ceil(p.x) : Math.floor(p.x);
              return x < min ? x : min;
            }, Math.floor(dataPoints[0].x));

            filesToProcessCopy[index].solved = true;

            const fileData = {
              name: file.name,
              size: file.size,
              type: file.type,
              lastModified: file.lastModified,
              content: readContent,
              dataPoints: dataPoints,
              npoints: dataPoints.length,
              edges: [{ min: "", max: "" }],
              dataRangeMin: minX,
              dataRangeMax: maxX,
              xUnit: optionObject.xUnit,
              ID: newId,
              options: optionObject,
            };

            // Increment the counters.
            newId++;
            newFileID++;
            updatedSolved[index] = fileData;
          }
        } catch (error) {
          recordedErrorLog(`Error processing file at index ${index}: `, error);
        }
      });

      // Wait for all file processes to complete.
      await Promise.all(processingPromises);

      // Update the state with the new counters and processed files.
      setIdForFile(newId);
      setFileID(newFileID);
      setFilesToProcess(filesToProcessCopy);
      setSolvedList(updatedSolved);

      // Set the next file to process or mark processing as done.
      const nextFreeIndex = getNextAvailableIndex(filesToProcessCopy);
      if (nextFreeIndex !== null) {
        setCurrentFileIndex(nextFreeIndex);
      } else {
        setDone(true);
      }
      setProcessedContent("");
    } catch (error) {
      recordedErrorLog("Error applying options to all files: ", error);
    }
  };

  const handleCheck = (optionObject, skipClear) => {
    try {
      let dataPoints;
      if (
        hasProperty(filesToProcess[currentFileIndex], "solved") &&
        filesToProcess[currentFileIndex].solved
      ) {
        dataPoints = processFileWithOptions(
          solvedList[currentFileIndex].content,
          optionObject,
          recordedErrorLog
        );
      } else {
        dataPoints = processFileWithOptions(
          currentFileContent,
          optionObject,
          recordedErrorLog
        );
      }

      if (
        hasProperty(filesToProcess[currentFileIndex], "solved") &&
        filesToProcess[currentFileIndex].solved &&
        !skipClear
      ) {
        let filesToProcessCopy = deepCopyFiles(filesToProcess);

        filesToProcessCopy[currentFileIndex].solved = false;

        setFilesToProcess(filesToProcessCopy);

        const updatedSolved = deepCopy(solvedList);
        updatedSolved[currentFileIndex] = null;
        setSolvedList(updatedSolved);
        setDone(false);
      }

      setProcessedContent(dataPoints);
    } catch (error) {
      recordedErrorLog("Check handler failure: ", error);
    }
  };

  const handleFinishLoad = () => {
    solveProcessing(solvedList);
  };

  const handleCancelLoad = () => {
    // Check if there are any solved files
    const solvedFiles = solvedList.filter((file) => file !== null);

    if (solvedFiles.length > 0) {
      // If there are solved files, save them before closing
      solveProcessing(solvedFiles);
    } else {
      // If no solved files, just cancel
      cancelProcessing();
    }
  };

  const handleButtonClick = (type) => {
    if (type === "file") {
      fileInputRef.current.click();
    } else if (type === "folder") {
      folderInputRef.current.click();
    }
  };

  async function handleFileChange(event) {
    try {
      const files = Array.from(event.target.files);
      const supportedExtensions = ["txt", "dat", "TXT", "DAT"];

      const filteredFiles = files.filter((file) => {
        const fileExtension = file.name.split(".").pop();
        return supportedExtensions.includes(fileExtension);
      });

      const unsupportedFiles = files.filter(
        (file) => !filteredFiles.includes(file)
      );

      if (unsupportedFiles.length > 0) {
        const unsupportedFileNames = unsupportedFiles
          .map((file) => file.name)
          .join(", ");
        alert(
          `The following files have unsupported formats and will not be processed: ${unsupportedFileNames}`
        );
      }

      const queueLength = filesToProcess.length;
      // Append to existing lists instead of replacing them
      setFilesToProcess((prevFiles) => [...prevFiles, ...filteredFiles]);
      setSolvedList((prevSolved) => [
        ...prevSolved,
        ...Array(filteredFiles.length).fill(null),
      ]);

      // Load the first file's content immediately
      if (queueLength === 0 && filteredFiles.length > 0) {
        const firstFile = filteredFiles[0]; // Use 0 directly instead of currentFileIndex since we just set it
        readFile(
          firstFile,
          generateWarningObject,
          setWarnings,
          setNewWarningCount,
          limitedToast,
          recordedErrorLog
        )
          .then((readContent) => {
            setCurrentFileContent(readContent);
          })
          .catch(() => {
            setCurrentFileContent(null);
            setCurrentFileIndex(1); // Move to next file if this one fails
          });
      }
    } catch (error) {
      recordedErrorLog("File change handler failure: ", error);
    }
  }

  return (
    <div className="fileProcessWindow" id="fileProcessWindow">
      <div className="deleteGraphButtonContainer">
        <CloseButton onClose={handleCancelLoad} />
        {/* <HighlightOffIcon 
          className="deleteGraphIcon"
          onClick={handleCancelLoad}
        /> */}
      </div>
      <div className="leftSide">
        <div className="leftTitle">File queue:</div>
        <div className="leftContent">
          <FileList
            selectedFileIndex={currentFileIndex}
            filesToProcess={filesToProcess}
            setSelectedFile={setCurrentFileIndex}
          />
          <div className="buttonArea">
            <CustomButton
              text="Files"
              extraClassnames={["noMargin", "dataManagerButton"]}
              handleClick={() => handleButtonClick("file")}
              id="file-upload-button"
            />
            <CustomButton
              text="Folder"
              extraClassnames={["noMargin", "dataManagerButton"]}
              handleClick={() => handleButtonClick("folder")}
              id="folder-upload-button"
            />
            {/* <Button
              variant="contained"
              className="finishButton"
              onClick={() => handleButtonClick("file")}
              id="file-upload-button"
            >
              Files
            </Button>
            <Button
              variant="contained"
              className="finishButton"
              onClick={() => handleButtonClick("folder")}
              id="folder-upload-button"
            >
              Folder
            </Button> */}
          </div>
          <input
            ref={fileInputRef}
            id="file-upload-input"
            type="file"
            multiple
            accept=".txt,.dat"
            onChange={handleFileChange}
            style={{ display: "none" }}
          />
          <input
            ref={folderInputRef}
            type="file"
            multiple
            webkitdirectory="true"
            accept=".txt,.dat"
            onChange={handleFileChange}
            style={{ display: "none" }}
          />
        </div>
      </div>
      <div className="rightSide">
        <RawAndSolved
          rawFileContent={currentFileContent}
          processedContent={processedContent}
        />
        <div className="divider" />
        <ProcessingOptions
          save={handleSave}
          apply={handleApply}
          applyAll={handleApplyToAll}
          check={handleCheck}
          modalId={modalId}
          fileContent={currentFileContent}
          processedFileDetails={solvedList[currentFileIndex]}
          processedContent={processedContent}
          cancel={handleCancelLoad}
        />
      </div>
    </div>
  );
}

export default FileProcessingWindow;
