import React from "react";
import ManualContent from "../../components/ManualContent";

const BetaProgram = () => {
  return (
    <ManualContent title="Speqqle Beta Program">
      <p>
        The Speqqle Beta Program allows early adopters to{" "}
        <strong>test new features</strong> and provide valuable feedback to
        improve the software.
      </p>

      <h2>What is the Beta Program?</h2>
      <p>The Beta Program is an early access initiative where any user can:</p>
      <ul>
        <li>
          Gain access to <strong>pre-release versions</strong> of new dedicated
          packages before they are officially launched.
        </li>
        <li>Provide feedback that helps shape the development process.</li>
      </ul>

      <h2>How to Join?</h2>
      <p>To participate in the Beta Program, follow these steps:</p>
      <ol>
        <li>
          Sign up for our <strong>newsletter</strong>—we announce BETA releases
          through email updates.
        </li>
        <li>
          When a BETA package becomes available, it will appear in the list of
          available packages with a <strong>BETA</strong> label.
        </li>
        <li>Sign up for the BETA package of your choice.</li>
        <li>
          <strong>Act quickly!</strong> Participation is limited to a small
          number of testers.
        </li>
        <li>
          Once you join, we expect you to actively use the features and provide
          feedback. Inactive participants may be removed from the Beta Program.
        </li>
      </ol>

      <h2>Providing Feedback</h2>
      <p>
        Your feedback is crucial for refining Speqqle. You can share your
        insights via:
      </p>
      <ul>
        <li>
          The built-in <strong>Feedback Form</strong> in the software
          (accessible on the left side of your profile page).
        </li>
        <li>
          Emailing our support team at{" "}
          <a href="mailto:contact@speqqle.com">contact@speqqle.com</a>.
        </li>
        <li>
          Participating in <strong>Beta tester surveys</strong> to provide
          structured feedback.
        </li>
      </ul>

      <h2>Important Considerations</h2>
      <p>As Beta releases are experimental, please note:</p>
      <ul>
        <li>
          We <strong>cannot guarantee</strong> backwards compatibility.
        </li>
        <li>
          We <strong>cannot guarantee</strong> that Beta versions will always be
          available.
        </li>
        <li>
          We <strong>cannot guarantee</strong> the scientific accuracy or final
          implementation of Beta features.
        </li>
      </ul>

      <h2>Leaving the Beta Program</h2>
      <p>You can opt out at any time by:</p>
      <ul>
        <li>
          Deactivating the BETA package in your profile settings. We appreciate
          if you <strong>share your reasons</strong> for leaving, as this helps
          us improve the program.
        </li>
      </ul>

      <h2>What Happens When a Beta Release Ends?</h2>
      <p>
        When a Beta release is discontinued, there are two possible outcomes:
      </p>
      <ul>
        <li>
          <strong>Official Launch:</strong> If the feature is successfully
          validated, it will be integrated into Speqqle. Beta testers who
          provided feedback will receive a special voucher.
        </li>
        <li>
          <strong>Discontinuation:</strong> If the feature does not meet
          expectations, it may not be included in future releases.
        </li>
      </ul>

      <p>
        Thank you for being a part of the Speqqle Beta Program! Your insights
        help us build a better platform. 🚀
      </p>
    </ManualContent>
  );
};

export default BetaProgram;
