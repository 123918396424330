import React from "react";
import ManualContent from "../../components/ManualContent";
import experimentTabImage from "../../images/Experiment_tabn.jpg";
import newModelSelectionImage from "../../images/New_model_selection.jpg";
import modelsAddedImage from "../../images/Models_added.jpg";

const NewModels = () => {
  return (
    <ManualContent title="Define Your Experiment by Adding New Models">
      <p>
        In Speqqle, the <strong>Experiments tab</strong> allows you to add,
        load, and manage theoretical models that describe your spectroscopic
        experiment. These models define how your sample interacts with the probe
        signal and are essential for fitting experimental data or running
        simulations.
      </p>

      <h2>How to Add a New Experiment Model</h2>
      <p>
        You can add new models based on the type of experiment you plan to
        conduct:
      </p>

      <ol>
        <li>
          Open the <strong>Experiments</strong> tab.
        </li>
        <li>
          Click the <strong>&quot;New Model&quot;</strong> button.
        </li>
        <li>A list of available Experiments will appear.</li>
        <li>Hover over an Experiment name to view a brief description.</li>
        <li>
          Select the experiment that best matches your data or intended
          simulation.
        </li>
        <li>Click the Experiment name to add it to your workspace.</li>
      </ol>

      <div className="image-row">
        <div className="image-container">
          <img
            src={experimentTabImage}
            alt="Opening Experiments Tab"
            className="workflow-image"
          />
          <p className="image-caption">
            <strong>Step 1:</strong> Open the <strong>Experiments</strong> tab.
          </p>
        </div>
        <div className="image-container">
          <img
            src={newModelSelectionImage}
            alt="Selecting a New Model"
            className="workflow-image"
          />
          <p className="image-caption">
            <strong>Step 2:</strong> Click <strong>New Model</strong> and choose
            from the available Experiments.
          </p>
        </div>
        <div className="image-container">
          <img
            src={modelsAddedImage}
            alt="Model Added to Experiment"
            className="workflow-image"
          />
          <p className="image-caption">
            <strong>Step 3:</strong> The selected Experiment has been added to
            your experiment window.
          </p>
        </div>
      </div>

      <h2>How Many Experiments Can I Add?</h2>
      <p>
        You can add or load as many Experiments as needed. There is no strict
        limit, but adding too many may slow down the analysis.
      </p>

      <h2>
        Why Do Multiple Models Appear When I Load the R/T/A/Ellips Experiment?
      </h2>
      <p>
        When you select the <strong>R/T/A/Ellips</strong> Experiment, multiple
        models appear in your experiment window. This is expected, as the
        R/T/A/Ellips Experiment is designed to simulate or fit{" "}
        <strong>
          Reflection (R), Transmission (T), Absorption (A), or Ellipsometry
        </strong>{" "}
        for bulk materials, thin films, or multilayer samples.
      </p>

      <p>The different models that appear correspond to:</p>

      <ul>
        <li>
          <strong>Configuration Model (&quot;R/T/Ellips_#&quot;):</strong>{" "}
          Defines experimental parameters such as layer structure, thickness,
          and conditions like angle of incidence and polarization.
        </li>
        <li>
          <strong>Material Model (&quot;material&quot;):</strong> Contains the
          optical response parameters for one of the materials in the sample.
          This model appears slightly indented, indicating that it is a{" "}
          <strong>submodel</strong> of the main experiment.
        </li>
        <li>
          <strong>Material Model (&quot;material_#&quot;):</strong> Another
          submodel representing the optical properties of the next material in
          the stack.
        </li>
      </ul>

      <h3>Modifying Experiment Models</h3>
      <p>You can adjust these models in the experiment window by:</p>

      <ul>
        <li>
          <strong>Removing</strong> a material layer (submodel) if it is not
          needed.
        </li>
        <li>
          <strong>Adding</strong> additional material layers (submodels) to
          represent more complex sample structures.
        </li>
        <li>
          <strong>Deleting the main model</strong>—this will also remove all
          linked submodels.
        </li>
      </ul>

      <p>
        For detailed instructions on how to adjust, remove, rename, or manage
        models in the Experiment window, visit the{" "}
        <a href="managingmodels">Managing Models</a> section.
      </p>

      <h2>
        Why Can&apos;t I Plot Reflectivity After Loading a Material Model?
      </h2>
      <p>
        Material models only define the{" "}
        <strong>optical response of a sample</strong>—such as the dielectric
        function, refractive index, or conductivity. They do not contain the
        configuration or experimental parameters needed for reflectivity
        simulations.
      </p>

      <p>
        If you want to <strong>simulate or fit reflectivity</strong> for a bulk
        sample or thin film:
      </p>

      <ul>
        <li>
          Select the <strong>R/T/A/Ellips</strong> model instead of a standalone
          material model.
        </li>
        <li>
          Remove all but one layer in the configuration settings (for a bulk
          sample).
        </li>
        <li>
          Adjust configuration parameters to set the{" "}
          <strong>angle of incidence</strong> and <strong>polarization</strong>.
        </li>
      </ul>

      <p>
        By following these steps, you will be able to generate reflectivity
        plots and fit experimental reflectivity data.
      </p>

      <h2>How Do I Know If the Experiment I Need Is Available?</h2>
      <p>You can find an overview of available Experiments in Speqqle here:</p>

      <ul>
        <li>
          <a href="modellist">Speqqle Experimental Model List Overview</a>
        </li>
      </ul>

      <h2>What If the Experiment You Need Is Not Listed?</h2>
      <p>
        If you do not find the Experiment you need in the available list, follow
        these steps:
      </p>

      <h3>Check Other Packages</h3>
      <p>
        Some Experiments are included only in specific Speqqle packages. To
        check if an experiment is available in another package:
      </p>
      <ul>
        <li>
          Go to the <a href="../packages/overview">Packages</a> section.
        </li>
        <li>Review the list of experiments available in each package.</li>
        <li>
          If the experiment you need is listed in another package, activate that
          package to gain access.
        </li>
      </ul>

      <h3>Request a New Experiment</h3>
      <p>
        If the experiment is not available in any existing package, you can
        request it by:
      </p>
      <ul>
        <li>
          Using the <strong>Feedback Form</strong> in your{" "}
          <a href="../profile">Profile</a> section.
        </li>
        <li>
          Emailing our support team with details of your required experiment.
        </li>
      </ul>

      <p>
        We continuously expand Speqqle&apos;s experiment library based on user
        needs, and your feedback helps shape future updates.
      </p>

      <h2>Next Steps</h2>
      <p>Now that you&apos;ve added your experiment, you may want to:</p>

      <ul>
        <li>
          <a href="loadmodels">Load a saved experiment or submodel</a>
        </li>
        <li>
          <a href="modellist">View the latest list of available experiments</a>
        </li>
        <li>
          <a href="managingmodels">Rename, link, or remove submodels</a>
        </li>
      </ul>
    </ManualContent>
  );
};

export default NewModels;
