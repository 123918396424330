import React, { useEffect } from "react";
import PropTypes from "prop-types";
import "../styles/manual-content.scss";

const ManualContent = ({ title, children }) => {
  useEffect(() => {
    // Update document title when component mounts or title changes
    document.title = `${title} - Speqqle Manual`;
  }, [title]);

  return (
    <div className="subsection-content">
      <h1>{title}</h1>
      {children}
    </div>
  );
};

ManualContent.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default ManualContent;
