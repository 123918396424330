import React from "react";
import ManualContent from "../../components/ManualContent";

const ModelList = () => {
  return (
    <ManualContent title="List of Available Experiments in Speqqle Base">
      <p>
        Speqqle Base includes a selection of predefined{" "}
        <strong>Experiments</strong> and <strong>Submodels</strong>. These can
        be found in the list that opens when you click{" "}
        <strong>&quot;Add New Model&quot;</strong> in the Experiments tab. The list is
        divided into two categories:
      </p>

      <ul>
        <li>
          <strong>Entire Experiments:</strong> These contain all relevant models
          for a specific type of analysis (e.g., Raman or Reflectivity
          experiments).
        </li>
        <li>
          <strong>Submodels:</strong> These are individual components, such as
          &quot;Material&quot;, that represent only a material&apos;s response. Submodels are
          not complete Experiments on their own but are used within an
          Experiment.
        </li>
      </ul>

      <h2 id="submodels">Included Submodels</h2>
      <p>
        In this version of Speqqle Base, the following submodel is available:
      </p>

      <ol>
        <li>
          <strong>&quot;Material&quot;:</strong> Adds a submodel to the Experiments tab
          that represents the response of a single material.
        </li>
      </ol>

      <h2 id="Experiments">Included Experiments</h2>
      <p>The following Experiments are currently included in Speqqle Base:</p>

      <ol>
        <li>
          <strong>&quot;R/T/A/Ellips&quot;:</strong>
          This Experiment can fit and simulate{" "}
          <strong>Reflectivity, Transmission, Absorption,</strong>{" "}
          and <strong>Ellipsometry</strong> for bulk, thin films, and multilayer
          samples.
        </li>
        <li>
          <strong>&quot;Raman&quot;:</strong>
          This Experiment can fit and simulate the{" "}
          <strong>Raman response</strong> of bulk samples.
        </li>
      </ol>

      <p>
        For a detailed description of each Experiment, their parameters, and how
        to use their outputs, visit the{" "}
        <a href="../packages/overview">Packages Overview</a>{" "}
        and click on the link for the relevant Experiment.
      </p>

      <h2>What If the Experiment You Need Is Not Listed?</h2>
      <p>
        If you do not find the Experiment you need in the available list, follow
        these steps:
      </p>

      <h3>Check Other Packages</h3>
      <p>
        Some Experiments are included only in specific Speqqle packages. To
        check if an experiment is available in another package:
      </p>
      <ul>
        <li>
          Go to the <a href="../packages/overview">Packages</a> section.
        </li>
        <li>Review the list of experiments available in each package.</li>
        <li>
          If the experiment you need is listed in another package, activate that
          package to gain access.
        </li>
      </ul>

      <h3>Request a New Experiment</h3>
      <p>
        If the experiment is not available in any existing package, you can
        request it by:
      </p>
      <ul>
        <li>
          Using the <strong>Feedback Form</strong> in your{" "}
          <a href="../profile">Profile</a> section.
        </li>
        <li>
          Emailing our support team with details of your required experiment.
        </li>
      </ul>

      <p>
        We continuously expand Speqqle&apos;s experiment library based on user needs,
        and your feedback helps shape future updates.
      </p>

      <h2>Next Steps</h2>
      <p>Now that you&apos;ve added your experiment, you may want to:</p>

      <ul>
        <li>
          <a href="loadmodels">Load a saved experiment or submodel</a>
        </li>
        <li>
          <a href="modellist">View the latest list of available experiments</a>
        </li>
        <li>
          <a href="../parameters/overview">
            Continue with manipulating the parameters of the Experiments
          </a>
        </li>
        <li>
          <a href="../graphs/overview">
            Continue by plotting your data and output of the models in a graph.
          </a>
        </li>
      </ul>
    </ManualContent>
  );
};

export default ModelList;
