import React from "react";
import ManualContent from "../../components/ManualContent";

const Fixing = () => {
  return (
    <ManualContent title="Fixing and Freeing Parameters">
      <p>
        When performing a fit in Speqqle, you can control whether parameters are{" "}
        <strong>fixed</strong> (held constant) or <strong>free</strong> (allowed
        to vary). Fixing parameters can be useful when you have known values
        that should not change, while freeing parameters allows the algorithm to
        optimize them for the best fit.
      </p>

      <h2>Why Fix Parameters?</h2>

      <p>Fixing parameters is useful in several cases:</p>

      <ul>
        <li>
          <strong>Known values:</strong> Some parameters, such as experimental
          conditions, may be well-determined and should remain constant.
        </li>
        <li>
          <strong>Reducing complexity:</strong> Fixing parameters reduces the
          number of variables, which can help the fit converge more efficiently.
        </li>
        <li>
          <strong>Avoiding overfitting:</strong> When too many parameters are
          free, the model may fit noise instead of actual trends in the data.
        </li>
      </ul>

      <h2>How to Fix or Free a Parameter</h2>

      <p>
        Parameters can be fixed or freed using the{" "}
        <strong>Parameter Sticky Window</strong>. To modify a parameter&apos;s
        status:
      </p>

      <ol>
        <li>
          Click on a parameter in the <strong>Experiments</strong> tab to open
          the Sticky Window.
        </li>
        <li>
          Locate the <strong>Fixed</strong> checkbox.
        </li>
        <li>
          Check the box to <strong>fix</strong> the parameter (it will no longer
          change during a fit).
        </li>
        <li>
          Uncheck the box to <strong>free</strong> the parameter (allowing it to
          be adjusted by the fit).
        </li>
      </ol>

      <p>
        Fixed parameters appear <strong>bold</strong> in the parameter table,
        making it easy to identify which values remain constant.
      </p>
      <p>
        <strong>Note:</strong> Some parameters are fixed by default and cannot
        be unfixed. These parameters can not be fitted but can still be changed
        by hand.
      </p>

      <h2>Effects of Fixing Parameters</h2>

      <p>
        Fixing parameters has both advantages and disadvantages, depending on
        the scenario.
      </p>

      <h3>✅ Benefits of Fixing Parameters</h3>

      <ul>
        <li>
          <strong>Stabilizes the fit:</strong> Helps prevent divergence by
          keeping certain values constant.
        </li>
        <li>
          <strong>Speeds up convergence:</strong> Reduces the number of free
          variables, making the fit process faster and more reliable.
        </li>
        <li>
          <strong>Preserves known values:</strong> Ensures that experimentally
          determined parameters remain unchanged.
        </li>
      </ul>

      <h3>⚠️ Risks and Limitations</h3>

      <ul>
        <li>
          <strong>Can lead to biased fits:</strong> If a parameter is
          incorrectly fixed, the model may not find the true optimal solution.
        </li>
        <li>
          <strong>Risk of misleading results:</strong> The fit may compensate
          for a fixed parameter by adjusting others in unrealistic ways.
        </li>
        <li>
          <strong>May prevent proper optimization:</strong> If a parameter
          should vary but is mistakenly fixed, the model may fail to adapt to
          the data.
        </li>
      </ul>

      <h2>Best Practices for Fixing Parameters</h2>

      <p>To effectively manage fixed and free parameters:</p>

      <ul>
        <li>
          <strong>Fix parameters only when necessary:</strong>
          Keep parameters free unless you have strong evidence that they should
          remain constant.
        </li>
        <li>
          <strong>Check parameter interactions:</strong>A fixed parameter can
          influence the optimization of other parameters.
        </li>
        <li>
          <strong>Test different scenarios:</strong>
          Run fits with and without fixed parameters to compare results.
        </li>
        <li>
          <strong>Monitor residuals:</strong>
          If residual errors are unusually large, reconsider which parameters
          are fixed.
        </li>
      </ul>

      <h2>Next Steps</h2>
      <p>
        Now that you understand how to fix and free parameters, explore other
        related topics:
      </p>

      <ul>
        <li>
          <a href="constraints">Setting Parameter Constraints</a>: Learn how to
          apply hard limits to prevent unrealistic values.
        </li>
        <li>
          <a href="multilayer">
            Add and Remove layers in a multilayer structure
          </a>
          : adapt it model to your experimental configuration.
        </li>
        <li>
          <a href="paramcolors">Understanding Parameter Color Codes</a>:
          Identify parameters that require attention using color-coded warnings.
        </li>
      </ul>
    </ManualContent>
  );
};

export default Fixing;
