import React, { createContext, useEffect, useState, useRef } from "react";
import units from "../utils/units";
import { DEFAULT_VAL_GROUPS } from "../utils/constants";

export const DashboardContext = createContext();

const isProduction =
  window.location.hostname === "lab.speqqle.com" ||
  window.location.hostname === "app.speqqle.com";

const DashboardContextProvider = (props) => {
  //saving loaded files into context to not lose them on ToggleButton close
  const [fileLoadData, setFileLoadData] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [fileID, setFileID] = useState(1);
  // const [graphs, setGraphs] = useState([]);

  //setting default values of the ZeroCutParams
  const [zeroCutParams, setZeroCutParams] = useState({
    min: -10,
    max: 10,
    auto: true,
  });

  //setting the default xAxisUnit to the first unit of the list of units imported from src/utils/units.js (should be cm-1)
  const [xAxisUnit, setXAxisUnit] = useState(units[0]);

  //setting default state of the otherParams to false ("only fit stokes" checkbox)
  const [otherParams, setOtherParams] = useState({
    onlyPos: false,
    analysisChecked: false,
  });

  const [minDataRangeVal, setMinDataRangeVal] = useState("");
  const [maxDataRangeVal, setMaxDataRangeVal] = useState("");
  const [abbrDic, setAbbrDic] = useState({});
  const [namingDic, setNamingDic] = useState({});
  const [modelNextID, setModelNextID] = useState(1);
  const [modelData, setModelData] = useState([]);
  const [allLocalModels, setAllLocalModels] = useState([]);
  const [warnings, setWarnings] = useState([]);
  const [newWarningCount, setNewWarningCount] = useState(0);

  // This variable keeps an old model state before fit/autofit was done.
  // If user decides that fit/autofit was incorrect, he can the revert back to old
  // model state. This variable is needed for that.
  const [undoModels, setUndoModels] = useState([]);

  // With this variable tracking we will know when to clean up models from unused curves
  const [cleanupNeeded, setCleanupNeeded] = useState(false);

  const [valueGroups, setValueGroups] = useState(DEFAULT_VAL_GROUPS);

  const [sectionsStatus, setSectionsStatus] = useState({
    paramOpen: false,
    fitOpen: false,
  });
  const [showLeftPanel, setShowLeftPanel] = useState(true);

  // We use different variables to track clicks that happen in the graph legend
  // because we have separate logic for clicks on models and files
  const [callFromGraph, setCallFromGraph] = useState(false);
  const [fileCallFromGraph, setFileCallFromGraph] = useState(false);

  const [filePresets, setFilePresets] = useState([
    {
      presetName: "Default",
      id: 0,
      presetOptions: {
        colCount: 2,
        xUnit: units[0],
        separator: "tab",
        autoRemove: true,
        manualLineStart: 0,
        columns: [
          "x",
          "y",
          "ignore",
          "ignore",
          "ignore",
          "ignore",
          "ignore",
          "ignore",
          "ignore",
          "ignore",
        ],
        shift: false,
        xShift: "none",
        yShift: "none",
        xShiftVal: "",
        yShiftVal: "",
      },
    },
  ]);

  const [selectedPreset, setSelectedPreset] = useState(null);
  const [fileToChange, setFileToChange] = useState(null);
  const [expandedWindows, setExpandedWindows] = useState({});
  const [fitIteration, setFitIteration] = useState(null);
  const [loopIteration, setLoopIteration] = useState(null);
  // const [requestedVdf, setRequestedVdf] = useState([]);

  // Fit options:
  const [maxIteration, setMaxIteration] = useState(20);
  const [iterationRefreshCount, setIterationRefreshCount] = useState("");
  const [maxRunTime, setMaxRunTime] = useState(100);
  const [chiVal, setChiVal] = useState({
    n: 3,
    val: 0.001,
  });
  const [totalActiveParams, setTotalActiveParams] = useState(0);
  const [demoOpen, setDemoOpen] = useState(false);
  const [dataOpen, setDataOpen] = useState(false);
  const [modelsOpen, setModelsOpen] = useState(false);
  const [parametersOpen, setParametersOpen] = useState(false);
  const [fitOpen, setFitOpen] = useState(false);
  const [resultsOpen, setResultsOpen] = useState(false);
  const [outputsOpen, setOutputsOpen] = useState(true);
  const [loopOpen, setLoopOpen] = useState(false);
  const [chi2ReqAllowed, setChi2ReqAllowed] = useState(true);
  const chi2QueRef = useRef({});
  const [gaugeDict, setGaugeDict] = useState({});
  const [addGraphModalOpen, setAddGraphModalOpen] = useState(false);
  const [loopModelFilesCustomName, setLoopModelFilesCustomName] = useState("");
  const [loopCurveFilesCustomName, setLoopCurveFilesCustomName] = useState("");
  const [listOfLoopModelsToSave, setListOfLoopModelsToSave] = useState([]);
  const [listOfLoopCurvesToSave, setListOfLoopCurvesToSave] = useState([]);
  const [trackedParameters, setTrackedParameters] = useState([]);
  const [pauseOnNextLoop, setPauseOnNextLoop] = useState(false);
  const [loopPaused, setLoopPaused] = useState(false);
  const [fitUndoAvailable, setFitUndoAvailable] = useState(false);
  const [stickyParamPosition, setStickyParamPosition] = useState({
    x: window.innerWidth - 380,
    y: window.innerHeight - 450,
  });

  // DEMO DEMO DEMO VVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV

  const [button1Active, setButton1Active] = useState(false);
  const [button2Active, setButton2Active] = useState(false);
  const [button3Active, setButton3Active] = useState(false);

  // DEMO DEMO DEMO ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^

  useEffect(() => {
    const handleResize = () => {
      const newX = Math.max(0, window.innerWidth - 380);
      const newY = Math.max(0, window.innerHeight - 450);
      setStickyParamPosition({ x: newX, y: newY });
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function setDashboardVariables(variables) {
    const {
      fileLoadData,
      zeroCutParams,
      xAxisUnit,
      otherParams,
      modelData,
      modelNextID,
      minDataRangeVal,
      maxDataRangeVal,
      abbrDic,
      namingDic,
      warnings,
      allLocalModels,
      valueGroups,
      uploadedFiles,
      fileID,
      newWarningCount,
      cleanupNeeded,
      filePresets,
      selectedPreset,
      maxIteration,
      iterationRefreshCount,
      maxRunTime,
      chiVal,
      totalActiveParams,
      gaugeDict,
    } = variables;

    let checkedFileID = fileID;

    for (let i = 0; i < uploadedFiles.length; i++) {
      const file = uploadedFiles[i];

      if (file.ID >= checkedFileID) {
        checkedFileID = file.ID + 1;
      }
    }

    if (!isProduction && checkedFileID !== fileID) {
      console.warn(
        "THERE IS A MISSMATCH OF ID IN SESSION FILE VERSUS TOP ID IN FILES. ORIGINAL ID IN SESSION: ",
        fileID
      );
    }

    setFileLoadData(fileLoadData);
    setUploadedFiles(uploadedFiles);
    setFileID(checkedFileID);
    setZeroCutParams(zeroCutParams);
    setXAxisUnit(xAxisUnit);
    setOtherParams(otherParams);
    setMinDataRangeVal(minDataRangeVal);
    setMaxDataRangeVal(maxDataRangeVal);
    setAbbrDic(abbrDic);
    setNamingDic(namingDic);
    setModelNextID(modelNextID);
    setModelData(modelData);
    setAllLocalModels(allLocalModels);
    setWarnings(warnings);
    setNewWarningCount(newWarningCount);
    setCleanupNeeded(cleanupNeeded);
    setValueGroups(valueGroups);
    setFilePresets(filePresets);
    setSelectedPreset(selectedPreset);
    setMaxIteration(maxIteration);
    setIterationRefreshCount(iterationRefreshCount);
    setMaxRunTime(maxRunTime);
    setChiVal(chiVal);
    setTotalActiveParams(totalActiveParams);
    setGaugeDict(gaugeDict);
  }

  const resetDashboardContext = () => {
    setFileLoadData([]);
    setUploadedFiles([]);
    setFileID(1);
    // setGraphs([]); // Uncomment if you need to reset this state as well

    setZeroCutParams({
      min: -10,
      max: 10,
      auto: true,
    });

    setXAxisUnit(units[0]);

    setOtherParams({
      onlyPos: false,
      analysisChecked: false,
    });

    setMinDataRangeVal("");
    setMaxDataRangeVal("");
    setAbbrDic({});
    setNamingDic({});
    setModelNextID(1);
    setModelData([]);
    setAllLocalModels([]);
    setWarnings([]);
    setNewWarningCount(0);
    setUndoModels([]);
    setCleanupNeeded(false);

    setValueGroups(DEFAULT_VAL_GROUPS);

    // DEMO states
    setButton1Active(false);
    setButton2Active(false);
    setButton3Active(false);

    setSectionsStatus({
      paramOpen: false,
      fitOpen: false,
    });
    setShowLeftPanel(true);
    setCallFromGraph(false);
    setFileCallFromGraph(false);
    setFilePresets([
      {
        presetName: "Default",
        id: 0,
        presetOptions: {
          colCount: 2,
          xUnit: units[0],
          separator: "tab",
          autoRemove: true,
          manualLineStart: 0,
          col1: "x",
          col2: "y",
          col3: "e",
        },
      },
    ]);
    setSelectedPreset(null);

    setMaxIteration(20);
    setIterationRefreshCount("");
    setMaxRunTime(100);
    setChiVal({
      n: 3,
      val: 0.001,
    });
    setFileToChange(null);
    setExpandedWindows({});
    setFitIteration(null);
    setTotalActiveParams(0);
    setGaugeDict({});
    setDemoOpen(false);
    setDataOpen(false);
    setModelsOpen(false);
    setParametersOpen(false);
    setFitOpen(false);
    setResultsOpen(false);
    setOutputsOpen(true);
    setLoopOpen(false);
    setChi2ReqAllowed(true);
    chi2QueRef.current = {};
    setGaugeDict({});
    setAddGraphModalOpen(false);
    setLoopModelFilesCustomName("");
    setLoopCurveFilesCustomName("");
    setLoopIteration(null);
    setListOfLoopCurvesToSave([]);
    setListOfLoopModelsToSave([]);
    setTrackedParameters([]);
    setPauseOnNextLoop(false);
    setLoopPaused(false);
    setFitUndoAvailable(false);
  };

  useEffect(() => {
    const fileLoadDataSession = JSON.parse(
      window.sessionStorage.getItem("fileLoadData")
    );
    const zeroCutParamsSession = JSON.parse(
      window.sessionStorage.getItem("zeroCutParams")
    );
    const xAxisUnitSession = JSON.parse(
      window.sessionStorage.getItem("xAxisUnit")
    );
    const otherParamsSession = JSON.parse(
      window.sessionStorage.getItem("otherParams")
    );
    const modelDataSession = JSON.parse(
      window.sessionStorage.getItem("modelData")
    );
    const modelNextIDSession = JSON.parse(
      window.sessionStorage.getItem("modelNextID")
    );
    const minDataRangeValSession = JSON.parse(
      window.sessionStorage.getItem("minDataRangeVal")
    );
    const maxDataRangeValSession = JSON.parse(
      window.sessionStorage.getItem("maxDataRangeVal")
    );
    const abbrDicSession = JSON.parse(window.sessionStorage.getItem("abbrDic"));
    const namingDicSession = JSON.parse(
      window.sessionStorage.getItem("namingDic")
    );
    const warningsSession = JSON.parse(
      window.sessionStorage.getItem("warnings")
    );
    const allLocalModelsSession = JSON.parse(
      window.sessionStorage.getItem("allLocalModels")
    );
    const valueGroupsSession = JSON.parse(
      window.sessionStorage.getItem("valueGroups")
    );
    const uploadedFilesSession = JSON.parse(
      window.sessionStorage.getItem("uploadedFiles")
    );
    // const graphsSession = JSON.parse(window.sessionStorage.getItem("graphs"));
    const fileIDSession = JSON.parse(window.sessionStorage.getItem("fileID"));
    const newWarningCountSession = JSON.parse(
      window.sessionStorage.getItem("newWarningCount")
    );
    const cleanupNeededSession = JSON.parse(
      window.sessionStorage.getItem("cleanupNeeded")
    );
    const filePresetsSession = JSON.parse(
      window.sessionStorage.getItem("filePresets")
    );
    const selectedPresetSession = JSON.parse(
      window.sessionStorage.getItem("selectedPreset")
    );
    const maxIterationSession = JSON.parse(
      window.sessionStorage.getItem("maxIteration")
    );
    const iterationRefreshCountSession = JSON.parse(
      window.sessionStorage.getItem("iterationRefreshCount")
    );
    const maxRunTimeSession = JSON.parse(
      window.sessionStorage.getItem("maxRunTime")
    );
    const chiValSession = JSON.parse(window.sessionStorage.getItem("chiVal"));
    const totalActiveParamsSession = JSON.parse(
      window.sessionStorage.getItem("totalActiveParams")
    );
    const gaugeDictSession = JSON.parse(
      window.sessionStorage.getItem("gaugeDict")
    );
    const trackedParametersSession = JSON.parse(
      window.sessionStorage.getItem("trackedParameters")
    );

    if (fileLoadDataSession != null) {
      setFileLoadData(fileLoadDataSession);
    }
    if (zeroCutParamsSession != null) {
      setZeroCutParams(zeroCutParamsSession);
    }
    if (xAxisUnitSession != null) {
      setXAxisUnit(xAxisUnitSession);
    }
    if (otherParamsSession != null) {
      setOtherParams(otherParamsSession);
    }
    if (modelDataSession != null) {
      setModelData(modelDataSession);
    }
    if (modelNextIDSession != null) {
      setModelNextID(modelNextIDSession);
    }
    if (minDataRangeValSession != null) {
      setMinDataRangeVal(minDataRangeValSession);
    }
    if (maxDataRangeValSession != null) {
      setMaxDataRangeVal(maxDataRangeValSession);
    }
    if (abbrDicSession != null) {
      setAbbrDic(abbrDicSession);
    }
    if (namingDicSession != null) {
      setNamingDic(namingDicSession);
    }
    if (warningsSession != null) {
      setWarnings(warningsSession);
    }
    if (allLocalModelsSession != null) {
      setAllLocalModels(allLocalModelsSession);
    }
    if (valueGroupsSession != null) {
      setValueGroups(valueGroupsSession);
    }
    if (uploadedFilesSession != null) {
      setUploadedFiles(uploadedFilesSession);
    }
    // if (graphsSession != null) {
    //   setGraphs(graphsSession);
    // }
    if (fileIDSession != null) {
      setFileID(fileIDSession);
    }
    if (newWarningCountSession != null) {
      setNewWarningCount(newWarningCountSession);
    }
    if (cleanupNeededSession != null) {
      setCleanupNeeded(cleanupNeededSession);
    }
    if (filePresetsSession != null) {
      setFilePresets(filePresetsSession);
    }
    if (selectedPresetSession != null) {
      setSelectedPreset(selectedPresetSession);
    }
    if (maxIterationSession != null) {
      setMaxIteration(maxIterationSession);
    }
    if (iterationRefreshCountSession != null) {
      setIterationRefreshCount(iterationRefreshCountSession);
    }
    if (maxRunTimeSession != null) {
      setMaxRunTime(maxRunTimeSession);
    }
    if (chiValSession != null) {
      setChiVal(chiValSession);
    }
    if (totalActiveParamsSession != null) {
      setTotalActiveParams(totalActiveParamsSession);
    }
    if (gaugeDictSession != null) {
      setGaugeDict(gaugeDictSession);
    }
    if (trackedParametersSession != null) {
      setTrackedParameters(trackedParametersSession);
    }
  }, []);

  function getDashboardVariables() {
    return {
      fileLoadData,
      zeroCutParams,
      xAxisUnit,
      otherParams,
      modelData,
      modelNextID,
      minDataRangeVal,
      maxDataRangeVal,
      abbrDic,
      namingDic,
      warnings,
      allLocalModels,
      valueGroups,
      uploadedFiles,
      fileID,
      newWarningCount,
      cleanupNeeded,
      filePresets,
      selectedPreset,
      maxIteration,
      iterationRefreshCount,
      maxRunTime,
      chiVal,
      totalActiveParams,
      gaugeDict,
    };
  }

  const value = {
    resetDashboardContext,
    fileLoadData,
    setFileLoadData,
    zeroCutParams,
    setZeroCutParams,
    xAxisUnit,
    setXAxisUnit,
    otherParams,
    setOtherParams,
    modelData,
    setModelData,
    modelNextID,
    setModelNextID,
    minDataRangeVal,
    setMinDataRangeVal,
    maxDataRangeVal,
    setMaxDataRangeVal,
    abbrDic,
    setAbbrDic,
    namingDic,
    setNamingDic,
    warnings,
    setWarnings,
    allLocalModels,
    setAllLocalModels,
    valueGroups,
    setValueGroups,
    uploadedFiles,
    setUploadedFiles,
    fileID: fileID,
    setFileID: setFileID,
    newWarningCount,
    setNewWarningCount,
    undoModels,
    setUndoModels,
    button1Active,
    setButton1Active,
    button2Active,
    setButton2Active,
    button3Active,
    setButton3Active,
    cleanupNeeded,
    setCleanupNeeded,
    sectionsStatus,
    setSectionsStatus,
    showLeftPanel,
    setShowLeftPanel,
    callFromGraph,
    setCallFromGraph,
    fileCallFromGraph,
    setFileCallFromGraph,
    filePresets,
    setFilePresets,
    selectedPreset,
    setSelectedPreset,
    fileToChange,
    setFileToChange,
    getDashboardVariables,
    setDashboardVariables,
    expandedWindows,
    setExpandedWindows,
    fitIteration,
    setFitIteration,
    maxIteration,
    setMaxIteration,
    iterationRefreshCount,
    setIterationRefreshCount,
    maxRunTime,
    setMaxRunTime,
    chiVal,
    setChiVal,
    totalActiveParams,
    setTotalActiveParams,
    demoOpen,
    setDemoOpen,
    dataOpen,
    setDataOpen,
    modelsOpen,
    setModelsOpen,
    parametersOpen,
    setParametersOpen,
    fitOpen,
    setFitOpen,
    resultsOpen,
    setResultsOpen,
    chi2ReqAllowed,
    setChi2ReqAllowed,
    chi2QueRef,
    gaugeDict,
    setGaugeDict,
    addGraphModalOpen,
    setAddGraphModalOpen,
    loopOpen,
    setLoopOpen,
    outputsOpen,
    setOutputsOpen,
    loopModelFilesCustomName,
    setLoopModelFilesCustomName,
    loopCurveFilesCustomName,
    setLoopCurveFilesCustomName,
    loopIteration,
    setLoopIteration,
    listOfLoopModelsToSave,
    setListOfLoopModelsToSave,
    listOfLoopCurvesToSave,
    setListOfLoopCurvesToSave,
    trackedParameters,
    setTrackedParameters,
    pauseOnNextLoop,
    setPauseOnNextLoop,
    loopPaused,
    setLoopPaused,
    fitUndoAvailable,
    setFitUndoAvailable,
    stickyParamPosition,
    setStickyParamPosition,
    // requestedVdf,
    // setRequestedVdf,
  };

  useEffect(() => {
    window.sessionStorage.setItem("fileLoadData", JSON.stringify(fileLoadData));
    window.sessionStorage.setItem(
      "zeroCutParams",
      JSON.stringify(zeroCutParams)
    );
    window.sessionStorage.setItem("xAxisUnit", JSON.stringify(xAxisUnit));
    window.sessionStorage.setItem("otherParams", JSON.stringify(otherParams));
    window.sessionStorage.setItem("modelData", JSON.stringify(modelData));
    window.sessionStorage.setItem("modelNextID", JSON.stringify(modelNextID));
    window.sessionStorage.setItem(
      "minDataRangeVal",
      JSON.stringify(minDataRangeVal)
    );
    window.sessionStorage.setItem(
      "maxDataRangeVal",
      JSON.stringify(maxDataRangeVal)
    );
    window.sessionStorage.setItem("abbrDic", JSON.stringify(abbrDic));
    window.sessionStorage.setItem("namingDic", JSON.stringify(namingDic));
    window.sessionStorage.setItem("warnings", JSON.stringify(warnings));
    window.sessionStorage.setItem(
      "allLocalModels",
      JSON.stringify(allLocalModels)
    );
    window.sessionStorage.setItem("valueGroups", JSON.stringify(valueGroups));
    window.sessionStorage.setItem(
      "uploadedFiles",
      JSON.stringify(uploadedFiles)
    );
    // window.sessionStorage.setItem("graphs", JSON.stringify(graphs));
    window.sessionStorage.setItem("fileID", JSON.stringify(fileID));
    window.sessionStorage.setItem(
      "newWarningCount",
      JSON.stringify(newWarningCount)
    );
    window.sessionStorage.setItem(
      "cleanupNeeded",
      JSON.stringify(cleanupNeeded)
    );
    window.sessionStorage.setItem("filePresets", JSON.stringify(filePresets));
    window.sessionStorage.setItem(
      "selectedPreset",
      JSON.stringify(selectedPreset)
    );
    window.sessionStorage.setItem("maxIteration", JSON.stringify(maxIteration));
    window.sessionStorage.setItem(
      "iterationRefreshCount",
      JSON.stringify(iterationRefreshCount)
    );
    window.sessionStorage.setItem("maxRunTime", JSON.stringify(maxRunTime));
    window.sessionStorage.setItem("chiVal", JSON.stringify(chiVal));
    window.sessionStorage.setItem(
      "totalActiveParams",
      JSON.stringify(totalActiveParams)
    );
    window.sessionStorage.setItem("gaugeDict", JSON.stringify(gaugeDict));
    window.sessionStorage.setItem(
      "trackedParameters",
      JSON.stringify(trackedParameters)
    );
  }, [value]);

  return (
    <DashboardContext.Provider value={value}>
      {props.children}
    </DashboardContext.Provider>
  );
};

export default DashboardContextProvider;
