import React from "react";
import ManualContent from "../../components/ManualContent";

const Overview = () => {
  return (
    <ManualContent title="Packages Overview">
      <p>
        <strong>Note:</strong> The distinction between different packages is not
        yet implemented. Currently, <strong>Speqqle Base</strong> includes all
        available features for the initial launch. In future updates,
        functionality will be grouped into distinct packages, allowing users to
        activate only the features they need.
      </p>

      <p>
        Below is an overview of the planned packages that are scheduled in the
        near future, along with a short description of what they will include.
      </p>

      <h2>📦 Core Package</h2>

      <h3>Speqqle Base</h3>
      <p>
        The fundamental package providing essential fitting and modeling tools
        for the most commonly used spectroscopic methods.{" "}
        <strong>Speqqle Base</strong> is a prerequisite for all other packages
        and must be activated for additional functionality to work.
      </p>

      <h2>🛠️ Planned Extension Packages</h2>

      <h3>RefFIT (Anisotropic Spectroscopy)</h3>
      <p>
        Designed for fitting and modeling <strong>anisotropic samples</strong>,
        where optical properties vary depending on the direction of light
        propagation. This package is essential for birefringent materials,
        crystal optics, and structured thin films.
      </p>

      <h3>NearFIT (Near-Field Spectroscopy)</h3>
      <p>
        Provides tools for analyzing and fitting{" "}
        <strong>near-field spectra</strong>, typically encountered in scanning
        near-field optical microscopy (SNOM) and other nano-optical techniques.
      </p>

      <h3>TeraFIT (THz Time-Domain Spectroscopy)</h3>
      <p>
        Specialized in <strong>THz time-domain spectroscopy (THz-TDS)</strong>,
        enabling users to fit and analyze THz absorption, reflectivity, and
        time-resolved spectra with advanced models.
      </p>

      <h3>GasFIT (Gas Emission and Absorption Spectroscopy)</h3>
      <p>
        Developed for analyzing <strong>gas-phase spectra</strong>, including
        emission and absorption lineshapes. This package supports fitting Voigt,
        Lorentzian, and Doppler broadening models for high-precision spectral
        analysis.
      </p>

      <h2>What&apos;s Next?</h2>

      <p>
        As package separation is not yet implemented, all features are currently
        available under <strong>Speqqle Base</strong>. We encourage users to
        explore and test all functionalities.
      </p>

      <p>
        Once packages are introduced, you will be able to activate only the
        tools relevant to your research.
      </p>
    </ManualContent>
  );
};

export default Overview;
