import React from "react";
import ManualContent from "../../components/ManualContent";

const Overview = () => {
  return (
    <ManualContent title="Data Loading Overview">
      <p>
        <strong>Note:</strong> The Data Loading module is fully functional, but
        it is still being regularly updated, including during the beta testing
        phase. As a result, we cannot yet provide a finalized manual for this
        section. However, we encourage you to use all available features and
        provide feedback.
      </p>

      <h2>How to Load Data</h2>

      <p>To load and manage datasets in Speqqle:</p>

      <ol>
        <li>
          Go to the <strong>Data</strong> tab.
        </li>
        <li>
          Click on <strong>&quot;Data Manager&quot;</strong>.
        </li>
        <li>
          Choose to load <strong>individual files</strong> or an{" "}
          <strong>entire folder</strong>.
        </li>
        <li>Select which data columns to import.</li>
        <li>
          Indicate the units of your <strong>x-axis</strong>
          (especially if your x-axis is not in wavenumbers—this step is
          important!).
        </li>
        <li>
          Review how your processed data appears to ensure everything is
          correct.
        </li>
      </ol>

      <h2>Basic Data Adjustments</h2>

      <p>Once the data is loaded, you can apply basic transformations:</p>

      <ul>
        <li>
          <strong>Shifting:</strong> Adjust the position of the dataset.
        </li>
        <li>
          <strong>Scaling:</strong> Modify the intensity or amplitude of the
          data.
        </li>
      </ul>

      <h2>Regular Updates</h2>

      <p>
        As mentioned earlier, this module is under continuous improvement.
        Updates will be released regularly, including new features and
        optimizations.
      </p>

      <p>
        Please use the data loading functions freely, but be aware that changes
        and refinements may occur during future updates.
      </p>
    </ManualContent>
  );
};

export default Overview;
