import React, { useState, useEffect, useContext } from "react";
import { DashboardContext } from "../../../context/DashboardContext";
import { GeneralContext } from "../../../context/GeneralContext";
import "./loop.scss";
import { FormattedText, hasProperty } from "../../../utils/helpers";
import {
  deepCopy,
  getModelById,
  replaceModelById,
} from "../../leftSide/Models/modelLogic";

function ParameterSelector(props) {
  const { model, handleSelectClicked } = props;
  const { recordedErrorLog } = useContext(GeneralContext);
  const { modelData, setModelData } = useContext(DashboardContext);

  const [nonRecTables, setNonRecTables] = useState([]);
  const [recTable, setRecTable] = useState([]);
  const [recTableHeaders, setRecTableHeaders] = useState([]);

  useEffect(() => {
    try {
      if (model) {
        let nonRecTablesToPut = [];
        let recTableToPut = [];
        if (hasProperty(model, "modelParams")) {
          for (let i = 0; i < model.modelParams.length; i++) {
            const parameter = model.modelParams[i];

            if (parameter.recuring === 0) {
              //We select just non recuring parameters here
              let arrayToPut;
              if (nonRecTablesToPut[parameter.displaytable] !== undefined) {
                arrayToPut = nonRecTablesToPut[parameter.displaytable];
                arrayToPut[parameter.displayposition] = parameter;
              } else {
                arrayToPut = [];
                arrayToPut[parameter.displayposition] = parameter;
              }
              nonRecTablesToPut[parameter.displaytable] = arrayToPut;
            }
          }
        }

        if (hasProperty(model, "recParams")) {
          for (let i = 0; i < model.recParams.length; i++) {
            const paramRow = model.recParams[i];

            const rowOfParameters = [];
            const rowOfHeaders = [];
            for (let j = 0; j < paramRow.length; j++) {
              const parameter = paramRow[j];

              if (i === 0) {
                if (parameter.type === "Model") {
                  rowOfHeaders[parameter.displayposition] = "Model";
                } else {
                  rowOfHeaders[parameter.displayposition] = parameter.name;
                }
              }

              if (parameter.type === "Model") {
                const modelInParam = getModelById(parameter.FE_ID, modelData);
                rowOfParameters[parameter.displayposition] = {
                  ...parameter,
                  value: modelInParam.displayName,
                };
              } else {
                rowOfParameters[parameter.displayposition] = {
                  ...parameter,
                  value: model.recTableRows[i][parameter.name],
                };
              }
            }

            if (i === 0) {
              setRecTableHeaders(rowOfHeaders);
            }

            recTableToPut.push(rowOfParameters);
          }
        }

        setRecTable(recTableToPut);
        setNonRecTables(nonRecTablesToPut);
      }
    } catch (error) {
      recordedErrorLog(
        "Error producing tables for parameter selection: ",
        error
      );
    }
  }, [model]);

  const handleNonRecCellClick = (cell) => {
    if (
      cell.type !== "Model" &&
      !((hasProperty(cell, "customFixed") && cell.customFixed) || cell.fixed)
    ) {
      const updatedModel = deepCopy(model);

      updatedModel.modelParams = updatedModel.modelParams.map((param) => {
        if (
          param.id === cell.id &&
          param.speqqle_id === cell.speqqle_id &&
          param.displayposition === cell.displayposition &&
          param.displaytable === cell.displaytable
        ) {
          const selectedUpdate = hasProperty(param, "selected")
            ? !param.selected
            : true;
          handleSelectClicked(updatedModel, false, param, selectedUpdate);
          return { ...param, selected: selectedUpdate };
        }
        return param;
      });

      const updatedModelList = replaceModelById(
        modelData,
        updatedModel,
        updatedModel.FE_ID
      );

      setModelData(updatedModelList);
    }
  };

  const handleRecCellClick = (rowIndex, cell) => {
    if (
      cell.type !== "Model" &&
      !((hasProperty(cell, "customFixed") && cell.customFixed) || cell.fixed)
    ) {
      const updatedModel = deepCopy(model);

      updatedModel.recParams[rowIndex] = updatedModel.recParams[rowIndex].map(
        (param) => {
          if (
            param.id === cell.id &&
            param.speqqle_id === cell.speqqle_id &&
            param.displayposition === cell.displayposition
          ) {
            const selectedUpdate = hasProperty(param, "selected")
              ? !param.selected
              : true;
            handleSelectClicked(
              updatedModel,
              true,
              param,
              selectedUpdate,
              rowIndex
            );
            return { ...param, selected: selectedUpdate };
          }
          return param;
        }
      );

      const updatedModelList = replaceModelById(
        modelData,
        updatedModel,
        updatedModel.FE_ID
      );

      setModelData(updatedModelList);
    }
  };

  return (
    <div className="parameterSelector">
      <div className="header">
        <div className="title">{model.displayName}</div>
      </div>
      <div className="content">
        <div className="nonRecArea">
          {nonRecTables.map((tableEntry, tableIndex) => {
            const headers = tableEntry.map((tableCell) => {
              return tableCell.name;
            });

            return (
              <table className="nonRecTable" key={tableIndex}>
                <thead className="nonRecHeader">
                  <tr className="nonRecHeadRow">
                    {headers.map((headerEntry, headerIndex) => {
                      return (
                        <th className="nonRecHeadEntry" key={headerIndex}>
                          <FormattedText input={headerEntry} />
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody className="nonRecTbody">
                  <tr className="nonRecRow">
                    {tableEntry.map((tableCell, cellIndex) => {
                      return (
                        <td
                          className={`nonRecCell${
                            tableCell.type !== "float" &&
                            tableCell.type !== "int"
                              ? " disabled"
                              : ""
                          }${
                            hasProperty(tableCell, "selected") &&
                            tableCell.selected
                              ? " selected"
                              : ""
                          }${
                            (hasProperty(tableCell, "customFixed") &&
                              tableCell.customFixed) ||
                            tableCell.fixed
                              ? " disabled fixed"
                              : ""
                          }`}
                          onClick={() => handleNonRecCellClick(tableCell)}
                          key={cellIndex}
                        >
                          {tableCell.value}
                        </td>
                      );
                    })}
                  </tr>
                </tbody>
              </table>
            );
          })}
        </div>
        <div className="recArea">
          <table>
            <thead className="recHeader">
              <tr className="recHeadRow">
                {recTableHeaders.map((headerEntry, headerIndex) => {
                  return (
                    <th className="recHeadEntry" key={headerIndex}>
                      <FormattedText input={headerEntry} />
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody className="recTbody">
              {recTable.map((row, rowIndex) => {
                return (
                  <tr className="recRow" key={rowIndex}>
                    {row.map((tableCell, cellIndex) => {
                      return (
                        <td
                          className={`recCell${
                            tableCell.type !== "float" &&
                            tableCell.type !== "int"
                              ? " disabled"
                              : ""
                          }${
                            hasProperty(tableCell, "selected") &&
                            tableCell.selected
                              ? " selected"
                              : ""
                          }${
                            (hasProperty(tableCell, "customFixed") &&
                              tableCell.customFixed) ||
                            tableCell.fixed
                              ? " disabled fixed"
                              : ""
                          }`}
                          onClick={() =>
                            handleRecCellClick(rowIndex, tableCell)
                          }
                          key={cellIndex}
                        >
                          {tableCell.type !== "Checkbox" ? (
                            tableCell.value
                          ) : (
                            <input
                              type="checkbox"
                              defaultChecked={tableCell.value}
                              disabled
                            />
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default ParameterSelector;
