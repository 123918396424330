import React from "react";
import { MathJax, MathJaxContext } from "better-react-mathjax";
import ManualContent from "../../components/ManualContent";

const VdfFit = () => {
  return (
    <MathJaxContext>
      <ManualContent title="Variational Dielectric Function (VDF) Fitting">
        {/* Top Navigation Menu */}
        <div className="vdf-top-menu">
          <nav>
            <ul className="menu-list">
              <li className="menu-item">
                <a href="#what-is-vdf">What is KK-VDF?</a>
              </li>
              <li className="menu-item">
                <a href="#why-use-vdf">Why Use VDF?</a>
              </li>
              <li className="menu-item">
                <a href="#how-to-use-vdf">How to Use VDF?</a>
              </li>
              <li className="menu-item">
                <a href="#common-mistakes">Common Mistakes</a>
              </li>
            </ul>
          </nav>
        </div>

        <h2 id="what-is-vdf">
          What is Kramers–Kronig Constrained Variational Analysis?
        </h2>
        <p>
          Kramers–Kronig constrained variational analysis, also known as KK-VDF,
          is a method used to extract the complex dielectric function from
          optical spectra while ensuring that the real and imaginary parts are
          linked by fundamental causality relations.
        </p>
        <p>The dielectric function is written as:</p>
        <MathJax>
          {
            "\\( \\varepsilon(\\omega) = \\varepsilon_1(\\omega) + i\\varepsilon_2(\\omega) \\)"
          }
        </MathJax>

        <p>
          The imaginary part,{" "}
          <MathJax inline>{"\\( \\varepsilon_2(\\omega) \\)"}</MathJax>, is
          parameterized using a flexible grid of anchor points, and the real
          part,
          <MathJax inline>{"\\( \\varepsilon_1(\\omega) \\)"}</MathJax>, is
          computed using the Kramers–Kronig integral transform.
        </p>

        <h2 id="why-use-vdf">Why Use VDF Fitting?</h2>
        <p>
          Traditional methods for extracting dielectric functions have several
          limitations:
        </p>
        <ul>
          <li>
            Standard Kramers–Kronig transformations require data extrapolation
            beyond the measured spectral range.
          </li>
          <li>
            Drude–Lorentz models depend on a predefined set of oscillators,
            which may not fully capture the material’s optical response.
          </li>
          <li>
            Using too many oscillators in a traditional fit may lead to
            instability and physically meaningless results.
          </li>
        </ul>
        <p>
          KK-VDF solves these issues by ensuring Kramers–Kronig consistency
          while remaining model-independent.
        </p>

        <h2 id="how-to-use-vdf">How to Perform KK-VDF Fitting in Speqqle</h2>
        <ol>
          <li>Go to the Parameters tab and select a model.</li>
          <li>Open the Settings menu and enable the VDF option.</li>
          <li>
            Define the fitting grid by either adding manual grid points or
            loading a predefined data file.
          </li>
          <li>
            Click Create VDF and choose whether to fix or free the material
            parameters.
          </li>
          <li>Navigate to the Fit tab and start the fitting process.</li>
        </ol>

        <h2 id="common-mistakes">Common Mistakes and How to Avoid Them</h2>
        <ul>
          <li>
            Using too many grid points can lead to overfitting and instability.
            Always apply a reasonable reduction factor.
          </li>
          <li>
            Parameter divergence may occur if constraints are too loose. Ensure
            proper parameter limits are in place.
          </li>
          <li>
            Inconsistent grid spacing can introduce errors in the KK transform.
            Optimize the grid density carefully.
          </li>
        </ul>

        <h2>Comparison of KK-VDF vs. Traditional Methods</h2>
        <table className="comparison-table">
          <thead>
            <tr>
              <th>Feature</th>
              <th>KK-VDF</th>
              <th>Standard KK Transform</th>
              <th>Drude–Lorentz Fitting</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Model Independence</td>
              <td>Yes</td>
              <td>No</td>
              <td>No</td>
            </tr>
            <tr>
              <td>Handles Multi-Measurement Data</td>
              <td>Yes</td>
              <td>No</td>
              <td>No</td>
            </tr>
            <tr>
              <td>Requires Extrapolation</td>
              <td>No</td>
              <td>Yes</td>
              <td>No</td>
            </tr>
            <tr>
              <td>Suitable for Thin Films & Anisotropic Materials</td>
              <td>Yes</td>
              <td>No</td>
              <td>Yes (Limited)</td>
            </tr>
          </tbody>
        </table>
      </ManualContent>
    </MathJaxContext>
  );
};

export default VdfFit;
