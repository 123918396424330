import React from "react";
import ManualContent from "../../components/ManualContent";
import image1 from "../../images/graphcurvesesssionexport.jpg";

const Saving = () => {
  return (
    <ManualContent title="Saving Curves from Graphs">
      <p>
        Speqqle allows you to export the curves displayed in your graphs using
        the <strong>&quot;Export&quot;</strong> button located in the top right
        corner of the graph.
      </p>

      <img src={image1} alt="exporting and saving" width="800" />

      <h2>Exporting Data and Output Curves</h2>

      <p>
        When you click the <strong>&quot;Export&quot;</strong> button, you will
        be prompted to enter a prefix. This prefix will be used to name the
        exported files.
      </p>

      <ul>
        <li>
          Each dataset file will be saved in your download folder as:
          <strong>&quot;prefix - filename.txt&quot;</strong>
        </li>
        <li>
          Each output curve generated by an Experiment model will be saved as:
          <strong>&quot;prefix - outputname.txt&quot;</strong>
        </li>
        <li>
          The exported curves will match the current axis ranges of the graph,
          so make sure to adjust them before exporting.
        </li>
      </ul>

      <h2>Saving a Graph as an Image</h2>

      <p>
        You can save an image of the graph by clicking the{" "}
        <strong>camera icon</strong>
        located in the top left corner of the graph.
      </p>

      <h2>Saving Your Entire Session</h2>

      <p>
        To save your entire analysis session, including all graphs, models,
        datasets, and any other progress, click the{" "}
        <strong>&quot;Save&quot;</strong> button in the top left of the session
        window.
      </p>

      <p>
        This will generate a session file (<strong>*.spqs</strong>) that you can
        reopen later to continue your analysis.
      </p>
    </ManualContent>
  );
};

export default Saving;
