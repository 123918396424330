import React from "react";
import ManualContent from "../../components/ManualContent";

const Overview = () => {
  return (
    <ManualContent title="Troubleshooting">
      <p>
        Below are solutions to common issues encountered while using Speqqle. If
        your issue is not listed, please consult the relevant sections of the
        manual or contact support.
      </p>

      <h2>🔄 Installation and Startup Issues</h2>

      <h3>Speqqle is not loading properly.</h3>
      <p>
        <strong>Solution:</strong> Try the following steps:
      </p>
      <ul>
        <li>
          Ensure you are using a supported browser (Chrome, Firefox, Edge).
        </li>
        <li>
          Clear your browser cache and refresh the page (
          <strong>CTRL + Shift + R</strong>).
        </li>
        <li>Check your internet connection.</li>
        <li>Disable any browser extensions that might interfere.</li>
      </ul>

      <h3>I am unable to log in.</h3>
      <p>
        <strong>Solution:</strong>
      </p>
      <ul>
        <li>Make sure you have verified your email.</li>
        <li>Check for typos in your username and password.</li>
        <li>Try resetting your password.</li>
      </ul>

      <h2>📂 Data Loading Issues</h2>

      <h3>My data is not loading or appears incorrectly.</h3>
      <p>
        <strong>Solution:</strong>
      </p>
      <ul>
        <li>Ensure your file format is supported.</li>
        <li>
          Verify that you selected the correct columns in the Data Manager.
        </li>
        <li>
          Check that the x-axis units are properly set (wavenumbers, frequency,
          etc.).
        </li>
        <li>Try reloading the file or using a different file format.</li>
      </ul>

      <h3>Some of my datasets are missing after loading a folder.</h3>
      <p>
        <strong>Solution:</strong>
      </p>
      <ul>
        <li>Ensure all files in the folder have compatible formats.</li>
        <li>Verify that you did not exceed the system’s file upload limit.</li>
        <li>Check the Data Manager to confirm that all files are listed.</li>
      </ul>

      <h2>⚙️ Parameter Issues</h2>

      <h3>
        I used the Demo to load some data, but know the session behaves
        strangely.
      </h3>
      <p>
        <strong>Solution:</strong> Never use the Demo together with your own
        data, start from a clean session.
      </p>

      <h3>My parameter values keep resetting.</h3>
      <p>
        <strong>Solution:</strong>
      </p>
      <ul>
        <li>
          Ensure you clicked outside the parameter cell to confirm the change.
        </li>
        <li>
          Check if the parameter is fixed (bold text) and unfix it if necessary.
        </li>
        <li>
          Look for constraints or grouped parameters affecting its behavior.
        </li>
      </ul>

      <h3>A parameter is highlighted in orange. What does this mean?</h3>
      <p>
        <strong>Solution:</strong>
      </p>
      <ul>
        <li>
          An orange parameter indicates that its value is outside of soft
          limits.
        </li>
        <li>Check if the value is physically meaningful for your model.</li>
        <li>Adjust constraints or verify the parameter’s expected range.</li>
      </ul>

      <h2>📊 Fitting Issues</h2>

      <h3>My fit is not converging or produces unreasonable results.</h3>
      <p>
        <strong>Solution:</strong>
      </p>
      <ul>
        <li>Ensure that all initial parameter values are reasonable.</li>
        <li>
          Check if constraints are too tight, preventing parameters from
          adjusting properly.
        </li>
        <li>Try freeing some fixed parameters to allow more flexibility.</li>
        <li>Increase the number of iterations in the Fit options.</li>
        <li>Check if your model is appropriate for the dataset.</li>
      </ul>

      <h3>The fitted curve does not match my data at all.</h3>
      <p>
        <strong>Solution:</strong>
      </p>
      <ul>
        <li>Ensure that the correct dataset is linked to the correct model.</li>
        <li>Check if an incorrect x-axis unit was assigned.</li>
        <li>
          Verify that the data has been properly scaled and shifted if
          necessary.
        </li>
        <li>Look at residuals to see where the largest discrepancies occur.</li>
      </ul>

      <h2>📁 Model and Export Issues</h2>

      <h3>My saved model did not reload correctly.</h3>
      <p>
        <strong>Solution:</strong>
      </p>
      <ul>
        <li>
          Ensure that you saved the full model, including submodels if
          necessary.
        </li>
        <li>Try reloading the model from the Experiment tab.</li>
        <li>
          Check if file corruption occurred and attempt to load an earlier
          version.
        </li>
      </ul>

      <h3>Graphs are not exporting as expected.</h3>
      <p>
        <strong>Solution:</strong>
      </p>
      <ul>
        <li>
          Ensure you are using the correct export option in the graph settings.
        </li>
        <li>Try exporting to a different file format.</li>
        <li>Check if the graph needs to be resized before exporting.</li>
      </ul>

      <h2>❓ Need Further Help?</h2>

      <p>If your issue is not listed here, try the following:</p>

      <ul>
        <li>Consult the relevant section of the manual.</li>
        <li>
          Use the <strong>Feedback Form</strong> on your profile page to report
          a problem.
        </li>
        <li>
          Contact support at{" "}
          <a href="mailto:support@speqqle.com">support@speqqle.com</a>.
        </li>
      </ul>
    </ManualContent>
  );
};

export default Overview;
