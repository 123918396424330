import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { BrowserRouter, Route, Routes, HashRouter } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import theme from "./theme";
import Login from "./pages/Login";
import AdminSystem from "./pages/profilePages/AdminSystem";
import Register from "./pages/Register";
import DashboardContextProvider from "./context/DashboardContext";
import GraphContextProvider from "./context/GraphContext";
import WebSocketContextProvider from "./context/WebSocketContext";
import AuthContextProvider from "./context/AuthContext";
import GeneralContextProvider from "./context/GeneralContext";
import WelcomePage from "./pages/profilePages/WelcomePage";
import FileHandlingContextProvider from "./context/FileHandlingContext";
import PasswordReset from "./pages/PaswordReset";
import ConfirmEmail from "./pages/ConfirmEmail";
import isElectron from "is-electron";
import ErrorBoundary from "./errorHandling/ErrorBoundary";
import MainAppCrash from "./errorHandling/MainAppCrash";
import CrashPage from "./errorHandling/CrashPage";
import { Toaster } from "react-hot-toast";
import TestersAgreement from "./pages/legal/TestersAgreement";
import PrivacyPage from "./pages/legal/PrivacyPage";
import TermsOfUse from "./pages/legal/TermsOfUse";
import Feedback from "./pages/Feedback";
import AlphaTestersManual from "./pages/legal/AlphaTestersManual";
import Manual from "./pages/manual/Manual";

// Checks which router to used based on if we are in Electron environment or not
const Router = isElectron() ? HashRouter : BrowserRouter;

function App() {
  return (
    <ErrorBoundary fallbackComponent={MainAppCrash}>
      <ThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <DashboardContextProvider>
          <GraphContextProvider>
            <AuthContextProvider>
              <GeneralContextProvider>
                <WebSocketContextProvider>
                  <FileHandlingContextProvider>
                    <Router>
                      {/* Page navigation */}
                      <Routes>
                        {/* the default path goes to login page using the imported login compmonent as the element */}
                        <Route exact path="/" element={<Login />} />
                        <Route
                          path="/test-agreement"
                          element={<TestersAgreement />}
                        />
                        <Route
                          path="/privacy-policy"
                          element={<PrivacyPage />}
                        />
                        <Route path="/terms-of-use" element={<TermsOfUse />} />
                        <Route path="/feedback" element={<Feedback />} />
                        <Route
                          path="/alpha-manual"
                          element={<AlphaTestersManual />}
                        />
                        <Route
                          path="/manual/*"
                          element={<Manual />}
                        />
                        <Route path="/register" element={<Register />} />
                        <Route
                          path="/reset-password"
                          element={<PasswordReset />}
                        />
                        <Route
                          path="/confirm-email"
                          element={<ConfirmEmail />}
                        />
                        <Route path="/welcome" element={<WelcomePage />} />
                        <Route path="/profile/*" element={<AdminSystem />} />
                        <Route
                          path="/dashboard"
                          element={
                            //<FileHandlingContextProvider>  <WebSocketContextProvider>
                            <ErrorBoundary
                              fallbackComponent={CrashPage}
                              fallbackProps={{
                                text: "Analysis page failure.",
                              }}
                            >
                              <Dashboard />
                            </ErrorBoundary>
                            //</WebSocketContextProvider>  </FileHandlingContextProvider>
                          }
                        />
                      </Routes>
                    </Router>
                  </FileHandlingContextProvider>
                </WebSocketContextProvider>
              </GeneralContextProvider>
            </AuthContextProvider>
          </GraphContextProvider>
        </DashboardContextProvider>
        <Toaster position="top-center" reverseOrder={false} />
      </ThemeProvider>
    </ErrorBoundary>
  );
}

export default App;
