import React from "react";
import ManualContent from "../../components/ManualContent";
import addCurvesImage from "../../images/addcurves.jpg";
import addModelCurvesImage from "../../images/addmodelcurves.jpg";

const AddingData = () => {
  return (
    <ManualContent title="Adding Data and Experiment Curves to Graphs">
      <p>
        In Speqqle, you can visualize datasets and Experiment outputs using
        graphs. You can either use the default graph that is automatically added
        when you start a session or create a new graph by clicking the{" "}
        <strong>&quot;Add Graph&quot;</strong> button in the top right.
      </p>

      <h2>Adding Curves to a Graph</h2>

      <p>
        If a graph is already open on the screen, follow these steps to add
        curves:
      </p>

      <ol>
        <li>
          Click the <strong>curves icon</strong> in the top left corner of the
          graph window (see image below).
        </li>
        <li>
          A window will open displaying the list of curves currently added to
          the graph.
        </li>
        <li>If no curves have been added yet, the list will be empty.</li>
        <li>
          Click <strong>&quot;Add Curves&quot;</strong> to open the curve
          selection window.
        </li>
      </ol>

      <div className="image-container wide-image">
        <img
          src={addCurvesImage}
          alt="Adding Curves to a Graph"
          className="workflow-image wide-workflow-image"
        />
      </div>

      <h2>Selecting Data and Experiment Outputs</h2>

      <p>
        After clicking the <strong>&quot;Add Curves&quot;</strong> button, the
        curve selection window appears. Here, you can add datasets or Experiment
        model outputs to your graph:
      </p>

      <ul>
        <li>
          <strong>Adding Datasets:</strong>{" "}Under the{" "}
          <strong>&quot;From Files:&quot;</strong> section, scroll through the
          list and click on the dataset you want to add.
        </li>
        <li>
          <strong>Adding Model Outputs:</strong>{" "}Under the{" "}
          <strong>&quot;From Models:&quot;</strong> section, select
          model-generated outputs.
          <ul>
            <li>
              Some models, like the Raman model, have only one type of output.
            </li>
            <li>
              Other models, such as <strong>R/T/A/Ellips</strong>, have multiple
              outputs. Click on the model name to expand the list, then select
              the desired output.
            </li>
          </ul>
        </li>
      </ul>

      <div className="image-container wide-image">
        <img
          src={addModelCurvesImage}
          alt="Adding Model Outputs to a Graph"
          className="workflow-image wide-workflow-image"
        />
      </div>

      <h2>Finalizing Your Selections</h2>

      <p>Once you have selected the desired datasets and outputs:</p>

      <ul>
        <li>
          Click <strong>&quot;Close&quot;</strong> to return to the list of
          curves in the graph.
        </li>
        <li>
          Click anywhere outside the Curves window to close it and return to the
          graph.
        </li>
      </ul>

      <h2>Adding Curves to a New Graph</h2>

      <p>To create a new graph and add curves to it:</p>

      <ol>
        <li>
          Click the <strong>&quot;Add Graph&quot;</strong> button in the top
          right corner of your Speqqle session.
        </li>
        <li>
          A window will open displaying a list of available datasets and
          Experiment outputs.
        </li>
        <li>Select the curve you want to display in the new graph.</li>
        <li>A new graph will be created, displaying the selected curve.</li>
        <li>
          To add more curves and outputs, follow the steps outlined in the
          previous section.
        </li>
      </ol>

      <h2>Next Steps</h2>
      <p>Now that you have data and model outputs plotted, you may want to:</p>

      <ul>
        <li>
          <a href="customization">
            Rename, Resize, and Customize Graph Appearance
          </a>
        </li>
        <li>
          <a href="saving">Save and Manage Graph Curves</a>
        </li>
        <li>
          <a href="interactive">Update Datasets via the Graph Legend</a>
        </li>
        <li>
          <a href="exporting">Export Graph Images</a>
        </li>
      </ul>
    </ManualContent>
  );
};

export default AddingData;
