import React from "react";
import ManualContent from "../../components/ManualContent";

const SpeqqleBase = () => {
  return (
    <ManualContent title="Speqqle Base">
      <p>
        <strong>Note:</strong> The distinction between different packages is not
        yet implemented. Currently, <strong>Speqqle Base</strong>
        includes all available features for the initial launch. In the future,
        functionalities will be grouped into separate packages, allowing users
        to activate only the tools they need.
      </p>

      <p>Below is an overview of what Speqqle Base will include.</p>

      <h2>📦 Core Functionality</h2>

      <p>
        Speqqle Base serves as the foundation for all other packages, providing
        essential modeling and fitting tools for standard spectroscopic methods.
        It is required for any additional packages to function.
      </p>

      <h2>🔬 Included Features</h2>

      <h3>1️⃣ Standard Spectroscopic Modeling and Fitting</h3>
      <p>
        Speqqle Base includes core functionalities for analyzing and fitting
        experimental data in common spectroscopic techniques, including:
      </p>

      <ul>
        <li>
          <strong>Reflection (R) and Transmission (T) Spectroscopy</strong>
        </li>
        <li>
          <strong>Absorption (A) Spectroscopy</strong>
        </li>
        <li>
          <strong>Ellipsometry</strong> for isotropic samples
        </li>
        <li>
          <strong>Basic Raman Spectroscopy</strong> fitting and modeling
        </li>
      </ul>

      <h3>2️⃣ Data Loading and Management</h3>
      <p>Tools for handling spectroscopic datasets:</p>

      <ul>
        <li>Loading and processing data files from different formats</li>
        <li>
          Assigning x-axis units (e.g., wavenumbers, wavelength, frequency)
        </li>
        <li>Applying basic data pre-processing (scaling, shifting)</li>
      </ul>

      <h3>3️⃣ Parameter Control and Optimization</h3>
      <p>Speqqle Base includes a robust parameter management system:</p>

      <ul>
        <li>Manually editing parameter values</li>
        <li>Setting parameter constraints and soft limits</li>
        <li>Fixing and grouping parameters</li>
      </ul>

      <h3>4️⃣ Multilayer Sample Support</h3>
      <p>
        Users can define and fit multilayer samples for standard spectroscopy:
      </p>

      <ul>
        <li>Adding, removing, and modifying material layers</li>
        <li>Renaming and replacing layer materials</li>
      </ul>

      <h3>5️⃣ Model Saving and Reuse</h3>
      <p>Saving and loading models for streamlined workflows:</p>

      <ul>
        <li>Saving full models, including parameter configurations</li>
        <li>Saving and reloading submodels separately</li>
      </ul>

      <h2>🚀 What&apos;s Next?</h2>

      <p>
        All functionalities are currently available under{" "}
        <strong>Speqqle Base</strong>. Once package separation is implemented,
        additional modules will expand its capabilities for specific
        spectroscopy applications.
      </p>
    </ManualContent>
  );
};

export default SpeqqleBase;
