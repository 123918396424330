import React from "react";
import ManualContent from "../../components/ManualContent";

const Multilayer = () => {
  return (
    <ManualContent title="Adapting the Multilayer Structure of a Model">
      <p>
        Some Experiments in Speqqle support multilayer samples, allowing you to
        define stacked material structures. This feature is currently available
        in the <strong>R/T/A/Ellips</strong> model and will be included in
        future models such as <strong>THz</strong> and <strong>SNOM</strong>.
      </p>

      <h2>Adding New Layers</h2>
      <p>To add new layers to a sample via the Parameter tab:</p>

      <ol>
        <li>
          Go to the <strong>Parameters</strong> tab.
        </li>
        <li>
          Locate the table labeled <strong>&quot;Sample Layers&quot;</strong>.
        </li>
        <li>
          Hover over the existing row (which represents a bulk or single-layer
          sample).
        </li>
        <li>
          Click the <strong>copy icon</strong> to duplicate the current layer,
          or click the <strong>green (+) icon</strong> to add a new empty layer.
        </li>
      </ol>

      <h2>Renaming Layers</h2>
      <p>To rename layers for better organization:</p>

      <ol>
        <li>
          Go to the <strong>Experiments</strong> tab.
        </li>
        <li>Right-click on the material layer you want to rename.</li>
        <li>
          Enter the new name and press <strong>Enter</strong>.
        </li>
      </ol>

      <h2>Replacing a Material in a Layer</h2>
      <p>You can replace a material layer with a different material model:</p>

      <ol>
        <li>
          Go to the <strong>Parameters</strong> tab.
        </li>
        <li>
          In the <strong>Sample Layers</strong> table, left-click on the name of
          the material.
        </li>
        <li>A window will open listing all available material models.</li>
        <li>Click on the desired material to replace the existing one.</li>
      </ol>

      <h2>Next Steps</h2>
      <p>
        Now that you&apos;ve adjusted the multilayer structure, explore
        additional customization options:
      </p>

      <ul>
        <li>
          <a href="editing">Editing Parameter Values</a>: Adjust layer
          thickness, refractive index, and other material properties.
        </li>
        <li>
          <a href="fixing">Fixing and Freeing Parameters</a>: Choose whether
          layer parameters should be fixed or optimized during fitting.
        </li>
        <li>
          <a href="constraints">Setting Parameter Constraints</a>: Define limits
          on layer thickness and other key properties.
        </li>
      </ul>
    </ManualContent>
  );
};

export default Multilayer;
