import React from "react";
import ManualContent from "../../components/ManualContent";
import image1 from "../../images/graphsappearance.jpg";
import image2 from "../../images/curvesettings.jpg";

const Customization = () => {
  return (
    <ManualContent title="Graphs and Curve Appearance">
      <p>
        Speqqle allows you to customize the appearance of both the graphs and
        the curves they display. You can modify these settings using the{" "}
        <strong>Settings</strong> icon in the top left corner, the{" "}
        <strong>Graph Tools</strong> in the top right corner (which appear when
        hovering in the top left of the graph), or by clicking and dragging the
        axes.
      </p>

      <h2>Customizing the Graph</h2>
      <p>To adjust the overall graph appearance:</p>
      <ul>
        <li>
          Click the <strong>Settings</strong> icon in the top left to change
          axis names and ranges.
        </li>
        <li>
          Use the <strong>Graph Tools</strong> to rescale the graph or export it
          as an image.
        </li>
        <li>Click the graph title to rename it.</li>
        <li>
          Drag the axes to manually adjust scaling or double-click a value to
          enter a new axis range.
        </li>
        <li>
          Click anywhere outside the settings window to close it and return to
          the graph.
        </li>
      </ul>

      <img src={image1} alt="Graph Appearance Customization" width="800" />

      <h2>Customizing Curve Appearance</h2>
      <p>You can modify the appearance of individual curves in a graph:</p>
      <ul>
        <li>
          Click the <strong>Curves</strong> icon in the top left corner of the
          graph.
        </li>
        <li>
          Click the <strong>colored rectangle</strong> next to a curve to change
          its color.
        </li>
        <li>
          Use the <strong>dropdown menus</strong> to change the line type, line
          thickness, and scatter style.
        </li>
        <li>
          Export model output curves by clicking the <strong>download</strong>{" "}
          icon.
        </li>
        <li>
          Remove a curve from the graph by clicking the{" "}
          <strong>red cross (X)</strong>.
        </li>
      </ul>

      <img src={image2} alt="Curve Customization Options" width="800" />
    </ManualContent>
  );
};

export default Customization;
