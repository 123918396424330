import React, { useState, useContext, useRef } from "react";
// import { Button } from "@mui/material";
import { DashboardContext } from "../../../context/DashboardContext";
import { GraphContext } from "../../../context/GraphContext";
import { AuthContext } from "../../../context/AuthContext";
import { file as demoFile, model } from "./demoData";
import "./demo.scss";
import { generatePlotData } from "../../middle/graphLogic";
import {
  filterCurveDataToRange,
  getColor,
  getDataPointsFromFile,
  getShortName,
} from "../../../utils/helpers";
import { deepCopy } from "../Models/modelLogic";
import { WebSocketContext } from "../../../context/WebSocketContext";
import CustomButton from "../../commonComponents/CustomButton";
import ConfirmationScreen from "../../commonComponents/ConfirmationScreen";

function Demo() {
  const {
    button1Active,
    setButton1Active,
    button2Active,
    setButton2Active,
    button3Active,
    setButton3Active,
    uploadedFiles,
    setUploadedFiles,
    setModelData,
    setFileID,
    setAbbrDic,
    setNamingDic,
    setModelNextID,
    resetDashboardContext,
    setDemoOpen,
  } = useContext(DashboardContext);

  const {
    graphs,
    setGraphs,
    setZIndices,
    setRequestedFitModel,
    resetGraphContext,
  } = useContext(GraphContext);

  const { sendJsonMessage, isFitOngoing, isLoopOngoing } =
    useContext(WebSocketContext);
  const { currentUser } = useContext(AuthContext);

  const [smallWindow] = useState({
    height: Math.max(window.innerWidth * 0.3, 220),
    width: Math.max(window.innerWidth * 0.4, 260),
  });
  const [step1ConfirmOpen, setStep1ConfirmOpen] = useState(false);
  const step1ButtonRef = useRef();

  function handleButton1Click() {
    setStep1ConfirmOpen(false);
    resetDashboardContext();
    resetGraphContext();
    setDemoOpen(true);
    setButton1Active(true);
    setUploadedFiles([demoFile]);
    generateGraphFromFile(demoFile);
  }

  function handleButton2Click() {
    setButton2Active(true);
    setModelData([model]);
    setAbbrDic((oldDict) => {
      return { ...oldDict, Raman: 1 };
    });
    setNamingDic((oldDict) => {
      return { ...oldDict, 1: "Raman" };
    });
    setModelNextID((oldId) => oldId + 1);

    const demoGraph = graphs.find((graph) => graph.id == 1);

    const plotData = generatePlotData(
      model.curves[0].curve,
      "",
      "",
      getColor(demoGraph.plotData.length + 1),
      "Raman",
      null,
      1,
      null,
      5
    );

    demoGraph.plotData = [...demoGraph.plotData, ...plotData];
    demoGraph.containedModels = [{ modelId: 1, quantity: 5 }];

    setGraphs([deepCopy(demoGraph)]);
  }

  function handleButton3Click() {
    setButton3Active(true);
    setRequestedFitModel((old) => [...old, { modelId: 1, quantity: 5 }]);
    handleStep3();
  }

  const generateGraphFromFile = (fileToSet) => {
    const data = Object.prototype.hasOwnProperty.call(fileToSet, "dataPoints")
      ? fileToSet.dataPoints
      : getDataPointsFromFile(fileToSet.content);

    createGraphEntry(
      data,
      fileToSet.name,
      fileToSet.ID,
      null,
      fileToSet.dataRangeMax,
      fileToSet.dataRangeMin,
      fileToSet.edges
    );
  };

  const createGraphEntry = (
    data,
    name,
    fileID,
    modelID,
    rangeMax,
    rangeMin,
    edges
  ) => {
    const shortName = getShortName(name);

    const plotData = generatePlotData(
      data,
      rangeMin,
      rangeMax,
      "blue",
      shortName,
      fileID,
      modelID,
      edges
    );

    const layout = {
      xaxis: {
        title: { text: "Wavenumber [cm<sup>-1</sup>]" },
        mirror: "ticks",
        showline: true,
        linecolor: "#000",
        linewidth: 1,
        range: [-200, 250],
      },
      yaxis: {
        title: { text: "Amplitude" },
        mirror: "ticks",
        showline: true,
        linecolor: "#000",
        linewidth: 1,
        range: [0, 950],
      },
      margin: {
        l: 60, // left margin
        r: 50, // right margin
        b: 50, // bottom margin
        t: 30, // top margin
        pad: 0, // padding between the plotting area and the axis labels
      },
      autosize: true,
      width: smallWindow.width - 2,
      height: smallWindow.height - 38,
      paper_bgcolor: "#edf7ff",
    };

    const position = {
      x: 50,
      y: 0,
    };

    const demoGraphs = [
      {
        type: "standard",
        id: 1,
        position: position,
        title: "Demo Graph",
        plotData: plotData,
        layout: layout,
        containedFiles: fileID != null ? [fileID] : [],
        containedModels: modelID != null ? [modelID] : [],
      },
    ];

    setGraphs(demoGraphs);

    setZIndices((prevZIndices) => ({ ...prevZIndices, [1]: 0 }));
    setFileID((old) => old + 1);
  };

  const handleStep3 = () => {
    const demoFile = uploadedFiles.find((uplFile) => uplFile.ID == 1);

    let data = Object.prototype.hasOwnProperty.call(demoFile, "dataPoints")
      ? demoFile.dataPoints
      : getDataPointsFromFile(demoFile.content);

    data = filterCurveDataToRange(
      data,
      demoFile.dataRangeMin,
      demoFile.dataRangeMax
    );

    const filteredCutParams = demoFile.edges.filter(
      (param) =>
        (param.min !== undefined && param.min !== "") ||
        (param.max !== undefined && param.max !== "")
    );

    let fitinfoLoc = {
      peak0auto: true,
      edges: filteredCutParams,
      onlypos: false,
      npoints: demoFile.npoints,
      range: { min: -200, max: 250 },
    };

    // let range = {};
    // range = {
    //   ...range,
    //   min: demoFile.dataRangeMin != "" ? demoFile.dataRangeMin : -1000,
    // };
    // range = {
    //   ...range,
    //   max: demoFile.dataRangeMax != "" ? demoFile.dataRangeMax : 1000,
    // };

    // fitinfoLoc = { ...fitinfoLoc, range: range };

    let payload = {
      User: currentUser.id,
      Data: {
        SendData: [
          {
            dataid: demoFile.ID,
            data: data,
          },
        ],
      },
      Fit: {
        AutoFit: [
          {
            modeltype: -113,
            modelid: 1,
            dataid: demoFile.ID,
            fitinfo: fitinfoLoc,
            quantity: 5, //Default Raman
          },
        ],
      },
      Model: {
        GetModelCurve: [
          {
            min: -200,
            max: 250,
            modelid: 1,
            quantity: 5,
          },
        ],
      },
    };

    sendJsonMessage(payload, { type: "autofit" });
  };

  return (
    <div className="demo">
      <div className="disclaimer">
        <b>Always</b> run the demo on a <b>CLEAN</b> session. After running the
        demo, reset by logging in again or clicking the &quot;Clear
        Session&quot; button.
      </div>
      <div className="buttonsContainer">
        <div className="buttonSection">
          <div className="buttonWithText" ref={step1ButtonRef}>
            <CustomButton
              text="Step 1"
              extraClassnames={["demoVersion"]}
              handleClick={() => setStep1ConfirmOpen(true)}
              id="load-example-data"
              disabled={isFitOngoing || isLoopOngoing}
            />
            {/* <Button
              variant="contained"
              size="small"
              sx={{ m: 1 }}
              onClick={() => handleButton1Click()}
              className="button"
              id="load-example-data"
              disabled={isFitOngoing || isLoopOngoing}
            >
              Step 1
            </Button> */}
            <div className="text">Load and display demo data.</div>
          </div>
          {button1Active ? (
            <div className="textAfterClick" id="1-step-text">
              <p>
                You have automatically loaded and displayed the demo data file,
                which is a Raman spectrum. If you click the &quot;Data&quot; tab
                in the top bar you see the file name DEMO-DATA displayed in the
                list of uploaded files. In the &quot;Graphs&quot; tab, you will
                see a graph displaying the spectrum.
              </p>
              <p>
                You can load your own data by clicking on the &quot;Data
                Manager&quot; button in the &quot;Data&quot; tab, and display it
                in a graph by clicking on the curves button.
              </p>
              <p>
                Please note that the &quot;Demo&quot; is not meant to work
                together with your own analysis. Please start from a clean
                session before loading your own data.
              </p>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="buttonSection">
          <div className="buttonWithText">
            <CustomButton
              text="Step 2"
              extraClassnames={["demoVersion"]}
              handleClick={handleButton2Click}
              id="demo-step-2"
              disabled={!button1Active || isFitOngoing || isLoopOngoing}
            />
            {/* <Button
              variant="contained"
              size="small"
              sx={{ m: 1 }}
              onClick={() => handleButton2Click()}
              className="button"
              id="demo-step-2"
              disabled={!button1Active || isFitOngoing || isLoopOngoing}
            >
              Step 2
            </Button> */}
            <div className="text">Create and display a Raman model</div>
          </div>
          {button2Active ? (
            <div className="textAfterClick" id="2-step-text">
              <p>
                You have automatically chosen and created a model of type Raman
                and displayed its output in the graph (green curve) together
                with the experimental data from the DEMO-DATA file.
              </p>
              <p>
                The new model is listed in the &quot;Experiments&quot; tab,
                where you can create your own models. In the
                &quot;Parameters&quot; tab, all the model parameters are shown
                and can be modified. You find more about the meaning of each
                parameter by hovering with your cursor on the parameter names.
              </p>
              <p>
                At the moment, all parameters are zero as the data has not been
                fitted yet. The output of the graph is also zero. Try playing
                with some model parameters. You can add oscillators by clicking
                on the green &quot;+&quot; sign that appears if you hover near
                to right side of the lower table.
              </p>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="buttonSection">
          <div className="buttonWithText">
            <CustomButton
              text="Step 3"
              extraClassnames={["demoVersion"]}
              handleClick={handleButton3Click}
              id="demo-step-3"
              disabled={
                !button1Active ||
                !button2Active ||
                isFitOngoing ||
                isLoopOngoing
              }
            />
            {/* <Button
              variant="contained"
              size="small"
              sx={{ m: 1 }}
              onClick={() => handleButton3Click()}
              className="button"
              id="demo-step-3"
              disabled={
                !button1Active ||
                !button2Active ||
                isFitOngoing ||
                isLoopOngoing
              }
            >
              Step 3
            </Button> */}
            <div className="text">Autofit the model to the data.</div>
          </div>
          {button3Active ? (
            <div className="textAfterClick" id="3-step-text">
              <p>
                You have run the AUTOFIT function, which has automatically
                fitted the Raman model to the Raman spectra. You can see that
                the green curve now fits the experimental data and that that the
                values in the &quot;Parameter&quot; tab changed.
              </p>
              <p>
                For each peak in the Raman spectrum, the AUTOFIT added another
                oscillator corresponding to a new line in the lower table. You
                can click on any parameter to change its value and see the
                effect this has on the model curve displayed in the graph. You
                can always click the &quot;AUTOFIT&quot; button in the graph
                window to come back to the best fit of the data. This will
                overwrite any parameter initialization you have done.
              </p>
              <p>
                For the AUTOFIT function, no initial parameter selection or
                other data manipulations are needed. More experienced users can
                fit manually by using the &quot;Fit&quot; tab in the top bar.
                The AUTOFIT functionality is not available for all model types,
                but fitting using the &quot;Fit&quot; tab is always possible.
              </p>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      <ConfirmationScreen
        onYes={handleButton1Click}
        onNo={() => setStep1ConfirmOpen(false)}
        text={
          "Starting a Demo will reset your current session. This is irreversible. Are you sure you want to continue?"
        }
        open={step1ConfirmOpen}
        setOpen={setStep1ConfirmOpen}
        element={step1ButtonRef}
      />
    </div>
  );
}

export default Demo;
