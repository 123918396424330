import React from "react";
import ManualContent from "../../components/ManualContent";
import registerImage from "../../images/register.jpg";
import activateImage from "../../images/activate.jpg";

const QuickStart = () => {
  return (
    <ManualContent title="Quick Start Guide">
      <p>
        Welcome to Speqqle! This guide will help you{" "}
        <strong>get up and running in just a few minutes</strong>.
      </p>

      <h2>Overview</h2>
      <p>In this Quick Start Guide, you will learn how to:</p>
      <ul>
        <li>
          <a href="#account">Create and activate your account</a>
        </li>
        <li>
          <a href="#packages">Choose and activate Speqqle packages</a>
        </li>
        <li>
          <a href="#interface">Navigate the Speqqle interface</a>
        </li>
        <li>
          <a href="#loading-data">Load your first dataset</a>
        </li>
        <li>
          <a href="#experiment">Select an experiment</a>
        </li>
        <li>
          <a href="#graph">Visualize your progress</a>
        </li>
        <li>
          <a href="#parameters">Adjust model parameters</a>
        </li>
        <li>
          <a href="#fit">Run a fit</a>
        </li>
        <li>
          <a href="#saving">Save and export results</a>
        </li>
        <li>
          <a href="#next">Explore advanced features</a>
        </li>
      </ul>

      <div className="package-section">
        <img
          src={registerImage}
          alt="Speqqle Registration"
          className="package-image"
        />

        <div className="package-text">
          <h2 id="account">1. Creating and Activating Your Account</h2>
          <p>Before using Speqqle, you need to create an account:</p>
          <ol>
            <li>
              <a href="#">Register</a> on the Speqqle website by providing your
              email and creating a password.
            </li>
            <li>
              Check your email inbox for a <strong>verification email</strong>.
            </li>
            <li>
              Click the <strong>verification link</strong> in the email to
              activate your account.
            </li>
            <li>Once verified, log in to Speqqle with your credentials.</li>
          </ol>
        </div>
      </div>

      <div className="package-section">
        <div className="image-container">
          <img
            src={activateImage}
            alt="Package activation"
            className="package-image"
          />
          <p className="image-caption">
            The alpha testing package is activated for this user.
          </p>
        </div>
        <div className="package-text">
          <h2 id="packages">2. Choosing Your Speqqle Packages</h2>
          <p>
            After logging in, you will land on your{" "}
            <strong>profile page</strong>. Here, you can:
          </p>
          <ul>
            <li>
              <strong>Activate different Speqqle packages</strong> based on your
              needs.
            </li>
            <li>
              During the <strong>beta testing</strong> phase, the free{" "}
              <strong>Beta Testing package</strong> will be available, providing
              full access for up to one month.
            </li>
            <li>
              Later, use the <strong>free version</strong>, which includes
              access to <strong>all packages and features</strong> for 7 days.
            </li>
            <li>
              After 7 days, you must <strong>activate specific packages</strong>{" "}
              to continue using Speqqle.
            </li>
            <li>
              If you already have packages activated, you will land directly on
              the <strong>Speqqle Dashboard</strong>, where you can start your
              analysis session.
            </li>
          </ul>
          <p>
            For details on available packages and activation, visit the{" "}
            <a href="../packages/overview">Packages</a> section.
          </p>
        </div>
      </div>

      <h2 id="interface">3. Navigating the Interface</h2>
      <p>
        Speqqle uses a <strong>tab-based workflow</strong> to keep everything
        structured. These tabs <strong>expand and collapse menus</strong> rather
        than acting as traditional navigation menus.
      </p>

      <p>
        In general, you work from <strong>left to right</strong> through the
        tabs, but you can always revisit previous steps.
      </p>

      <ul>
        <li>
          <strong>
            <a href="../data-loading/overview">Data</a>
          </strong>{" "}
          – Upload and manage datasets.
        </li>
        <li>
          <strong>
            <a href="../experiments/overview">Experiments</a>
          </strong>{" "}
          – Select and configure the spectroscopic model for your data.
        </li>
        <li>
          <strong>
            <a href="../parameters/overview">Parameters</a>
          </strong>{" "}
          – Adjust model parameters.
        </li>
        <li>
          <strong>
            <a href="../fit/overview">Fit</a>
          </strong>{" "}
          – Run and optimize the fitting process.
        </li>
        <li>
          <strong>
            <a href="../graphs/overview">Graphs</a>
          </strong>{" "}
          – Visualize and analyze results.
        </li>
        <li>
          <strong>
            <a href="../loop/overview">Loop</a>
          </strong>{" "}
          – Automate fitting processes for multiple datasets.
        </li>
      </ul>

      <h2 id="loading-data">4. Loading Your First Dataset</h2>
      <ol>
        <li>
          Click on the <strong>&quot;Data&quot;</strong> tab in the top left.
        </li>
        <li>
          Click on the <strong>&quot;Data Manager&quot;</strong> button to open
          the data manager.
        </li>
        <li>
          Select one or more datasets from your computer using the{" "}
          <strong>&quot;Files&quot;</strong> or{" "}
          <strong>&quot;Folders&quot;</strong> button.
        </li>
        <li>
          Approve the loading options by clicking on the{" "}
          <strong>&quot;Apply&quot;</strong> or{" "}
          <strong>&quot;Apply All&quot;</strong> button.
        </li>
        <li>
          Once uploaded, the data appears in the <strong>data window</strong>.
        </li>
      </ol>

      <h2 id="experiment">5. Selecting the Experiment</h2>
      <ol>
        <li>
          Click on the <strong>&quot;Experiments&quot;</strong> tab.
        </li>
        <li>
          Select <strong>&quot;Add a new model&quot;</strong> and choose the
          appropriate experiment.
        </li>
        <li>
          The model name will now appear in the{" "}
          <strong>experiment window</strong>.
        </li>
      </ol>

      <h2 id="graph">6. Visualizing Your Progress</h2>
      <ol>
        <li>
          A default graph is available. Click the <strong>lines icon</strong> in
          the top left of the graph to add curves.
        </li>
        <li>
          Adjust colors, line styles, and settings using the{" "}
          <strong>gear icon</strong> in the top left.
        </li>
        <li>
          Add additional graphs using the <strong>&quot;Add Graph&quot;</strong>{" "}
          button in the top right.
        </li>
      </ol>

      <h2 id="parameters">7. Adjusting Model Parameters</h2>
      <ol>
        <li>
          Click on the <strong>Parameters</strong> tab.
        </li>
        <li>Modify values manually or use sliders.</li>
        <li>Changes will immediately update the model curve.</li>
      </ol>

      <h2 id="fit">8. Running a Fit</h2>
      <ol>
        <li>
          Go to the <strong>Fit</strong> tab.
        </li>
        <li>
          Click <strong>&quot;Add χ² term&quot;</strong> to define the fitting
          conditions.
        </li>
        <li>
          Specify which experimental model curve should fit which dataset.
        </li>
        <li>
          Click <strong>&quot;Options&quot;</strong> to customize settings, or
          directly click <strong>&quot;Fit&quot;</strong>.
        </li>
      </ol>

      <h2 id="saving">9. Saving & Exporting Results</h2>
      <ul>
        <li>
          Click <strong>&quot;Save&quot;</strong> to store your work in Speqqle.
        </li>
        <li>
          Use the <strong>&quot;Export&quot;</strong> button in the graphs and
          parameter windows to save results.
        </li>
      </ul>

      <h2 id="next">10. Where to Go Next?</h2>
      <ul>
        <li>
          Learn more about the <a href="interface">User Interface</a>.
        </li>
        <li>
          Explore fitting options in the <a href="../fit/overview">Fitting</a>{" "}
          section.
        </li>
        <li>
          Speed up your work with <a href="../loop/overview">Loop</a>.
        </li>
        <li>
          Check out <a href="../packages/overview">Packages</a> for more
          options.
        </li>
      </ul>

      <p>Happy analyzing! 🚀</p>
    </ManualContent>
  );
};

export default QuickStart;
