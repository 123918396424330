import React from "react";
import ManualContent from "../../components/ManualContent";
import profileImage from "../../images/profile.jpg";
import packagesImage from "../../images/packages.jpg";
import dashboardImage from "../../images/dashboard.jpg";

const Interface = () => {
  return (
    <ManualContent title="Speqqle Interface Overview">
      <p>The Speqqle interface consists of two main sections:</p>

      <ul>
        <li>
          <a href="#profile-interface">Profile Interface</a> – Manage your
          account, packages, and general settings.
        </li>
        <li>
          <a href="#dashboard-interface">Dashboard Interface</a> – The main
          workspace for analysis and spectroscopic modeling.
        </li>
      </ul>

      <h2 id="profile-interface">
        1. Profile Interface: Managing Your Account
      </h2>
      <p>
        The <strong>Profile Interface</strong> is where users can manage their
        account settings, activate software packages, access documentation, and
        contact the Speqqle team.
      </p>

      <img src={profileImage} alt="Speqqle Profile Interface" />

      <h3>🔹 Key Features in the Profile Interface</h3>
      <ul>
        <li>
          <strong>Account Management:</strong> Update personal details and login
          credentials.
        </li>
        <li>
          <strong>Packages Management:</strong> View active, available, and
          expired packages, activate new packages, or renew expired ones.
        </li>
        <li>
          <strong>Support & Contact:</strong> Submit feedback, contact support,
          and review testing agreements.
        </li>
        <li>
          <strong>Legal & Policy Pages:</strong> Access the terms of use,
          privacy policy, and agreements.
        </li>
      </ul>

      <h3>📌 Navigating the Profile Interface</h3>
      <p>Key elements in the profile interface:</p>
      <ul>
        <li>
          <strong>Left Sidebar Menu:</strong> Provides quick access to:
          <ul>
            <li>
              <strong>Home:</strong> Overview of your account.
            </li>
            <li>
              <strong>Feedback:</strong> Submit feature suggestions and bug
              reports.
            </li>
            <li>
              <strong>Manual:</strong> Access the Speqqle user guide.
            </li>
            <li>
              <strong>Privacy Policy & Terms of Use:</strong> Review legal
              policies.
            </li>
            <li>
              <strong>Testing Agreement:</strong> For beta testers.
            </li>
            <li>
              <strong>User Details:</strong> Update your name and password. Here
              you can also delete or deactivate your account.
            </li>
            <li>
              <strong>Contact:</strong> Reach out to the Speqqle team.
            </li>
          </ul>
        </li>
        <li>
          <strong>Main Content Area:</strong> The displays of the selected
          section from the sidebar.
        </li>
        <li>
          <strong>Main Content Area &quot;Home&quot;:</strong> View active,
          available, and expired packages, activate new packages, or renew
          expired ones.
        </li>
        <li>
          <strong>&quot;TO SPEQQLE&quot; Button (Top Right):</strong> Switches
          to the <a href="#dashboard-interface">Dashboard Interface</a>.
        </li>
      </ul>

      <h3>
        Managing Packages via the <strong>Home</strong> Page
      </h3>

      <div className="package-section">
        <img
          src={packagesImage}
          alt="Speqqle Profile Interface"
          className="package-image"
        />

        <div className="package-text">
          <p>
            For the first <strong>7 days</strong>, you have full access to all
            features without needing to activate any package. After this period,
            you must activate specific packages to continue using Speqqle.
          </p>

          <p>
            If you haven&apos;t activated any packages yet, you will see a list
            of available packages. Click the <strong>&quot;More&quot;</strong>{" "}
            button for additional details about a package. To activate a
            package, simply click the <strong>&quot;Activate&quot;</strong>{" "}
            button.
          </p>

          <p>
            Each package has its own expiration date, which is displayed under
            the <strong>Active Packages</strong> section as{" "}
            <strong>&quot;Active Till: ...&quot;</strong>. Expired packages
            appear under the <strong>Expired Packages</strong> section. To renew
            an expired package, click the <strong>&quot;Renew&quot;</strong>{" "}
            button.
          </p>

          <p>
            <strong>Profile vs. Dashboard View:</strong>
          </p>
          <ul>
            <li>
              <strong>If you have no active packages:</strong> Your profile
              homepage will be displayed.
            </li>
            <li>
              <strong>If you have active packages:</strong> You will be taken
              directly to the <strong>Dashboard</strong> where you can start
              your analysis.
            </li>
          </ul>
        </div>
      </div>

      <h2 id="dashboard-interface">
        2. Dashboard Interface: The Analysis Workspace
      </h2>
      <p>
        The <strong>Dashboard Interface</strong> is the core analysis workspace
        where users upload data, configure models, run fits, and visualize
        results.
      </p>

      <h3>🔹 Key Elements of the Dashboard</h3>
      <ul>
        <li>
          <strong>Tab-Based Workflow:</strong> The dashboard follows a
          structured workflow:
          <ul>
            <li>
              <strong>Data:</strong> Upload and manage datasets.
            </li>
            <li>
              <strong>Experiments:</strong> Select and configure spectroscopic
              models.
            </li>
            <li>
              <strong>Parameters:</strong> Adjust fitting model parameters.
            </li>
            <li>
              <strong>Fit:</strong> Run and optimize the fitting process.
            </li>
            <li>
              <strong>Results:</strong> View fitted model outputs.
            </li>
            <li>
              <strong>Outputs:</strong> Export processed data.
            </li>
            <li>
              <strong>Loop:</strong> Automate repetitive analyses.
            </li>
          </ul>
        </li>
      </ul>

      <img src={dashboardImage} alt="Speqqle Dashboard Interface" />

      <ul>
        <li>
          <strong>Top Navigation Bar:</strong> Provides quick access to:
          <ul>
            <li>
              <strong>Feedback Button:</strong> Report issues and provide
              suggestions.
            </li>
            <li>
              <strong>Add Graph Button:</strong> Create visual representations
              of data.
            </li>
            <li>
              <strong>Demo, Open, Save Buttons:</strong> Manage projects.
            </li>
            <li>
              <strong>User Settings Icon:</strong> Adjust software preferences.
            </li>
          </ul>
        </li>
        <li>
          <strong>Graph Section:</strong> Displays real-time data,
          fits, and parameter adjustments.
        </li>
      </ul>

      <h3>📌 Navigating the Dashboard</h3>
      <p>Follow these steps for a structured workflow:</p>
      <ol>
        <li>
          <strong>Start by Uploading Data</strong> in the{" "}
          <a href="../data-loading/overview">Data tab</a>.
        </li>
        <li>
          <strong>Choose an Experiment Model</strong> in the{" "}
          <a href="../experiments/overview">Experiments tab</a>.
        </li>
        <li>
          <strong>Adjust Parameters</strong> before running a fit.
        </li>
        <li>
          <strong>Run the Fit</strong> to optimize the model.
        </li>
        <li>
          <strong>Analyze Results</strong> in the{" "}
          <a href="../graphs/overview">Graphs</a> tab.
        </li>
        <li>
          <strong>Save or Export Data</strong> as needed.
        </li>
      </ol>

      <h2>Next Steps</h2>
      <p>For a deeper dive into each tab, check out these detailed sections:</p>
      <ul>
        <li>
          <a href="../data-loading/overview">Data Loading</a>
        </li>
        <li>
          <a href="../experiments/overview">Experiments</a>
        </li>
        <li>
          <a href="../parameters/overview">Parameters</a>
        </li>
        <li>
          <a href="../fit/overview">Fit Process</a>
        </li>
        <li>
          <a href="../graphs/overview">Graphs & Visualization</a>
        </li>
        <li>
          <a href="../loop/overview">Loop Automation</a>
        </li>
      </ul>

      <p>
        🚀 Now you&apos;re ready to explore Speqqle&apos;s full capabilities!
      </p>
    </ManualContent>
  );
};

export default Interface;
