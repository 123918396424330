import React from "react";
import ManualContent from "../../components/ManualContent";
import { Link } from "react-router-dom";

const Overview = () => {
  return (
    <ManualContent title="Fit Overview">
      <p>
        <strong>Note:</strong> The fitting module in Speqqle is actively being
        developed, but it is already fully functional. You can run both
        model-based and model-independent fits, and explore your data using
        highly customizable tools.
      </p>

      <h2>What is Fitting in Speqqle?</h2>
      <p>
        Fitting is the process of optimizing the parameters of a model so that
        its simulated output best matches your measured data. In Speqqle, this
        is typically done by minimizing the chi-square value, which measures the
        difference between your dataset and the model.
      </p>
      <p>
        You can fit optical spectra such as reflectivity, transmission,
        ellipsometry, and Raman data — all within the same session. Speqqle
        supports both traditional model-based fitting and model-independent
        variational approaches.
      </p>

      <h2>Why Would You Perform a Fit?</h2>
      <p>
        Fitting allows you to extract physical parameters from your
        measurements. For example, you might determine optical constants,
        material thicknesses, temperature, carrier densities, or oscillator
        strengths — depending on the experiment type and model.
      </p>
      <p>
        It is also a powerful tool for verifying experimental results, comparing
        different samples, or simulating hypothetical configurations.
      </p>

      <h2>What Can You Fit?</h2>
      <p>Speqqle allows you to fit:</p>
      <ul>
        <li>
          <strong>Single datasets</strong>: such as one reflectivity curve.
        </li>
        <li>
          <strong>Simultaneous datasets</strong>: such as reflectivity and
          transmission measured on the same sample.
        </li>
        <li>
          <strong>Multiple models</strong>: such as a multilayer stack using
          submodels for each material.
        </li>
        <li>
          <strong>Combined data from different sources</strong>: such as near
          field and far field optical data on the same material.
        </li>
      </ul>
      <p>
        You can assign weights to different datasets and manage their influence
        on the overall fit.
      </p>

      <h2>How to Perform a Fit</h2>
      <ol>
        <li>
          Click the <strong>Fit</strong> tab in the top navigation.
        </li>
        <li>
          Use <strong>Add χ² term</strong> to define which data should be
          compared to which model output.
        </li>
        <li>
          You can add multiple chi-square terms to perform simultaneous fitting.
        </li>
        <li>
          Use the <strong>Options</strong> menu to configure iteration settings
          and update frequency.
        </li>
        <li>
          Click <strong>Fit</strong> to begin optimization.
        </li>
      </ol>

      <p>
        The result will appear in your graphs and parameters tabs. You can
        evaluate performance by inspecting the chi-square values and visual
        comparison between model and data.
      </p>
      <p>
        Learn more in the <Link to="/fit/least-sqr">Least Squares Fitting</Link>{" "}
        section.
      </p>

      <h2>Fitting Methods: Least Squares vs. VDF</h2>
      <p>Speqqle supports two main fitting approaches:</p>
      <ul>
        <li>
          <Link to="/fit/least-sqr">
            <strong>Least Squares Fitting:</strong>
          </Link>{" "}
          A model-based approach where you define a physical model and adjust
          parameters to minimize the chi-square. Suitable when you have a clear
          idea of the experiment and material structure.
        </li>
        <li>
          <Link to="/fit/vdf-fit">
            {" "}
            <strong>VDF (Variational Dielectric Function) Fitting:</strong>
          </Link>{" "}
          A model-independent method that reconstructs the dielectric function
          directly from data while respecting physical constraints (e.g.
          Kramers–Kronig relations). It is ideal for free-form fitting or
          validating model assumptions.
        </li>
      </ul>

      <p>
        For more information about variational fitting, see
        <Link to="/fit/vdf-fit"> VDF Fitting</Link>.
      </p>

      <h2>Refining Your Fit</h2>
      <ul>
        <li>
          Undo any fit from the <strong>Fit window</strong> if the results are
          unsatisfactory.
        </li>
        <li>
          Use grouping, fixing, and constraints to guide the optimizer toward
          realistic results.
        </li>
        <li>
          Improve or reduce the influence of a specific dataset using{" "}
          <strong>weights</strong> or modify error bar assumptions (see the
          <Link to="/fit/least-sqr"> chi-square discussion</Link>).
        </li>
        <li>Save and export your results for reporting or post-processing.</li>
      </ul>

      <h2>Troubleshooting and Mistakes to Avoid</h2>
      <ul>
        <li>
          <strong>Incorrect model selection:</strong> The optimizer can only
          refine parameters within the constraints of the model you choose. If
          your model doesn&apos;t reflect the actual experimental setup, even a
          mathematically perfect fit may be physically meaningless. For example,
          fitting a sample that consists of a thin film on a substrate using a
          bulk model will likely give poor or misleading results. Always start
          by choosing a model that matches your experimental geometry and
          measurement type.
        </li>

        <li>
          <strong>Unrealistic starting values:</strong> Optimization algorithms
          rely on initial parameter guesses. If the starting values are far from
          the correct range, the fit may converge to a non-physical solution or
          fail entirely. Always review your initial values and compare them with
          expected literature values or known physical limits before starting
          the fit.
        </li>
        <li>
          <strong>Overfitting:</strong> Too many free parameters or too small
          error bars can lead to fitting noise rather than data.
        </li>
        <li>
          <strong>Ignoring weights and errors:</strong> These control which data
          points matter most in the fit. Adjust them to reflect your priorities.
        </li>
        <li>
          <strong>Expecting one solution:</strong> Many fits have multiple local
          minima. Try different starting values or explore variational fitting
          if unsure.
        </li>
      </ul>

      <h2>Next Steps</h2>
      <ul>
        <li>
          Learn about <Link to="/fit/least-sqr">Least Squares Fitting</Link> to
          understand optimization in Speqqle.
        </li>
        <li>
          Explore <Link to="/fit/vdf-fit">VDF Fitting</Link> for
          model-independent analysis.
        </li>
        <li>
          See how{" "}
          <Link to="/manual/fit/kk-trans">Kramers–Kronig constraints</Link> are
          used in physics-based fitting.
        </li>
        <li>
          Review <Link to="/parameters/overview">Parameter tools</Link> for
          fixing, grouping, and saving model values.
        </li>
      </ul>
    </ManualContent>
  );
};

export default Overview;
