import React from "react";
import ManualContent from "../../components/ManualContent";
import image1 from "../../images/exportgraph.jpg";

const Exporting = () => {
  return (
    <ManualContent title="Exporting Graphs">
      <h2>Saving a Graph as an Image</h2>

      <p>
        To save a graph as an image, click the <strong>camera icon</strong>
        located in the top left corner of the graph window. This will generate a
        high-quality image of the graph, which can be used for reports,
        presentations, or publications.
      </p>

      <img src={image1} alt="Exporting and Saving Graphs" width="800" />

      <h2>Saving Your Entire Analysis Session</h2>

      <p>
        For a more comprehensive backup, you can save your entire session,
        including all graphs, models, datasets, and analysis progress.
      </p>

      <p>
        To do this, click the <strong>&quot;Save&quot;</strong> button in the top left
        corner of the session window.
      </p>

      <p>
        This will generate a Speqqle session file (<strong>*.spqs</strong>),
        which you can reopen at any time to resume your analysis exactly where
        you left off.
      </p>
    </ManualContent>
  );
};

export default Exporting;
