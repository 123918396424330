import React from "react";
import ManualContent from "../../components/ManualContent";
import experimentTabImage from "../../images/Experiment_tabn.jpg";
import experimentNewModelImage from "../../images/Experiment_newmodeln.jpg";
import experimentLoadedModelImage from "../../images/Experiment_loadedmodeln.jpg";

const Overview = () => {
  return (
    <ManualContent title="Experiments">
      <p>
        When fitting or simulating a spectroscopic response, the first step is
        to determine the type of experiment you performed or wish to model.
        Spectroscopic experiments vary widely and can include techniques such as
        reflection, transmission, Faraday rotation, THz time-domain
        spectroscopy, and even near-field spectroscopy. Thus after loading your
        data, your next task in Speqqle is to select the appropriate{" "}
        <strong>Experiment</strong> that corresponds to your dataset.
      </p>

      <p>
        In Speqqle, the <strong>Experiments</strong> tab allows you to add,
        load, and manage a <strong>set of theoretical models</strong> that
        describe your experiment. These models define:
      </p>

      <ul>
        <li>
          The <strong>spectroscopic measurement setup</strong> (e.g.,
          transmission, reflection, ellipsometry).
        </li>
        <li>
          The <strong>physical properties of the sample</strong> (e.g., layer
          structure, refractive index, dielectric function).
        </li>
        <li>
          The{" "}
          <strong>interaction between the sample and the probe signal</strong>{" "}
          (e.g., light, electrons, or phonons).
        </li>
      </ul>

      <p>
        By applying these models, you can{" "}
        <strong>
          fit experimental data, perform simulations, and optimize
          configurations
        </strong>{" "}
        before conducting real-world measurements.
      </p>

      <h2>Managing Experiments in Speqqle</h2>
      <p>
        The <strong>Experiments tab</strong> is the central interface for
        handling spectroscopic models. Clicking this tab will{" "}
        <strong>expand or collapse</strong>
        the experiment window, where you can:
      </p>

      <ul>
        <li>
          <strong>Add new experimental models</strong> to fit your dataset.
        </li>
        <li>
          <strong>Load previously saved models</strong> for further analysis.
        </li>
        <li>
          <strong>Manage all active experiment models</strong> within the
          Experiments tab.
        </li>
      </ul>

      <h2>How to Use the Experiments Tab</h2>
      <p>
        The Experiments tab helps you manage models effectively. Below are the
        main actions you can take:
      </p>

      <div className="image-row">
        <div className="image-container">
          <img
            src={experimentTabImage}
            alt="Speqqle Experiment tab"
            className="workflow-image"
          />
          <p className="image-caption">
            <strong>Opening the Experiments Tab:</strong> Click to expand and
            manage your experimental models.
          </p>
        </div>
        <div className="image-container">
          <img
            src={experimentNewModelImage}
            alt="Speqqle new model"
            className="workflow-image"
          />
          <p className="image-caption">
            <strong>Adding a New Model:</strong> Select a model that fits your
            dataset from the available options.
          </p>
        </div>
        <div className="image-container">
          <img
            src={experimentLoadedModelImage}
            alt="Speqqle loaded models"
            className="workflow-image"
          />
          <p className="image-caption">
            <strong>Managing Models:</strong> View, rename, link, remove, or
            adjust loaded models.
          </p>
        </div>
      </div>

      <h2>Understanding Models in Speqqle</h2>

      <p>
        In Speqqle, an experiment can consist of a <strong>Main Model</strong>{" "}
        and associated <strong>Submodels</strong>. These models work together to
        describe the interaction between the sample and the signal.
        Understanding their roles is essential for accurately fitting data and
        interpreting results.
      </p>

      <p>
        However, not all experiments are structured this way. Some models, such
        as the <strong>Raman model</strong>, combine both the material response
        function and key experimental parameters within a single model. The
        Raman model, for example, includes instrument correction parameters and
        sample temperature settings, making additional submodels unnecessary.
      </p>

      <h3>Main Model vs. Submodels</h3>

      <p>
        Where applicable, experiment configuration parameters (such as
        polarization settings) are captured within the main model, while
        material models are linked as submodels.
      </p>

      <ul>
        <li>
          <strong>Main Model:</strong> Defines the full experimental setup,
          including the spectroscopic technique (e.g., reflection, transmission,
          ellipsometry) and key configuration parameters such as layer structure
          and measurement conditions.
        </li>
        <li>
          <strong>Submodels:</strong> Represent specific material responses
          within the experiment, such as individual material layers. Submodels
          are linked to the main model and, in most cases, cannot be used
          independently.
        </li>
      </ul>

      <h2>Jump to:</h2>
      <p>Click on a topic below to quickly navigate to the relevant section:</p>
      <ul>
        <li>
          <a href="newmodels">Adding a New Experiment Model</a>
        </li>
        <li>
          <a href="loadmodels">Loading and Saving Experiment Models</a>
        </li>
        <li>
          <a href="modellist">
            Viewing and Managing all models in the Experiment Model List
          </a>
        </li>
        <li>
          <a href="managingmodels">
            Linking, Renaming, and Removing Experiment Models
          </a>
        </li>
      </ul>
    </ManualContent>
  );
};

export default Overview;
