import React from "react";
import { MathJax, MathJaxContext } from "better-react-mathjax";
import ManualContent from "../../components/ManualContent";

const LeastSqr = () => {
  return (
    <MathJaxContext>
      <ManualContent title="Least Squares Fitting">
        <p>
          <strong>Note:</strong> The fitting module is still under active
          development, but it is fully functional. You are encouraged to explore
          its features and refine your fitting process.
        </p>

        <h2>Introduction to Least Squares Fitting</h2>
        <p>
          Least squares fitting is a mathematical optimization technique used to
          find the best-fit parameters of a model by minimizing the sum of the
          squared differences between experimental data points and model
          predictions. In Speqqle, this method is applied to spectroscopic data
          fitting.
        </p>

        <h2>Mathematical Definition</h2>
        <p>
          Given a set of <MathJax inline>{"N"}</MathJax> experimental data
          points <MathJax inline>{"\\(x_i, y_i\\)"}</MathJax> where{" "}
          <MathJax inline>{"i = 1,2, ... N"}</MathJax>, we define a model
          function <MathJax inline>{"\\(f(x, p_1, p_2, ... p_M)\\)"}</MathJax>{" "}
          that depends on a set of parameters{" "}
          <MathJax inline>{"\\(p_1, p_2, ... p_M\\)"}</MathJax>. The goal of
          least squares fitting is to find the optimal parameters that minimize
          the chi-square function:
        </p>
        <MathJax>
          {
            "\\[ \\chi^2 = \\sum_{i=1}^{N} \\frac{(y_i - f(x_i, p_1, p_2, ... p_M))^2}{\\sigma_i^2} \\]"
          }
        </MathJax>
        <p>
          Here, <MathJax inline>{"\\( \\sigma_i\\)"}</MathJax> represents the
          experimental error of each data point. Minimizing{" "}
          <MathJax inline>{"\\( \\chi^2 \\)"}</MathJax> ensures that the model
          best fits the given dataset.
        </p>

        <h2>Nonlinear Fitting and the Levenberg-Marquardt Algorithm</h2>
        <p>
          When the model function <MathJax inline>{"\\( f(x, p) \\)"}</MathJax>{" "}
          is nonlinear in parameters, the optimization requires iterative
          methods. Speqqle uses the{" "}
          <strong>Levenberg-Marquardt (LM) algorithm</strong>, which combines
          the gradient descent and inverse Hessian matrix methods.
        </p>
        <ul>
          <li>
            <strong>Gradient Descent:</strong> Moves the parameters in the
            steepest descent direction to reduce{" "}
            <MathJax inline>{"\\( \\chi^2 \\)"}</MathJax>, ensuring rapid
            convergence from far away.
          </li>
          <li>
            <strong>Inverse Hessian Matrix:</strong> Uses second-order
            derivatives for precise adjustments near the minimum, refining the
            final solution.
          </li>
        </ul>
        <p>
          The LM algorithm balances these two approaches to achieve fast and
          stable parameter optimization.
        </p>

        <h2>How to Perform a Least Squares Fit in Speqqle</h2>
        <ol>
          <li>
            Go to the <strong>Fit</strong> tab in Speqqle.
          </li>
          <li>
            Click on <strong>Add Chi-Square Term</strong> to define which
            dataset should be fitted with which model.
          </li>
          <li>
            Choose the iterations, update frequency, and optimization settings
            in the <strong>Options</strong> menu.
          </li>
          <li>
            Click <strong>Fit</strong> to begin the optimization.
          </li>
          <li>
            If unsatisfied with the results, refine the fit by adjusting
            parameter constraints, relationships, or values, then rerun the fit.
          </li>
        </ol>

        <h2>Understanding Chi-Square in Simultaneous Fitting</h2>
        <p>
          When fitting a single dataset, the chi-square function
          <MathJax inline>{"\\( \\chi^2 \\)"}</MathJax> quantifies the goodness
          of fit by comparing the model to the measured data. However, in a
          simultaneous fit across multiple datasets, the situation becomes more
          complex because different datasets may have vastly different numbers
          of points and measurement characteristics.
        </p>

        <p>
          In simultaneous fitting, Speqqle calculates a separate chi-square
          value for each dataset:
        </p>

        <MathJax>
          {
            "\\[ \\chi^2_j = \\sum_{i=1}^{N_j} \\frac{(y_{i,j} - f(x_{i,j}, p))^2}{\\sigma_{i,j}^2} \\]"
          }
        </MathJax>

        <p>
          where j refers to the dataset index, N<sub>j</sub> is the number of
          data points in that dataset, and σ<sub>i,j</sub> represents the
          uncertainty of each point.
        </p>

        <p>
          Since different datasets may have very different sizes, their
          individual chi-square contributions can be very different. This means
          that datasets with more data points will dominate the fit, potentially
          drowning out smaller datasets. The effect of each dataset in the
          overall fit can be controlled using weights.
        </p>

        <h2>Using Weights in Simultaneous Fitting</h2>
        <p>
          Weights allow users to adjust the relative importance of different
          datasets or even specific regions within a dataset. This is
          particularly useful when:
        </p>
        <ul>
          <li>
            Some datasets have significantly more points than others, leading to
            an unbalanced fit.
          </li>
          <li>
            Certain spectral regions are more important and should influence the
            fit more strongly.
          </li>
          <li>
            Experimental noise is unevenly distributed, requiring compensation.
          </li>
        </ul>

        <p>The weighted chi-square is defined as:</p>

        <MathJax>
          {
            "\\[ \\chi^2 = \\sum_j w_j \\chi^2_j = \\sum_j w_j \\sum_{i=1}^{N_j} \\frac{(y_{i,j} - f(x_{i,j}, p))^2}{\\sigma_{i,j}^2} \\]"
          }
        </MathJax>

        <p>
          Here, w<sub>j</sub> is the weight assigned to dataset j. Increasing
          the weight of a dataset will amplify its contribution to the total
          chi-square, effectively giving it more influence over the fit results.
        </p>

        <h3>How to Adjust Weights in Speqqle</h3>
        <ul>
          <li>
            To increase the effect of a dataset, assign it a larger weight.
          </li>
          <li>To downplay a dataset, reduce its weight. </li>
          <li>
            When you change the weights, you will see the total chi-square
            changing, but the sub chi-square remains the same. Use weights to
            balance out large differences in dataset sizes.
          </li>
          <li>
            Apply localized weights in specific spectral regions where accuracy
            is critical. currently you need to load in your dataset several
            times and create seperate chi-squares for specific regions.
          </li>
        </ul>

        <h2>The Role of Error Bars in Fitting</h2>
        <p>
          Error bars σ<sub>i</sub> play a crucial role in defining how much each
          data point contributes to the chi-square function. They serve two main
          purposes:
        </p>
        <ul>
          <li>
            They ensure that data points with high uncertainty have less
            influence on the fit.
          </li>
          <li>
            They allow the chi-square function to account for measurement
            accuracy, rather than treating all points equally.
          </li>
        </ul>

        <p>
          If the error bars are incorrectly assigned, they can mislead the
          optimization process:
        </p>
        <ul>
          <li>
            If error bars are too small, small deviations will be overly
            penalized, making the fit too sensitive.
          </li>
          <li>
            If error bars are too large, the dataset will have very little
            impact, leading to an unreliable fit.
          </li>
        </ul>

        <h3>How to Properly Use Error Bars</h3>
        <p>
          Error bars play a critical role in weighting data points in the
          fitting process. They determine how much confidence is given to each
          measurement, influencing how strongly different points contribute to
          the chi-square minimization.
        </p>

        <p>
          In most real-world scenarios, error bars should reflect the
          measurement uncertainty, which depends on the experimental method and
          the instrument used. A well-calibrated error model is essential for
          reliable parameter estimation.
        </p>

        <h3>Absolute Error vs. Percentage-Based Error</h3>
        <ul>
          <li>
            <strong>Flat Absolute Error:</strong>A constant uncertainty is
            applied to all data points, regardless of their value. This means
            that low-intensity regions contribute just as much to the chi-square
            function as high-intensity regions. While simple, this approach can
            disproportionately influence results if the dataset spans multiple
            orders of magnitude.
          </li>
          <li>
            <strong>Percentage-Based Error:</strong>
            The uncertainty scales with the data value. Higher-intensity points
            receive larger error bars, reducing their contribution to the
            chi-square function, while lower-intensity points have smaller error
            bars, making them more significant in the fit. This is often a
            better reflection of real experimental uncertainty.
          </li>
        </ul>

        <p>
          A realistic error model should take into account the measurement
          technique and the detection limits of the instrument. Some methods,
          such as lock-in detection, have well-characterized noise floors, while
          others, such as optical transmission measurements, have uncertainties
          that scale with signal intensity.
        </p>

        <h2>Current and Future Handling of Error Bars in Speqqle</h2>
        <p>
          Currently, Speqqle assumes a flat absolute error for calculating the
          chi-square function. This means that all points are treated equally in
          terms of their uncertainty, regardless of signal strength or noise
          characteristics.
        </p>

        <p>
          In future updates, Speqqle will allow users to choose between
          different error-handling approaches:
        </p>

        <ul>
          <li>
            Uploading custom error bars to reflect real experimental
            uncertainties.
          </li>
          <li>
            Selecting a flat absolute error for consistency with the current
            implementation.
          </li>
          <li>
            Using a percentage-based error model with a user-defined minimum
            value to prevent near-zero points from being overemphasized.
          </li>
        </ul>

        <p>
          These options will provide greater flexibility and allow users to
          fine-tune the influence of different datasets and spectral regions in
          their fits.
        </p>

        <h2>Combining Weights and Error Bars for Optimal Fitting</h2>
        <p>
          In Speqqle, both weights and error bars can be used to fine-tune the
          fitting process:
        </p>

        <ul>
          <li>
            <strong>Weights adjust the dataset importance</strong>, allowing
            users to control how different datasets influence the optimization.
          </li>
          <li>
            <strong>Error bars determine individual point sensitivity</strong>,
            ensuring that the fit is not disproportionately affected by noise.
          </li>
          <li>
            A well-balanced fit should have properly chosen weights and error
            bars so that no dataset or spectral region unfairly dominates the
            chi-square function.
          </li>
        </ul>

        <p>
          Once Speqqle introduces support for user-defined error models, users
          will have greater control over how uncertainties influence their fits,
          allowing for more accurate parameter estimation.
        </p>

        <h2>Practical Example</h2>
        <p>Suppose you are fitting two datasets:</p>
        <ul>
          <li>
            Dataset A: A high-resolution reflectivity spectrum with 5000 data
            points.
          </li>
          <li>
            Dataset B: A low-resolution transmission spectrum with 300 points.
          </li>
        </ul>

        <p>
          By default, the chi-square function will be dominated by Dataset A
          simply due to its size. If both datasets are equally important, you
          might:
        </p>
        <ul>
          <li>
            Assign a higher weight to Dataset B to compensate for its lower
            number of points.
          </li>
          <li>
            Check that error bars in Dataset A are properly set to avoid
            artificially inflating its impact.
          </li>
          <li>
            Use localized weights to emphasize specific regions in the
            reflectivity spectrum where key features appear.
          </li>
        </ul>

        <p>
          These adjustments ensure that both datasets contribute meaningfully to
          the final fit results.
        </p>

        <h2>Conclusion</h2>
        <p>
          Understanding and properly using chi-square, weights, and error bars
          is essential for obtaining accurate fitting results in simultaneous
          datasets. Adjusting weights ensures that datasets with different sizes
          contribute appropriately, while error bars prevent unreliable data
          points from misleading the optimization.
        </p>

        <p>
          In Speqqle, users have full control over these parameters, allowing
          them to fine-tune their fits for the best possible accuracy.
        </p>

        <h2>Common Mistakes and How to Avoid Them</h2>
        <ul>
          <li>
            <strong>Overfitting:</strong> Using too many free parameters may fit
            noise rather than real data. Use constraints where applicable.
          </li>
          <li>
            <strong>Poor Initial Values:</strong> Poor starting values can lead
            to divergence. Use reasonable initial guesses.
          </li>
          <li>
            <strong>Ignoring Residuals:</strong> Always inspect residual plots
            to check for systematic deviations.
          </li>
        </ul>

        <h2>Advanced Features in Speqqle</h2>
        <ul>
          <li>
            <a href="../parameters/constraints">Constraining Parameters</a>:
            Apply limits to prevent unphysical results.
          </li>
          <li>
            <a href="../parameters/fixing">Fixing and Freeing Parameters</a>:
            Control which parameters are optimized.
          </li>
          <li>
            <a href="../graphs/interactive">Visualizing Fit Results</a>: Use
            interactive graphs to analyze residuals and trends.
          </li>
        </ul>
      </ManualContent>
    </MathJaxContext>
  );
};

export default LeastSqr;
