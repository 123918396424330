import React from "react";
import ManualContent from "../../components/ManualContent";

const Saving = () => {
  return (
    <ManualContent title="Saving Models and Submodels">
      <p>
        Saving models and submodels allows you to store your progress and reuse
        specific model configurations for future analyses. This is especially
        useful when working with complex models that require extensive parameter
        adjustments.
      </p>

      <h2>Why Save a Model?</h2>

      <p>Saving models is beneficial in multiple scenarios:</p>

      <ul>
        <li>
          <strong>Reusing a setup:</strong> If you have optimized a model,
          saving it ensures you can reload the exact configuration later.
        </li>
        <li>
          <strong>Sharing models:</strong> Saved models can be shared with
          colleagues for collaboration and consistency in data analysis.
        </li>
        <li>
          <strong>Preserving parameter adjustments:</strong>
          Avoid losing fine-tuned parameter values between sessions.
        </li>
      </ul>

      <h2>How to Save a Model</h2>

      <p>To save a model:</p>

      <ol>
        <li>
          Go to the <strong>Parameters</strong> tab.
        </li>
        <li>Locate the model you want to save in the parameter list.</li>
        <li>
          Click the <strong>settings icon</strong> (wheel) in the top bar of the
          model.
        </li>
        <li>
          Click the <strong>floppy disk (save) icon</strong>.
        </li>
        <li>The model is saved to your download folder.</li>
      </ol>

      <h2>Saving Models with Submodels</h2>

      <p>
        If a main model contains submodels, saving the main model will
        automatically include all associated submodels.
      </p>

      <ul>
        <li>
          For example, in an <strong>R/T/A/Ellips</strong> model, saving the
          main model will also save its material submodels.
        </li>
        <li>
          When you reload the saved model, all linked submodels will be
          restored.
        </li>
      </ul>

      <h2>Saving Submodels Separately</h2>

      <p>
        Submodels, such as material models, can also be saved independently.
        However, this will only save the selected submodel and{" "}
        <strong>not</strong>
        the main model it was associated with.
      </p>

      <p>To save a submodel separately:</p>

      <ol>
        <li>
          Locate the submodel in the <strong>Parameters</strong> tab.
        </li>
        <li>
          Click the <strong>settings icon</strong> in the top bar of the
          submodel.
        </li>
        <li>
          Click the <strong>floppy disk (save) icon</strong>.
        </li>
      </ol>

      <h2>Loading a Saved Model</h2>

      <p>To reuse a previously saved model:</p>

      <ol>
        <li>
          Go to the <strong>Experiments</strong> tab.
        </li>
        <li>
          Click the <strong>&quot;Load Experiment&quot;</strong> button.
        </li>
        <li>Select the saved model file from your computer.</li>
        <li>
          Click <strong>Open</strong> to load the model into your session.
        </li>
        <li>
          The model will appear in the <strong>Experiments</strong> and{" "}
          <strong>Parameters</strong> tabs.
        </li>
      </ol>

      <p>
        If the loaded model includes submodels, they will be automatically
        restored along with the main model.
      </p>

      <h2>Loading a Saved Submodel</h2>

      <p>
        You can also load a submodel separately and apply it to an existing
        experiment:
      </p>

      <ol>
        <li>
          Go to the <strong>Experiments</strong> tab.
        </li>
        <li>
          Click the <strong>&quot;Load Experiment&quot;</strong> button.
        </li>
        <li>Select the submodel file from your computer.</li>
        <li>
          Click <strong>Open</strong> to load the submodel.
        </li>
        <li>
          In the <strong>Parameters</strong> tab, replace an existing material
          model with the loaded submodel by clicking on the material name and
          selecting the new submodel.
        </li>
      </ol>

      <h2>Next Steps</h2>

      <p>
        Now that you understand how to save models and submodels, you may want
        to explore:
      </p>

      <ul>
        <li>
          <a href="editing">Editing Parameter Values</a>: Modify saved models by
          adjusting parameter settings.
        </li>
        <li>
          <a href="fixing">Fixing and Freeing Parameters</a>: Control which
          parameters remain constant or change during a fit.
        </li>
      </ul>
    </ManualContent>
  );
};

export default Saving;
