import React, { useState, useContext } from "react";
import Range from "../../leftSide/Files/Range";
import units from "../../../utils/units";
import {
  MenuItem,
  Select,
  FormControlLabel,
  Checkbox,
  TextField,
  Button,
} from "@mui/material";
import addIcon from "../../../res/icons/add.png";
import deleteIcon from "../../../res/icons/delete.png";
import "./parameters.scss";
import { deepCopy } from "../../leftSide/Models/modelLogic";
import {
  convertToKelvin,
  doesAnyRangeWrapZero,
  filterCurveDataToRange,
  generateModelsRangesQuantPairsForModelDist,
  getRangesForModelsFromGraphs,
  segmentsIntoSingleCurve,
  splitCurveIntoSegmentsByEdges,
} from "../../../utils/helpers";
import { processFileWithOptions } from "../../leftSide/Files/fileProcessing/processingLogic";
import { AuthContext } from "../../../context/AuthContext";
import { GraphContext } from "../../../context/GraphContext";
import { DashboardContext } from "../../../context/DashboardContext";
import { GeneralContext } from "../../../context/GeneralContext";
import { createModelDistPayload } from "../../middle/graphLogic";

function FileSettings(props) {
  const { file, confirmFile, modelID, modelSpeqqleID } = props;
  const { currentUser } = useContext(AuthContext);
  const { graphs } = useContext(GraphContext);
  const { modelData } = useContext(DashboardContext);
  const { recordedErrorLog } = useContext(GeneralContext);
  const [xUnit, setXUnit] = useState(file.xUnit);
  const [rangeMin, setRangeMin] = useState(file.dataRangeMin);
  const [rangeMax, setRangeMax] = useState(file.dataRangeMax);
  const [edges, setEdges] = useState(file.edges);
  const [autoCut, setAutoCut] = useState(true);
  const [onlyPos, setOnlyPos] = useState(false);
  const [temperature, setTemperature] = useState("");
  const [tempUnits, setTempUnits] = useState("K");
  const [highlightReq, setHighlightReq] = useState(false);

  const handleChange = (event) => {
    setTempUnits(event.target.value);
  };

  function updateCutMin(value, index) {
    try {
      const cutParamsCopy = deepCopy(edges);
      if (value === "") {
        cutParamsCopy[index] = { ...cutParamsCopy[index], min: value };
      } else {
        cutParamsCopy[index] = {
          ...cutParamsCopy[index],
          min: parseFloat(value),
        };
      }
      setEdges(cutParamsCopy);
    } catch (error) {
      recordedErrorLog("Min cut updated failure: ", error);
    }
  }

  function updateCutMax(value, index) {
    try {
      const cutParamsCopy = deepCopy(edges);
      if (value === "") {
        cutParamsCopy[index] = { ...cutParamsCopy[index], max: value };
      } else {
        cutParamsCopy[index] = {
          ...cutParamsCopy[index],
          max: parseFloat(value),
        };
      }
      setEdges(cutParamsCopy);
    } catch (error) {
      recordedErrorLog("Max cut update failure: ", error);
    }
  }

  function handleAddCut() {
    setEdges([...edges, { min: "", max: "" }]);
  }

  function handleDeleteCut(index) {
    setEdges(edges.filter((_, i) => i !== index));
  }

  const handleUnitChange = (e) => {
    try {
      const value = e.target.value;
      const unit = units.filter((u) => u.value === value)[0];
      setXUnit(unit);
    } catch (error) {
      recordedErrorLog("Unit change handler failure: ", error);
    }
  };

  const handleConfirmClick = () => {
    try {
      if (onlyPos && (temperature == "" || temperature == undefined)) {
        setHighlightReq(true);
      } else {
        let data = Object.prototype.hasOwnProperty.call(file, "dataPoints")
          ? file.dataPoints
          : processFileWithOptions(file.content, file.options, recordedErrorLog);

        data = filterCurveDataToRange(
          data,
          file.dataRangeMin,
          file.dataRangeMax
        );

        data = segmentsIntoSingleCurve(
          splitCurveIntoSegmentsByEdges(data, file.edges)
        );

        const filteredCutParams = edges.filter(
          (param) =>
            (param.min !== undefined && param.min !== "") ||
            (param.max !== undefined && param.max !== "")
        );

        let fitinfoLoc = {
          peak0auto: autoCut,
          edges: filteredCutParams,
          onlypos: onlyPos,
          npoints: file.npoints,
        };
        if (temperature != "") {
          fitinfoLoc = {
            ...fitinfoLoc,
            temperature: convertToKelvin(parseFloat(temperature), tempUnits),
          };
        }

        let range = {};
        range = { ...range, min: rangeMin != "" ? rangeMin : -1000 };
        range = { ...range, max: rangeMax != "" ? rangeMax : 1000 };

        if (Object.keys(range).length > 0) {
          fitinfoLoc = { ...fitinfoLoc, range: range };
        }

        const rangesForModels = getRangesForModelsFromGraphs(graphs);

        const parametersForCurvePayload =
          generateModelsRangesQuantPairsForModelDist(
            [modelID],
            rangesForModels,
            modelData
          );

        let payload = {
          User: currentUser.id,
          Data: {
            SendData: [
              {
                dataid: file.ID,
                data: data,
              },
            ],
          },
          Fit: {
            AutoFit: [
              {
                modeltype: modelSpeqqleID,
                modelid: modelID,
                dataid: file.ID,
                fitinfo: fitinfoLoc,
                quantity: 5, // Default Raman, we keep it 5 until there are more models with AutoFit functionality
              },
            ],
          },
        };

        if (parametersForCurvePayload.models.length > 0) {
          const modelDistPayload = createModelDistPayload(
            parametersForCurvePayload.models,
            rangesForModels,
            parametersForCurvePayload.quantityPairs,
            currentUser
          );

          payload = {
            ...payload,
            Model: modelDistPayload.Model,
          };
        }

        confirmFile(payload, modelID);
      }
    } catch (error) {
      recordedErrorLog("Confirm click handler failure: ", error);
    }
  };

  return (
    <div className="fileSettingContainer" data-testid="file-settings">
      <div className="fileName" id="autofit-data-file-name">
        {file.name}
      </div>
      <div className="unitsContainer">
        <div className="unitSelectorLabel">x-data unit: </div>
        <div className="xUnitSelectContainer">
          <Select
            labelId="selectUnit"
            id="unitSelection"
            value={xUnit.value}
            onChange={handleUnitChange}
            className="x_unit_select"
            style={{ paddingTop: "0px" }}
          >
            {units.map(({ name, value }) => (
              <MenuItem key={name} value={value}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>
      <Range
        initialMin={rangeMin}
        initialMax={rangeMax}
        updateMin={setRangeMin}
        updateMax={setRangeMax}
        rangeTitle={"Data Range:"}
      />
      <div className="autoCutting">
        <FormControlLabel
          control={
            <Checkbox onClick={() => setAutoCut(!autoCut)} checked={autoCut} />
          }
          label="Automatic 0-peak Cutting"
        />
        {!autoCut ? (
          <div className="note">Please exclude 0-peak manually</div>
        ) : (
          <></>
        )}
      </div>
      <div className="onlyPos">
        <FormControlLabel
          control={
            <Checkbox onClick={() => setOnlyPos(!onlyPos)} checked={onlyPos} />
          }
          label="Positive Values only"
        />
      </div>
      <div className="temperature">
        <TextField
          required={onlyPos}
          style={{
            height: "100%",
            width: "80px",
          }}
          label="Temp"
          value={temperature}
          onChange={(e) => setTemperature(e.target.value)}
          className="rangeVal"
          size="small"
          type="number"
          error={highlightReq}
        />
        <Select
          labelId="demo-simple-select-label"
          MenuProps={{
            container: () => document.getElementById("autoFit-modal"),
          }}
          id="demo-simple-select"
          value={tempUnits}
          defaultValue="K"
          onChange={handleChange}
          size="small"
          className="tempDropdown"
        >
          <MenuItem value={"C"}>C</MenuItem>
          <MenuItem value={"K"}>K</MenuItem>
          <MenuItem value={"F"}>F</MenuItem>
        </Select>
      </div>
      <div className="cutSettingsTitle">Exclusion Ranges:</div>
      <div
        className="allCutsContainer"
        style={edges.length > 1 ? {} : { height: "75px" }}
      >
        {edges &&
          edges.map((param, index) => {
            return (
              <div className="cutContainer" key={index}>
                <Range
                  initialMin={param.min}
                  initialMax={param.max}
                  updateMin={updateCutMin}
                  updateMax={updateCutMax}
                  rangeTitle={null}
                  index={index}
                  required={index === 0 && !autoCut}
                />
                <img
                  src={deleteIcon}
                  alt="delete Cut"
                  className="deleteCut"
                  onClick={() => handleDeleteCut(index)}
                />
              </div>
            );
          })}
        <img
          src={addIcon}
          alt="add another Cut"
          className="addCut"
          onClick={() => handleAddCut()}
        />
      </div>
      <div className="confirmButtonSection">
      <div className="warningNote">The Autofit will reset all model parameters</div>
        <Button
          variant="contained"
          size="small"
          sx={{ m: 1 }}
          onClick={() => handleConfirmClick()}
          className="confirmButton"
          id="confirm-autofit-button"
          disabled={
            !autoCut && edges.length > 0 && !doesAnyRangeWrapZero(edges)
          }
        >
          Autofit
        </Button>
      </div>
    </div>
  );
}

export default FileSettings;
