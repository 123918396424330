/**
 * @category LeftSide
 * @component
 * @module Files/Range
 * @description This component is responsible for displaying range choice with entry points for 'min' and 'max'
 * values. It's primarily used in 'StickyFileWindow' component.
 */
import React, { useState, useEffect, useContext } from "react";
import { TextField } from "@mui/material";
import { GeneralContext } from "../../../context/GeneralContext";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import "../leftSide.scss";

function Range(params) {
  const {
    initialMin,
    initialMax,
    updateMin,
    updateMax,
    rangeTitle,
    index,
    required,
    tooltip = null,
  } = params;

  const { recordedErrorLog } = useContext(GeneralContext);

  const [localMin, setLocalMin] = useState(initialMin);
  const [localMax, setLocalMax] = useState(initialMax);

  /**
   * This function is called when user changes the min value of the range. It updates the local state and calls
   * the updateMin function that is passed as a prop.
   * @function handleMinChange
   * @param {Event} e - This is the event that is triggered when user changes the min value of the range.
   */
  const handleMinChange = (e) => {
    try {
      if (e.target.value === "") {
        updateMin("", index);
        setLocalMin("");
      } else {
        const parsedValue = parseFloat(e.target.value);

        if (index !== undefined) {
          updateMin(parsedValue, index);
          setLocalMin(parsedValue);
        } else {
          updateMin(parsedValue);
          setLocalMin(parsedValue);
        }
      }
    } catch (error) {
      recordedErrorLog("Min change handler failure: ", error);
    }
  };

  /**
   * This function is called when user changes the max value of the range. It updates the local state and calls
   * the updateMax function that is passed as a prop.
   * @function handleMaxChange
   * @param {Event} e - This is the event that is triggered when user changes the max value of the range.
   */
  const handleMaxChange = (e) => {
    try {
      if (e.target.value === "") {
        updateMax("", index);
        setLocalMax("");
      } else {
        const parsedValue = parseFloat(e.target.value);

        if (index !== undefined) {
          updateMax(parsedValue, index);
          setLocalMax(parsedValue);
        } else {
          updateMax(parsedValue);
          setLocalMax(parsedValue);
        }
      }
    } catch (error) {
      recordedErrorLog("Max change handler failure: ", error);
    }
  };

  useEffect(() => {
    if (initialMin !== localMin) {
      setLocalMin(initialMin);
    }
    if (initialMax !== localMax) {
      setLocalMax(initialMax);
    }
  }, [initialMin, initialMax]);

  return (
    <div className="rangeContainer">
      {rangeTitle && (
        <div className="rangeTitle" title={tooltip}>
          {rangeTitle}
          {tooltip !== null ? <HelpOutlineIcon className="infoIcon" /> : <></>}
        </div>
      )}
      <div className="rangeValContainer">
        <TextField
          style={{
            height: "100%",
          }}
          label="Min"
          value={localMin}
          onChange={handleMinChange}
          className="rangeVal"
          size="small"
          type="number"
          required={required}
          error={localMin >= localMax && localMax !== "" && localMin !== ""}
        />
        <TextField
          style={{
            height: "100%",
          }}
          label="Max"
          value={localMax}
          onChange={handleMaxChange}
          className="rangeVal"
          size="small"
          type="number"
          required={required}
          error={localMin >= localMax && localMax !== "" && localMin !== ""}
        />
      </div>
    </div>
  );
}

export default Range;
