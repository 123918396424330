import React from "react";
import ManualContent from "../../components/ManualContent";
import parameterTabImage from "../../images/parametertab.jpg";
import parameterStickyWindowImage from "../../images/parametertastickywindow.jpg";

const Overview = () => {
  return (
    <ManualContent title="Parameters Overview">
      <p>
        The <strong>Parameters</strong> tab in Speqqle allows you to view,
        initialize, adjust, and manage the fitting parameters of your Experiment
        models. Here, you can modify model parameters, set constraints, link or
        group them, and define whether parameters are included or excluded from
        a fit.
      </p>

      <p>
        A built-in color-coding system alerts you if a parameter has a
        suspicious value that may require attention.
      </p>

      <div className="image-row two-images">
        <div className="image-container">
          <img
            src={parameterTabImage}
            alt="Parameter Tab Overview"
            className="workflow-image"
          />
          <p className="image-caption">
            The Parameter tab is open: users can view and adjust fitting
            parameters.
          </p>
        </div>
        <div className="image-container">
          <img
            src={parameterStickyWindowImage}
            alt="Sticky Window in Parameter Tab"
            className="workflow-image"
          />
          <p className="image-caption">
            When you select a parameter in a model, a window opens to interact
            with that parameter. We call this window the &quot;Parameter Sticky
            Window&quot;.
          </p>
        </div>
      </div>

      <h2>What You Can Do in the Parameters Tab</h2>

      <p>The Parameters tab offers a range of functionalities, including:</p>

      <ul>
        <li>
          <a href="editing">Viewing and Editing Parameter Values</a>: Manually
          adjust parameter values by typing or using sliders.
        </li>
        <li>
          <a href="constraints">Setting Parameter Constraints</a>: Define limits
          to prevent parameters from exceeding realistic values or link
          parameters across several models.
        </li>
        <li>
          <a href="fixing">Fixing and Freeing Parameters</a>: Lock specific
          parameters to prevent them from changing during the fit.
        </li>
        <li>
          <a href="multilayer">Managing Multilayer Structures</a>: Add, remove,
          or adjust material layers in multilayer models.
        </li>
        <li>
          <a href="saving">Saving and Loading Models</a>: Save models for reuse
          in future analyses.
        </li>
        <li>
          <a href="paramcolors">Color Codes of the Parameters</a>: Learn what
          each color means.
        </li>
      </ul>

      <p>
        Click on any of the topics above to explore specific parameter-related
        functions in Speqqle.
      </p>
    </ManualContent>
  );
};

export default Overview;
