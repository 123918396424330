import React from "react";
import ManualContent from "../../components/ManualContent";

const Overview = () => {
  return (
    <ManualContent title="Graphs Overview">
      <p>
        Graphs in Speqqle provide a visual representation of your data and model
        outputs. When starting a new session in Speqqle, a default graph is
        automatically created. You can add <strong>data curves</strong> and{" "}
        <strong>output curves</strong> from your Experiment models to this
        graph. Additionally, you can create multiple graphs to visualize
        different aspects of your analysis.
      </p>

      <p>Graphs allow you to:</p>
      <ul>
        <li>
          Overlay experimental data and model outputs for easy comparison.
        </li>
        <li>Adjust graph settings to customize appearance and layout.</li>
        <li>Interactively update datasets using the graph legend.</li>
        <li>Save, export, and manage curves for further analysis.</li>
      </ul>

      <h2>Graph Features</h2>
      <p>Graphs in Speqqle offer the following functionalities:</p>

      <ul>
        <li>
          <a href="addingdata">Adding Data and Model Outputs to Graphs</a>
        </li>
        <li>
          <a href="customization">
            Renaming, Resizing, and Changing Graph Appearance
          </a>
        </li>
        <li>
          <a href="saving">Saving and Managing Graph Curves</a>
        </li>
        <li>
          <a href="interactive">Updating Datasets via the Graph Legend</a>
        </li>
        <li>
          <a href="exporting">Exporting Graph Images</a>
        </li>
      </ul>

      <p>
        Click on any of the links above to explore specific graph-related
        functions in Speqqle.
      </p>
    </ManualContent>
  );
};

export default Overview;
