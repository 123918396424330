import React, { useContext, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
// import ToggleButton from "../commonComponents/ToggleButton";
import Parameters from "./parameters/Parameters";
import FitSection from "./fit/FitSection";
import { DashboardContext } from "../../context/DashboardContext";
// import { GeneralContext } from "../../context/GeneralContext";
import "./rightSide.scss";
// import { isAllFalse } from "../../utils/helpers";
import ToggleDisplayModal from "../commonComponents/ToggleDisplayModal";
import Results from "./results/Results";

function RightSide() {
  // const { setShowOtherPanel } = props;
  const {
    // sectionsStatus,
    // setSectionsStatus,
    callFromGraph,
    setCallFromGraph,
    parametersOpen,
    fitOpen,
    resultsOpen,
    modelData,
  } = useContext(DashboardContext);
  const [listForGauges, setListForGauges] = useState([]);
  // const { recordedErrorLog } = useContext(GeneralContext);
  // const [gridWidth, setGridWidth] = useState(2);

  // function handleSection(isOpen) {
  //   if (isOpen) {
  //     setShowOtherPanel(false);
  //     setGridWidth(6);
  //   } else {
  //     setShowOtherPanel(true);
  //     setGridWidth(2);
  //   }
  // }

  useEffect(() => {
    if (callFromGraph) {
      setCallFromGraph(false);
    }
  }, [callFromGraph]);

  useEffect(() => {
    const filteredList = modelData.filter((model) => model.speqqleID === -113); //We filter only Raman
    setListForGauges(filteredList);
  }, [modelData]);

  // useEffect(() => {
  //   handleSection(!isAllFalse(sectionsStatus));
  // }, [sectionsStatus]);

  // function handleParamsStatus(isOpen, type) {
  //   try {
  //     let updatedSection = { ...sectionsStatus };
  //     switch (type) {
  //       case "Fit":
  //         updatedSection = { ...updatedSection, fitOpen: isOpen };
  //         break;
  //       case "Param":
  //         updatedSection = { ...updatedSection, paramOpen: isOpen };
  //         break;
  //       default:
  //         updatedSection = {
  //           paramOpen: false,
  //           fitOpen: false,
  //         };
  //         setShowOtherPanel(true);
  //         setGridWidth(2);
  //         break;
  //     }

  //     setSectionsStatus(updatedSection);
  //   } catch (error) {
  //     recordedErrorLog("Params status handler failure: ", error);
  //   }
  // }

  return (
    <Grid item xs={4} sx={{ textAlign: "right" }}>
      <div className="toggleButtonContainerRight">
        {/* <ToggleButton
          content={<Parameters fitOpen={sectionsStatus.fitOpen} />}
          title={"Parameters"}
          id="parameters-switch"
          position={"right"}
          type={"Param"}
          handleStatusChange={handleParamsStatus}
          needSlideOutClose={true}
          fitContent
          callFromGraph={callFromGraph}
          noBgNoBorder
        /> */}
        {resultsOpen ? (
          <ToggleDisplayModal
            content={<Results list={listForGauges} />}
            white
            fitContent
            noBgNoBorder
          />
        ) : (
          <></>
        )}
        {parametersOpen ? (
          <ToggleDisplayModal
            content={<Parameters fitOpen={fitOpen} />}
            white={false}
            fitContent
            noBgNoBorder
            smallZIndex
          />
        ) : (
          <></>
        )}
        {/* <ToggleButton
          content={<FitSection />}
          white
          title={"Fit"}
          id="fit-switch"
          position={"right"}
          type={"Fit"}
          handleStatusChange={handleParamsStatus}
          needSlideOutClose={true}
          fitContent
        /> */}
        {fitOpen ? (
          <ToggleDisplayModal
            content={<FitSection />}
            white
            fitContent
            noBgNoBorder={false}
          />
        ) : (
          <></>
        )}
      </div>
    </Grid>
  );
}

export default RightSide;
