/**
 * @category LeftSide
 * @component
 * @module Files/fileProcessing/ProcessingOptions
 * @description This component displays options that user can select for file processing. It combines other
 * components responsible for some of the file loading functionality and provides a way to save/load presets.
 */
import React, { useState, useContext, useEffect, useRef } from "react";
import {
  // Button,
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
  Checkbox,
  TextField,
} from "@mui/material";
import units from "../../../../utils/units";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import PresetLoader from "./PresetLoader";
import { DashboardContext } from "../../../../context/DashboardContext";
import { GeneralContext } from "../../../../context/GeneralContext";
import "./fileProcessing.scss";
import CustomButton from "../../../commonComponents/CustomButton";

function ProcessingOptions(props) {
  const {
    save,
    apply,
    applyAll,
    check,
    modalId,
    cancel,
    fileContent,
    processedFileDetails,
    single = false,
    processedContent = null,
  } = props;
  const { filePresets, setFilePresets } = useContext(DashboardContext);
  const { recordedErrorLog } = useContext(GeneralContext);
  const [colCount, setColCount] = useState(2);
  const [separator, setSeparator] = useState("tab");
  // const [col1, setCol1] = useState("x");
  // const [col2, setCol2] = useState("y");
  // const [col3, setCol3] = useState("e");
  const [autoRemove, setAutoRemove] = useState(true);
  const [manualLineStart, setManualLineStart] = useState(0);
  const [xUnit, setXUnit] = useState(units[0]);
  const changeFromProcessedFile = useRef(false);
  const changeFromPreset = useRef(false);
  const [wasChanged, setWasChanged] = useState(false);
  const [shiftEnabled, setShiftEnabled] = useState(false);
  const [xShift, setXShift] = useState("none");
  const [yShift, setYShift] = useState("none");
  const [xVal, setXVal] = useState("");
  const [yVal, setYVal] = useState("");
  const [columnCountArray, setColumnCountArray] = useState(
    Array.from({ length: 2 }, (v, i) => i + 1)
  );
  const [xColNum, setXColNum] = useState(1);
  const [yColNum, setYColNum] = useState(2);
  const [eColNum, setEColNum] = useState("none");

  useEffect(() => {
    // console.log("file content: ", fileContent);
    // console.log("processed file details: ", processedFileDetails);
    if (fileContent !== null && processedFileDetails === null) {
      // When we load a file for a first time, we automatically check for a correct separator
      // Works only on default type files (2 columns, separated by space or tab, automatic header removal)
      const spacePattern = new RegExp(
        `-?\\d+(\\.)?(\\d+)?([eE][+-]?\\d+)?\\s+-?\\d+(\\.)?(\\d+)?([eE][+-]?\\d+)?(\\r|\\\\r)?$`
      );
      const tabPattern = new RegExp(
        `-?\\d+(\\.)?(\\d+)?([eE][+-]?\\d+)?\\t+-?\\d+(\\.)?(\\d+)?([eE][+-]?\\d+)?(\\r|\\\\r)?$`
      );

      const lines = fileContent.split("\n");

      // Find the index of the first line that looks like a data point using tab separator
      const firstTabDataLineIndex = lines.findIndex((line) =>
        tabPattern.test(line)
      );

      // If no data point line was found, we try out space separator
      if (firstTabDataLineIndex === -1) {
        // Find the index of the first line that looks like a data point using space separator
        const firstSpaceDataLineIndex = lines.findIndex((line) =>
          spacePattern.test(line)
        );

        // If no data point line was found, that means file is non standard (extra columns, strange header, etc.)
        if (firstSpaceDataLineIndex !== -1) {
          setSeparator("space");
        }
      } else {
        setSeparator("tab");
      }

      performCheckAfterChange();
    }
  }, [fileContent]);

  useEffect(() => {
    try {
      if (processedFileDetails !== null && processedFileDetails !== undefined) {
        const options = processedFileDetails.options;

        setAutoRemove(options.autoRemove);
        // setCol1(options.col1);
        // setCol2(options.col2);
        // setCol3(options.col3);
        setColCount(options.colCount);
        setManualLineStart(options.manualLineStart);
        setSeparator(options.separator);
        setXUnit(options.xUnit);
        // setColumnOptions(options.columns);
        setShiftEnabled(options.shift);
        setXShift(options.xShift);
        setXVal(options.xShiftVal);
        setYShift(options.yShift);
        setYVal(options.yShiftVal);

        changeFromProcessedFile.current = true;
      }
    } catch (error) {
      recordedErrorLog("processedFileDetails useEffect failure: ", error);
    }
  }, [processedFileDetails]);

  useEffect(() => {
    try {
      // We check if file content was filled in and only then we try to check it against options
      if (fileContent !== null) {
        if (changeFromProcessedFile.current) {
          performCheckAfterChange(true);
          changeFromProcessedFile.current = false;
        } else {
          performCheckAfterChange();
        }

        if (changeFromPreset.current) {
          changeFromPreset.current = false;
        } else {
          setWasChanged(true);
        }
      }
    } catch (error) {
      recordedErrorLog("Check after option change failure: ", error);
    }
  }, [
    // fileContent,
    colCount,
    xUnit,
    separator,
    autoRemove,
    manualLineStart,
    // columnOptions,
    shiftEnabled,
    yVal,
    yShift,
    xVal,
    xShift,
    eColNum,
    yColNum,
    xColNum,
  ]);

  /**
   * This function is called when user changes the number of columns in the file. It sets the number of columns
   * @function handleColChange
   * @param {Event} e - This is the event that is triggered when user changes the number of columns in the file.
   */
  const handleColChange = (e) => {
    setColumnCountArray(
      Array.from({ length: e.target.value }, (v, i) => i + 1)
    );
    setColCount(e.target.value);
  };

  /**
   * This function is called when user changes the separator of the file. It sets the separator.
   * @function handleSepChange
   * @param {Event} e - This is the event that is triggered when user changes the separator of the file.
   */
  const handleSepChange = (e) => {
    setSeparator(e.target.value);
  };

  /**
   * This function is called when user changes the value of a column. It sets the value of the column and
   * conditionally changes the value of the other columns.
   * @function handleColValChange
   * @param {Event} e - This is the event that is triggered when user changes the value of a column.
   * @param {number} col - This is the number of the column that is being changed.
   */
  // const handleColValChange = (e, col) => {
  //   const valToSet = e.target.value;
  //   switch (col) {
  //     case 1:
  //       if (col2 === valToSet) {
  //         setCol2(col1);
  //       } else {
  //         setCol3(col1);
  //       }
  //       setCol1(valToSet);
  //       break;
  //     case 2:
  //       if (col1 === valToSet) {
  //         setCol1(col2);
  //       } else {
  //         setCol3(col2);
  //       }
  //       setCol2(valToSet);
  //       break;
  //     case 3:
  //       if (col2 === valToSet) {
  //         setCol2(col3);
  //       } else {
  //         setCol1(col3);
  //       }
  //       setCol3(valToSet);
  //       break;

  //     default:
  //       break;
  //   }
  // };

  /**
   * This function generates a list of menu items that can be used to select the value of a column.
   * @function getColValSelectOptions
   * @returns {JSX.Element[]} Returns a list of menu items that can be used to select the value of a column.
   */
  // const getColValSelectOptions = () => {
  //   // Generate menu items dynamically based on colCount
  //   const menuItems = [
  //     <MenuItem key={"x"} value={"x"} className="menuItem">
  //       x
  //     </MenuItem>,
  //     <MenuItem key={"y"} value={"y"} className="menuItem">
  //       y
  //     </MenuItem>,
  //   ];

  //   // Conditionally add the third option
  //   if (colCount === 3) {
  //     menuItems.push(
  //       <MenuItem key={"e"} value={"e"} className="menuItem">
  //         e
  //       </MenuItem>
  //     );
  //   }

  //   return menuItems;
  // };

  /**
   * This function is called when user changes the line number. It sets the line number.
   * @function handleLineChange
   * @param {Event} e - This is the event that is triggered when user changes the line number.
   */
  const handleLineChange = (e) => {
    const lineVal = e.target.value;

    if (lineVal < 0) {
      setManualLineStart(0);
    } else {
      setManualLineStart(lineVal);
    }
  };

  /**
   * This function is called when user changes the unit of the x-data unit. It sets the unit of the x-data unit.
   * @function handleUnitChange
   * @param {Event} e - This is the event that is triggered when user changes the unit of the x-data unit.
   */
  const handleUnitChange = (e) => {
    const value = e.target.value;
    const unit = units.filter((u) => u.value === value)[0];
    setXUnit(unit);
  };

  /**
   * This function generates an object containing the options for processing the file.
   * @function generateOptionObject
   * @returns {object} Returns an object containing the options for processing the file.
   */
  const generateOptionObject = () => {
    try {
      return {
        colCount: colCount,
        xUnit: xUnit,
        separator: separator,
        autoRemove: autoRemove,
        manualLineStart: manualLineStart,
        // columns: columnOptions,
        eColNum: eColNum,
        yColNum: yColNum,
        xColNum: xColNum,
        shift: shiftEnabled,
        xShift: xShift,
        yShift: yShift,
        xShiftVal: xVal,
        yShiftVal: yVal,
      };
    } catch (error) {
      recordedErrorLog("Options object generation failure: ", error);
    }
  };

  /**
   * This function perfoms a check to determined if selected options are working with the file.
   * @function performCheckAfterChange
   */
  const performCheckAfterChange = (skipSolveClear = false) => {
    try {
      check(generateOptionObject(), skipSolveClear);
    } catch (error) {
      recordedErrorLog("Check after change failure: ", error);
    }
  };

  /**
   * This function is called when user loads a preset. It sets the options based on the preset.
   * @function handlePresetLoad
   * @param {number} id - This is the id of the preset that is being loaded.
   */
  const handlePresetLoad = (id) => {
    try {
      changeFromPreset.current = true;
      const selectedPreset = filePresets.find((preset) => preset.id === id);

      setColCount(selectedPreset.presetOptions.colCount);
      setXUnit(selectedPreset.presetOptions.xUnit);
      setSeparator(selectedPreset.presetOptions.separator);
      setAutoRemove(selectedPreset.presetOptions.autoRemove);
      setManualLineStart(selectedPreset.presetOptions.manualLineStart);
      // setCol1(selectedPreset.presetOptions.col1);
      // setCol2(selectedPreset.presetOptions.col2);
      // setCol3(selectedPreset.presetOptions.col3);
      // setColumnOptions(selectedPreset.presetOptions.columns);
      setShiftEnabled(selectedPreset.presetOptions.shift);
      setXShift(selectedPreset.presetOptions.xShift);
      setXVal(selectedPreset.presetOptions.xShiftVal);
      setYShift(selectedPreset.presetOptions.yShift);
      setYVal(selectedPreset.presetOptions.yShiftVal);
    } catch (error) {
      recordedErrorLog("Preset load handler failure: ", error);
    }
  };

  /**
   * This function is called when user deletes a preset. It deletes the preset from the list of presets.
   * @function handleEntryDelete
   * @param {number} id - This is the id of the preset that is being deleted.
   */
  const handleEntryDelete = (id) => {
    try {
      const clearedPresets = filePresets.filter((preset) => preset.id !== id);
      setFilePresets(clearedPresets);
    } catch (error) {
      recordedErrorLog("Entry delete handler failure: ", error);
    }
  };

  /**
   * This function is called when user saves a preset. It saves the preset to the list of presets.
   * @function savePreset
   */
  const savePreset = () => {
    try {
      save(generateOptionObject());
    } catch (error) {
      recordedErrorLog("Preset saving failure: ", error);
    }
  };

  const handleColOptionChange = (axis, val) => {
    try {
      // let colOptionUpdate = deepCopy(columnOptions);
      // if (colCount > 2) {
      //   if (colOptionUpdate[index] !== val) {
      //     colOptionUpdate = colOptionUpdate.map((column, i) => {
      //       if (column === val && index !== i) {
      //         return "ignore";
      //       }
      //       if (index === i) {
      //         return val;
      //       }
      //       return column;
      //     });
      //   }
      // } else {
      //   if (colOptionUpdate[index] !== val) {
      //     const placeholder = colOptionUpdate[index];
      //     colOptionUpdate[index] = val;
      //     if (index === 0) {
      //       colOptionUpdate[1] = placeholder;
      //     } else {
      //       colOptionUpdate[0] = placeholder;
      //     }
      //   }
      // }

      // setColumnOptions(colOptionUpdate);

      // const [xColNum, setXColNum] = useState(0);
      // const [yColNum, setYColNum] = useState(1);
      // const [eColNum, setEColNum] = useState(2);

      switch (axis) {
        case "x":
          if (val === yColNum) {
            const temp = xColNum;
            setXColNum(val);
            setYColNum(temp);
          } else if (val === eColNum) {
            const temp = xColNum;
            setXColNum(val);
            setEColNum(temp);
          } else {
            setXColNum(val);
          }
          break;
        case "y":
          if (val === xColNum) {
            const temp = yColNum;
            setYColNum(val);
            setXColNum(temp);
          } else if (val === eColNum) {
            const temp = yColNum;
            setYColNum(val);
            setEColNum(temp);
          } else {
            setYColNum(val);
          }
          break;
        case "e":
          if (val === yColNum) {
            if (eColNum === "none") {
              if (
                (xColNum === 1 && val === 2) ||
                (xColNum === 2 && val === 1)
              ) {
                setYColNum(3);
              } else if (xColNum === 1 || val === 1) {
                setYColNum(2);
              } else {
                setYColNum(1);
              }
            } else {
              const temp = eColNum;
              setYColNum(temp);
            }
            setEColNum(val);
          } else if (val === xColNum) {
            if (eColNum === "none") {
              if (
                (yColNum === 1 && val === 2) ||
                (yColNum === 2 && val === 1)
              ) {
                setXColNum(3);
              } else if (yColNum === 1 || val === 1) {
                setXColNum(2);
              } else {
                setXColNum(1);
              }
            } else {
              const temp = eColNum;
              setXColNum(temp);
            }
            setEColNum(val);
          } else {
            setEColNum(val);
          }
          break;
        default:
          break;
      }
    } catch (error) {
      recordedErrorLog("Column option change handler failure: ", error);
    }
  };

  const renderColumnOptions = () => {
    try {
      // const optionList = [];

      // if (columnOptions && columnOptions.length > 0) {
      //   for (let i = 0; i < colCount; i++) {
      //     optionList.push(
      //       <div className="columnOptionDropdown" key={i}>
      //         <div className="colNumber">{i + 1}:</div>
      // <FormControl variant="standard" className="formControl">
      //   <Select
      //     MenuProps={{
      //       container: () => document.getElementById(modalId),
      //     }}
      //     value={columnOptions[i]}
      //     onChange={(e) => handleColOptionChange(i, e.target.value)}
      //     className="select"
      //   >
      //     <MenuItem key={"x"} value={"x"} className="menuItem">
      //       x
      //     </MenuItem>
      //     <MenuItem key={"y"} value={"y"} className="menuItem">
      //       y
      //     </MenuItem>
      //     {colCount > 2 && (
      //       <MenuItem key={"e"} value={"e"} className="menuItem">
      //         e
      //       </MenuItem>
      //     )}
      //     {colCount > 2 && (
      //       <MenuItem
      //         key={"ignore"}
      //         value={"ignore"}
      //         className="menuItem"
      //       >
      //         Ignore
      //       </MenuItem>
      //     )}
      //   </Select>
      // </FormControl>
      //       </div>
      //     );
      //   }
      // }

      // return optionList;

      return (
        <div className="columnSelector">
          <div className="singleColumn">
            <div className="columnName">X:</div>
            <FormControl variant="standard" className="formControl">
              <Select
                MenuProps={{
                  container: () => document.getElementById(modalId),
                }}
                value={xColNum}
                onChange={(e) => handleColOptionChange("x", e.target.value)}
                className="select"
              >
                {columnCountArray.map((num) => (
                  <MenuItem key={num} value={num} className="menuItem">
                    {num}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="singleColumn">
            <div className="columnName">Y:</div>
            <FormControl variant="standard" className="formControl">
              <Select
                MenuProps={{
                  container: () => document.getElementById(modalId),
                }}
                value={yColNum}
                onChange={(e) => handleColOptionChange("y", e.target.value)}
                className="select"
              >
                {columnCountArray.map((num) => (
                  <MenuItem key={num} value={num} className="menuItem">
                    {num}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          {/*{colCount > 2 && (
            <div className="singleColumn">
              <div className="columnName">E:</div>
              <FormControl variant="standard" className="formControl">
                <Select
                  MenuProps={{
                    container: () => document.getElementById(modalId),
                  }}
                  value={eColNum}
                  onChange={(e) => handleColOptionChange("e", e.target.value)}
                  className="select"
                >
                  <MenuItem key={"none"} value={"none"} className="menuItem">
                    None
                  </MenuItem>
                  {columnCountArray.map((num) => (
                    <MenuItem key={num} value={num} className="menuItem">
                      {num}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          )}*/}
        </div>
      );
    } catch (error) {
      recordedErrorLog("Column option rendered has failed: ", error);
    }
  };

  return (
    <div className="processingOptionsSection">
      <div className="optionsSection">
        <div className="optionsArea">
          <div className="optionsIntro">Data Loading Options:</div>
          {processedContent ? (
            <div className="optionRow">
              <div className="optionTitle">Total data points:&nbsp;</div>
              <div>{processedContent.length}</div>
            </div>
          ) : (
            <></>
          )}
          <div className="optionRow">
            <div className="optionTitle">x-data unit:</div>
            <FormControl variant="standard" className="formControl">
              <Select
                MenuProps={{
                  container: () => document.getElementById(modalId),
                }}
                labelId="selectUnit"
                value={xUnit.value}
                onChange={handleUnitChange}
                className="select"
              >
                {units.map(({ name, value }) => (
                  <MenuItem key={name} value={value}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <div
              className="extraInfo"
              title="File units will be translated to cm-1 before showing in the graphs"
            >
              <sup>
                <HelpOutlineIcon className="infoIcon" />
              </sup>
            </div>
          </div>
          <div className="optionRow">
            <div className="optionTitle">Number of Columns:</div>
            <FormControl variant="standard" className="formControl">
              <Select
                MenuProps={{
                  container: () => document.getElementById(modalId),
                }}
                labelId="group-select-label"
                value={colCount}
                onChange={handleColChange}
                className="select mid"
              >
                <MenuItem key={2} value={2} className="menuItem">
                  2
                </MenuItem>
                <MenuItem key={3} value={3} className="menuItem">
                  3
                </MenuItem>
                <MenuItem key={4} value={4} className="menuItem">
                  4
                </MenuItem>
                <MenuItem key={5} value={5} className="menuItem">
                  5
                </MenuItem>
                <MenuItem key={6} value={6} className="menuItem">
                  6
                </MenuItem>
                <MenuItem key={7} value={7} className="menuItem">
                  7
                </MenuItem>
                <MenuItem key={8} value={8} className="menuItem">
                  8
                </MenuItem>
                <MenuItem key={9} value={9} className="menuItem">
                  9
                </MenuItem>
                <MenuItem key={10} value={10} className="menuItem">
                  10
                </MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="optionRow">
            <div className="optionTitle">Separated by:</div>
            <FormControl variant="standard" className="formControl">
              <Select
                MenuProps={{
                  container: () => document.getElementById(modalId),
                }}
                labelId="group-select-label"
                value={separator}
                onChange={handleSepChange}
                className="select"
              >
                <MenuItem key={"tab"} value={"tab"} className="menuItem">
                  Tab
                </MenuItem>
                <MenuItem key={"space"} value={"space"} className="menuItem">
                  Space
                </MenuItem>
              </Select>
            </FormControl>
          </div>
          {/* <div className="optionRow">
            <div className="optionTitle">Column 1:</div>
            <FormControl variant="standard" className="formControl">
              <Select
                MenuProps={{
                  container: () => document.getElementById(modalId),
                }}
                labelId="group-select-label"
                value={col1}
                onChange={(e) => handleColValChange(e, 1)}
                className="select min"
              >
                {getColValSelectOptions()}
              </Select>
            </FormControl>
          </div>
          <div className="optionRow">
            <div className="optionTitle">Column 2:</div>
            <FormControl variant="standard" className="formControl">
              <Select
                MenuProps={{
                  container: () => document.getElementById(modalId),
                }}
                labelId="group-select-label"
                value={col2}
                onChange={(e) => handleColValChange(e, 2)}
                className="select min"
              >
                {getColValSelectOptions()}
              </Select>
            </FormControl>
          </div> */}
          {/* {colCount === 3 ? (
            <div className="optionRow">
              <div className="optionTitle">Column 3:</div>
              <FormControl variant="standard" className="formControl">
                <Select
                  MenuProps={{
                    container: () => document.getElementById(modalId),
                  }}
                  labelId="group-select-label"
                  value={col3}
                  onChange={(e) => handleColValChange(e, 3)}
                  className="select min"
                >
                  <MenuItem key={"x"} value={"x"} className="menuItem">
                    x
                  </MenuItem>
                  <MenuItem key={"y"} value={"y"} className="menuItem">
                    y
                  </MenuItem>
                  <MenuItem key={"e"} value={"e"} className="menuItem">
                    e
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
          ) : (
            <></>
          )} */}
          <div className="optionRow vertical">
            <div className="title">Columns:</div>
            <div className="columnOptions">{renderColumnOptions()}</div>
          </div>
          <div className="optionRow">
            <FormControl
              sx={{
                width: "250px",
                userSelect: "none",
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={autoRemove}
                    onChange={() => setAutoRemove((old) => !old)}
                  />
                }
                label={"Auto-Remove file header"}
              />
            </FormControl>
          </div>
          {!autoRemove ? (
            <div className="optionRow">
              <div className="optionTitle">Data starts at line:</div>
              <TextField
                variant="standard"
                size="small"
                type="number"
                value={manualLineStart}
                onChange={handleLineChange}
                className="select mid"
              />
            </div>
          ) : (
            <></>
          )}
          <div className="optionRow">
            <FormControl
              sx={{
                width: "250px",
                userSelect: "none",
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={shiftEnabled}
                    onChange={() => setShiftEnabled((old) => !old)}
                  />
                }
                label={"Shift or scale column values"}
              />
            </FormControl>
          </div>
          {shiftEnabled ? (
            <div className="optionRow vertical">
              <div className="shiftRow">
                <div className="title">X-axis:</div>
                <FormControl variant="standard" className="formControl">
                  <Select
                    MenuProps={{
                      container: () => document.getElementById(modalId),
                    }}
                    value={xShift}
                    onChange={(e) => setXShift(e.target.value)}
                    className="select big"
                  >
                    <MenuItem key={"none"} value={"none"} className="menuItem">
                      No shift
                    </MenuItem>
                    <MenuItem
                      key={"val-x"}
                      value={"val-x"}
                      className="menuItem"
                    >
                      Value - X
                    </MenuItem>
                    <MenuItem
                      key={"x+val"}
                      value={"x+val"}
                      className="menuItem"
                    >
                      X + Value
                    </MenuItem>
                    <MenuItem
                      key={"x-val"}
                      value={"x-val"}
                      className="menuItem"
                    >
                      X - Value
                    </MenuItem>
                    <MenuItem
                      key={"x*val"}
                      value={"x*val"}
                      className="menuItem"
                    >
                      X * Value
                    </MenuItem>
                  </Select>
                </FormControl>
                <div className="valTitle">Value:</div>
                <input
                  type="number"
                  className="valueInput"
                  value={xVal}
                  onChange={(e) => setXVal(e.target.value)}
                />
              </div>
              <div className="shiftRow">
                <div className="title">Y-axis:</div>
                <FormControl variant="standard" className="formControl">
                  <Select
                    MenuProps={{
                      container: () => document.getElementById(modalId),
                    }}
                    value={yShift}
                    onChange={(e) => setYShift(e.target.value)}
                    className="select big"
                  >
                    <MenuItem key={"none"} value={"none"} className="menuItem">
                      No shift
                    </MenuItem>
                    <MenuItem
                      key={"val-x"}
                      value={"val-x"}
                      className="menuItem"
                    >
                      Value - Y
                    </MenuItem>
                    <MenuItem
                      key={"x+val"}
                      value={"x+val"}
                      className="menuItem"
                    >
                      Y + Value
                    </MenuItem>
                    <MenuItem
                      key={"x-val"}
                      value={"x-val"}
                      className="menuItem"
                    >
                      Y - Value
                    </MenuItem>
                    <MenuItem
                      key={"x*val"}
                      value={"x*val"}
                      className="menuItem"
                    >
                      Y * Value
                    </MenuItem>
                  </Select>
                </FormControl>
                <div className="valTitle">Value:</div>
                <input
                  type="number"
                  className="valueInput"
                  value={yVal}
                  onChange={(e) => setYVal(e.target.value)}
                />
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="optionLoadingArea">
          <div className="loadingAreaTitle">Presets:</div>
          <div className="presetLitContainer">
            <PresetLoader
              presetList={filePresets}
              load={handlePresetLoad}
              deleteEntry={handleEntryDelete}
              wasChanged={wasChanged}
              setWasChanged={setWasChanged}
            />
          </div>
        </div>
      </div>

      <div className="buttonArea">
        <CustomButton
          text="Apply"
          extraClassnames={["noMargin", "dataManagerButton"]}
          handleClick={() => apply(generateOptionObject())}
          id="data-apply-button"
        />
        {/* <Button
          id="data-apply-button"
          variant="contained"
          className="solveButton"
          onClick={() => apply(generateOptionObject())}
        >
          Apply
        </Button> */}
        {!single ? (
          <CustomButton
            text="Apply to All"
            extraClassnames={["noMargin", "dataManagerButton"]}
            handleClick={() => applyAll(generateOptionObject())}
          />
        ) : (
          // <Button
          //   variant="contained"
          //   className="solveButton"
          //   onClick={() => applyAll(generateOptionObject())}
          // >
          //   Apply to All
          // </Button>
          <></>
        )}
        <CustomButton
          text="Save as Preset"
          extraClassnames={["noMargin", "dataManagerButton"]}
          handleClick={savePreset}
        />
        {/* <Button
          variant="contained"
          className="loadPresetButton"
          onClick={() => savePreset()}
        >
          Save as Preset
        </Button> */}
        {single ? (
          // <Button
          //   variant="contained"
          //   className="solveButton"
          //   onClick={() => cancel()}
          // >
          //   Cancel
          // </Button>
          <CustomButton
            text="Cancel"
            extraClassnames={["noMargin", "dataManagerButton"]}
            handleClick={cancel}
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default ProcessingOptions;
