import React from "react";
import { Button } from "@mui/material";
import "./commonComponents.scss";

function CustomButton(props) {
  const {
    variant = "contained",
    size = "small",
    id = null,
    text,
    handleClick,
    disabled = false,
    style = {},
    extraClassnames = [],
    reference = null,
  } = props;

  const constructedClassName = `customButton colored${extraClassnames.map(
    (name) => ` ${name}`
  )}`.replaceAll(",", "");

  return (
    <Button
      variant={variant}
      size={size}
      sx={{ m: 1 }}
      onClick={() => handleClick()}
      className={constructedClassName}
      id={id}
      disabled={disabled}
      style={style}
      ref={reference}
    >
      {text}
    </Button>
  );
}

export default CustomButton;
