import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Copyright from "../components/Copyright";
import AppBar from "../components/actionBar/AppBar";
import { GraphContext } from "../context/GraphContext";
import { WebSocketContext } from "../context/WebSocketContext";
import { DashboardContext } from "../context/DashboardContext";
import { FileHandlingContext } from "../context/FileHandlingContext";
import { GeneralContext } from "../context/GeneralContext";
import LeftSide from "../components/leftSide/LeftSide";
import RightSide from "../components/rightSide/RightSide";
import GraphContainer from "../components/middle/GraphContainer";
import "./dashboard.scss";
import {
  // getListOfTermsToUpdate,
  performChi2Message,
  updatedChi2,
} from "../components/rightSide/fit/fitLogic";
import { ReadyState } from "react-use-websocket";
import { AuthContext } from "../context/AuthContext";
import {
  checkBool,
  cleanModelsFromUnusedCurves,
  downloadStringTxtFile,
  findBestMatchingCurve,
  generateModelsRangesQuantPairsForModelDist,
  generateWarningObject,
  getColor,
  getRangesForModelsFromGraphs,
  getReturnedCurveDictionary,
  getShortName,
  hasProperty,
  isDeepEqual,
  produceCurveObject,
  replaceModelPlotData,
  updateOrAddCurve,
} from "../utils/helpers";
import StickyFileWindow from "../components/leftSide/Files/StickyFileWindow";
import { isClickedOutside } from "../components/leftSide/Files/FileUploadLogic";
import BroadcastModal from "../components/commonComponents/BroadcastModal";
import Modal from "react-modal";
import SingleFileReprocess from "../components/leftSide/Files/fileProcessing/SingleFileReprocess";
import {
  createModelFromParamList,
  deepCopy,
  getModelById,
  replaceModelById,
} from "../components/leftSide/Models/modelLogic";
import WindowSelector from "../components/windowSelector/WindowSelector";
import Loop from "../components/middle/loop/Loop";
import FitStopWindow from "../components/middle/FitStopWindow";
import LoopStopWindow from "../components/middle/loop/LoopStopWindow";
import {
  containedModelIDsInGraphs,
  createModelDistPayload,
  generatePlotData,
} from "../components/middle/graphLogic";
import { DEFAULT_GRAPH } from "../utils/constants";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
// import { getUpdatedUniqueIds } from "../components/rightSide/parameters/parameterLogic";

//this is the main function of the whole application
export default function Dashboard() {
  const navigate = useNavigate();
  const { selectedFiles, setSelectedFiles, updateSelected } =
    useContext(FileHandlingContext);
  const {
    chi2Terms,
    setChi2Terms,
    graphs,
    setGraphs,
    selectedChi2Terms,
    requestedFitModel,
    setRequestedFitModel,
    updatedModelFE_IDs,
    setUpdatedModelFE_IDs,
    graphId,
    setGraphId,
    setZIndices,
  } = useContext(GraphContext);
  const {
    lastJsonMessage,
    readyState,
    sendMessage,
    authSent,
    setAuthSent,
    isFitOngoing,
    isLoopOngoing,
    setIsLoopOngoing,
    setIsFitOngoing,
    retrieveRequestData,
    deleteRequestDataEntry,
    sendJsonMessage,
    clearMessage,
    lastMessage,
    setAuthConfirmed,
  } = useContext(WebSocketContext);
  const {
    fitIteration,
    setFitIteration,
    setWarnings,
    setNewWarningCount,
    cleanupNeeded,
    setCleanupNeeded,
    modelData,
    setModelData,
    // showLeftPanel,
    // setShowLeftPanel,
    fileCallFromGraph,
    setFileCallFromGraph,
    fileToChange,
    setFileToChange,
    uploadedFiles,
    setUploadedFiles,
    setTotalActiveParams,
    chi2ReqAllowed,
    setChi2ReqAllowed,
    chi2QueRef,
    loopOpen,
    // setLoopOpen,
    outputsOpen,
    // setOutputsOpen,
    // requestedVdf,
    // setRequestedVdf,
    loopIteration,
    setLoopIteration,
    listOfLoopModelsToSave,
    setListOfLoopModelsToSave,
    listOfLoopCurvesToSave,
    setListOfLoopCurvesToSave,
    loopModelFilesCustomName,
    loopCurveFilesCustomName,
    valueGroups,
    setValueGroups,
    pauseOnNextLoop,
    setPauseOnNextLoop,
    loopPaused,
    setLoopPaused,
    trackedParameters,
  } = useContext(DashboardContext);
  const { authToken, isAuthReady, currentUser } = useContext(AuthContext);
  const {
    setBroadcastMessage,
    limitedToast,
    limitedWarningToast,
    limitedSucessToast,
    recordedErrorLog,
  } = useContext(GeneralContext);
  const [broadcastOpen, setBroadcastOpen] = useState(false);
  const [reprocessOpen, setReprocessOpen] = useState(false);
  const [fileToReprocess, setFileToReprocess] = useState(null);

  // We need state and ref in this case, because ref updates are not caught in StickyFileWindow immediately
  // and state change is not visible in useEffect where we have click outside logic
  const [fromGraphState, setfromGraphState] = useState(false);
  const [updateChiTerms, setUpdateChiTerms] = useState(false);
  const fromGraph = useRef(false);
  const dashboardRef = useRef();
  const updateIDWaitList = useRef([]);
  const updatePending = useRef(false);
  const requestedFitModelsLocal = useRef([]);
  const localValueGroups = useRef(valueGroups);
  const debounceTimeoutRef = useRef(null);

  useEffect(() => {
    // This useEffect is used to updated Chi2 term values with relevant file and model names after
    // user has changed them
    try {
      if (chi2Terms.length > 0) {
        const updatedTerms = chi2Terms.map((term) => {
          const relevantModel = getModelById(term.modelId, modelData);
          const relevantFile = uploadedFiles.find(
            (file) => file.ID === term.fileId
          );
          const newTerm = {
            ...term,
            modelName: relevantModel.displayName,
            fileName: relevantFile.name,
          };

          return newTerm;
        });

        setChi2Terms(updatedTerms);
      }
    } catch (error) {
      recordedErrorLog(
        "Error updating chi2 terms after modelData/uploadedFiles change: ",
        error
      );
    }
  }, [modelData, uploadedFiles]);

  useEffect(() => {
    requestedFitModelsLocal.current = requestedFitModel;
  }, [requestedFitModel]);

  useEffect(() => {
    if (!isDeepEqual(valueGroups, localValueGroups.current)) {
      localValueGroups.current = valueGroups;
    }
  }, [valueGroups]);

  useEffect(() => {
    if (updatedModelFE_IDs.length > 0) {
      const idsToUpdate = containedModelIDsInGraphs(
        updatedModelFE_IDs,
        graphs,
        modelData
      );
      if (!isFitOngoing && !isLoopOngoing) {
        handleMultipleIdUpdate(idsToUpdate);

        updateChi2Terms();
        // if (chi2Terms.length > 0 && selectedChi2Terms.length > 0) {
        //   const termsToSend = chi2Terms.filter((term) => {
        //     if (
        //       selectedChi2Terms.some((id) => id === term.id) &&
        //       term.weight > 0
        //     ) {
        //       return true;
        //     } else {
        //       return false;
        //     }
        //   });

        //   if (termsToSend.length > 0) {
        //     performChi2Message(
        //       termsToSend,
        //       modelData,
        //       uploadedFiles,
        //       sendJsonMessage,
        //       currentUser,
        //       recordedErrorLog,
        //       chi2ReqAllowed,
        //       setChi2ReqAllowed,
        //       chi2QueRef
        //     );
        //   }
        // }
      }

      setUpdatedModelFE_IDs([]);
    }
  }, [updatedModelFE_IDs]);

  const updateChi2Terms = () => {
    if (chi2Terms.length > 0 && selectedChi2Terms.length > 0) {
      const termsToSend = chi2Terms.filter((term) => {
        if (selectedChi2Terms.some((id) => id === term.id) && term.weight > 0) {
          return true;
        } else {
          return false;
        }
      });

      if (termsToSend.length > 0) {
        performChi2Message(
          termsToSend,
          modelData,
          uploadedFiles,
          sendJsonMessage,
          currentUser,
          recordedErrorLog,
          chi2ReqAllowed,
          setChi2ReqAllowed,
          chi2QueRef
        );
      }
    }
  };

  const checkAndReportFileIdIssues = (localUploadedFiles) => {
    // Retrieve the session fileID from sessionStorage
    const sessionFileID = JSON.parse(window.sessionStorage.getItem("fileID"));

    // Ensure uploadedFiles is an array
    if (!Array.isArray(localUploadedFiles)) {
      limitedToast("CRITICAL ERROR! UploadedFiles is not a valid array.");
      generateWarningObject(
        "CRITICAL ERROR! UploadedFiles is not a valid array.",
        2,
        setWarnings,
        setNewWarningCount
      );
      return;
    }

    // Check for duplicate IDs
    const ids = localUploadedFiles.map((file) => file.ID);
    const uniqueIds = new Set(ids);
    if (ids.length !== uniqueIds.size) {
      limitedToast(
        "CRITICAL ERROR! Duplicate file ID detected in uploadedFiles."
      );
      generateWarningObject(
        "CRITICAL ERROR! Duplicate file ID detected in uploadedFiles. Please report to support how you ended up in this situation!",
        2,
        setWarnings,
        setNewWarningCount
      );
      return;
    }

    // Check that all file IDs are smaller than the session fileID
    const invalidFile = localUploadedFiles.find(
      (file) => file.ID >= sessionFileID
    );
    if (invalidFile) {
      // WARNING: Add your warning production code here for invalid file IDs.
      limitedToast(
        `File ID of ${invalidFile.name} is not smaller than session fileID (${sessionFileID}). Please report this to support!`
      );
      generateWarningObject(
        `File ID of ${invalidFile.name} is not smaller than session fileID (${sessionFileID}). Please report this to support!`,
        2,
        setWarnings,
        setNewWarningCount
      );
      return;
    }
  };

  const handleFilesUpdated = (localUploadedFiles) => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    debounceTimeoutRef.current = setTimeout(() => {
      updateChi2Terms();
      checkAndReportFileIdIssues(localUploadedFiles);
      debounceTimeoutRef.current = null;
    }, 800);
  };

  useEffect(() => {
    if (uploadedFiles.length > 0) {
      handleFilesUpdated(uploadedFiles);
    }
  }, [uploadedFiles]);

  useEffect(() => {
    try {
      if (lastJsonMessage) {
        if (hasProperty(lastJsonMessage, "Broadcast")) {
          setBroadcastMessage(lastJsonMessage.Broadcast);
          setBroadcastOpen(true);
          clearMessage();
        }
      }
    } catch (error) {
      recordedErrorLog(
        "Last Json message broadcast useEffect has failed: ",
        error
      );
    }
  }, [lastJsonMessage]);

  useEffect(() => {
    try {
      if (fileToChange !== null) {
        const foundFile = uploadedFiles.find(
          (uFile) => uFile.ID === fileToChange
        );
        if (foundFile !== undefined) {
          setFileToReprocess(foundFile);
          setReprocessOpen(true);
        }
      }
    } catch (error) {
      recordedErrorLog("'fileToChange' useEffect has failed: ", error);
    }
  }, [fileToChange]);

  useEffect(() => {
    fromGraph.current = false;
    setfromGraphState(false);
  }, [selectedFiles]);

  useEffect(() => {
    if (fileCallFromGraph && !fromGraph.current) {
      fromGraph.current = true;
      setfromGraphState(true);
    }
  }, [fileCallFromGraph]);

  useEffect(() => {
    if (fileCallFromGraph) {
      setFileCallFromGraph(false);
    }
  }, [fileCallFromGraph]);

  function resetSelection() {
    setSelectedFiles([]);
    fromGraph.current = false;
    setfromGraphState(false);
  }

  useEffect(() => {
    function handleMouseDownOutside(event) {
      if (
        !fromGraph.current &&
        dashboardRef.current &&
        isClickedOutside(event)
      ) {
        setSelectedFiles([]);
      }
    }

    document.addEventListener("mousedown", handleMouseDownOutside);

    return () => {
      document.removeEventListener("mousedown", handleMouseDownOutside);
    };
  }, [dashboardRef]);

  useEffect(() => {
    if (isAuthReady) {
      if (authToken === "") {
        navigate("/");
      }
    }
  }, [isAuthReady]);

  useEffect(() => {
    if (ReadyState.OPEN === readyState && !authSent) {
      sendMessage("Token: " + authToken);
      setAuthSent(true);
    }
  }, [readyState]);

  useEffect(() => {
    if (lastMessage === "websocket connection autheticated") {
      setAuthConfirmed(true);
    }
  }, [lastMessage]);

  useEffect(() => {
    if (chi2ReqAllowed && chi2QueRef.current.length > 0) {
      performChi2Message(
        [],
        modelData,
        uploadedFiles,
        sendJsonMessage,
        currentUser,
        recordedErrorLog,
        chi2ReqAllowed,
        setChi2ReqAllowed,
        chi2QueRef
      );
    }
  }, [chi2ReqAllowed]);

  // We are cleaning up unused curves from models here
  useEffect(() => {
    try {
      if (cleanupNeeded) {
        const neededRangeDictionary = getRangesForModelsFromGraphs(graphs);

        const cleanedModels = cleanModelsFromUnusedCurves(
          modelData,
          neededRangeDictionary
        );

        setModelData(cleanedModels);

        setCleanupNeeded(false);
      }
    } catch (error) {
      recordedErrorLog("Cleanup needed useEffect failure: ", error);
    }
  }, [cleanupNeeded]);

  // We do this chi2 update here instead of in the fit, because it takes a bit of time to unlock the websocket
  // to allow other requests straight after fit.
  useEffect(() => {
    // if (updateChiTerms && chiTermsToUpdate.current.length > 0) {
    if (updateChiTerms) {
      if (selectedChi2Terms.length > 0) {
        const termsToSend = chi2Terms.filter((term) => {
          if (
            selectedChi2Terms.some((id) => id === term.id) &&
            term.weight > 0
          ) {
            return true;
          } else {
            return false;
          }
        });

        if (termsToSend.length > 0) {
          performChi2Message(
            termsToSend,
            modelData,
            uploadedFiles,
            sendJsonMessage,
            currentUser,
            recordedErrorLog,
            chi2ReqAllowed,
            setChi2ReqAllowed,
            chi2QueRef
          );
        }
      }

      // chiTermsToUpdate.current = [];
      setUpdateChiTerms(false);
    }
  }, [updateChiTerms]);

  useEffect(() => {
    try {
      if (
        lastJsonMessage != null &&
        hasProperty(lastJsonMessage, "Error") &&
        lastJsonMessage.Error.length > 0
      ) {
        for (let i = 0; i < lastJsonMessage.Error.length; i++) {
          const error = lastJsonMessage.Error[i];
          const idRequest = hasProperty(lastJsonMessage, "requestID")
            ? lastJsonMessage.requestID
            : "none";
          limitedToast(error.message);
          generateWarningObject(
            error.message,
            2,
            setWarnings,
            setNewWarningCount,
            error.reportable,
            idRequest
          );
        }
        if (isFitOngoing) {
          setIsFitOngoing(false);
        }
      }
      if (
        lastJsonMessage != null &&
        hasProperty(lastJsonMessage, "Warning") &&
        lastJsonMessage.Warning.length > 0
      ) {
        for (let i = 0; i < lastJsonMessage.Warning.length; i++) {
          const warning = lastJsonMessage.Warning[i];
          limitedWarningToast(warning);
          generateWarningObject(warning, 1, setWarnings, setNewWarningCount);
        }
      }
    } catch (error) {
      recordedErrorLog("Error and Warning handling useEffect failure: ", error);
    }
  }, [lastJsonMessage]);

  function handleChi2Message(jsonMessage, fit = true) {
    const newTerms = fit
      ? jsonMessage.Fit.chi2.chi2s
      : jsonMessage.Loop.chi2.chi2s;
    const newTotalAp = fit
      ? jsonMessage.Fit.chi2.nActiveParameters
      : jsonMessage.Loop.chi2.nActiveParameters;

    setTotalActiveParams(newTotalAp);

    const updatedTerms = updatedChi2(newTerms, chi2Terms);

    setChi2Terms(updatedTerms);
  }

  function handleVdfResponse(lastMessage, requestedVdfs) {
    try {
      let modelsCopy = deepCopy(modelData);
      const requestedModelIDs = [];
      for (let i = 0; i < requestedVdfs.length; i++) {
        const requestedModelVDF = requestedVdfs[i];
        const foundVdf = lastMessage.Model.SendModel.find(
          (model) => model.modelid === requestedModelVDF.id
        );
        if (foundVdf !== null) {
          requestedModelIDs.push(requestedModelVDF.id);
          const modelToUpdate = deepCopy(
            getModelById(requestedModelVDF.id, modelsCopy)
          );

          modelToUpdate.vdfData = {
            ...modelToUpdate.vdfData,
            ...foundVdf.vdf,
          };
          if (hasProperty(requestedModelVDF, "gridData")) {
            modelToUpdate.vdfData.gridData = requestedModelVDF.gridData;
          }
          if (hasProperty(requestedModelVDF, "gridlinear")) {
            modelToUpdate.vdfData.gridlinear = requestedModelVDF.gridlinear;
          }

          modelsCopy = replaceModelById(
            modelsCopy,
            modelToUpdate,
            requestedModelVDF.id
          );
        } else {
          console.warn(
            "VDF requested but not received for: ",
            requestedModelVDF[i]
          );
        }
      }

      setModelData(modelsCopy);
      setUpdatedModelFE_IDs(requestedModelIDs);
    } catch (error) {
      recordedErrorLog("Vdf response handling error: ", error);
    }
  }

  function handleLineshapeResponse(lastMessage, requestedModelID) {
    try {
      let modelsCopy = deepCopy(modelData);
      const modelToUpdate = deepCopy(
        getModelById(requestedModelID, modelsCopy)
      );
      if (modelToUpdate) {
        let shapeSpecs = lastMessage.Model.ShapeSpecs;
        for (let i = 0; i < shapeSpecs.length; i++) {
          const shapeSpec = shapeSpecs[i]; // Get the current shape spec
          let lineshapeIndex = -1;
          if (
            "lineshapeData" in modelToUpdate &&
            modelToUpdate.lineshapeData.length > 0
          ) {
            lineshapeIndex = modelToUpdate.lineshapeData.findIndex(
              (line) => line.lineshape_id === shapeSpec.lineshape_id
            );
          }
          if (!("lineshapeData" in modelToUpdate)) {
            modelToUpdate.lineshapeData = [];
          }

          // Create a new lineshape entry based on the shape spec
          const newLineshapeEntry = {
            lineshape_id: shapeSpec.lineshape_id,
            lineParams: [],
            lineTableRows: [{}],
            lineTemplate: {},
          };

          // Process parameters if they exist
          let parameterRow = [];
          if (shapeSpec.Parameters && Array.isArray(shapeSpec.Parameters)) {
            for (let j = 0; j < shapeSpec.Parameters.length; j++) {
              const param = shapeSpec.Parameters[j];

              if ("lineshape_id" in param) {
                delete param.lineshape_id;
              }
              // Set default values
              param.value = param.default != null ? param.default : 0;
              param.customFixed = param.fixed != null ? param.fixed : false;
              parameterRow.push(param);

              // Create row object for this parameter
              newLineshapeEntry.lineTableRows[0][param.name] = param.value;
            }
          }
          if (parameterRow.length > 0) {
            newLineshapeEntry.lineParams.push(parameterRow);
          }
          // Add the processed lineshape entry to the model

          let lineTableRow = newLineshapeEntry.lineTableRows[0];
          newLineshapeEntry.lineTemplate = {
            row: deepCopy(lineTableRow),
            params: deepCopy(parameterRow),
          };
          if (lineshapeIndex === -1) {
            modelToUpdate.lineshapeData.push(newLineshapeEntry);
          } else {
            modelToUpdate.lineshapeData[lineshapeIndex].lineTemplate =
              newLineshapeEntry.lineTemplate;
            modelToUpdate.lineshapeData[lineshapeIndex].lineTableRows = [];
            for (
              let i = 0;
              i < modelToUpdate.lineshapeData[lineshapeIndex].lineParams.length;
              i++
            ) {
              let row = {};
              for (
                let j = 0;
                j <
                modelToUpdate.lineshapeData[lineshapeIndex].lineParams[i]
                  .length;
                j++
              ) {
                let tempParam = newLineshapeEntry.lineTemplate.params.find(
                  (param) =>
                    param.speqqle_id ===
                    modelToUpdate.lineshapeData[lineshapeIndex].lineParams[i][j]
                      .speqqle_id
                );
                if (tempParam) {
                  tempParam.value =
                    modelToUpdate.lineshapeData[lineshapeIndex].lineParams[i][
                      j
                    ].value;
                  tempParam.customFixed = tempParam.fixed;
                  if (
                    hasProperty(
                      modelToUpdate.lineshapeData[lineshapeIndex].lineParams[i][
                        j
                      ],
                      "group"
                    )
                  ) {
                    tempParam.group =
                      modelToUpdate.lineshapeData[lineshapeIndex].lineParams[i][
                        j
                      ].group;
                  }
                  if (
                    hasProperty(
                      modelToUpdate.lineshapeData[lineshapeIndex].lineParams[i][
                        j
                      ],
                      "hardmax"
                    )
                  ) {
                    tempParam.hardMax =
                      modelToUpdate.lineshapeData[lineshapeIndex].lineParams[i][
                        j
                      ].hardmax;
                  }
                  if (
                    hasProperty(
                      modelToUpdate.lineshapeData[lineshapeIndex].lineParams[i][
                        j
                      ],
                      "hardmin"
                    )
                  ) {
                    tempParam.hardMin =
                      modelToUpdate.lineshapeData[lineshapeIndex].lineParams[i][
                        j
                      ].hardmin;
                  }
                  if (!tempParam.fixed) {
                    if (
                      hasProperty(
                        modelToUpdate.lineshapeData[lineshapeIndex].lineParams[
                          i
                        ][j],
                        "customFixed"
                      )
                    ) {
                      tempParam.customFixed =
                        modelToUpdate.lineshapeData[lineshapeIndex].lineParams[
                          i
                        ][j].customFixed;
                    } else if (
                      hasProperty(
                        modelToUpdate.lineshapeData[lineshapeIndex].lineParams[
                          i
                        ][j],
                        "fixed"
                      )
                    ) {
                      tempParam.customFixed =
                        modelToUpdate.lineshapeData[lineshapeIndex].lineParams[
                          i
                        ][j].fixed;
                    }
                  }
                  modelToUpdate.lineshapeData[lineshapeIndex].lineParams[i][j] =
                    tempParam;
                }
                row[
                  modelToUpdate.lineshapeData[lineshapeIndex].lineParams[i][
                    j
                  ].name
                ] =
                  modelToUpdate.lineshapeData[lineshapeIndex].lineParams[i][
                    j
                  ].value;
              }
              modelToUpdate.lineshapeData[lineshapeIndex].lineTableRows.push(
                row
              );
            }
          }
        }
        modelsCopy = replaceModelById(
          modelsCopy,
          modelToUpdate,
          requestedModelID
        );
        setModelData(modelsCopy);
        setUpdatedModelFE_IDs([requestedModelID]);
      }
    } catch (error) {
      recordedErrorLog("Lineshape response handling error: ", error);
    }
  }

  useEffect(() => {
    try {
      if (lastJsonMessage) {
        if (hasProperty(lastJsonMessage, "requestID")) {
          const requestData = retrieveRequestData(lastJsonMessage.requestID);
          if (requestData !== null) {
            switch (requestData.type) {
              case "chi2-message":
                handleChi2Message(lastJsonMessage);
                deleteRequestDataEntry(lastJsonMessage.requestID);
                setChi2ReqAllowed(true);
                break;
              case "vdf-request":
                handleVdfResponse(lastJsonMessage, requestData.requestedVdfs);
                deleteRequestDataEntry(lastJsonMessage.requestID);
                break;
              case "binary-vdf-load":
                // handleVdfBinaryLoad(lastJsonMessage);
                handleVdfResponse(lastJsonMessage, requestData.requestedVdfs);
                deleteRequestDataEntry(lastJsonMessage.requestID);
                break;
              case "loop-request":
                // handleVdfBinaryLoad(lastJsonMessage);
                handleLoopResponse(lastJsonMessage);
                break;
              case "multiple-id-dist-update":
                handleMultiIdDistUpdate(lastJsonMessage);
                deleteRequestDataEntry(lastJsonMessage.requestID);
                break;
              case "autofit":
                handleFitResponse(lastJsonMessage, true, false);
                deleteRequestDataEntry(lastJsonMessage.requestID);
                break;
              case "fit-message":
                handleFitMessage(lastJsonMessage);
                break;
              case "get-shape-specs":
                handleLineshapeResponse(
                  lastJsonMessage,
                  requestData.requestedModelID
                );
                deleteRequestDataEntry(lastJsonMessage.requestID);
                break;
              default:
                break;
            }
          }
        } else {
          throw new Error("Request ID was not attached to the json message.");
        }
      }
    } catch (error) {
      recordedErrorLog(
        "Last Json message useEffect handler has failed: ",
        error
      );
    }
  }, [lastJsonMessage]);

  function handleMultiIdDistUpdate(jsonMessage) {
    try {
      // if (updatePending.current) {
      // We extract coordinate set from the message
      const coordSets = jsonMessage.Model.SendCurve;

      const dict = getReturnedCurveDictionary(coordSets);
      const modelIds = Object.keys(dict);
      let graphsCopy = deepCopy(graphs);
      let modelsCopy = deepCopy(modelData);

      // We loop through graphs in order to update them
      for (let i = 0; i < graphsCopy.length; i++) {
        const min = graphsCopy[i].layout.xaxis.range[0];
        const max = graphsCopy[i].layout.xaxis.range[1];

        // We loop through contained model ids in graphs to check which ones received updated data
        for (let j = 0; j < graphsCopy[i].containedModels.length; j++) {
          const modelQuantitySet = graphsCopy[i].containedModels[j];

          // We check if current model id from graph is contained in updates
          if (
            modelIds.some((key) => parseInt(key) === modelQuantitySet.modelId)
          ) {
            const bestCurve = findBestMatchingCurve(
              dict[modelQuantitySet.modelId][modelQuantitySet.quantity],
              min,
              max,
              modelQuantitySet.quantity
            );

            modelsCopy = modelsCopy.map((modelToChange) => {
              if (modelToChange.FE_ID === modelQuantitySet.modelId) {
                const modelToUpdate = modelToChange;

                modelToUpdate.curves = updateOrAddCurve(
                  modelToUpdate.curves,
                  bestCurve.curve
                );
                return modelToUpdate;
              } else {
                return modelToChange;
              }
            });

            graphsCopy[i].plotData = replaceModelPlotData(
              graphsCopy[i].plotData,
              bestCurve,
              modelQuantitySet.modelId,
              modelQuantitySet.quantity
            );
          }
        }
      }

      // localGraphs.current = graphsCopy;
      setGraphs(graphsCopy);
      setModelData(modelsCopy);

      if (updateIDWaitList.current.length > 0) {
        // handle ids from wait list here
        handleMultipleIdUpdate(updateIDWaitList.current);
        updateIDWaitList.current = [];
      }

      // We reset the pending update flag to false
      // THIS DOES NOT GET RESET ALWAYS, MAINLY ON SLOW PCS
      updatePending.current = false;
      // }
    } catch (error) {
      recordedErrorLog("Error handling new model curve response: ", error);
    }
  }

  const handleMultipleIdUpdate = (idList) => {
    if (idList.length > 0) {
      try {
        if (updatePending.current) {
          updateIDWaitList.current = [
            ...new Set([...updateIDWaitList.current, ...idList]),
          ];
        } else {
          // requestedModelsUpdate.current = idList;

          const rangesForModels = getRangesForModelsFromGraphs(graphs);

          const paramsForPayload = generateModelsRangesQuantPairsForModelDist(
            idList,
            rangesForModels,
            modelData
          );

          const payload = createModelDistPayload(
            paramsForPayload.models,
            rangesForModels,
            paramsForPayload.quantityPairs,
            currentUser
          );

          updatePending.current = true;
          sendJsonMessage(payload, { type: "multiple-id-dist-update" });
        }
      } catch (error) {
        recordedErrorLog("Multiple Id update failed: ", error);
      }
    }
  };

  const handleFitResponse = (
    response,
    isDone = true,
    requestChiUpdate = false
  ) => {
    if (hasProperty(response, "Error") && response.Error.length > 0) {
      requestedFitModelsLocal.current = [];
      setRequestedFitModel(requestedFitModelsLocal.current);
    } else if (requestedFitModelsLocal.current.length > 0) {
      try {
        const newTerms = response.Fit.chi2.chi2s;

        let updatedTerms = updatedChi2(newTerms, chi2Terms);

        const termsLeftToUpdate = [];

        const termsToUpdateAfterFit = [];

        updatedTerms.forEach((term) => {
          if (
            !newTerms.some((newTerm) => {
              return (
                term.modelId === newTerm.ModelID &&
                term.fileId === newTerm.DataID &&
                term.quantity === newTerm.Quantity
              );
            })
          ) {
            termsLeftToUpdate.push(term);
          }
          if (requestChiUpdate) {
            if (hasProperty(term, "needsUpdate") && term.needsUpdate) {
              termsToUpdateAfterFit.push(term);
            }
          }
        });

        // if (requestChiUpdate && termsToUpdateAfterFit.length > 0) {
        //   chiTermsToUpdate.current = termsToUpdateAfterFit;
        // }

        if (termsLeftToUpdate.length > 0) {
          // FOR NOW NO ERROR, BUT LATER WE NEED TO RETRIEVE ALL THE TERMS WITH FIT, NOT JUST THE ONES FOR THE SENT
          // FILE

          // limitedToast("Chi2 retrieval error.");
          generateWarningObject(
            "Not all the Chi2 Terms were updated in request. Please report this to support.",
            1,
            setWarnings,
            setNewWarningCount
          );

          // If we perform chi 2 message here instead of throwing an error, we get into infinite request loop
          // if BE never returns that chi 2 due to an error
          // performChi2Message(
          //   termsLeftToUpdate,
          //   modelData,
          //   uploadedFiles,
          //   sendJsonMessage
          // );
        }

        setChi2Terms(updatedTerms);
      } catch (error) {
        recordedErrorLog(
          "There was an error retrieving chi2 values from fit message: ",
          error
        );
      }

      try {
        //Updating edges of the file
        const filesCopy = deepCopy(uploadedFiles);
        for (let i = 0; i < response.Fit.chi2.chi2s.length; i++) {
          const chi2 = response.Fit.chi2.chi2s[i];
          if (hasProperty(chi2, "autofitedge")) {
            for (let j = 0; j < filesCopy.length; j++) {
              if (filesCopy[j].ID === chi2.DataID) {
                const maxFromFit = chi2.autofitedge[1];
                const minFromFit = chi2.autofitedge[0];
                if (
                  !filesCopy[j].edges.some(
                    (edge) => edge.min === minFromFit && edge.max === maxFromFit
                  )
                ) {
                  filesCopy[j].edges.push({ min: minFromFit, max: maxFromFit });
                  filesCopy[j].edges = filesCopy[j].edges.filter(
                    (edge) => edge.min !== "" && edge.max !== ""
                  );
                }
              }
            }
          }
        }
        //Files edges updated from autofit, we can update the files now.
        if (!isDeepEqual(filesCopy, uploadedFiles)) {
          setUploadedFiles(filesCopy);
        }

        let remainingIDs = [...requestedFitModelsLocal.current];
        let copyOfModels = deepCopy(modelData);
        let copyOfGraphs = deepCopy(graphs);
        let updateModels = false;
        let updateGraphs = false;

        // This variable will check if there are any strange values from the BE for parameters that are not used in FE
        let strangeResults = false;

        // We are updating model parameters and curves separately, because when we make a fit request, more than
        // just requested curve model is returned - sub models don't necessarily require curve update, but their
        // parameter update needs to be taken into account

        // UPDATING MODEL PARAMETERS AND ROWS
        for (let i = 0; i < response.Model.SendModel.length; i++) {
          const modelDataFromResponse = response.Model.SendModel[i];

          // Check if requested model is actually received
          updateModels = true;

          // Find the model which needs updating
          const modelToUpdate = deepCopy(
            getModelById(modelDataFromResponse.modelid, copyOfModels)
          );

          if (
            hasProperty(modelDataFromResponse, "vdf") &&
            hasProperty(modelToUpdate, "vdfData")
          ) {
            modelToUpdate.vdfData = {
              ...modelToUpdate.vdfData,
              ...modelDataFromResponse.vdf,
            };
          }

          if (
            hasProperty(modelDataFromResponse.parameters, "lineshapes") &&
            hasProperty(modelToUpdate, "lineshapeData")
          ) {
            let newLineshapeData = modelDataFromResponse.parameters.lineshapes;
            for (let i = 0; i < newLineshapeData.length; i++) {
              let lineshapetype = newLineshapeData[i].shapes;
              let linshape_index = modelToUpdate.lineshapeData.findIndex(
                (lineshape) =>
                  lineshape.lineshape_id === newLineshapeData[i].lineshape_id
              );
              if (
                linshape_index === -1 &&
                lineshapetype.length !==
                  modelToUpdate.lineshapeData[linshape_index].lineParams.length
              ) {
                recordedErrorLog(
                  "Lineshape response doesn't match model: ",
                  newLineshapeData[i].lineshape_id
                );
                continue;
              }
              for (let j = 0; j < lineshapetype.length; j++) {
                for (let k = 0; k < lineshapetype[j].length; k++) {
                  let param_index = modelToUpdate.lineshapeData[
                    linshape_index
                  ].lineParams[j].findIndex(
                    (param) =>
                      param.speqqle_id === lineshapetype[j][k].speqqle_id
                  );
                  if (param_index === -1) {
                    recordedErrorLog(
                      "Lineshape parameter not found in model: ",
                      lineshapetype[j][k].speqqle_id
                    );
                    continue;
                  }
                  modelToUpdate.lineshapeData[linshape_index].lineParams[j][
                    param_index
                  ].value = lineshapetype[j][k].value;
                  modelToUpdate.lineshapeData[linshape_index].lineTableRows[j][
                    modelToUpdate.lineshapeData[linshape_index].lineParams[j][
                      param_index
                    ].name
                  ] = lineshapetype[j][k].value;
                }
              }
            }
          }

          // Find non recuring params from model
          const nonRecParams = modelToUpdate.modelParams.filter(
            (param) => param.recuring == 0
          );

          // Get new parameters for the model, remove first entry, since it's not needed
          const modelParamsToUse = deepCopy(
            modelDataFromResponse.parameters.global
          );
          if (modelDataFromResponse.modeltype !== 0) {
            modelParamsToUse.shift(); // IF NOT A DL MODEL ONLY!
          }

          let recParamsForUpdate = [];
          const hasRec = hasProperty(modelToUpdate, "recTemplate");

          // From a model recuring parameter template, get a parameter with a smallest speqqle id
          const smallestIDTemplateParam = hasRec
            ? modelToUpdate.recTemplate.params.reduce(
                (min, param) =>
                  min.speqqle_id < param.speqqle_id ? min : param,
                modelToUpdate.recTemplate.params[0]
              )
            : null;

          // In this array we will keep all the found parameters and we will use it to check which parameters
          // have not been used
          let allFoundParams = [];

          // Loop through all the new parameters to sort them
          for (let i = 0; i < modelParamsToUse.length; i++) {
            const modelParam = modelParamsToUse[i];
            // If new parameter matches non recuring parameter speqqle id, update the non recuring parameter
            if (
              nonRecParams.some(
                (param) => param.speqqle_id == modelParam.speqqle_id
              )
            ) {
              modelToUpdate.modelParams = modelToUpdate.modelParams.map(
                (param) => {
                  if (param.speqqle_id == modelParam.speqqle_id) {
                    return {
                      ...param,
                      value:
                        param.type !== "Checkbox"
                          ? modelParam.value
                          : checkBool(modelParam.value),
                    };
                  } else {
                    return param;
                  }
                }
              );

              // Add Non Recuring parameters to the list of found values
              allFoundParams.push(modelParam);
            } else {
              // If it doesn't match, that means the new parameter is potentially a recuring one, add it to
              // possibly recuring parameter array if its speqqle id is equal or higher than the smallest template speqqle id
              // this prevents of adding values which are guaranteed unfit for the recuring parameters
              if (
                hasRec &&
                modelParam.speqqle_id >= smallestIDTemplateParam.speqqle_id
              ) {
                recParamsForUpdate.push(modelParam);
              } else {
                if (modelParam.value !== 0) {
                  strangeResults = true;
                }
              }
            }
          }

          // The following actions are only relevant if model has recurring parameters
          if (hasRec) {
            // Find the new parameter with the largest speqqle id
            const paramForUpdateWithLargestID = recParamsForUpdate.reduce(
              (max, param) => (max.speqqle_id > param.speqqle_id ? max : param),
              recParamsForUpdate[0]
            );

            // Find the recuring parameter from a template with the largest speqqle id and recuring value combination for
            // given largest speqqle id of parameter for updating
            const largestIDModelTemplateParam =
              modelToUpdate.recTemplate.params.reduce(
                (max, param) =>
                  (paramForUpdateWithLargestID.speqqle_id - max.speqqle_id) /
                    max.recuring >
                  (paramForUpdateWithLargestID.speqqle_id - param.speqqle_id) /
                    param.recuring
                    ? max
                    : param,
                modelToUpdate.recTemplate.params[0]
              );

            // Calculate maximum possible recuring table rows
            const maxPossibleRows = Math.floor(
              (paramForUpdateWithLargestID.speqqle_id -
                largestIDModelTemplateParam.speqqle_id) /
                largestIDModelTemplateParam.recuring
            );

            let rowsOfValues = [];

            // Loop until max possible rows are reached, <= is used because we calculate rows from 0
            for (let row = 0; row <= maxPossibleRows; row++) {
              let values = [];
              // loop through template parameters to match each parameter with
              for (
                let paramIndex = 0;
                paramIndex < modelToUpdate.recTemplate.params.length;
                paramIndex++
              ) {
                // Find the value according to a parameter from the template
                const param = modelToUpdate.recTemplate.params[paramIndex];
                const foundValue = recParamsForUpdate.find(
                  (paramToUpdate) =>
                    paramToUpdate.speqqle_id ==
                    param.speqqle_id + row * param.recuring
                );
                // Add recuring parameters to the list of found values
                allFoundParams.push(foundValue);
                // If the value is found, push it to the value row
                if (foundValue != undefined) {
                  values.push({
                    value:
                      param.type !== "Checkbox"
                        ? foundValue.value
                        : checkBool(foundValue.value),
                    speqqle_id: param.speqqle_id,
                  });
                }
              }
              // Push the row of values to the array of rows
              rowsOfValues.push(values);
            }

            // Find all the unincluded parameters
            const allNotIncluded = recParamsForUpdate.filter(
              (item) => !allFoundParams.includes(item)
            );

            if (allNotIncluded.some((param) => param.value !== 0)) {
              strangeResults = true;
            }

            // Check if results are not strange and if they are, create an error message for the user
            if (strangeResults) {
              limitedToast("Model parameter calculation error: E0001");
              generateWarningObject(
                "There was an error with model parameter calculations. Please report this to support. Error code: E0001",
                2,
                setWarnings,
                setNewWarningCount
              );
            }

            // Check if the last row is completely filled, if not, discard it
            if (
              rowsOfValues[rowsOfValues.length - 1].length !=
              modelToUpdate.recTemplate.params.length
            ) {
              rowsOfValues.pop();
            }

            // Loop through the array of new row values
            for (let rowIndex = 0; rowIndex < rowsOfValues.length; rowIndex++) {
              const rowValues = rowsOfValues[rowIndex];

              // Check if the row is in the recuring parameters table
              if (modelToUpdate.recParams.length > rowIndex) {
                // If the recuring parameters table contains this row, do the updates
                for (
                  let i = 0;
                  i < modelToUpdate.recParams[rowIndex].length;
                  i++
                ) {
                  // Find the value from row values that matches rec table parameter speqqle id
                  const foundVal = rowValues.find(
                    (rowVal) =>
                      rowVal.speqqle_id ==
                      modelToUpdate.recParams[rowIndex][i].speqqle_id
                  );

                  // If the value is found, update rec table parameter and row values
                  if (foundVal != undefined) {
                    if (
                      Object.prototype.hasOwnProperty.call(
                        modelToUpdate.recParams[rowIndex][i],
                        "group"
                      )
                    ) {
                      const groupToUpdate =
                        modelToUpdate.recParams[rowIndex][i].group;

                      const updatedGroups = localValueGroups.current.map(
                        (group) => {
                          if (group.groupNumber === groupToUpdate) {
                            const maxVal =
                              group.hardMax < foundVal.value
                                ? null
                                : group.hardMax;
                            const minVal =
                              group.hardMin > foundVal.value
                                ? null
                                : group.hardMin;
                            return {
                              ...group,
                              value: foundVal.value,
                              hardMax: maxVal,
                              hardMin: minVal,
                            };
                          } else {
                            return group;
                          }
                        }
                      );

                      localValueGroups.current = updatedGroups;
                    }

                    modelToUpdate.recParams[rowIndex][i] = {
                      ...modelToUpdate.recParams[rowIndex][i],
                      value: foundVal.value,
                    };

                    modelToUpdate.recTableRows[rowIndex] = {
                      ...modelToUpdate.recTableRows[rowIndex],
                      [modelToUpdate.recParams[rowIndex][i].name]:
                        foundVal.value,
                    };
                  }
                }

                if (!isDeepEqual(localValueGroups.current, valueGroups)) {
                  setValueGroups(localValueGroups.current);
                }
              } else {
                // If row index of new values is greater than current recuring parameter table lenght, that means we
                // need to add additional parameter row from template
                let paramRow = [];
                let tableRow = {};

                // Loop through recuring parameters template
                for (
                  let i = 0;
                  i < modelToUpdate.recTemplate.params.length;
                  i++
                ) {
                  // Find the value from values row that matches template parameter speqqle id
                  const foundVal = rowValues.find(
                    (rowVal) =>
                      rowVal.speqqle_id ==
                      modelToUpdate.recTemplate.params[i].speqqle_id
                  );
                  // If the value is found, add the paramter with value from row of values to temporary parameters
                  // If the value is not found, we still add entry to the table, but with value from the template - this
                  // prevents the creation of tables with undefined table cells
                  if (foundVal != undefined) {
                    paramRow.push({
                      ...modelToUpdate.recTemplate.params[i],
                      value: foundVal.value,
                    });
                    tableRow = {
                      ...tableRow,
                      [modelToUpdate.recTemplate.params[i].name]:
                        foundVal.value,
                    };
                  } else {
                    paramRow.push({
                      ...modelToUpdate.recTemplate.params[i],
                    });
                    tableRow = {
                      ...tableRow,
                      [modelToUpdate.recTemplate.params[i].name]:
                        modelToUpdate.recTemplate.params[i].value,
                    };
                  }
                }

                // When temporary recuring parameter and table row values are filled, update model with them
                modelToUpdate.recParams.push(paramRow);
                modelToUpdate.recTableRows.push(tableRow);
              }

              // Here we will check if there are any other rows remaining in the parameter table after we finished adding ones from BE. We will remove them if found
              if (rowIndex === rowsOfValues.length - 1) {
                modelToUpdate.recTableRows.splice(rowIndex + 1);
                modelToUpdate.recParams.splice(rowIndex + 1);
              }
            }
          }

          copyOfModels = replaceModelById(
            copyOfModels,
            modelToUpdate,
            modelDataFromResponse.modelid
          );
        }

        // UPDATING MODEL CURVES
        for (let i = 0; i < requestedFitModelsLocal.current.length; i++) {
          const requestedModelQuantitySet = requestedFitModelsLocal.current[i];
          const modelCurveFound = hasProperty(response.Model, "SendCurve")
            ? response.Model.SendCurve.filter(
                (modelCurve) =>
                  modelCurve.modelid === requestedModelQuantitySet.modelId &&
                  modelCurve.curves[0].quantity ===
                    requestedModelQuantitySet.quantity
              )
            : [];

          if (isDone) {
            remainingIDs = remainingIDs.filter(
              (id) =>
                id.modelId != requestedModelQuantitySet.modelId &&
                id.quantity != requestedModelQuantitySet.quantity
            );
          }
          // Check if requested model is actually received
          if (modelCurveFound.length > 0) {
            // Find the model which needs updating
            const modelToUpdate = deepCopy(
              getModelById(requestedModelQuantitySet.modelId, copyOfModels)
            );

            // Update model with new Curve
            // We need to check if the curve exists here. If we do fit and model is not displayed in graph, we don't
            // receive a curve back from BE.
            const curveObj = produceCurveObject(
              modelCurveFound[0].curves[0].coordinates,
              modelCurveFound[0].quantity
            );
            modelToUpdate.curves = updateOrAddCurve(
              modelToUpdate.curves,
              curveObj
            );

            copyOfModels = replaceModelById(
              copyOfModels,
              modelToUpdate,
              requestedModelQuantitySet.modelId
            );
            copyOfGraphs = copyOfGraphs.map((graph) => {
              if (
                graph.containedModels.some(
                  (modelQuantityPair) =>
                    modelQuantityPair.modelId ===
                      requestedModelQuantitySet.modelId &&
                    modelQuantityPair.quantity ===
                      requestedModelQuantitySet.quantity
                )
              ) {
                updateGraphs = true;
                graph.plotData = graph.plotData.map((data) => {
                  if (
                    hasProperty(data, "modelId") &&
                    data.modelId === requestedModelQuantitySet.modelId &&
                    data.quantity === requestedModelQuantitySet.quantity
                  ) {
                    let coordSetForData =
                      modelCurveFound[0].curves[0].coordinates.map((coord) => {
                        return {
                          x: parseFloat(coord.x),
                          y: parseFloat(coord.y),
                        };
                      });
                    const dataGenerated = generatePlotData(
                      coordSetForData,
                      "",
                      "",
                      data.line.color,
                      data.name,
                      null,
                      requestedModelQuantitySet.modelId,
                      null,
                      data.quantity
                    );
                    return {
                      ...data,
                      x: dataGenerated[0].x,
                      y: dataGenerated[0].y,
                    };
                  } else {
                    return data;
                  }
                });
              }
              return graph;
            });
          }
        }

        requestedFitModelsLocal.current = remainingIDs;
        setRequestedFitModel(requestedFitModelsLocal.current);
        if (updateModels) {
          setModelData(copyOfModels);
        }
        if (updateGraphs) {
          setGraphs(copyOfGraphs);
        }
      } catch (error) {
        recordedErrorLog("Error updating details from AutoFit: ", error);
      }
    }
  };

  function handleFitMessage(jsonMessage) {
    try {
      if (hasProperty(jsonMessage, "Error") && jsonMessage.Error.length > 0) {
        setUpdateChiTerms(true);
        setIsFitOngoing(false);
        setFitIteration(null);
        // Timeout here to make sure this is displayed, as the warnings and erros are generally caught and
        // displayed in another useEffect hook
        // setTimeout(() => {
        //   limitedToast(`The fit has failed.`);
        // }, 10);
        deleteRequestDataEntry(lastJsonMessage.requestID);
      } else {
        const fitSection = jsonMessage.Fit;
        if (hasProperty(fitSection, "fitactive")) {
          setIsFitOngoing(false);
          setFitIteration(null);
          deleteRequestDataEntry(lastJsonMessage.requestID);
        } else if (fitSection.fit.fit_done) {
          const fitStatus = fitSection.fit;
          handleFitResponse(jsonMessage, true, true);
          setUpdateChiTerms(true);
          setIsFitOngoing(false);
          setFitIteration(null);
          limitedSucessToast(
            `The fit was finished succesfully with ${fitStatus.n_iterations} iterations.`
          );
          generateWarningObject(
            `The fit was finished succesfully with ${fitStatus.n_iterations} iterations.`,
            0,
            setWarnings,
            setNewWarningCount
          );
          deleteRequestDataEntry(lastJsonMessage.requestID);
        } else {
          const fitStatus = fitSection.fit;
          setFitIteration(fitStatus.n_iterations);
          handleFitResponse(jsonMessage, false, false);

          const newTerms = jsonMessage.Fit.chi2.chi2s;
          const updatedTerms = updatedChi2(newTerms, chi2Terms);
          setChi2Terms(updatedTerms);
        }
      }
    } catch (error) {
      recordedErrorLog("Error handling Fit message: ", error);
    }
  }

  const handleLoopResponse = (lastJsonMessage) => {
    try {
      // We check if this is standard message or the one idetifying end of Loop
      if (hasProperty(lastJsonMessage, "Loop")) {
        //HERE WE HANDLE CHI2 UPDATES

        if (pauseOnNextLoop) {
          setLoopPaused(true);
        }

        if (hasProperty(lastJsonMessage.Loop, "chi2")) {
          handleChi2Message(lastJsonMessage, false);
        }

        //We make a copy of models to keep updating single array
        let modelsCopy = deepCopy(modelData);
        let graphsCopy = deepCopy(graphs);

        let graphsNeedUpdate = false;

        //Here we deal with curve updates
        if (lastJsonMessage.Model.SendCurve.length > 0) {
          const coordSets = lastJsonMessage.Model.SendCurve;
          const dict = getReturnedCurveDictionary(coordSets);
          const modelIds = Object.keys(dict);
          graphsNeedUpdate = true;
          // let modelsCopy = deepCopy(modelData);
          // We loop through graphs in order to update them
          for (let i = 0; i < graphsCopy.length; i++) {
            const min = graphsCopy[i].layout.xaxis.range[0];
            const max = graphsCopy[i].layout.xaxis.range[1];

            // We loop through contained model ids in graphs to check which ones received updated data
            for (let j = 0; j < graphsCopy[i].containedModels.length; j++) {
              const modelQuantitySet = graphsCopy[i].containedModels[j];

              // We check if current model id from graph is contained in updates
              if (
                modelIds.some(
                  (key) => parseInt(key) === modelQuantitySet.modelId
                )
              ) {
                const bestCurve = findBestMatchingCurve(
                  dict[modelQuantitySet.modelId][modelQuantitySet.quantity],
                  min,
                  max,
                  modelQuantitySet.quantity
                );

                modelsCopy = modelsCopy.map((modelToChange) => {
                  if (modelToChange.FE_ID === modelQuantitySet.modelId) {
                    const modelToUpdate = modelToChange;

                    modelToUpdate.curves = updateOrAddCurve(
                      modelToUpdate.curves,
                      bestCurve.curve
                    );
                    return modelToUpdate;
                  } else {
                    return modelToChange;
                  }
                });

                graphsCopy[i].plotData = replaceModelPlotData(
                  graphsCopy[i].plotData,
                  bestCurve,
                  modelQuantitySet.modelId,
                  modelQuantitySet.quantity
                );
              }
            }
          }
        }

        if (
          hasProperty(lastJsonMessage.Loop, "AccumulatedOutputs") &&
          hasProperty(lastJsonMessage.Loop.AccumulatedOutputs, "Variables") &&
          lastJsonMessage.Loop.AccumulatedOutputs.Variables.length > 0
        ) {
          const hasVariableGraph = graphsCopy.some(
            (graph) => graph.type === "loop-variable-tracking"
          );

          const newPlotData =
            lastJsonMessage.Loop.AccumulatedOutputs.Variables.map(
              (variable, index) => {
                const lineColor = getColor(index);

                const variableModel = trackedParameters.find(
                  (model) => model.FE_ID === variable.modelid
                );

                const parameterDetails = variableModel.selectedParams.find(
                  (param) => {
                    if (param.rec) {
                      return param.calcSpeqId === variable.speqqle_id;
                    } else {
                      return param.speqqle_id === variable.speqqle_id;
                    }
                  }
                );

                let plotName = `${getShortName(variableModel.modelName, 10)}-${
                  parameterDetails.name
                }`;

                if (parameterDetails.rec) {
                  plotName = plotName + `R:${parameterDetails.rowIndex}`;
                }

                const varId = `${variable.modelid}|${variable.speqqle_id}`;

                return {
                  line: { color: lineColor },
                  variableId: varId,
                  mode: "lines+markers",
                  name: plotName,
                  legendgroup: plotName,
                  type: "scattergl",
                  x: variable.Output.map((point) => point.x),
                  y: variable.Output.map((point) => point.y),
                };
              }
            );

          if (hasVariableGraph) {
            graphsCopy = graphsCopy.map((graph) => {
              if (graph.type !== "loop-variable-tracking") {
                return graph;
              }

              return {
                ...graph,
                plotData: newPlotData,
                layout: {
                  ...graph.layout,
                  xaxis: {
                    ...graph.layout.xaxis,
                    range: [
                      0,
                      lastJsonMessage.Loop.AccumulatedOutputs.Variables[0]
                        .Output.length + 1,
                    ],
                  },
                },
              };
            });
          } else {
            let newGraph = {
              ...DEFAULT_GRAPH,
              id: graphId,
              type: "loop-variable-tracking",
              plotData: newPlotData,
              title: "Loop Variable Tracking",
              layout: {
                ...DEFAULT_GRAPH.layout,
                xaxis: {
                  ...DEFAULT_GRAPH.layout.xaxis,
                  title: { text: "Iteration" },
                  range: [
                    0,
                    lastJsonMessage.Loop.AccumulatedOutputs.Variables[0].Output
                      .length + 1,
                  ],
                },
                yaxis: {
                  ...DEFAULT_GRAPH.layout.yaxis,
                  title: { text: "Value" },
                },
              },
            };
            setGraphId((old) => old + 1);
            graphsCopy.push(newGraph);
          }

          setZIndices((prevZIndices) => {
            return {
              ...prevZIndices,
              [graphId]: Object.keys(prevZIndices).length,
            };
          });

          graphsNeedUpdate = true;
        }

        if (graphsNeedUpdate) {
          setGraphs(graphsCopy);
        }

        //Here we deal with parameter updates
        if (
          hasProperty(lastJsonMessage.Model, "SendModel") &&
          lastJsonMessage.Model.SendModel.length > 0
        ) {
          for (let i = 0; i < lastJsonMessage.Model.SendModel.length; i++) {
            const updatedModelEntry = lastJsonMessage.Model.SendModel[i];
            const modelToUpdate = getModelById(
              updatedModelEntry.modelid,
              modelsCopy
            );

            const getModelForParams = (speqqleID, modelId) => {
              const subModel = getModelById(modelId, modelsCopy);

              if (subModel === null || speqqleID !== subModel.speqqleID) {
                recordedErrorLog(
                  "There was issue retrieving sub model for updating model from parameter list.",
                  new Error("Sub model finding error.")
                );
              }

              return subModel;
            };

            const filledModelWithParams = createModelFromParamList(
              updatedModelEntry,
              modelToUpdate,
              getModelForParams,
              sendJsonMessage,
              currentUser
            );

            modelsCopy = replaceModelById(
              modelsCopy,
              filledModelWithParams,
              modelToUpdate.FE_ID
            );
          }
        } else {
          limitedWarningToast(
            "The loop response did not contain any model information."
          );
        }

        //We update models array to reflect the changes
        setModelData(modelsCopy);

        //Here we handle with users request to save MODELS into files
        const modelsThatNeedSaving = listOfLoopModelsToSave.filter(
          (model) => model.save
        );
        if (modelsThatNeedSaving.length > 0) {
          for (let i = 0; i < modelsThatNeedSaving.length; i++) {
            const modelToSave = modelsThatNeedSaving[i];

            const relevantModelFromResponse =
              lastJsonMessage.Model.SendModel.find(
                (modelFromResponse) =>
                  modelFromResponse.modelid === modelToSave.FE_ID
              );

            if (relevantModelFromResponse) {
              downloadStringTxtFile(
                JSON.stringify(relevantModelFromResponse),
                `${loopModelFilesCustomName}_${modelToSave.name}_Iteration_${lastJsonMessage.Loop.current_loop_iteration}`,
                ".spqm"
              );
            } else {
              console.warn("No loop model found to save.");
            }
          }
        }
        //Here we handle with users request to save CURVES into files
        const curvesThatNeedSaving = listOfLoopCurvesToSave.filter(
          (curve) => curve.save
        );
        if (curvesThatNeedSaving.length > 0) {
          for (let i = 0; i < curvesThatNeedSaving.length; i++) {
            const curveToSave = curvesThatNeedSaving[i];

            const correctModelCurves = lastJsonMessage.Model.SendCurve.find(
              (curveSet) => curveSet.modelid === curveToSave.modelId
            );

            if (correctModelCurves) {
              const correctQuantity = correctModelCurves.curves.find(
                (curveEntry) => curveEntry.quantity === curveToSave.quantity
              );

              if (correctQuantity) {
                let fileContent = `X\tY\n`;

                for (let i = 0; i < correctQuantity.coordinates.length; i++) {
                  const coordSet = correctQuantity.coordinates[i];
                  fileContent = fileContent + `${coordSet.x}\t${coordSet.y}\n`;
                }

                downloadStringTxtFile(
                  fileContent,
                  `${loopCurveFilesCustomName}_${curveToSave.name}_${curveToSave.quantityName}_Iteration_${lastJsonMessage.Loop.current_loop_iteration}`
                );
              } else {
                console.warn("No correct curve quantity found to save.");
              }
            } else {
              console.warn("No loop curve found to save.");
            }
          }
        }

        //Here we finish up with loop handling and check if it is done. If so, we reset relevant variables, otherwise, we
        //update iteration count
        if (lastJsonMessage.Loop.loop_done) {
          setLoopPaused(false);
          setPauseOnNextLoop(false);
          setIsLoopOngoing(false);
          setLoopIteration(null);
          setListOfLoopModelsToSave([]);
          setListOfLoopCurvesToSave([]);
          deleteRequestDataEntry(lastJsonMessage.requestID);
        } else {
          setLoopIteration(lastJsonMessage.Loop.current_loop_iteration);
        }
      } else {
        if (
          hasProperty(lastJsonMessage, "Fit") &&
          hasProperty(lastJsonMessage.Fit, "fitactive") &&
          !lastJsonMessage.Fit.fitactive
        ) {
          setIsLoopOngoing(false);
          setLoopIteration(null);
          setListOfLoopModelsToSave([]);
          setListOfLoopCurvesToSave([]);
          deleteRequestDataEntry(lastJsonMessage.requestID);
        }
      }
    } catch (error) {
      recordedErrorLog("Error handling loop response: ", error);
    }
  };

  const handleCloseIconClick = () => {
    setBroadcastOpen(false);
  };

  const handleReprocessClose = () => {
    setReprocessOpen(false);
    setFileToReprocess(null);
    setFileToChange(null);
  };

  const handleReprocessDone = (newFile) => {
    try {
      const updatedFiles = uploadedFiles.map((uFile) => {
        if (uFile.ID === newFile.ID) {
          return newFile;
        } else {
          return uFile;
        }
      });

      setUploadedFiles(updatedFiles);

      handleReprocessClose();
    } catch (error) {
      recordedErrorLog("Reprocess done handler failure: ", error);
    }
  };

  const handleFitStop = () => {
    sendJsonMessage(
      {
        User: currentUser.id,
        stopfit: true,
      },
      false
    );
    limitedSucessToast(`The fit was stopped after ${fitIteration} iterations.`);
    generateWarningObject(
      `The fit was stopped after ${fitIteration} iterations.`,
      0,
      setWarnings,
      setNewWarningCount
    );
    setIsFitOngoing(false);
    setFitIteration(null);
  };

  const handleLoopStop = () => {
    sendJsonMessage(
      {
        User: currentUser.id,
        stop_task: true,
      },
      false
    );

    setLoopPaused(false);
    setPauseOnNextLoop(false);
  };

  const handleLoopStep = () => {
    sendJsonMessage(
      {
        User: currentUser.id,
        pause_next_iteration: true,
      },
      false
    );
    setLoopPaused(false);
    setPauseOnNextLoop(true);
  };

  const handleLoopRun = () => {
    sendJsonMessage(
      {
        User: currentUser.id,
        stop_pause: true,
      },
      false
    );
    setLoopPaused(false);
    setPauseOnNextLoop(false);
  };

  return (
    <Box sx={{ display: "flex" }} ref={dashboardRef}>
      <Box
        component="main"
        id="main-dashboard-container"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 3,
          height: "100vh",
          overflow: "scroll",
          width: 1,
        }}
      >
        <AppBar />
        <WindowSelector />
        <Container maxWidth={false} disableGutters className="mainContainer">
          <Grid
            container
            spacing={0.5}
            sx={{ mt: 0, marginTop: "0.5vh", flexWrap: "nowrap" }}
            className="gridContainer"
            style={{ position: "relative", zIndex: "5" }}
          >
            <DndProvider backend={HTML5Backend}>
              {/* dashboard is divided by 3 grids 
                First grid = data loading, data settings, models */}
              {/* {showLeftPanel ? <LeftSide /> : <></>} */}
              <LeftSide />
              {/* Second grid = graph */}
              <Grid item xs={5}>
                {outputsOpen ? <GraphContainer /> : <></>}
                {loopOpen ? <Loop /> : <></>}
              </Grid>
              {/* Third grid = fit results, params table, warnings and export (all imported components) */}
              {/* <RightSide setShowOtherPanel={setShowLeftPanel} /> */}
              <RightSide />
            </DndProvider>
          </Grid>
          {/* imported Copyright component */}
          <div className="copyright">
            <Copyright sx={{ pb: 4 }} />
          </div>
        </Container>
        {isFitOngoing && (
          <FitStopWindow
            iterationCount={fitIteration}
            handleStop={handleFitStop}
          />
        )}
        {isLoopOngoing && (
          <LoopStopWindow
            iterationCount={loopIteration}
            handleStop={handleLoopStop}
            handleLoopStep={handleLoopStep}
            handleLoopRun={handleLoopRun}
            paused={loopPaused}
          />
        )}
      </Box>
      {selectedFiles !== undefined && selectedFiles.length == 1 ? (
        <StickyFileWindow
          selectedFiles={selectedFiles}
          updateSelected={updateSelected}
          callFromGraph={fromGraphState}
          resetSelection={resetSelection}
        />
      ) : (
        <></>
      )}
      <BroadcastModal
        elementRef={dashboardRef}
        isOpen={broadcastOpen}
        setIsOpen={setBroadcastOpen}
        handleClose={handleCloseIconClick}
      />
      <Modal
        isOpen={reprocessOpen}
        onRequestClose={handleReprocessClose}
        shouldCloseOnOverlayClick={true}
        contentLabel="File Re-Processing Modal"
        appElement={dashboardRef.current}
        id="fileReProcessModal"
        style={{
          content: {
            width: "80vw",
            height: "70vh",
            top: `10%`,
            left: "10%",
            right: "auto",
          },
          overlay: {
            zIndex: "8000",
          },
        }}
      >
        <SingleFileReprocess
          file={fileToReprocess}
          handleDone={handleReprocessDone}
          handleCancel={handleReprocessClose}
          modalId={"fileReProcessModal"}
        />
      </Modal>
    </Box>
  );
}
