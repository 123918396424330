import React, { useContext, useState, useRef, useEffect } from "react";
import { GraphContext } from "../../../context/GraphContext";
import "./loop.scss";
import Chi2LoopEntry from "./Chi2LoopEntry";
// import { Button, TextField } from "@mui/material";
import { TextField } from "@mui/material";
import {
  deepCopy,
  getModelById,
  getModelsByIds,
} from "../../leftSide/Models/modelLogic";
import { DashboardContext } from "../../../context/DashboardContext";
import { WebSocketContext } from "../../../context/WebSocketContext";
import { AuthContext } from "../../../context/AuthContext";
import { GeneralContext } from "../../../context/GeneralContext";
import {
  checkModelsInGraphs,
  filterCurveDataToRange,
  getRangesForModelsFromGraphs,
  hasProperty,
  processSymbols,
  segmentsIntoSingleCurve,
  splitCurveIntoSegmentsByEdges,
} from "../../../utils/helpers";
import {
  createModelDistPayload,
  createModelFitPayloadParams,
} from "../graphLogic";
import Modal from "react-modal";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import ParameterSelectorWindow from "./ParameterSelectorWindow";
import TrackedParamDisplay from "./TrackedParamDisplay";
import CustomButton from "../../commonComponents/CustomButton";

function Loop() {
  const { chi2Terms, setChi2Terms, graphs } = useContext(GraphContext);
  const { recordedErrorLog } = useContext(GeneralContext);
  const {
    modelData,
    chiVal,
    maxRunTime,
    iterationRefreshCount,
    maxIteration,
    uploadedFiles,
    loopModelFilesCustomName,
    setLoopModelFilesCustomName,
    loopCurveFilesCustomName,
    setLoopCurveFilesCustomName,
    listOfLoopModelsToSave,
    setListOfLoopModelsToSave,
    listOfLoopCurvesToSave,
    setListOfLoopCurvesToSave,
    setLoopOpen,
    setOutputsOpen,
    setParametersOpen,
    trackedParameters,
    setTrackedParameters,
    setPauseOnNextLoop,
  } = useContext(DashboardContext);
  const { sendJsonMessage, setIsLoopOngoing } = useContext(WebSocketContext);
  const { currentUser } = useContext(AuthContext);
  const [paramSelectOpen, setParamSelectOpen] = useState(false);
  const [autosaveModSelectOpen, setAutosaveModSelectOpen] = useState(false);
  const [autosaveCurveSelectOpen, setAutosaveCurveSelectOpen] = useState(false);
  const [modelsForCurvesDictionary, setModelsForCurvesDictionary] = useState(
    []
  );
  const paramSelectRef = useRef();
  const autosaveModSelectRef = useRef();
  const autosaveCurveSelectRef = useRef();

  useEffect(() => {
    try {
      const modelsConnected = [];

      const idsAlreadyIn = [];

      for (let i = 0; i < chi2Terms.length; i++) {
        const term = chi2Terms[i];

        if (hasProperty(term, "modelList")) {
          for (let j = 0; j < term.modelList.length; j++) {
            const modelEntry = term.modelList[j];

            if (!idsAlreadyIn.some((id) => id === modelEntry.FE_ID)) {
              let currentEntry = listOfLoopModelsToSave.find(
                (currentEntry) => currentEntry.FE_ID === modelEntry.FE_ID
              );

              if (currentEntry === undefined) {
                currentEntry = { ...modelEntry, save: false };
              }

              modelsConnected.push(currentEntry);
              idsAlreadyIn.push(modelEntry.FE_ID);
            }
          }
        }
      }

      setListOfLoopModelsToSave(modelsConnected);
    } catch (error) {
      recordedErrorLog("Error in loop model list production: ", error);
    }
  }, [chi2Terms]);

  useEffect(() => {
    try {
      if (graphs !== undefined && listOfLoopModelsToSave !== undefined) {
        let listOfModelIds = [];

        for (let i = 0; i < chi2Terms.length; i++) {
          const term = chi2Terms[i];

          if (hasProperty(term, "modelList")) {
            listOfModelIds.push(...term.modelList.map((entry) => entry.FE_ID));
          }
        }

        // Making sure there are no duplicates in modelIds
        listOfModelIds = [...new Set(listOfModelIds)];

        const modelsInGraphDict = checkModelsInGraphs(listOfModelIds, graphs);
        setModelsForCurvesDictionary(modelsInGraphDict);
        setListOfLoopCurvesToSave((old) => {
          return modelsInGraphDict.pairs.map((pair) => {
            const oldEntry = old.find(
              (entry) =>
                entry.modelId === pair.modelId &&
                entry.quantity === pair.quantity
            );
            const currentModel = getModelById(pair.modelId, modelData);
            if (oldEntry !== undefined) {
              return { ...oldEntry, name: currentModel.displayName };
            } else {
              const quantityObject = currentModel.outputs.find(
                (output) => output.speqqle_id === pair.quantity
              );

              const quantityName = quantityObject
                ? quantityObject.name
                : "NAME_NOT_FOUND";

              return {
                ...pair,
                save: false,
                name: currentModel.displayName,
                quantityName: quantityName,
              };
            }
          });
        });
      }
    } catch (error) {
      recordedErrorLog("Error in loop curve list production: ", error);
    }
  }, [listOfLoopModelsToSave, graphs]);

  const updateTerm = (updatedTerm) => {
    setChi2Terms((old) =>
      old.map((oldTerm) => {
        if (oldTerm.id === updatedTerm.id) {
          return updatedTerm;
        } else {
          return oldTerm;
        }
      })
    );
  };

  const handleSelectParams = () => {
    setParamSelectOpen(true);
  };

  const handleSelectModels = () => {
    setAutosaveModSelectOpen(true);
  };

  const handleSelectCurves = () => {
    setAutosaveCurveSelectOpen(true);
  };

  const generateAccumulatedVariableList = () => {
    try {
      const variableList = [];

      for (let i = 0; i < listOfLoopModelsToSave.length; i++) {
        const modelEntry = listOfLoopModelsToSave[i];

        const relevantModel = getModelById(modelEntry.FE_ID, modelData);

        for (let j = 0; j < relevantModel.modelParams.length; j++) {
          const parameter = relevantModel.modelParams[j];

          if (hasProperty(parameter, "selected") && parameter.selected) {
            variableList.push({
              modelid: modelEntry.FE_ID,
              speqqle_id: parameter.speqqle_id,
            });
          }
        }

        for (let j = 0; j < relevantModel.recParams.length; j++) {
          const recParamRow = relevantModel.recParams[j];

          for (let k = 0; k < recParamRow.length; k++) {
            const parameter = recParamRow[k];

            if (hasProperty(parameter, "selected") && parameter.selected) {
              variableList.push({
                modelid: modelEntry.FE_ID,
                speqqle_id: parameter.speqqle_id + parameter.recuring * j,
              });
            }
          }
        }
      }

      return variableList;
    } catch (error) {
      recordedErrorLog("Error generating accumulated variable list: ", error);
      return [];
    }
  };

  const handleSubmit = () => {
    try {
      // let listOfModelIds = [];
      const modelsToSend = [];

      const filePayload = [];

      for (let i = 0; i < chi2Terms.length; i++) {
        const term = chi2Terms[i];

        // listOfModelIds.push(...term.modelList.map((entry) => entry.FE_ID));

        const keepCuts = hasProperty(term, "keepCuts") ? term.keepCuts : false;

        let currentTermFile = null;

        if (keepCuts) {
          currentTermFile = uploadedFiles.find(
            (file) => file.ID === term.fileId
          );
        }

        const filesToAdd = [
          { ID: term.fileId },
          ...(hasProperty(term, "selectedLoopFiles")
            ? term.selectedLoopFiles
            : []),
        ];

        const dataList = filesToAdd.map((selectedFile) => {
          // let relevantFile = null;

          let dataRangeMin = null;
          let dataRangeMax = null;
          let edgesToUse = [];

          const fileToProcess = uploadedFiles.find(
            (file) => file.ID === selectedFile.ID
          );

          if (keepCuts) {
            dataRangeMin = currentTermFile.dataRangeMin;
            dataRangeMax = currentTermFile.dataRangeMax;
            edgesToUse = currentTermFile.edges;
          } else {
            dataRangeMin = fileToProcess.dataRangeMin;
            dataRangeMax = fileToProcess.dataRangeMax;
            edgesToUse = fileToProcess.edges;
          }

          let hasEdges = false;
          let filteredDataPoints = filterCurveDataToRange(
            fileToProcess.dataPoints,
            dataRangeMin,
            dataRangeMax
          );
          if (
            edgesToUse.length > 0 &&
            edgesToUse[0].min !== "" &&
            edgesToUse[0].max !== ""
          ) {
            hasEdges = true;
            filteredDataPoints = segmentsIntoSingleCurve(
              splitCurveIntoSegmentsByEdges(filteredDataPoints, edgesToUse)
            );
          }

          const details = {
            data: filteredDataPoints,
            edges: hasEdges ? edgesToUse : [],
          };
          return details;
        });

        filePayload.push({
          dataid: term.fileId,
          datalist: dataList,
        });

        modelsToSend.push(...term.modelList);
      }

      const modelsForCurves = getModelsByIds(
        modelsForCurvesDictionary.modelList,
        modelData
      );

      const rangesForModels = getRangesForModelsFromGraphs(graphs);

      const curvesPayload = createModelDistPayload(
        modelsForCurves,
        rangesForModels,
        modelsForCurvesDictionary.pairs,
        currentUser
      );

      const fitDetails = chi2Terms.map((chiTerm) => {
        return {
          modelid: chiTerm.modelId,
          dataid: chiTerm.fileId,
          weight: chiTerm.weight,
          quantity: chiTerm.quantity,
        };
      });

      const dataListForModels = modelsToSend.map((modelToSend) => {
        const foundModel = getModelById(modelToSend.FE_ID, modelData);

        const paramsPayload = createModelFitPayloadParams([foundModel]);

        const dataEntry = {
          ...paramsPayload[0],
          revert: modelToSend.option === "revert",
        };

        return dataEntry;
      });

      const fitOptions = {
        maxiters: maxIteration,
        maxsecs: maxRunTime,
        mindiffchi2: { numiters: chiVal.n, diffchi2: chiVal.val },
      };

      if (iterationRefreshCount !== "") {
        fitOptions.numupdateiters = iterationRefreshCount;
      }

      const variablesToTrack = generateAccumulatedVariableList();

      const payload = {
        User: currentUser.id,
        pause_next_iteration: true,
        Model: {
          GetModelCurve: curvesPayload.Model.GetModelCurve,
        },
        Loop: {
          FitOptions: fitOptions,
          // Fit: [{ modelid: 1, dataid: 1, weight: 1, quantity: 5 }],
          Fit: fitDetails,
          // Autofit: [{}],
          Data: filePayload,
          Model: dataListForModels,
        },
        ///and / or autofit
        // AccumulatedOutputs: {
        //   Variables: [
        //     {
        //       modelid: 1,
        //       speqqle_id: 2,
        //     },
        //     //.......
        //   ],
        // },
        // [
        //   {
        //     dataid: 1,
        //     datalist: [
        //       {
        //         data: [{ x: -294.342, y: 40 } /* ......*/],
        //         edges: [],
        //       },
        //       /// ........ data for other iterations if the file changes. If not the length of this array is 1
        //     ],
        //   },
        //   /// ........  same for other datafiles in the chisqrts
        // ],

        // [
        //   {
        //     modelid: 1,
        //     modeltype: -113,
        //     parameters: [
        //       { speqqle_id: -1, value: -113, fixed: true /*....... */ },
        //     ],
        //     revert: true,
        //   },
        //   //other models
        // ],
      };

      if (variablesToTrack.length > 0) {
        payload.Loop.AccumulatedOutputs = {};
        payload.Loop.AccumulatedOutputs.Variables = variablesToTrack;
      }

      sendJsonMessage(payload, { type: "loop-request" });
      setPauseOnNextLoop(true);
      setIsLoopOngoing(true);
      setLoopOpen(false);
      setOutputsOpen(true);
      setParametersOpen(true);
    } catch (error) {
      recordedErrorLog("Error in loop submit handling: ", error);
    }
  };

  const handleParamSelectClose = () => {
    setParamSelectOpen(false);
  };

  const handleModAutosaveSelectClose = () => {
    setAutosaveModSelectOpen(false);
  };

  const handleCurveAutosaveSelectClose = () => {
    setAutosaveCurveSelectOpen(false);
  };

  const handleModelSaveStatusChange = (FE_ID) => {
    setListOfLoopModelsToSave((oldList) => {
      return oldList.map((entry) => {
        if (entry.FE_ID === FE_ID) {
          return { ...entry, save: !entry.save };
        }
        return entry;
      });
    });
  };

  const handleCurveSaveStatusChange = (modelId, quantity) => {
    setListOfLoopCurvesToSave((oldList) => {
      return oldList.map((entry) => {
        if (entry.modelId === modelId && entry.quantity === quantity) {
          return { ...entry, save: !entry.save };
        }
        return entry;
      });
    });
  };

  const updateAllModelsOnOptionChange = (modelId, option) => {
    try {
      const chi2TermsCopy = deepCopy(chi2Terms);

      for (let i = 0; i < chi2TermsCopy.length; i++) {
        const term = chi2TermsCopy[i];

        if (hasProperty(term, "modelList")) {
          chi2TermsCopy[i].modelList = chi2TermsCopy[i].modelList.map(
            (modelEntry) => {
              if (modelEntry.FE_ID === modelId) {
                return { ...modelEntry, option: option };
              }
              return modelEntry;
            }
          );
        }
      }

      setChi2Terms(chi2TermsCopy);
    } catch (error) {
      recordedErrorLog(
        "Error updating all terms models on option change: ",
        error
      );
    }
  };

  const handleSelectClicked = (
    model,
    isRec,
    param,
    tracked,
    rowIndex = null
  ) => {
    let updatedTrackedParams = deepCopy(trackedParameters);

    if (
      !updatedTrackedParams.some((modelSet) => modelSet.FE_ID === model.FE_ID)
    ) {
      if (tracked) {
        let calculatedSpeqId = null;
        if (isRec) {
          calculatedSpeqId = param.speqqle_id + rowIndex * param.recuring;
        }
        updatedTrackedParams.push({
          FE_ID: model.FE_ID,
          modelName: model.displayName,
          selectedParams: [
            {
              id: param.id,
              speqqle_id: param.speqqle_id,
              calcSpeqId: calculatedSpeqId,
              displayposition: param.displayposition,
              name: param.name,
              rowIndex: rowIndex,
              rec: isRec,
            },
          ],
        });
      }
    } else {
      if (tracked) {
        updatedTrackedParams = updatedTrackedParams.map((modelSet) => {
          if (modelSet.FE_ID === model.FE_ID) {
            let calculatedSpeqId = null;
            if (isRec) {
              calculatedSpeqId = param.speqqle_id + rowIndex * param.recuring;
            }
            return {
              ...modelSet,
              selectedParams: modelSet.selectedParams.some(
                (trackedParam) =>
                  trackedParam.id === param.id &&
                  trackedParam.speqqle_id === param.speqqle_id &&
                  trackedParam.displayposition === param.displayposition &&
                  trackedParam.rec === isRec &&
                  trackedParam.rowIndex === rowIndex
              )
                ? modelSet.selectedParams
                : [
                    ...modelSet.selectedParams,
                    {
                      id: param.id,
                      speqqle_id: param.speqqle_id,
                      calcSpeqId: calculatedSpeqId,
                      displayposition: param.displayposition,
                      name: param.name,
                      rowIndex: rowIndex,
                      rec: isRec,
                    },
                  ],
            };
          }
          return modelSet;
        });
      } else {
        updatedTrackedParams = updatedTrackedParams.map((modelSet) => {
          if (modelSet.FE_ID === model.FE_ID) {
            return {
              ...modelSet,
              selectedParams: [
                ...modelSet.selectedParams.filter(
                  (trackedParam) =>
                    !(
                      trackedParam.id === param.id &&
                      trackedParam.speqqle_id === param.speqqle_id &&
                      trackedParam.displayposition === param.displayposition &&
                      trackedParam.rec === isRec &&
                      trackedParam.rowIndex === rowIndex
                    )
                ),
              ],
            };
          }
          return modelSet;
        });
      }
    }

    updatedTrackedParams = updatedTrackedParams.filter(
      (modelSet) => modelSet.selectedParams.length > 0
    );

    setTrackedParameters(updatedTrackedParams);
  };

  return (
    <div className={`loopWindow${chi2Terms.length > 0 ? "" : " empty"}`}>
      <div className="disclaimer">
        * The loop functionality is still under development and will change in
        the near future. Not all functionality is fully available yet, but feel
        free to try it out. We value your feedback.
      </div>
      {chi2Terms.length > 0 ? (
        <div className="contentContainer">
          <div className="loopOptions">
            <div className="loopInput">
              {chi2Terms.map((term, index) => (
                <Chi2LoopEntry
                  key={`${term.id}|${index}`}
                  term={term}
                  updateTerm={updateTerm}
                  updateAllOnOptionChange={updateAllModelsOnOptionChange}
                  model={getModelById(term.modelId, modelData)}
                />
              ))}
            </div>
            <div className="loopOutputs">
              <div className="buttonsSection">
                <div className="titleContainer">
                  <div className="title">Autosaving:</div>
                </div>
                <div className="autosaveArea">
                  <CustomButton
                    text="Select models"
                    extraClassnames={["loopExtrasVariant"]}
                    handleClick={handleSelectModels}
                    reference={autosaveModSelectRef}
                  />
                  {/* <Button
                    variant="contained"
                    className="selectionButton"
                    onClick={() => handleSelectModels()}
                    style={{ backgroundColor: "#145d9d" }}
                    ref={autosaveModSelectRef}
                  >
                    Select models
                  </Button> */}
                  <CustomButton
                    text="Select curves"
                    extraClassnames={["loopExtrasVariant"]}
                    handleClick={handleSelectCurves}
                    reference={autosaveCurveSelectRef}
                  />
                  {/* <Button
                    variant="contained"
                    className="selectionButton"
                    onClick={() => handleSelectCurves()}
                    style={{ backgroundColor: "#145d9d" }}
                    ref={autosaveCurveSelectRef}
                  >
                    Select curves
                  </Button> */}
                </div>
                <div className="titleContainer">
                  <div className="title">Tracking:</div>
                </div>
                <div className="trackingArea">
                  <CustomButton
                    text="Select parameters"
                    extraClassnames={["loopExtrasVariant"]}
                    handleClick={handleSelectParams}
                    reference={paramSelectRef}
                  />
                  {/* <Button
                    variant="contained"
                    className="selectionButton"
                    onClick={() => handleSelectParams()}
                    ref={paramSelectRef}
                    style={{ backgroundColor: "#145d9d" }}
                  >
                    Select parameters
                  </Button> */}
                </div>
                {trackedParameters.length > 0 ? (
                  <div className="titleContainer">
                    <div className="title">Tracked Parameters:</div>
                  </div>
                ) : (
                  <></>
                )}
                <div className="trackedParameters">
                  {trackedParameters.length > 0 ? (
                    <TrackedParamDisplay listOfTrackables={trackedParameters} />
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="submitButtons">
            <CustomButton
              text="Start Loop"
              extraClassnames={["largeStart"]}
              handleClick={handleSubmit}
            />
            {/* <Button
              variant="contained"
              className="submitButton"
              onClick={() => handleSubmit()}
              style={{ backgroundColor: "#145d9d" }}
            >
              Start Loop
            </Button> */}
          </div>
        </div>
      ) : (
        <div className="noTerms">
          <div className="text">
            No Χ<sup>2</sup> Terms have been defined.
          </div>
        </div>
      )}
      <Modal
        isOpen={paramSelectOpen}
        onRequestClose={handleParamSelectClose}
        contentLabel="Confirmation Modal"
        shouldCloseOnOverlayClick={true}
        appElement={paramSelectRef.current}
        style={{
          content: {
            top: "35%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            transform: "translate(-50%, -35%)",
            width: "650px",
            height: "800px",
          },
          overlay: {
            zIndex: "999",
          },
        }}
      >
        <div className="paramSelectModal">
          <ParameterSelectorWindow
            modelList={listOfLoopModelsToSave}
            handleSelectClicked={handleSelectClicked}
          />
        </div>
      </Modal>
      <Modal
        isOpen={autosaveModSelectOpen}
        onRequestClose={handleModAutosaveSelectClose}
        contentLabel="Confirmation Modal"
        shouldCloseOnOverlayClick={true}
        appElement={autosaveModSelectRef.current}
        style={{
          content: {
            top: "35%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            transform: "translate(-50%, -35%)",
            width: "300px",
            height: "300px",
          },
          overlay: {
            zIndex: "999",
          },
        }}
      >
        <div className="modelSaveSelectModal">
          <TextField
            label="File Prefix"
            value={loopModelFilesCustomName}
            onChange={(e) => setLoopModelFilesCustomName(e.target.value)}
            className="fileNamePrefix"
            size="small"
          />
          <div className="list">
            {listOfLoopModelsToSave.map((modelEntry) => {
              return (
                <div className="modelEntry" key={modelEntry.FE_ID}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={modelEntry.save}
                          onChange={() =>
                            handleModelSaveStatusChange(modelEntry.FE_ID)
                          }
                        />
                      }
                      label={modelEntry.name}
                    />
                  </FormGroup>
                </div>
              );
            })}
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={autosaveCurveSelectOpen}
        onRequestClose={handleCurveAutosaveSelectClose}
        contentLabel="Confirmation Modal"
        shouldCloseOnOverlayClick={true}
        appElement={autosaveCurveSelectRef.current}
        style={{
          content: {
            top: "35%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            transform: "translate(-50%, -35%)",
            width: "350px",
            height: "300px",
          },
          overlay: {
            zIndex: "999",
          },
        }}
      >
        <div className="curveSaveSelectModal">
          {listOfLoopCurvesToSave.length > 0 ? (
            <>
              <TextField
                label="File Prefix"
                value={loopCurveFilesCustomName}
                onChange={(e) => setLoopCurveFilesCustomName(e.target.value)}
                className="fileNamePrefix"
                size="small"
              />
              <div className="list">
                {listOfLoopCurvesToSave.map((curveEntry) => {
                  return (
                    <div
                      className="curveEntry"
                      key={`${curveEntry.modelId}|${curveEntry.quantity}`}
                    >
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={curveEntry.save}
                              onChange={() =>
                                handleCurveSaveStatusChange(
                                  curveEntry.modelId,
                                  curveEntry.quantity
                                )
                              }
                            />
                          }
                          label={
                            <div className="checkboxLabelContainer">
                              <div className="modelName">{curveEntry.name}</div>
                              |
                              <div className="quantityName">
                                {processSymbols(curveEntry.quantityName)}
                              </div>
                            </div>
                          }
                        />
                      </FormGroup>
                    </div>
                  );
                })}
              </div>{" "}
            </>
          ) : (
            <div className="noCurves">
              <div className="mainTitle">
                There are no relevant curves in graphs
              </div>
              <div className="subText">
                Please first add the curve(s) you would like to track to a graph
                and adapt the graph ranges according to your needs. Your output
                data will be exported for these ranges.
              </div>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
}

export default Loop;
