import React from "react";
import ManualContent from "../../components/ManualContent";
import linkSubmodelPaperclipImage from "../../images/experiment_link_submodel_paperclip.jpg";
import linkSubmodelSelectListImage from "../../images/experiment_link_submodel_selectlist.jpg";
import linkSubmodelDoneImage from "../../images/experiment_link_submodel_done.jpg";

const ManagingModels = () => {
  return (
    <ManualContent title="Managing Models">
      <p>In this section, you will learn how to:</p>

      <ul>
        <li>
          <a href="#renaming-models">Rename main models and submodels</a>.
        </li>
        <li>
          <a href="#adding-submodels">Add a submodel to a main model</a>.
        </li>
        <li>
          <a href="#replacing-models">
            Replace main models and submodels with new or loaded models
          </a>
          .
        </li>
        <li>
          <a href="#removing-models">Remove main models and submodels</a>.
        </li>
        <li>
          <a href="#re-using-models">
            Re-use models across several Experiments
          </a>
          .
        </li>
      </ul>

      <h2>Related Topics in Other Sections</h2>
      <p>
        For additional model management topics, refer to the following sections
        of the manual:
      </p>

      <ul>
        <li>
          <a href="loadmodels">Loading Experiments and Submodels</a>.
        </li>
        <li>
          <a href="newmodels">Adding a New Model</a>.
        </li>
        <li>
          <a href="../parameters/orderadjustment">
            Changing the Order of Submodels
          </a>
          .
        </li>
        <li>
          <a href="../parameters/saving">Saving Experiments and Submodels</a>.
        </li>
        <li>
          <a href="../parameters/overview">Adjusting Parameters of Models</a>.
        </li>
      </ul>

      <h2 id="renaming-models">Renaming Main Models and Submodels</h2>
      <p>
        To rename a model in the <strong>Experiments</strong> tab:
      </p>
      <ol>
        <li>Hover over the model name.</li>
        <li>Rick click with the mouse on the model name.</li>
        <li>
          Enter the new name and press <strong>Enter</strong>.
        </li>
      </ol>

      <h2 id="adding-submodels">Adding Submodels to Main Models</h2>
      <p>
        To add a new submodel to a main model to expand for example the number
        of layers in the Experiment:
      </p>
      <ol>
        <li>Hover over the main model.</li>
        <li>
          A plus (+), paperclip, and cross (X) icon will appear. Click the{" "}
          <strong>plus (+) icon</strong> to add a new submodel to the main
          model.
        </li>
        <li>
          The new submodel will appear in the lowest position in the list.
        </li>
      </ol>

      <h2 id="replacing-models">Replacing Main Models and Submodels</h2>
      <p>
        If you want to replace a main model or submodel with a different one
        that you already created or loaded:
      </p>
      <ol>
        <li>Hover over the model you want to replace.</li>
        <li>
          Click the <strong>paperclip (replace) icon</strong> that appears.
        </li>
        <li>A list of available models will open. Select the desired model.</li>
        <li>
          The selected model will replace the old one in the Experiment window.
        </li>
      </ol>

      <p>
        If you want to replace a main model or submodel but you did not yet
        create or load the replacing model:
      </p>
      <ol>
        <li>Create or load the model that should replace the previous one</li>
        <li>Follow the steps above</li>
      </ol>

      <h2 id="removing-models">Removing Main Models and Submodels</h2>
      <p>To remove a model from the Experiment window:</p>
      <ol>
        <li>Hover over the model you want to remove.</li>
        <li>
          Click the <strong>red cross (X) icon</strong>.
        </li>
        <li>
          If you remove a main model, all linked submodels will also be removed.
        </li>
      </ol>

      <h2 id="re-using-models">Re-using a Submodel in Several Main Models</h2>

      <p>
        In some cases, you may need to use the same material models across
        multiple Experiments. For example, if you measured{" "}
        <strong>reflectivity</strong> and{" "}
        <strong>THz time-domain spectroscopy</strong>
        on a multilayer sample and a reference substrate, you want to ensure
        that the same material models are used in each Experiment for
        consistency.
      </p>

      <p>
        To re-use the same material models across different Experiments, follow
        these steps:
      </p>

      <ol>
        <li>
          <strong>Load or Create Material Models:</strong>
          <ul>
            <li>
              If you have previously saved Experiments or material models, start
              by <a href="loadmodels">loading them</a>.
            </li>
            <li>
              If you need to create new Experiments and material models, add
              them using the <a href="newmodels">New Model</a> option.
            </li>
          </ul>
        </li>

        <li>
          <strong>Rename Material Models:</strong>
          Once your material models are added, rename them clearly to match
          their corresponding materials.
        </li>

        <li>
          <strong>Load the Necessary Experiments:</strong>
          Add as many Experiments as needed to correspond to the datasets you
          want to analyze.
        </li>

        <li>
          <strong>Adjust the Number of Submodels:</strong>
          Add or remove submodels within each Experiment to match the material
          configurations.
        </li>

        <li>
          <strong>Replace Placeholder Submodels:</strong>
          Replace the placeholder submodels in each Experiment with the material
          models you created or loaded:
        </li>

        <li>
          Hover over the submodel you want to replace in the{" "}
          <strong>Experiments</strong> tab.
        </li>
        <li>
          A paperclip and a cross (X) icon will appear. Click the{" "}
          <strong>paperclip icon</strong> to open a window listing all available
          submodels.
        </li>
        <li>
          Select the material model that will replace the placeholder submodel.
        </li>
        <li>
          <strong>Note:</strong> This action cannot be undone, the placeholder
          submodel will be lost if you haven&apos;t safed it previously.
        </li>
      </ol>

      <div className="image-row">
        <div className="image-container">
          <img
            src={linkSubmodelPaperclipImage}
            alt="Linking Submodels"
            className="workflow-image"
          />
          <p className="image-caption">
            1. Click the paperclip next to a main or submodel to open a list of
            available replacements.
          </p>
        </div>
        <div className="image-container">
          <img
            src={linkSubmodelSelectListImage}
            alt="Selecting a Submodel"
            className="workflow-image"
          />
          <p className="image-caption">
            2. Select the model to replace the original. Here, &quot;Material_12&quot; is
            replaced with &quot;Material_thinfilm&quot;.
          </p>
        </div>
        <div className="image-container">
          <img
            src={linkSubmodelDoneImage}
            alt="Submodel Replaced"
            className="workflow-image"
          />
          <p className="image-caption">
            3. &quot;Material_12&quot; has been successfully replaced with
            &quot;Material_thinfilm&quot;.
          </p>
        </div>
      </div>
    </ManualContent>
  );
};

export default ManagingModels;
