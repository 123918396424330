import React, { useState, useEffect, useContext, useRef } from "react";
import {
  updateParameterChanges,
  handleCorrectFontParams,
  handleCorrectCellColorParams,
  arraysOfObjectsAreEqual,
  isInChain,
  getNonRecParamsFromTableNum,
  checkSortOrder,
  sortAscending,
  sortDescending,
  updateLinksAfterChange,
  updateRecParamsAndRowsForParams,
} from "./parameterLogic";
import { DashboardContext } from "../../../context/DashboardContext";
import {
  generateWarningObject,
  FormattedText,
  useInputSize,
  handleFileChange,
  isDeepEqual,
  downloadStringTxtFile,
  hasProperty,
  simpleReadFile,
  constructAndDownloadCSV,
} from "../../../utils/helpers";
import { getGroupColor } from "../../../theme/groupingColors";
import {
  collectClashingGroupsFromNonRecParams,
  collectClashingGroupsFromRecParams,
  createModelFromParamList,
  deepCopy,
  generateModel,
  getModelById,
  replaceModelById,
} from "../../leftSide/Models/modelLogic";
import Checkbox from "./CheckBox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Checkbox as MuiCheckbox } from "@mui/material";
import ParameterTable from "./ParameterTable";
import "./parameters.scss";
import AutoFitModal from "../../commonComponents/AutoFitModal";
import { createModelFitPayloadParams } from "../../middle/graphLogic";
import Modal from "react-modal";
import GroupClashWindow from "../../leftSide/Models/GroupClashWindow";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import SaveIcon from "@mui/icons-material/Save";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import { GeneralContext } from "../../../context/GeneralContext";
import { AuthContext } from "../../../context/AuthContext";
import { WebSocketContext } from "../../../context/WebSocketContext";
import ModalVDF from "./ModalVDF";
import ConfirmationScreen from "../../commonComponents/ConfirmationScreen";
import MenuIcon from "@mui/icons-material/Menu";
import HtmlTooltip from "../../commonComponents/HtmlTooltip";
import CustomButton from "../../commonComponents/CustomButton";

function ParameterWindow(props) {
  const {
    model,
    modelIndex,
    changeModel,
    addSubModelFromParams,
    handleModelClick,
    startLinking,
    canLink,
    isLinkActive,
    openParameterWindow,
    idChain,
    setValueUpdate,
    setFixedUpdate,
    defaultExpanded,
    changeExpansion,
    loadBinary,
  } = props;
  const {
    setWarnings,
    setNewWarningCount,
    valueGroups,
    setValueGroups,
    uploadedFiles,
    undoModels,
    setUndoModels,
    // setRequestedVdf,
    modelData,
    setModelData,
    modelNextID,
    setModelNextID,
    abbrDic,
    setAbbrDic,
    namingDic,
    setNamingDic,
    allLocalModels,
  } = useContext(DashboardContext);
  const { limitedToast, limitedWarningToast, recordedErrorLog } =
    useContext(GeneralContext);
  const { currentUser } = useContext(AuthContext);
  const { sendJsonMessage } = useContext(WebSocketContext);
  const [nonRecRows, setNonRecRows] = useState([]);
  const [nonRecColumns, setNonRecColumns] = useState([]);
  const [nonRecParams, setNonRecParams] = useState([]);
  const [nonRecCheckboxes, setNonRecCheckboxes] = useState([]);
  const [recColumns, setRecColumns] = useState([]);
  const [recTemplate, setRecTemplate] = useState([]);
  const [recParams, setRecParams] = useState([]);
  const [recRows, setRecRows] = useState([]);
  const [skipPageReset, setSkipPageReset] = useState(false);
  const [windowExtraStyle, setWindowExtraStyle] = useState({});
  const isAllFixed = useRef(false);
  const localGroups = useRef(valueGroups);
  const updateGroupNeeded = useRef(false);
  const [autofitModalIsOpen, setAutofitModalIsOpen] = useState(false);
  const autoFitRef = useRef();
  const fileInputRef = useRef(null);
  const [undoAvailable, setUndoAvailable] = useState(false);
  const [clashModalIsOpen, setClashModalIsOpen] = useState(false);
  const [clashingModel, setClashingModel] = useState(null);
  const [clashingGroups, setClashingGroups] = useState(null);
  const [upadatedGroupsWithNoClash, setUpdatedGroupsWithNoClash] =
    useState(null);
  const [isExpanded, setIsExpanded] = useState(defaultExpanded);
  const [modelHeaderMinWidth, setModelHeaderMinWidth] = useState(100);
  const vdfButton = useRef(null);
  const [isVDFOpen, setIsVDFOpen] = useState(false);
  const [vdfFixAllOpen, setVdfFixAllOpen] = useState(false);
  const [isOnLocal, setIsOnLocal] = useState(false);
  const [isKKLocal, setIsKKLocal] = useState(false);
  const [isFitActiveLocal, setIsFitActiveLocal] = useState(false);
  const [hasVDFSection, setHasVDFSection] = useState(false);
  const [lineshapeRows, setLineshapeRows] = useState({});
  const [lineshapeParams, setLineshapeParams] = useState({});
  const [hasLineshapeSection, setHasLineshapeSection] = useState(false);
  const [isLineshapeModalOpen, setIsLineshapeModalOpen] = useState(false);
  const [lineshapeModalPosition, setLineshapeModalPosition] = useState({
    top: "30%",
    left: "50%",
  });
  const lineshapeButtonRef = useRef(null);
  const currentID = useRef(modelNextID);
  const localAbbrDic = useRef(abbrDic);

  useEffect(() => {
    // Check for VDF data
    if (hasProperty(model, "vdfData")) {
      setIsOnLocal(model.vdfData.on);
      setIsKKLocal(model.vdfData.kk);
      setIsFitActiveLocal(model.vdfData.fitactive);
      setHasVDFSection(true);
    } else {
      setHasVDFSection(false);
    }

    // Check for lineshape property
    if (
      hasProperty(model, "lineshape") &&
      model.lineshape !== 0 &&
      model.lineshape !== false
    ) {
      setHasLineshapeSection(true);
    } else {
      setHasLineshapeSection(false);
    }

    if (hasProperty(model, "lineshapeData") && model.lineshapeData.length > 0) {
      let rows = {};
      let params = {};
      for (let i = 0; i < model.lineshapeData.length; i++) {
        params[model.lineshapeData[i].lineshape_id] = model.lineshapeData[i].lineParams || [];
        rows[model.lineshapeData[i].lineshape_id] = model.lineshapeData[i].lineTableRows || [];
      }
      setLineshapeRows(rows);
      setLineshapeParams(params);
    }
  }, [model]);

  useEffect(() => {
    if (hasVDFSection) {
      if (
        model.vdfData.on !== isOnLocal ||
        model.vdfData.kk !== isKKLocal ||
        model.vdfData.fitactive !== isFitActiveLocal
      ) {
        const modelParams = createModelFitPayloadParams([model]);

        const vdfParams = {
          modelid: model.FE_ID,
          kk: isKKLocal,
          on: isOnLocal,
          fitactive: isFitActiveLocal,
        };

        let payload = {
          User: currentUser.id,
          Model: {
            SendModel: modelParams,
            Vdf: [vdfParams],
          },
        };

        sendJsonMessage(payload, {
          type: "vdf-request",
          requestedVdfs: [{ id: model.FE_ID }],
        });
        // setRequestedVdf((old) => [...old, { id: model.FE_ID }]);
      }
    }
  }, [isOnLocal, isKKLocal, isFitActiveLocal]);

  useEffect(() => {
    try {
      if (model) {
        let modelNameMinWidth = 0;
        if (isExpanded) {
          modelNameMinWidth += 70; // Save icon + Load icon + expand/minimise icon + export button

          modelNameMinWidth += Math.min(useInputSize(model.displayName), 135); // size of the model name itself

          // if (model.vdf) {
          //   modelNameMinWidth += 40;
          // }

          // if (model.autoFit) {
          //   modelNameMinWidth += 56;
          // }

          // if (undoAvailable) {
          //   modelNameMinWidth += 64;
          // }

          // if (isAllFixed.current) {
          //   modelNameMinWidth += 52;
          // } else {
          //   modelNameMinWidth += 49;
          // }
        } else {
          modelNameMinWidth = useInputSize(model.displayName) + 38;
        }

        setModelHeaderMinWidth(modelNameMinWidth);
      }
    } catch (error) {
      recordedErrorLog(
        "Model name width calculation failure in useEffect: ",
        error
      );
    }
  }, [model, isExpanded]);

  useEffect(() => {
    if (clashingModel !== null) {
      setClashModalIsOpen(true);
    }
  }, [clashingModel]);

  useEffect(() => {
    try {
      if (
        undoModels !== undefined &&
        undoModels.length > 0 &&
        undoModels.some((undoModel) => model.FE_ID === undoModel.FE_ID)
      ) {
        setUndoAvailable(true);
      } else {
        setUndoAvailable(false);
      }
    } catch (error) {
      recordedErrorLog("Undo model useEffect failure: ", error);
    }
  }, [undoModels]);

  useEffect(() => {
    localGroups.current = valueGroups;
  }, [valueGroups]);

  const handleWindowClick = () => {
    if (isLinkActive()) {
      startLinking(model.FE_ID, model.speqqleID);
    } else {
      handleModelClick(model.FE_ID);
    }
  };

  const handleAutoFit = () => {
    if (uploadedFiles.length > 0) {
      setAutofitModalIsOpen(true);
    } else {
      limitedToast(
        "There needs to be at least 1 file uploaded for AutoFit to function"
      );
      generateWarningObject(
        "There needs to be at least 1 file uploaded for AutoFit to function",
        0,
        setWarnings,
        setNewWarningCount
      );
    }
  };

  const handleUndo = () => {
    try {
      const foundUndoModel = undoModels.find(
        (undoModel) => undoModel.FE_ID === model.FE_ID
      );
      changeModel(foundUndoModel, model.FE_ID, null);
      setUndoModels((old) =>
        old.filter((oldModel) => oldModel.FE_ID !== model.FE_ID)
      );
    } catch (error) {
      recordedErrorLog("Undo handler failure: ", error);
    }
  };

  const getRecurringTableTitle = () => {
    const firstRecParam = model.modelParams.find((param) => param.recuring > 0);
    const tableIndex = firstRecParam ? firstRecParam.displaytable : -1;
    return model.tableTitles &&
      tableIndex >= 0 &&
      tableIndex < model.tableTitles.length
      ? model.tableTitles[tableIndex]
      : "";
  };

  const getLineshapeTableTitle = (lineshapeid) => {
    if (
      allLocalModels &&
      allLocalModels.length > 0 &&
      model.speqqleID !== undefined
    ) {
      const matchingModel = allLocalModels.find(
        (m) => m.speqqle_id === model.speqqleID
      );
      if (
        matchingModel &&
        matchingModel.shapes &&
        matchingModel.shapes.length > 0
      ) {
        const matchingShape = matchingModel.shapes.find(
          (s) => s.speqqle_id === lineshapeid
        );
        if (matchingShape) {
          // Only show tooltip if there's a description
          if (matchingShape.description) {
            return (
              <HtmlTooltip
                title={
                  <React.Fragment>
                    <div className="customTooltip">
                      {matchingShape.description}
                    </div>
                  </React.Fragment>
                }
              >
                <span>{`${matchingShape.name}:`}</span>
              </HtmlTooltip>
            );
          } else {
            // Just show the name without tooltip if no description
            return `${matchingShape.name}:`;
          }
        }
      }
    } else {
      return `Shape ${lineshapeid}:`;
    }
  };

  const getlineshapeCulumnsRows = (lineshapeparameters) => {
    let lineshapeColumnRow = [];
    let maxDisplayPosition = 0;
    for (let i = 0; i < lineshapeparameters.length; i++) {
      const parameter = lineshapeparameters[i];
      const position =
        parameter.displayposition !== undefined ? parameter.displayposition : i;
      if (position > maxDisplayPosition) {
        maxDisplayPosition = position;
      }
    }
    for (let i = 0; i <= maxDisplayPosition; i++) {
      lineshapeColumnRow[i] = null;
    }
    for (let i = 0; i < lineshapeparameters.length; i++) {
      const parameter = lineshapeparameters[i];
      if (parameter.displayposition !== undefined) {
        lineshapeColumnRow[parameter.displayposition] = {
          Header: <FormattedText input={parameter.name} />,
          accessor: parameter.name,
        };
      } else {
        lineshapeColumnRow.push({
          Header: <FormattedText input={parameter.name} />,
          accessor: parameter.name,
        });
      }
    }
    return lineshapeColumnRow.filter((column) => column !== null);
  };

  useEffect(() => {
    try {
      if (idChain.length > 0) {
        let windowStyle = {};
        if (isInChain(model.FE_ID, idChain)) {
          windowStyle = { ...windowStyle, borderWidth: "2px" };
        }
        if (isLinkActive() && canLink(model.FE_ID, model.speqqleID)) {
          windowStyle = { ...windowStyle, cursor: "pointer" };
        }
        setWindowExtraStyle(windowStyle);
      }
    } catch (error) {
      recordedErrorLog("idChain useEffect failure: ", error);
    }
  }, [idChain]);

  // let nonRecSubModels = [];

  function findLargestTableNum(params) {
    try {
      let largestNum = 0;

      for (let i = 0; i < params.length; i++) {
        const param = params[i];

        if (param.displaytable > largestNum) {
          largestNum = param.displaytable;
        }
      }

      return largestNum;
    } catch (error) {
      recordedErrorLog("Largest table num finder has failed: ", error);
    }
  }

  useEffect(() => {
    try {
      let nonRecCheckboxesInitial = [];

      // Recuring Param Setting
      let recColumnsInitial = [];
      let recRowInitial = {};
      let recRowsSaved = [];
      let recParamsInitial = [];

      if (model.modelParams.length > 0) {
        let paramsCopy = model.modelParams.map((obj) => Object.assign({}, obj));

        const filteredParams = paramsCopy.filter(
          (param) => param.recuring === 0
        );

        const largestTableNum = findLargestTableNum(filteredParams);

        let nonRecGroupedParams = [];
        let nonRecGroupedColumnHeads = [];
        let nonRecGroupedRows = [];

        for (let tableIndex = 0; tableIndex <= largestTableNum; tableIndex++) {
          let table = [];
          let headers = [];
          let row = {};
          for (let i = 0; i < filteredParams.length; i++) {
            const param = filteredParams[i];
            if (param.displaytable === tableIndex) {
              if (param.type !== "Model") {
                table[param.displayposition] = param;

                headers[param.displayposition] = {
                  Header: <FormattedText input={param.name} />,
                  accessor: param.name,
                };

                if (Object.prototype.hasOwnProperty.call(param, "value")) {
                  row = {
                    ...row,
                    [param.name]: param.value,
                  };
                } else {
                  row = {
                    ...row,
                    [param.name]: param.default,
                  };
                }
              } else {
                table[param.displayposition] = param;

                headers[param.displayposition] = {
                  Header: <FormattedText input={"Model"} />,
                  accessor: `${param.FE_ID}|Non-rec-sub-model`,
                };

                const subModel = model.subModels.find(
                  (subMod) => subMod.FE_ID === param.FE_ID
                );

                row = {
                  ...row,
                  [`${param.FE_ID}|Non-rec-sub-model`]: subModel.displayName,
                };
              }
            }
          }

          nonRecGroupedParams.push(table);
          nonRecGroupedColumnHeads.push(headers);

          // row is made to an array because the table is designed for multiple rows and therefore expects an array,
          // but in our case we have just one row for non recuring parameters.
          nonRecGroupedRows.push([row]);
        }

        setNonRecParams(filteredParams);
        setNonRecColumns(nonRecGroupedColumnHeads);
        setNonRecRows(nonRecGroupedRows);

        paramsCopy
          .filter((param) => param.recuring == 0 && param.type == "Checkbox")
          .forEach((element) => {
            nonRecCheckboxesInitial.push(element);
          });

        setNonRecCheckboxes(nonRecCheckboxesInitial);

        if (Object.prototype.hasOwnProperty.call(model, "recParams")) {
          recParamsInitial = deepCopy(model.recParams);
          let recParamsCopy = deepCopy(model.recTemplate.params);
          if (Object.prototype.hasOwnProperty.call(model, "recTableRows")) {
            recRowsSaved = deepCopy(model.recTableRows);
            recParamsCopy.forEach((element) => {
              if (
                element.type == "int" ||
                element.type == "float" ||
                element.type == "Checkbox"
              ) {
                recColumnsInitial[element.displayposition] = {
                  Header: <FormattedText input={element.name} />,
                  accessor: element.name,
                };
              } else if (element.type == "Model") {
                recColumnsInitial[element.displayposition] = {
                  Header: "Model",
                  accessor: "Model",
                };
              } else {
                recordedErrorLog("UNKNOWN PARAM TYPE FOUND: ", element.type);
              }
            });
          } else {
            limitedToast(
              "Website error, report to support: Parameter window had 'params' set, but without 'rows'."
            );
            generateWarningObject(
              "Website error, report to support: Parameter window had 'params' set, but without 'rows'.",
              2,
              setWarnings,
              setNewWarningCount
            );
          }
        } else {
          if (
            paramsCopy.some(
              (parameter) => parameter.type == "Model" && parameter.recuring > 0
            )
          ) {
            let nonModelParams = {};
            paramsCopy
              .filter((param) => param.recuring > 0)
              .forEach((element) => {
                recParamsInitial.push(element);
                if (
                  element.type == "int" ||
                  element.type == "float" ||
                  element.type == "Checkbox"
                ) {
                  recColumnsInitial[element.displayposition] = {
                    Header: <FormattedText input={element.name} />,
                    accessor: element.name,
                  };
                  if (Object.prototype.hasOwnProperty.call(element, "value")) {
                    nonModelParams = {
                      ...nonModelParams,
                      [element.name]: element.value,
                    };
                  } else {
                    nonModelParams = {
                      ...nonModelParams,
                      [element.name]: element.default,
                      // value: element.default,
                    };
                  }
                } else if (element.type == "Model") {
                  recColumnsInitial[element.displayposition] = {
                    Header: "Model",
                    accessor: "Model",
                  };
                } else {
                  recordedErrorLog("UNKNOWN PARAM TYPE FOUND: ", element.type);
                }
              });

            recRowInitial = [];
            recParamsInitial = [recParamsInitial];

            if (model.subModels.length > 1) {
              for (let i = 0; i < model.subModels.length - 1; i++) {
                recParamsInitial.push(deepCopy(recParamsInitial[0]));
              }
            }

            for (let i = 0; i < model.subModels.length; i++) {
              let rowObject = {
                Model: model.subModels[i].displayName,
                ...nonModelParams,
              };
              recRowInitial.push(rowObject);
            }
          } else {
            paramsCopy
              .filter((param) => param.recuring > 0)
              .forEach((element) => {
                recParamsInitial.push(element);
                if (
                  element.type == "int" ||
                  element.type == "float" ||
                  element.type == "Checkbox"
                ) {
                  recColumnsInitial[element.displayposition] = {
                    Header: <FormattedText input={element.name} />,
                    accessor: element.name,
                  };
                  if (Object.prototype.hasOwnProperty.call(element, "value")) {
                    recRowInitial = {
                      ...recRowInitial,
                      [element.name]: element.value,
                    };
                  } else {
                    recRowInitial = {
                      ...recRowInitial,
                      [element.name]: element.default,
                      // value: element.default,
                    };
                  }
                } else {
                  recordedErrorLog("UNKNOWN PARAM TYPE FOUND: ", element.type);
                }
              });
          }
          if (recParamsInitial.length > 0) {
            let modelWithRecParams;
            if (recParamsInitial.every((item) => Array.isArray(item))) {
              modelWithRecParams = { ...model, recParams: recParamsInitial };
            } else {
              modelWithRecParams = { ...model, recParams: [recParamsInitial] };
            }
            changeModel(modelWithRecParams, model.FE_ID, null);
          }
        }
        if (recColumnsInitial.length > 0) {
          setRecColumns(recColumnsInitial);
          if (recRowsSaved.length > 0) {
            setRecRows(() => recRowsSaved);
            setRecTemplate(model.recTemplate);
          } else {
            if (recRowInitial.length > 1) {
              setRecRows(() => recRowInitial);
              setRecTemplate({
                row: recRowInitial[0],
                params: recParamsInitial[0],
              });
            } else {
              if (Array.isArray(recRowInitial)) {
                setRecRows(() => recRowInitial);
                setRecTemplate({
                  row: recRowInitial[0],
                  params: recParamsInitial[0],
                });
              } else {
                setRecRows(() => [recRowInitial]);
                setRecTemplate({
                  row: recRowInitial,
                  params: recParamsInitial,
                });
              }
            }
          }
          if (recParamsInitial.every((item) => Array.isArray(item))) {
            setRecParams(recParamsInitial);
          } else {
            setRecParams([recParamsInitial]);
          }
        }
      }
    } catch (error) {
      recordedErrorLog(
        "Initial model params handling in useEffect has failed: ",
        error
      );
    }
  }, []);

  useEffect(() => {
    try {
      if (
        model.modelParams.some((param) => param.recuring > 0) &&
        Object.prototype.hasOwnProperty.call(model, "recTableRows") &&
        Object.prototype.hasOwnProperty.call(model, "recParams")
      ) {
        if (
          recRows != undefined &&
          model.recTableRows != undefined &&
          !arraysOfObjectsAreEqual(recRows, model.recTableRows)
        ) {
          setRecRows(() => model.recTableRows);
        }

        if (
          recParams != undefined &&
          model.recParams != undefined &&
          !arraysOfObjectsAreEqual(recParams, model.recParams)
        ) {
          setRecParams(model.recParams);
        }

        handleNonRecUpdate();
      } else if (nonRecRows !== undefined && nonRecRows.length > 0) {
        handleNonRecUpdate();
      }
    } catch (error) {
      recordedErrorLog("Model change handler in useEffect has failed: ", error);
    }
  }, [model]);

  const handleNonRecUpdate = () => {
    try {
      if (nonRecRows !== undefined && model.modelParams !== undefined) {
        let paramsCopy = model.modelParams.map((obj) => Object.assign({}, obj));

        let filteredParams = paramsCopy.filter(
          (param) =>
            param.recuring == 0 &&
            (param.type == "int" || param.type == "float")
        );

        const largestTableNum = findLargestTableNum(filteredParams);

        let nonRecGroupedRows = [];

        for (let tableIndex = 0; tableIndex <= largestTableNum; tableIndex++) {
          let row = {};
          for (let i = 0; i < filteredParams.length; i++) {
            const param = filteredParams[i];
            if (param.displaytable === tableIndex) {
              if (Object.prototype.hasOwnProperty.call(param, "value")) {
                row = {
                  ...row,
                  [param.name]: param.value,
                };
              } else {
                row = {
                  ...row,
                  [param.name]: param.default,
                };
              }
            }
          }

          // row is made to an array because the table is designed for multiple rows and therefore expects an array,
          // but in our case we have just one row for non recuring parameters.
          nonRecGroupedRows.push([row]);
        }

        if (
          nonRecRows.length > 0 &&
          !isDeepEqual(nonRecGroupedRows, nonRecRows)
        ) {
          setNonRecRows(nonRecGroupedRows);
        }
      }

      if (nonRecParams != undefined && model.modelParams != undefined) {
        let paramsCopy = model.modelParams.map((obj) => Object.assign({}, obj));

        const filteredParams = paramsCopy.filter(
          (param) =>
            param.recuring == 0 &&
            (param.type == "int" || param.type == "float")
        );

        if (
          filteredParams.length > 0 &&
          !arraysOfObjectsAreEqual(filteredParams, nonRecParams)
        ) {
          setNonRecParams(filteredParams);
        }
      }
    } catch (error) {
      recordedErrorLog("Non recuring parameter updated has failed: ", error);
    }
  };

  useEffect(() => {
    try {
      if (nonRecRows.length > 0) {
        const copyOfModel = { ...model };
        const newParams = updateParameterChanges(
          copyOfModel.modelParams,
          nonRecRows // non rec rows [table index] [row index]
        );
        let updateNeeded = false;
        if (!arraysOfObjectsAreEqual(model.modelParams, newParams)) {
          updateNeeded = true;
          copyOfModel.modelParams = newParams;
          setNonRecParams(
            newParams.filter(
              (param) =>
                param.recuring == 0 &&
                (param.type == "int" || param.type == "float")
            )
          );
        }
        if (updateNeeded && updateGroupNeeded.current) {
          changeModel(copyOfModel, model.FE_ID, localGroups.current);
          updateGroupNeeded.current = false;
        } else if (updateNeeded) {
          changeModel(copyOfModel, model.FE_ID, null);
        }
      }
    } catch (error) {
      recordedErrorLog("nonRecRows useEffect handler has failed: ", error);
    }
  }, [nonRecRows]);

  useEffect(() => {
    try {
      let copyOfModel = { ...model };
      let needsUpdate = false;

      const modelNonRecParams = model.modelParams.filter(
        (param) =>
          param.recuring == 0 && (param.type == "int" || param.type == "float")
      );

      if (
        nonRecParams.length > 0 &&
        modelNonRecParams.length > 0 &&
        !arraysOfObjectsAreEqual(modelNonRecParams, nonRecParams)
      ) {
        needsUpdate = true;
        for (let i = 0; i < copyOfModel.modelParams.length; i++) {
          const paramFromModel = copyOfModel.modelParams[i];
          if (nonRecParams.some((param) => param.id == paramFromModel.id)) {
            copyOfModel.modelParams[i] = nonRecParams.find(
              (param) => param.id == paramFromModel.id
            );
          }
        }
      }

      if (recRows.length > 0) {
        if (
          Object.prototype.hasOwnProperty.call(model, "recTemplate") &&
          !arraysOfObjectsAreEqual(recRows, model.recTableRows)
        ) {
          needsUpdate = true;
          copyOfModel = {
            ...copyOfModel,
            recTableRows: recRows,
          };
          // changeModel(copyOfModel, model.FE_ID);
        } else if (
          !Object.prototype.hasOwnProperty.call(model, "recTemplate")
        ) {
          needsUpdate = true;
          copyOfModel = {
            ...copyOfModel,
            recTableRows: recRows,
            recParams: recParams,
            recTemplate: recTemplate,
          };
          // changeModel(copyOfModel, model.FE_ID);
        }
      }
      if (
        recParams != undefined &&
        recParams.length > 0 &&
        model.recParams != undefined &&
        model.recParams.length > 0 &&
        !arraysOfObjectsAreEqual(recParams, model.recParams)
      ) {
        needsUpdate = true;
        copyOfModel = {
          ...copyOfModel,
          recParams: recParams,
        };
      }
      if (needsUpdate && updateGroupNeeded.current) {
        changeModel(copyOfModel, model.FE_ID, localGroups.current);
        updateGroupNeeded.current = false;
      } else if (needsUpdate) {
        changeModel(copyOfModel, model.FE_ID, null);
      }
    } catch (error) {
      recordedErrorLog(
        "recRows and recParams useEffect handler has failed: ",
        error
      );
    }
  }, [recRows, recParams]);

  useEffect(() => {
    try {
      let copyOfModel = deepCopy(model);
      let needsUpdate = false;

      if (
        Object.keys(lineshapeRows).length === 0 &&
        hasProperty(copyOfModel, "lineshapeData")
      ) {
        delete copyOfModel.lineshapeData;
        needsUpdate = true;
      }
      // Only proceed if we have lineshape data to work with
      if (Object.keys(lineshapeRows).length > 0) {
        if (!hasProperty(copyOfModel, "lineshapeData")) {
          copyOfModel.lineshapeData = [];
          needsUpdate = true;
        }
        // Check each lineshape in the model
        for (let i = copyOfModel.lineshapeData.length - 1; i >= 0; i--) {
          if (!hasProperty(copyOfModel.lineshapeData[i], "lineTemplate")) {
            return;
          }
          const lineshape = copyOfModel.lineshapeData[i];
          const lineshape_id = lineshape.lineshape_id;

          // If this lineshape's rows exist in our state and differ from the model
          if (
            lineshapeRows[lineshape_id] &&
            !arraysOfObjectsAreEqual(
              lineshapeRows[lineshape_id],
              lineshape.lineTableRows
            )
          ) {
            needsUpdate = true;
            copyOfModel.lineshapeData[i].lineTableRows = deepCopy(
              lineshapeRows[lineshape_id]
            );
          }

          // If this lineshape's params exist in our state and differ from the model
          if (
            lineshapeParams[lineshape_id] &&
            !arraysOfObjectsAreEqual(
              lineshapeParams[lineshape_id],
              lineshape.lineParams
            )
          ) {
            needsUpdate = true;
            copyOfModel.lineshapeData[i].lineParams = deepCopy(
              lineshapeParams[lineshape_id]
            );
          }

          if (!lineshapeParams[lineshape_id]) {
            needsUpdate = true;
            copyOfModel.lineshapeData.splice(i, 1);
          }
        }

        // Handle lineshapes that exist in state but not in model (newly added)
        const modelLineshapeIds = copyOfModel.lineshapeData.map(
          (ls) => ls.lineshape_id
        );
        for (const stateLineshapeId in lineshapeRows) {
          if (!modelLineshapeIds.includes(Number(stateLineshapeId))) {
            // This is a new lineshape not yet in the model
            needsUpdate = true;

            const newLineshape = {
              lineshape_id: Number(stateLineshapeId),
              lineTableRows: deepCopy(lineshapeRows[stateLineshapeId]),
              lineParams: deepCopy(lineshapeParams[stateLineshapeId]),
              lineTemplate: {
                params: deepCopy(lineshapeParams[stateLineshapeId][0]),
                row: deepCopy(lineshapeRows[stateLineshapeId][0]),
              },
            };
            for (let i = 0; i < newLineshape.lineTemplate.params.length; i++) {
              if (
                newLineshape.lineTemplate.params[i].value !==
                newLineshape.lineTemplate.params[i].default
              ) {
                newLineshape.lineTemplate.params[i].value =
                  newLineshape.lineTemplate.params[i].default;
                newLineshape.lineTemplate.row[
                  newLineshape.lineTemplate.params[i].name
                ] = newLineshape.lineTemplate.params[i].default;
              }
              if (
                !hasProperty(newLineshape.lineTemplate.params[i], "fixed") ||
                !newLineshape.lineTemplate.params[i].fixed
              ) {
                newLineshape.lineTemplate.params[i].customFixed = true;
              }
              if (!hasProperty(newLineshape.lineTemplate.params[i], "group")) {
                delete newLineshape.lineTemplate.params[i].group;
              }
              if (
                !hasProperty(newLineshape.lineTemplate.params[i], "hardMax")
              ) {
                delete newLineshape.lineTemplate.params[i].hardMax;
              }
              if (
                !hasProperty(newLineshape.lineTemplate.params[i], "hardMin")
              ) {
                delete newLineshape.lineTemplate.params[i].hardMin;
              }
            }
            copyOfModel.lineshapeData.push(newLineshape);
          }
        }
      }

      if (needsUpdate && updateGroupNeeded.current) {
        changeModel(copyOfModel, model.FE_ID, localGroups.current);
        updateGroupNeeded.current = false;
      } else if (needsUpdate) {
        changeModel(copyOfModel, model.FE_ID, null);
      }
    } catch (error) {
      recordedErrorLog(
        "lineshapeRows and lineshapeParams useEffect handler has failed: ",
        error
      );
    }
  }, [lineshapeRows, lineshapeParams]);

  function getCellColor(cell, rec, lineshape_id = -1) {
    const columnHead = cell.column.id;
    const rowIndex = cell.row.index;
    const valueOfCell = cell.value;

    let correctParams;

    try {
      if (columnHead !== "Model") {
        if (lineshape_id !== -1) {
          if (
            hasProperty(model, "lineshapeData") &&
            model.lineshapeData.length > 0
          ) {
            const lineshape = model.lineshapeData.find(
              (ls) => ls.lineshape_id === lineshape_id
            );
            if (
              lineshape &&
              lineshape.lineParams &&
              lineshape.lineParams[rowIndex]
            ) {
              correctParams = lineshape.lineParams[rowIndex].find(
                (param) => param.name === columnHead
              );
            }
          }
        } else if (
          rec &&
          valueOfCell != null &&
          model.recParams !== undefined &&
          model.recParams[rowIndex] !== undefined
        ) {
          // Recurring parameter case
          correctParams = model.recParams[rowIndex].find(
            (param) => param.name === columnHead
          );
        } else if (rec === false) {
          // Non-recurring parameter case
          correctParams = nonRecParams.find(
            (param) => param.name === columnHead
          );
        }

        return handleCorrectCellColorParams(correctParams, valueOfCell);
      }

      return;
    } catch (e) {
      recordedErrorLog("ERROR IN GETTING CELL COLOR: ", e);
      limitedToast("Website error, report to support: " + e.message);
      generateWarningObject(
        "Website error, report to support: " + e.message,
        2,
        setWarnings,
        setNewWarningCount
      );
      return;
    }
  }

  function setFixed(cell, rec, tableIndex, lineshape_id) {
    try {
      const columnHead = cell.column.id;
      if (columnHead != "Model") {
        //This variable is keeping track of what needs to be passed to update fixed checkbox in sticky parameter window
        let fixToUpdate = null;

        const rowIndex = cell.row.index;
        let correctParams;
        const copyOfModel = deepCopy(model);
        if (
          lineshape_id !== -1 &&
          model.lineshapeData != undefined &&
          model.lineshapeData.length > 0
        ) {
          let lineshape_index = model.lineshapeData.findIndex(
            (ls) => ls.lineshape_id === lineshape_id
          );
          correctParams = model.lineshapeData[lineshape_index].lineParams[rowIndex].map((obj) =>
            Object.assign({}, obj)
          );
          copyOfModel.lineshapeData[lineshape_index].lineParams[rowIndex] = correctParams.map((parameter) => {
            if (parameter.name == columnHead) {
              if (!parameter.fixed) {
                if (Object.prototype.hasOwnProperty.call(parameter, "group")) {
                  const valueGroupsCopy = deepCopy(localGroups.current);
                  for (let j = 0; j < valueGroupsCopy.length; j++) {
                    if (valueGroupsCopy[j].groupNumber == parameter.group) {
                      if (
                        Object.prototype.hasOwnProperty.call(
                          parameter,
                          "customFixed"
                        )
                      ) {
                        const isFixed = parameter.customFixed;
                        valueGroupsCopy[j] = {
                          ...valueGroupsCopy[j],
                          fixed: !isFixed,
                        };
                        localGroups.current = valueGroupsCopy;
                        updateGroupNeeded.current = true;

                        //Setting value for sticky parameter window
                        fixToUpdate = !isFixed;

                        return { ...parameter, customFixed: !isFixed };
                      } else {
                        const isFixed = parameter.fixed;
                        valueGroupsCopy[j] = {
                          ...valueGroupsCopy[j],
                          fixed: !isFixed,
                        };
                        localGroups.current = valueGroupsCopy;
                        updateGroupNeeded.current = true;

                        //Setting value for sticky parameter window
                        fixToUpdate = !isFixed;

                        return { ...parameter, customFixed: !isFixed };
                      }
                    }
                  }
                } else {
                  updateGroupNeeded.current = false;
                  if (
                    Object.prototype.hasOwnProperty.call(
                      parameter,
                      "customFixed"
                    )
                  ) {
                    const isFixed = parameter.customFixed;

                    //Setting value for sticky parameter window
                    fixToUpdate = !isFixed;

                    return { ...parameter, customFixed: !isFixed };
                  } else {
                    const isFixed = parameter.fixed;

                    //Setting value for sticky parameter window
                    fixToUpdate = !isFixed;

                    return { ...parameter, customFixed: !isFixed };
                  }
                }
              } else {
                limitedToast(
                  "Fixing and un-fixing is only allowed on values that are not fixed by default."
                );
                generateWarningObject(
                  "Fixing and un-fixing is only allowed on values that are not fixed by default.",
                  0,
                  setWarnings,
                  setNewWarningCount
                );
                return parameter;
              }
            } else {
              return parameter;
            }
          });
          setLineshapeParams((oldParams) => {
            const newParams = { ...oldParams };
            newParams[lineshape_id] = copyOfModel.lineshapeData[lineshape_index].lineParams;
            return newParams;
          });
        } else if (
          rec &&
          model.recParams != undefined &&
          model.recParams[rowIndex] != undefined
        ) {
          correctParams = model.recParams[rowIndex].map((obj) =>
            Object.assign({}, obj)
          );
          copyOfModel.recParams[rowIndex] = correctParams.map((parameter) => {
            if (parameter.name == columnHead) {
              if (!parameter.fixed) {
                if (Object.prototype.hasOwnProperty.call(parameter, "group")) {
                  const valueGroupsCopy = deepCopy(localGroups.current);
                  for (let j = 0; j < valueGroupsCopy.length; j++) {
                    if (valueGroupsCopy[j].groupNumber == parameter.group) {
                      if (
                        Object.prototype.hasOwnProperty.call(
                          parameter,
                          "customFixed"
                        )
                      ) {
                        const isFixed = parameter.customFixed;
                        valueGroupsCopy[j] = {
                          ...valueGroupsCopy[j],
                          fixed: !isFixed,
                        };
                        localGroups.current = valueGroupsCopy;
                        updateGroupNeeded.current = true;

                        //Setting value for sticky parameter window
                        fixToUpdate = !isFixed;

                        return { ...parameter, customFixed: !isFixed };
                      } else {
                        const isFixed = parameter.fixed;
                        valueGroupsCopy[j] = {
                          ...valueGroupsCopy[j],
                          fixed: !isFixed,
                        };
                        localGroups.current = valueGroupsCopy;
                        updateGroupNeeded.current = true;

                        //Setting value for sticky parameter window
                        fixToUpdate = !isFixed;

                        return { ...parameter, customFixed: !isFixed };
                      }
                    }
                  }
                } else {
                  updateGroupNeeded.current = false;
                  if (
                    Object.prototype.hasOwnProperty.call(
                      parameter,
                      "customFixed"
                    )
                  ) {
                    const isFixed = parameter.customFixed;

                    //Setting value for sticky parameter window
                    fixToUpdate = !isFixed;

                    return { ...parameter, customFixed: !isFixed };
                  } else {
                    const isFixed = parameter.fixed;

                    //Setting value for sticky parameter window
                    fixToUpdate = !isFixed;

                    return { ...parameter, customFixed: !isFixed };
                  }
                }
              } else {
                limitedToast(
                  "Fixing and un-fixing is only allowed on values that are not fixed by default."
                );
                generateWarningObject(
                  "Fixing and un-fixing is only allowed on values that are not fixed by default.",
                  0,
                  setWarnings,
                  setNewWarningCount
                );
                return parameter;
              }
            } else {
              return parameter;
            }
          });
          setRecParams(copyOfModel.recParams);
        } else {
          if (!rec) {
            correctParams = nonRecParams.map((obj) => Object.assign({}, obj));
            let newParams = correctParams.map((parameter) => {
              if (
                parameter.name === columnHead &&
                parameter.displaytable === tableIndex
              ) {
                if (!parameter.fixed) {
                  if (
                    Object.prototype.hasOwnProperty.call(parameter, "group")
                  ) {
                    const valueGroupsCopy = deepCopy(localGroups.current);
                    for (let j = 0; j < valueGroupsCopy.length; j++) {
                      if (valueGroupsCopy[j].groupNumber == parameter.group) {
                        if (
                          Object.prototype.hasOwnProperty.call(
                            parameter,
                            "customFixed"
                          )
                        ) {
                          const isFixed = parameter.customFixed;
                          valueGroupsCopy[j] = {
                            ...valueGroupsCopy[j],
                            fixed: !isFixed,
                          };
                          localGroups.current = valueGroupsCopy;
                          updateGroupNeeded.current = true;

                          //Setting value for sticky parameter window
                          fixToUpdate = !isFixed;

                          return { ...parameter, customFixed: !isFixed };
                        } else {
                          const isFixed = parameter.fixed;
                          valueGroupsCopy[j] = {
                            ...valueGroupsCopy[j],
                            fixed: !isFixed,
                          };
                          localGroups.current = valueGroupsCopy;
                          updateGroupNeeded.current = true;

                          //Setting value for sticky parameter window
                          fixToUpdate = !isFixed;

                          return { ...parameter, customFixed: !isFixed };
                        }
                      }
                    }
                  } else {
                    updateGroupNeeded.current = false;
                    if (
                      Object.prototype.hasOwnProperty.call(
                        parameter,
                        "customFixed"
                      )
                    ) {
                      const isFixed = parameter.customFixed;

                      //Setting value for sticky parameter window
                      fixToUpdate = !isFixed;

                      return { ...parameter, customFixed: !isFixed };
                    } else {
                      const isFixed = parameter.fixed;

                      //Setting value for sticky parameter window
                      fixToUpdate = !isFixed;

                      return { ...parameter, customFixed: !isFixed };
                    }
                  }
                } else {
                  limitedToast(
                    "Fixing and un-fixing is only allowed on values that are not fixed by default."
                  );
                  generateWarningObject(
                    "Fixing and un-fixing is only allowed on values that are not fixed by default.",
                    0,
                    setWarnings,
                    setNewWarningCount
                  );
                  return parameter;
                }
              } else {
                return parameter;
              }
            });

            const modelNonRecParams = model.modelParams.filter(
              (param) =>
                param.recuring == 0 &&
                (param.type == "int" || param.type == "float")
            );

            if (
              newParams.length > 0 &&
              modelNonRecParams.length > 0 &&
              !arraysOfObjectsAreEqual(modelNonRecParams, newParams)
            ) {
              for (let i = 0; i < copyOfModel.modelParams.length; i++) {
                const paramFromModel = copyOfModel.modelParams[i];
                if (newParams.some((param) => param.id == paramFromModel.id)) {
                  copyOfModel.modelParams[i] = newParams.find(
                    (param) => param.id == paramFromModel.id
                  );
                }
              }
            }

            setNonRecParams(newParams);
          }
        }

        if (updateGroupNeeded.current) {
          changeModel(copyOfModel, model.FE_ID, localGroups.current);
          updateGroupNeeded.current = false;
        } else {
          changeModel(copyOfModel, model.FE_ID, null);
        }

        //Here we are setting what value should be sent to sticky window for 'fixed' checkbox update
        if (fixToUpdate !== null) {
          //adding additional info to clarify which table cell was fixed/unfixed
          fixToUpdate = {
            value: fixToUpdate,
            cell: cell,
            rec: rec,
            lineshape_id: lineshape_id,
          };
          setFixedUpdate(fixToUpdate);
        }

        checkIfAllIsFixed(copyOfModel);
      }
    } catch (error) {
      recordedErrorLog("Fixed setter has failed: ", error);
    }
  }

  function getFontWeight(cell, rec, lineshape_id) {
    const columnHead = cell.column.id;
    const rowIndex = cell.row.index;

    let correctParams;

    try {
      if (columnHead != "Model") {
        if (lineshape_id !== -1) {
          let lineshape_index = model.lineshapeData.findIndex(
            (ls) => ls.lineshape_id === lineshape_id
          );
          correctParams = model.lineshapeData[lineshape_index].lineParams[
            rowIndex
          ].find((param) => param.name == columnHead);
        } else if (
          rec &&
          model.recParams != undefined &&
          model.recParams[rowIndex] != undefined
        ) {
          correctParams = model.recParams[rowIndex].find(
            (param) => param.name == columnHead
          );
        } else {
          correctParams = nonRecParams.find(
            (param) => param.name == columnHead
          );
        }
        return handleCorrectFontParams(correctParams);
      }
      return "500";
    } catch (e) {
      recordedErrorLog("ERROR IN FONT WEIGHT: ", e);
      limitedToast("Website error, report to support: " + e.message);
      generateWarningObject(
        "Website error, report to support: " + e.message,
        2,
        setWarnings,
        setNewWarningCount
      );
      return "500";
    }
  }

  function getHeaderTitle(column, lineshape_id) {
    try {
      if (column.Header != "Model") {
        let correctParam;
        if (lineshape_id !== -1) {
          let lineshape_index = model.lineshapeData.findIndex(  
            (ls) => ls.lineshape_id === lineshape_id
          );
          correctParam = model.lineshapeData[
            lineshape_index
          ].lineTemplate.params.find((param) => param.name == column.id);
        } else {
          correctParam = model.modelParams.find(
            (param) => param.name == column.id
          );
        }

        const titleToReturn = correctParam
          ? `${
              correctParam.min != null
                ? `${
                    correctParam.max != null
                      ? `Min Value: ${correctParam.min} | Max Value: ${correctParam.max}\n`
                      : `Min Value: ${correctParam.min}\n`
                  }`
                : `${
                    correctParam.max != null
                      ? `Max Value: ${correctParam.max}\n`
                      : ""
                  }`
            }`
          : "";

        const headerDiv = (
          <div className="tooltipContent">
            <div>{titleToReturn}</div>
            <div>
              {correctParam && correctParam.description != "" ? (
                <FormattedText input={correctParam.description} />
              ) : null}
            </div>
          </div>
        );

        if (
          titleToReturn !== "" ||
          (correctParam && correctParam.description != "")
        ) {
          return headerDiv;
        } else {
          return "";
        }
      }
      return "";
    } catch (error) {
      recordedErrorLog("Header title getter has failed: ", error);
      return "";
    }
  }

  const handleAddLine = (row, lineshape_id = -1) => {
    try {
      let lineshape_index = -1;
      let template = {};
      let copyOfModel = deepCopy(model);
      if (lineshape_id == -1) {
        template = copyOfModel.recTemplate;
      } else {
        // Find the lineshape in the model data by id instead of using the index
        lineshape_index = copyOfModel.lineshapeData.findIndex(
          (ls) => ls.lineshape_id === lineshape_id
        );
        if (lineshape_index === -1) {
          recordedErrorLog(`Lineshape with id ${lineshape_id} not found`);
          return;
        }
        const lineshape = copyOfModel.lineshapeData[lineshape_index];
        if (!lineshape) {
          recordedErrorLog(`Lineshape with id ${lineshape_id} not found`);
          return;
        }
        template = lineshape.lineTemplate;
      }

      if (template.params.some((param) => param.type == "Model")) {
        if (lineshape_id != -1) {
          recordedErrorLog("Submodel addition is not supported for lineshapes");
          return;
        }
        let indexToInsert = row.index + 1;
        const newModel = addSubModelFromParams(
          copyOfModel.FE_ID,
          copyOfModel.linked,
          indexToInsert
        );

        setRecRows(() => newModel.recTableRows);
        setRecParams(newModel.recParams);

        copyOfModel = {
          ...copyOfModel,
          recTableRows: newModel.recTableRows,
          recParams: newModel.recParams,
          subModels: newModel.subModels,
        };
        changeModel(copyOfModel, model.FE_ID, null);
      } else {
        let indexToInsert = row.index + 1;
        let rowObject = {};
        if (template.params.some((param) => param.defaultperline != null)) {
          for (let j = 0; j < template.params.length; j++) {
            const element = template.params[j];
            if (element.defaultperline != null) {
              rowObject = {
                ...rowObject,
                [element.name]:
                  element.default + element.defaultperline * indexToInsert,
              };
            } else {
              rowObject = { ...rowObject, [element.name]: element.default };
            }
          }
        } else {
          rowObject = { ...template.row };
        }
        let newRecRows = [];
        let newRecParams = [];
        if (lineshape_id == -1) {
          newRecRows = [...recRows];
          newRecParams = [...recParams];
        } else {
          newRecRows = [...lineshapeRows[lineshape_id]];
          newRecParams = [...lineshapeParams[lineshape_id]];
        }
        newRecRows.splice(indexToInsert, 0, rowObject);
        newRecParams.splice(indexToInsert, 0, template.params);

        if (lineshape_id == -1) {
          setRecRows(() => newRecRows);
          setRecParams(newRecParams);
          copyOfModel = {
            ...copyOfModel,
            recTableRows: newRecRows,
            recParams: newRecParams,
          };
        } else {
          setLineshapeRows((oldRows) => {
            const newRows = { ...oldRows };
            newRows[lineshape_id] = newRecRows;
            return newRows;
          });
          setLineshapeParams((oldParams) => {
            const newParams = { ...oldParams };
            newParams[lineshape_id] = newRecParams;
            return newParams;
          });
          let newLineshapeData = [...copyOfModel.lineshapeData];
          newLineshapeData[lineshape_index].lineTableRows = newRecRows;
          newLineshapeData[lineshape_index].lineParams = newRecParams;
          copyOfModel = {
            ...copyOfModel,
            lineshapeData: newLineshapeData, // Use the updated lineshapeData
          };
        }
        changeModel(copyOfModel, copyOfModel.FE_ID, null);
      }

      checkIfAllIsFixed(copyOfModel);
    } catch (error) {
      recordedErrorLog("AddLine handler has failed: ", error);
    }
  };

  const handleDuplicateLine = (row, lineshape_id = -1) => {
    try {
      let lineshape_index = -1;
      let template = {};
      let copyOfModel = deepCopy(model);
      if (lineshape_id == -1) {
        template = copyOfModel.recTemplate;
      } else {
        // Find the lineshape in the model data by id instead of using the index
        lineshape_index = copyOfModel.lineshapeData.findIndex(
          (ls) => ls.lineshape_id === lineshape_id
        );
        if (lineshape_index === -1) {
          recordedErrorLog(`Lineshape with id ${lineshape_id} not found`);
          return;
        }
        const lineshape = copyOfModel.lineshapeData[lineshape_index];
        if (!lineshape) {
          recordedErrorLog(`Lineshape with id ${lineshape_id} not found`);
          return;
        }
        template = lineshape.lineTemplate;
      }

      if (template.params.some((param) => param.type == "Model")) {
        if (lineshape_id != -1) {
          recordedErrorLog("Submodel addition is not supported for lineshapes");
          return;
        }
        // THIS NEEDS UPDATE IF WE START TO HAVE MORE THAN 2 SUB MODELS PER REC ROW!!!!!
        const idOfSubModel = copyOfModel.recParams[row.index].find((param) =>
          hasProperty(param, "FE_ID")
        ).FE_ID;

        const currentSubModel = copyOfModel.subModels.find(
          (subMod) => subMod.FE_ID === idOfSubModel
        );

        if (!currentSubModel.linked) {
          currentSubModel.linked = true;

          copyOfModel.subModels = copyOfModel.subModels.map((subMod) => {
            if (subMod.FE_ID === idOfSubModel) {
              return currentSubModel;
            }
            return subMod;
          });
        }

        let indexOfSubModel = null;

        for (let i = 0; i < copyOfModel.subModels.length; i++) {
          const element = copyOfModel.subModels[i];

          if (element.FE_ID === idOfSubModel) {
            indexOfSubModel = i;
            break;
          }
        }

        copyOfModel.subModels.splice(
          indexOfSubModel + 1,
          0,
          deepCopy(currentSubModel)
        );
      }
      const indexToCopyFrom = row.index;
      const indexToInsert = row.index + 1;
      let rowObject = {};
      if (lineshape_id == -1) {
        rowObject = deepCopy(copyOfModel.recTableRows[indexToCopyFrom]);
      } else {
        rowObject = deepCopy(
          copyOfModel.lineshapeData[lineshape_index].lineTableRows[
            indexToCopyFrom
          ]
        );
      }

      let paramRowToInsert = [];
      if (lineshape_id == -1) {
        paramRowToInsert = deepCopy(copyOfModel.recParams[indexToCopyFrom]);
      } else {
        paramRowToInsert = deepCopy(
          copyOfModel.lineshapeData[lineshape_index].lineParams[indexToCopyFrom]
        );
      }

      let groupsUpdated = false;
      let groupsCopy = deepCopy(valueGroups);

      for (let i = 0; i < paramRowToInsert.length; i++) {
        const parameter = paramRowToInsert[i];

        if (
          hasProperty(parameter, "group") &&
          typeof parameter.group === "number"
        ) {
          groupsUpdated = true;

          groupsCopy = groupsCopy.map((groupToUpdate) => {
            if (groupToUpdate.groupNumber === parameter.group) {
              return {
                ...groupToUpdate,
                memberCount: groupToUpdate.memberCount + 1,
              };
            }
            return groupToUpdate;
          });
        }
      }

      if (groupsUpdated) {
        setValueGroups(groupsCopy);
      }

      let newRecRows = [];
      let newRecParams = [];
      if (lineshape_id == -1) {
        newRecRows = [...recRows];
        newRecParams = [...recParams];
      } else {
        newRecRows = [...lineshapeRows[lineshape_id]];
        newRecParams = [...lineshapeParams[lineshape_id]];
      }
      newRecRows.splice(indexToInsert, 0, rowObject);
      newRecParams.splice(indexToInsert, 0, paramRowToInsert);

      if (lineshape_id == -1) {
        setRecRows(() => newRecRows);
        setRecParams(newRecParams);
        copyOfModel = {
          ...copyOfModel,
          recTableRows: newRecRows,
          recParams: newRecParams,
        };
      } else {
        setLineshapeRows((oldRows) => {
          const newRows = { ...oldRows };
          newRows[lineshape_id] = newRecRows;
          return newRows;
        });
        setLineshapeParams((oldParams) => {
          const newParams = { ...oldParams };
          newParams[lineshape_id] = newRecParams;
          return newParams;
        });
        let newLineshapeData = [...copyOfModel.lineshapeData];
        newLineshapeData[lineshape_index].lineTableRows = newRecRows;
        newLineshapeData[lineshape_index].lineParams = newRecParams;
        copyOfModel = {
          ...copyOfModel,
          lineshapeData: newLineshapeData,
        };
      }

      changeModel(copyOfModel, copyOfModel.FE_ID, null);

      checkIfAllIsFixed(copyOfModel);
    } catch (error) {
      recordedErrorLog("Line adder has failed: ", error);
    }
  };

  const canDelete = () => {
    try {
      if (model.subModels != undefined && model.subModels.length > 0) {
        return model.subModels.length > model.minSubModelCount;
      } else if (model.recTableRows != undefined) {
        return model.recTableRows.length > 1;
      } else {
        return false;
      }
    } catch (error) {
      recordedErrorLog("Delete allowance check has failed: ", error);
    }
  };

  const handleLineDelete = (row, lineshape_id = -1) => {
    try {
      const copyOfModel = deepCopy(model);
      let lineshape_index = -1;
      if (lineshape_id != -1) {
        lineshape_index = copyOfModel.lineshapeData.findIndex(
          (ls) => ls.lineshape_id === lineshape_id
        );
        if (lineshape_index === -1) {
          recordedErrorLog(`Lineshape with id ${lineshape_id} not found`);
          return;
        }
      }
      const indexToDelete = row.index;

      let rowBeingDeleted = [];
      if (lineshape_id == -1) {
        rowBeingDeleted = copyOfModel.recParams[indexToDelete];
      } else {
        rowBeingDeleted =
          copyOfModel.lineshapeData[lineshape_index].lineParams[indexToDelete];
      }

      let groupsUpdated = false;
      let groupsCopy = deepCopy(valueGroups);

      for (let i = 0; i < rowBeingDeleted.length; i++) {
        const parameter = rowBeingDeleted[i];

        if (
          hasProperty(parameter, "group") &&
          typeof parameter.group === "number"
        ) {
          groupsUpdated = true;

          groupsCopy = groupsCopy.map((groupToUpdate) => {
            if (groupToUpdate.groupNumber === parameter.group) {
              const newMemeberCount = groupToUpdate.memberCount - 1;

              if (newMemeberCount <= 0) {
                return {
                  ...groupToUpdate,
                  value: null,
                  hardMax: null,
                  hardMin: null,
                  memberCount: 0,
                  fixed: null,
                };
              } else {
                return {
                  ...groupToUpdate,
                  memberCount: newMemeberCount,
                };
              }
            }
            return groupToUpdate;
          });
        }
      }

      if (groupsUpdated) {
        setValueGroups(groupsCopy);
      }

      if (lineshape_id == -1) {
        copyOfModel.recTableRows.splice(indexToDelete, 1);
        copyOfModel.recParams.splice(indexToDelete, 1);
        if (
          copyOfModel.recTemplate.params.some((param) => param.type == "Model")
        ) {
          copyOfModel.subModels.splice(indexToDelete, 1);
        }

        setRecRows(() => copyOfModel.recTableRows);
        setRecParams(() => copyOfModel.recParams);
      } else {
        copyOfModel.lineshapeData[lineshape_index].lineTableRows.splice(
          indexToDelete,
          1
        );
        copyOfModel.lineshapeData[lineshape_index].lineParams.splice(
          indexToDelete,
          1
        );

        if (
          copyOfModel.lineshapeData[lineshape_index].lineTableRows.length == 0
        ) {
          copyOfModel.lineshapeData.splice(lineshape_index, 1);
        }
        if (copyOfModel.lineshapeData.length == 0) {
          delete copyOfModel.lineshapeData;
        }

        if (!copyOfModel.lineshapeData) {
          setLineshapeRows({});
          setLineshapeParams({});
        } else {
          if (
            copyOfModel.lineshapeData.length > lineshape_index &&
            copyOfModel.lineshapeData[lineshape_index].lineshape_id ===
              lineshape_id
          ) {
            setLineshapeRows((oldRows) => {
              const newRows = { ...oldRows };
              newRows[lineshape_id] =
                copyOfModel.lineshapeData[lineshape_index].lineTableRows;
              return newRows;
            });
            setLineshapeParams((oldParams) => {
              const newParams = { ...oldParams };
              newParams[lineshape_id] =
                copyOfModel.lineshapeData[lineshape_index].lineParams;
              return newParams;
            });
          } else {
            setLineshapeRows((oldRows) => {
              const newRows = { ...oldRows };
              delete newRows[lineshape_id];
              return newRows;
            });
            setLineshapeParams((oldParams) => {
              const newParams = { ...oldParams };
              delete newParams[lineshape_id];
              return newParams;
            });
          }
        }
      }
      changeModel(copyOfModel, copyOfModel.FE_ID, null);
    } catch (error) {
      recordedErrorLog("Line delete handler has failed: ", error);
    }
  };

  const handleFixAll = () => {
    try {
      const copyOfModel = deepCopy(model);

      let nonRecParamsCopy = nonRecParams.map((obj) => Object.assign({}, obj));

      for (let i = 0; i < nonRecParamsCopy.length; i++) {
        if (nonRecParamsCopy[i].recuring == 0 && !nonRecParamsCopy[i].fixed) {
          nonRecParamsCopy[i] = {
            ...nonRecParamsCopy[i],
            customFixed: true,
          };
        }
      }

      setNonRecParams(nonRecParamsCopy);
      const modelNonRecParams = model.modelParams.filter(
        (param) =>
          param.recuring == 0 && (param.type == "int" || param.type == "float")
      );

      if (
        nonRecParamsCopy.length > 0 &&
        modelNonRecParams.length > 0 &&
        !arraysOfObjectsAreEqual(modelNonRecParams, nonRecParamsCopy)
      ) {
        for (let i = 0; i < copyOfModel.modelParams.length; i++) {
          const paramFromModel = copyOfModel.modelParams[i];
          if (nonRecParamsCopy.some((param) => param.id == paramFromModel.id)) {
            copyOfModel.modelParams[i] = nonRecParamsCopy.find(
              (param) => param.id == paramFromModel.id
            );
          }
        }
      }

      if (
        Object.prototype.hasOwnProperty.call(copyOfModel, "recParams") &&
        copyOfModel.recParams.length > 0
      ) {
        for (let i = 0; i < copyOfModel.recParams.length; i++) {
          for (let j = 0; j < copyOfModel.recParams[i].length; j++) {
            if (
              copyOfModel.recParams[i][j].type != "Model" &&
              !copyOfModel.recParams[i][j].fixed
            ) {
              copyOfModel.recParams[i][j] = {
                ...copyOfModel.recParams[i][j],
                customFixed: true,
              };
            }
          }
        }
      }
      setRecParams(copyOfModel.recParams);

      if (
        Object.prototype.hasOwnProperty.call(copyOfModel, "lineshapeData") &&
        copyOfModel.lineshapeData.length > 0
      ) {
        let newLineshapeParams = deepCopy(lineshapeParams);
        for (let i = 0; i < copyOfModel.lineshapeData.length; i++) {
          let lineshape_id = copyOfModel.lineshapeData[i].lineshape_id;
          let copyOfLineshapeParams = newLineshapeParams[lineshape_id];
          for (let j = 0; j < copyOfLineshapeParams.length; j++) {
            for (let k = 0; k < copyOfLineshapeParams[j].length; k++) {
              if (!copyOfLineshapeParams[j][k].fixed) {
                copyOfLineshapeParams[j][k] = {
                  ...copyOfLineshapeParams[j][k],
                  customFixed: true,
                };
              }
            }
          }
          newLineshapeParams[lineshape_id] = copyOfLineshapeParams;
          copyOfModel.lineshapeData[i].lineParams = copyOfLineshapeParams;
        }
        setLineshapeParams(newLineshapeParams);
      }
      changeModel(copyOfModel, model.FE_ID, null);
      isAllFixed.current = true;
    } catch (error) {
      recordedErrorLog("Fix all handler failure: ", error);
    }
  };

  const handleUnfixAll = () => {
    try {
      const copyOfModel = deepCopy(model);

      let nonRecParamsCopy = nonRecParams.map((obj) => Object.assign({}, obj));

      for (let i = 0; i < nonRecParamsCopy.length; i++) {
        if (nonRecParamsCopy[i].recuring == 0 && !nonRecParamsCopy[i].fixed) {
          nonRecParamsCopy[i] = {
            ...nonRecParamsCopy[i],
            customFixed: false,
          };
        }
      }

      setNonRecParams(nonRecParamsCopy);

      const modelNonRecParams = model.modelParams.filter(
        (param) =>
          param.recuring == 0 && (param.type == "int" || param.type == "float")
      );

      if (
        nonRecParamsCopy.length > 0 &&
        modelNonRecParams.length > 0 &&
        !arraysOfObjectsAreEqual(modelNonRecParams, nonRecParamsCopy)
      ) {
        for (let i = 0; i < copyOfModel.modelParams.length; i++) {
          const paramFromModel = copyOfModel.modelParams[i];
          if (nonRecParamsCopy.some((param) => param.id == paramFromModel.id)) {
            copyOfModel.modelParams[i] = nonRecParamsCopy.find(
              (param) => param.id == paramFromModel.id
            );
          }
        }
      }

      if (
        Object.prototype.hasOwnProperty.call(copyOfModel, "recParams") &&
        copyOfModel.recParams.length > 0
      ) {
        for (let i = 0; i < copyOfModel.recParams.length; i++) {
          for (let j = 0; j < copyOfModel.recParams[i].length; j++) {
            if (
              copyOfModel.recParams[i][j].type != "Model" &&
              !copyOfModel.recParams[i][j].fixed
            ) {
              copyOfModel.recParams[i][j] = {
                ...copyOfModel.recParams[i][j],
                customFixed: false,
              };
            }
          }
        }
      }
      setRecParams(copyOfModel.recParams);
      if (
        Object.prototype.hasOwnProperty.call(copyOfModel, "lineshapeData") &&
        copyOfModel.lineshapeData.length > 0
      ) {
        let newLineshapeParams = deepCopy(lineshapeParams);
        for (let i = 0; i < copyOfModel.lineshapeData.length; i++) {
          let lineshape_id = copyOfModel.lineshapeData[i].lineshape_id;
          let copyOfLineshapeParams = newLineshapeParams[lineshape_id];
          for (let j = 0; j < copyOfLineshapeParams.length; j++) {
            for (let k = 0; k < copyOfLineshapeParams[j].length; k++) {
              if (!copyOfLineshapeParams[j][k].fixed) {
                copyOfLineshapeParams[j][k] = {
                  ...copyOfLineshapeParams[j][k],
                  customFixed: false,
                };
              }
            }
          }
          newLineshapeParams[lineshape_id] = copyOfLineshapeParams;
          copyOfModel.lineshapeData[i].lineParams = copyOfLineshapeParams;
        }
        setLineshapeParams(newLineshapeParams);
      }
      changeModel(copyOfModel, model.FE_ID, null);
      isAllFixed.current = false;
    } catch (error) {
      recordedErrorLog("Unfix all handler failure: ", error);
    }
  };

  const handleExport = () => {
    try {
      constructAndDownloadCSV(model);
    } catch (error) {
      recordedErrorLog("Export handler failure: ", error);
    }
  };

  const checkIfAllIsFixed = (modelToUse = null) => {
    try {
      let copyOfModel;
      if (modelToUse == null) {
        copyOfModel = deepCopy(model);
      } else {
        copyOfModel = deepCopy(modelToUse);
      }
      let allIsFixed = true;

      for (let i = 0; i < copyOfModel.modelParams.length; i++) {
        if (
          copyOfModel.modelParams[i].recuring == 0 &&
          !copyOfModel.modelParams[i].fixed
        ) {
          if (
            Object.prototype.hasOwnProperty.call(
              copyOfModel.modelParams[i],
              "customFixed"
            ) &&
            copyOfModel.modelParams[i].customFixed
          ) {
            continue;
          } else {
            allIsFixed = false;
            break;
          }
        }
      }

      if (
        allIsFixed &&
        Object.prototype.hasOwnProperty.call(copyOfModel, "recParams") &&
        copyOfModel.recParams.length > 0
      ) {
        for (let i = 0; i < copyOfModel.recParams.length; i++) {
          if (!allIsFixed) {
            break;
          }
          for (let j = 0; j < copyOfModel.recParams[i].length; j++) {
            if (
              copyOfModel.recParams[i][j].type != "Model" &&
              !copyOfModel.recParams[i][j].fixed
            ) {
              if (
                Object.prototype.hasOwnProperty.call(
                  copyOfModel.recParams[i][j],
                  "customFixed"
                ) &&
                copyOfModel.recParams[i][j].customFixed
              ) {
                continue;
              } else {
                allIsFixed = false;
                break;
              }
            }
          }
        }
      }
      if (
        allIsFixed &&
        Object.prototype.hasOwnProperty.call(copyOfModel, "lineshapeData") &&
        copyOfModel.lineshapeData.length > 0
      ) {
        for (let i = 0; i < copyOfModel.lineshapeData.length; i++) {
          for (
            let j = 0;
            j < copyOfModel.lineshapeData[i].lineParams.length;
            j++
          ) {
            for (
              let k = 0;
              k < copyOfModel.lineshapeData[i].lineParams[j].length;
              k++
            ) {
              if (!copyOfModel.lineshapeData[i].lineParams[j][k].fixed) {
                allIsFixed = false;
                break;
              }
            }
          }
        }
      }
      isAllFixed.current = allIsFixed;
    } catch (error) {
      recordedErrorLog("Is All Fixed checker failure: ", error);
    }
  };

  const handleNameClickLinking = () => {
    startLinking(model.FE_ID, model.speqqleID);
  };

  const handleAddLineshapeType = () => {
    // Position the modal next to the button that opened it
    if (lineshapeButtonRef.current) {
      const rect = lineshapeButtonRef.current.getBoundingClientRect();
      const modalWidth = 285; // Width of the modal
      const modalHeight = 350; // Height of the modal

      // Get parameter window dimensions
      const paramWindowEl =
        lineshapeButtonRef.current.closest(".parameterWindow");
      const paramWindowRect = paramWindowEl
        ? paramWindowEl.getBoundingClientRect()
        : null;

      // Position modal to the left of the button
      const leftPosition = rect.left - modalWidth - 5;

      // Align bottom of modal with bottom of parameter window
      let topPosition = paramWindowRect
        ? paramWindowRect.bottom - modalHeight
        : rect.top;

      // If that would place the modal above the top of the parameter window, adjust downward
      if (paramWindowRect && topPosition < paramWindowRect.top) {
        topPosition = paramWindowRect.top;
      }

      // If the modal would go off-screen to the left, position it to the right of the button instead
      const finalLeftPosition =
        leftPosition < 0 ? rect.right + 5 : leftPosition;

      setLineshapeModalPosition({
        top: `${topPosition}px`,
        left: `${finalLeftPosition}px`,
      });
    }
    setIsLineshapeModalOpen(true);
  };

  const handleCloseLineshapeModal = () => {
    setIsLineshapeModalOpen(false);
  };

  const handleLineshapeSelect = (shapeid) => {
    // Add the selected shape to the selectedLineshapes array if not already present
    if (
      !("lineshapeData" in model) ||
      !model.lineshapeData.some(
        (lineshape) => lineshape.lineshape_id === shapeid
      )
    ) {
      sendJsonMessage(
        {
          User: currentUser.id,
          Model: { GetShapeSpecs: [shapeid] },
        },
        { type: "get-shape-specs", requestedModelID: model.FE_ID }
      );
    }
    setIsLineshapeModalOpen(false);
  };

  // When our cell renderer calls updateMyData, we'll use
  // the rowIndex, columnId and new value to update the
  // original data
  const updateMyData = (
    rowIndex,
    columnId,
    value,
    rec,
    tableIndex,
    lineshape_id = -1
  ) => {
    try {
      // We also turn on the flag to not reset the page
      setSkipPageReset(true);
      if (rec) {
        if (lineshape_id === -1) {
          if (recRows[rowIndex][columnId] != value) {
            // Update recRows state
            setRecRows((old) =>
              old.map((row, index) => {
                if (index === rowIndex) {
                  return {
                    ...old[rowIndex],
                    [columnId]: value,
                  };
                }
                return row;
              })
            );

            // Update recParams state to keep it in sync
            setRecParams((prevRecParams) => {
              const newRecParams = deepCopy(prevRecParams);
              const correctParams = newRecParams[rowIndex];
              for (let i = 0; i < correctParams.length; i++) {
                if (correctParams[i].name == columnId) {
                  // Update the parameter value
                  correctParams[i].value = value;
                  break;
                }
              }
              return newRecParams;
            });

            const correctParams = recParams[rowIndex];
            for (let i = 0; i < correctParams.length; i++) {
              if (correctParams[i].name == columnId) {
                if (
                  Object.prototype.hasOwnProperty.call(
                    correctParams[i],
                    "group"
                  )
                ) {
                  const valueGroupsCopy = deepCopy(localGroups.current);
                  for (let j = 0; j < valueGroupsCopy.length; j++) {
                    if (
                      valueGroupsCopy[j].groupNumber == correctParams[i].group
                    ) {
                      valueGroupsCopy[j] = {
                        ...valueGroupsCopy[j],
                        value: value,
                      };
                      localGroups.current = valueGroupsCopy;
                      break;
                    }
                  }
                  updateGroupNeeded.current = true;
                } else {
                  updateGroupNeeded.current = false;
                }
                break;
              }
            }
          }
        } else {
          if (
            lineshapeRows[lineshape_id] &&
            lineshapeRows[lineshape_id][rowIndex] &&
            lineshapeRows[lineshape_id][rowIndex][columnId] !== value
          ) {
            // Update the lineshapeRows state
            setLineshapeRows((old) => {
              const newRows = { ...old };
              newRows[lineshape_id] = newRows[lineshape_id].map(
                (row, index) => {
                  if (index === rowIndex) {
                    return {
                      ...row,
                      [columnId]: value,
                    };
                  }
                  return row;
                }
              );
              return newRows;
            });

            // Update lineshapeParams state to keep it in sync
            setLineshapeParams((prevLineshapeParams) => {
              const newLineshapeParams = deepCopy(prevLineshapeParams);
              if (
                newLineshapeParams[lineshape_id] &&
                newLineshapeParams[lineshape_id][rowIndex]
              ) {
                const paramsList = newLineshapeParams[lineshape_id][rowIndex];
                for (let i = 0; i < paramsList.length; i++) {
                  if (paramsList[i].name === columnId) {
                    // Update the parameter value
                    paramsList[i].value = value;
                    break;
                  }
                }
              }
              return newLineshapeParams;
            });

            // Handle parameter groups if they exist
            if (
              lineshapeParams[lineshape_id] &&
              lineshapeParams[lineshape_id][rowIndex]
            ) {
              const correctParams = lineshapeParams[lineshape_id][rowIndex];
              for (let i = 0; i < correctParams.length; i++) {
                if (correctParams[i].name === columnId) {
                  if (
                    Object.prototype.hasOwnProperty.call(
                      correctParams[i],
                      "group"
                    )
                  ) {
                    const valueGroupsCopy = deepCopy(localGroups.current);
                    for (let j = 0; j < valueGroupsCopy.length; j++) {
                      if (
                        valueGroupsCopy[j].groupNumber ===
                        correctParams[i].group
                      ) {
                        valueGroupsCopy[j] = {
                          ...valueGroupsCopy[j],
                          value: value,
                        };
                        localGroups.current = valueGroupsCopy;
                        break;
                      }
                    }
                    updateGroupNeeded.current = true;
                  } else {
                    updateGroupNeeded.current = false;
                  }
                  break;
                }
              }
            }
          }
        }
      } else {
        if (nonRecRows[tableIndex][rowIndex][columnId] != value) {
          // Update nonRecRows state
          setNonRecRows((oldTable) => {
            return oldTable.map((table, index) => {
              if (index === tableIndex) {
                return table.map((row, index) => {
                  if (index === rowIndex) {
                    return {
                      ...row,
                      [columnId]: value,
                    };
                  }
                  return row;
                });
              } else {
                return table;
              }
            });
          });

          // Update nonRecParams state to keep it in sync
          setNonRecParams((prevNonRecParams) => {
            const newNonRecParams = deepCopy(prevNonRecParams);
            const tableParams = getNonRecParamsFromTableNum(
              newNonRecParams,
              tableIndex
            );

            if (tableParams && tableParams[rowIndex]) {
              // Find the parameter with matching name and update its value
              for (let i = 0; i < tableParams.length; i++) {
                if (i === rowIndex && tableParams[i].name === columnId) {
                  tableParams[i].value = value;
                  break;
                }
              }
            }

            return newNonRecParams;
          });

          const correctParams = getNonRecParamsFromTableNum(
            nonRecParams,
            tableIndex
          );

          if (
            Object.prototype.hasOwnProperty.call(
              correctParams[rowIndex],
              "group"
            )
          ) {
            const valueGroupsCopy = deepCopy(localGroups.current);
            for (let j = 0; j < valueGroupsCopy.length; j++) {
              if (
                valueGroupsCopy[j].groupNumber == correctParams[rowIndex].group
              ) {
                valueGroupsCopy[j] = {
                  ...valueGroupsCopy[j],
                  value: value,
                };
                localGroups.current = valueGroupsCopy;
                break;
              }
            }
            updateGroupNeeded.current = true;
          } else {
            updateGroupNeeded.current = false;
          }
        }
      }

      //This sets value to let sticky parameter window know, that the value was updated in the table itself
      setValueUpdate(value);
    } catch (error) {
      recordedErrorLog("Data updater has failed: ", error);
    }
  };

  const getCheckedValue = (
    rowIndex,
    columnId,
    value,
    rec,
    tableIndex,
    lineshape_id
  ) => {
    try {
      let params;
      if (rec && lineshape_id === -1) {
        params = recParams[rowIndex];
      } else if (lineshape_id !== -1) {
        params = lineshapeParams[lineshape_id][rowIndex];
      } else {
        params = getNonRecParamsFromTableNum(nonRecParams, tableIndex);
      }

      for (let i = 0; i < params.length; i++) {
        const param = params[i];
        if (param.name == columnId) {
          if (
            Object.prototype.hasOwnProperty.call(param, "hardMax") &&
            param.hardMax != "" &&
            value > param.hardMax
          ) {
            limitedToast("Wrong entered value.");
            generateWarningObject(
              `Entered value "${value}" is greater than hard maximum "${
                param.hardMax
              }" for model "${model.displayName}" at ${
                rec ? "recuring" : "non-recuring"
              } parameter table; Column ${columnId}; Row ${rowIndex}`,
              1,
              setWarnings,
              setNewWarningCount
            );
            return param.hardMax;
          }
          if (
            Object.prototype.hasOwnProperty.call(param, "hardMin") &&
            param.hardMin != "" &&
            value < param.hardMin
          ) {
            limitedToast("Wrong entered value.");
            generateWarningObject(
              `Entered value "${value}" is smalled than hard minimum "${
                param.hardMin
              }" for model "${model.displayName}" at ${
                rec ? "recuring" : "non-recuring"
              } parameter table; Column ${columnId}; Row ${rowIndex}`,
              1,
              setWarnings,
              setNewWarningCount
            );
            return param.hardMin;
          }
          return value;
        }
      }
    } catch (error) {
      recordedErrorLog("Checked value getter has failed: ", error);
    }
  };

  const handleTableCellClick = (cell, rec, tableIndex, lineshape_id) => {
    try {
      let isCheckBox = false;
      if (rec && lineshape_id === -1) {
        const foundParam = model.recTemplate.params.find(
          (param) => param.name === cell.column.id
        );
        if (foundParam !== undefined && foundParam.type === "Checkbox") {
          isCheckBox = true;
        }
      } else if (lineshape_id !== -1) {
        let lineshape_index = model.lineshapeData.findIndex(  
          (ls) => ls.lineshape_id === lineshape_id
        );
        const foundParam = model.lineshapeData[lineshape_index].lineTemplate.params.find(
          (param) => param.name === cell.column.id
        );
        if (foundParam !== undefined && foundParam.type === "Checkbox") {
          isCheckBox = true;
        }
      }

      openParameterWindow(
        cell,
        rec,
        modelIndex,
        model.FE_ID,
        tableIndex,
        isCheckBox,
        lineshape_id
      );
    } catch (error) {
      recordedErrorLog("Table cell click handler failed: ", error);
    }
  };

  const getColorForCell = (cell, rec, lineshape_id) => {
    try {
      const rowIndex = cell.row.index;
      const columnId = cell.column.id;

      if (lineshape_id !== -1) {
        let lineshape_index = model.lineshapeData.findIndex(
          (ls) => ls.lineshape_id === lineshape_id
        );
        if (
          model.lineshapeData[lineshape_index].lineParams != undefined &&
          model.lineshapeData[lineshape_index].lineParams[rowIndex] != undefined
        ) {
          let lineshapeParamsLocal =
            model.lineshapeData[lineshape_index].lineParams[rowIndex];
          for (let i = 0; i < lineshapeParamsLocal.length; i++) {
            const param = lineshapeParamsLocal[i];
            if (param.name == columnId) {
              if (Object.prototype.hasOwnProperty.call(param, "group")) {
                return getGroupColor(param.group);
              } else {
                return "";
              }
            }
          }
        } else {
          return "";
        }
      } else if (rec) {
        if (
          model.recParams != undefined &&
          model.recParams[rowIndex] != undefined
        ) {
          let recParamsLocal = model.recParams[rowIndex];
          for (let i = 0; i < recParamsLocal.length; i++) {
            const param = recParamsLocal[i];
            if (param.name == columnId) {
              if (Object.prototype.hasOwnProperty.call(param, "group")) {
                return getGroupColor(param.group);
              } else {
                return "";
              }
            }
          }
        } else {
          return "";
        }
      } else {
        for (let i = 0; i < nonRecParams.length; i++) {
          const param = nonRecParams[i];
          if (param.name == columnId) {
            if (Object.prototype.hasOwnProperty.call(param, "group")) {
              return getGroupColor(param.group);
            } else {
              return "";
            }
          }
        }
      }

      return "";
    } catch (error) {
      recordedErrorLog("Cell color getter has failed: ", error);
      return "";
    }
  };

  function handleSave() {
    try {
      const modelsForSaving = [];
      const simplifiedMainModel = createModelFitPayloadParams([model])[0];
      simplifiedMainModel.displayName = model.displayName;

      modelsForSaving.push(JSON.stringify(simplifiedMainModel));

      const pushSubModelToList = (modelToSave) => {
        const simplifiedModel = createModelFitPayloadParams([modelToSave])[0];

        modelsForSaving.push(JSON.stringify(simplifiedModel));

        if (modelToSave.subModels.length > 0) {
          for (let i = 0; i < modelToSave.subModels.length; i++) {
            const subModel = modelToSave.subModels[i];

            pushSubModelToList(subModel);
          }
        }
      };

      if (model.subModels.length > 0) {
        for (let i = 0; i < model.subModels.length; i++) {
          const subModel = model.subModels[i];

          pushSubModelToList(subModel);
        }
      }

      const unifiedString = modelsForSaving.join("###MODEL_SEPARATOR###");

      downloadStringTxtFile(
        unifiedString,
        `${model.displayName}_Parameters`,
        ".spqm"
      );
    } catch (error) {
      recordedErrorLog("Save handler has failed: ", error);
    }
  }

  function generateModelForParams(speqqleID) {
    const generatedModel = generateModel(
      speqqleID,
      getID,
      upID,
      getAbbrDic,
      setLocalAbbrDic,
      // allModels,
      allLocalModels,
      namingDic,
      setNamingDic,
      false,
      recordedErrorLog
    );

    return generatedModel;
  }

  function getModelFromFromFilesById(FE_ID, listOfParsedModels, speqqleId) {
    try {
      const generatedModel = generateModelForParams(speqqleId);
      const foundModel = listOfParsedModels.find(
        (parsedModel) => parsedModel.modelid === FE_ID
      );
      if (foundModel !== undefined) {
        foundModel.displayName = generatedModel.displayName;
        const updatedModel = createModelFromParamList(
          foundModel,
          generatedModel,
          (speqqleId, FE_ID) => {
            return getModelFromFromFilesById(
              FE_ID,
              listOfParsedModels,
              speqqleId
            );
          },
          sendJsonMessage,
          currentUser
        );

        return updatedModel;
      } else {
        limitedWarningToast("Couldn't find sub model.");
        generateWarningObject(
          "Could not find required sub model in loaded in file, using default one instead.",
          1,
          setWarnings,
          setNewWarningCount
        );

        return generatedModel;
      }
    } catch (error) {
      recordedErrorLog("Issue with getting loaded sub models: ", error);
    }
  }

  const processFiles = async (files) => {
    let jsonAndTxtFiles = [];
    let rfmFiles = [];

    try {
      // Separate the files into two lists: one for SPQM (Speqqle model format), and another for RFM
      jsonAndTxtFiles = files.filter((file) => file.name.endsWith(".spqm"));
      rfmFiles = files.filter(
        (file) => file.name.endsWith(".rfm") || file.name.endsWith(".RFM")
      );
    } catch (error) {
      recordedErrorLog("Error separating files: ", error);
    }

    try {
      if (jsonAndTxtFiles.length > 0) {
        const filesData = await Promise.all(
          jsonAndTxtFiles.map((file) => simpleReadFile(file))
        );

        const listOfModelsToParse = filesData[0].content.split(
          "###MODEL_SEPARATOR###"
        );
        const parsedModels = [];

        for (let i = 0; i < listOfModelsToParse.length; i++) {
          const modelToParse = listOfModelsToParse[i];
          parsedModels.push(JSON.parse(modelToParse));
        }

        // Remove VDF grid data from parsed models for backward compatibility
        for (let i = 0; i < parsedModels.length; i++) {
          if (hasProperty(parsedModels[i], "vdf")) {
            // Keep the VDF data but remove the grid data
            if (hasProperty(parsedModels[i].vdf, "gridData")) {
              delete parsedModels[i].vdf.gridData;
            }
          }
        }

        //We select the first file, since you can only open 1 file in model parameters:
        // const mainModel = JSON.parse(filesData[0].content);
        // We select the first model in the file because we are always putting main model first when saving.
        const mainModel = parsedModels[0];

        const copyOfModel = deepCopy(model);

        if (mainModel.modeltype === copyOfModel.speqqleID) {
          let groupsUpdate = deepCopy(localGroups.current);

          // REMOVING GROUP (MATCH) ASSIGNMENTS HERE, BECAUSE WE WILL BE OVERWRITING ALL THE PARAMETERS
          for (let i = 0; i < copyOfModel.modelParams.length; i++) {
            const param = copyOfModel.modelParams[i];
            if (hasProperty(param, "group")) {
              // groupsNeedUpdate = true;
              groupsUpdate = groupsUpdate.map((group) => {
                if (group.groupNumber === param.group) {
                  group.memberCount = group.memberCount - 1;
                  if (group.memberCount < 1) {
                    group.memberCount = 0;
                    group.value = null;
                    group.hardMax = null;
                    group.hardMin = null;
                  }
                  return group;
                } else {
                  return group;
                }
              });
              //deleting group from model parameters too, since we will be overwriting whole model with new parameters
              delete copyOfModel.modelParams[i].group;
            }
          }
          for (let i = 0; i < copyOfModel.recParams.length; i++) {
            const recParamRow = copyOfModel.recParams[i];
            for (let j = 0; j < recParamRow.length; j++) {
              const param = recParamRow[j];
              if (hasProperty(param, "group")) {
                // groupsNeedUpdate = true;
                groupsUpdate = groupsUpdate.map((group) => {
                  if (group.groupNumber === param.group) {
                    group.memberCount = group.memberCount - 1;
                    if (group.memberCount < 1) {
                      group.memberCount = 0;
                      group.value = null;
                      group.hardMax = null;
                      group.hardMin = null;
                    }
                    return group;
                  } else {
                    return group;
                  }
                });
                //deleting group from model parameters too, since we will be overwriting whole model with new parameters
                delete copyOfModel.recParams[i][j].group;
              }
            }
          }
          // GROUPS HAVE BEEN REMOVED FROM PARAMETERS and model, NOW WE START WRITING IN NEW DATA FROM FILE
          if (hasProperty(copyOfModel, "lineshapeData")) {
            for (let i = 0; i < copyOfModel.lineshapeData.length; i++) {
              const lineshapeData = copyOfModel.lineshapeData[i];
              for (let j = 0; j < lineshapeData.lineParams.length; j++) {
                const lineParamRow = lineshapeData.lineParams[j];
                for (let k = 0; k < lineParamRow.length; k++) {
                  const param = lineParamRow[k];

                  if (hasProperty(param, "group")) {
                    // groupsNeedUpdate = true;
                    groupsUpdate = groupsUpdate.map((group) => {
                      if (group.groupNumber === param.group) {
                        group.memberCount = group.memberCount - 1;
                        if (group.memberCount < 1) {
                          group.memberCount = 0;
                          group.value = null;
                          group.hardMax = null;
                          group.hardMin = null;
                        }
                        return group;
                      } else {
                        return group;
                      }
                    });
                    //deleting group from model parameters too, since we will be overwriting whole model with new parameters
                    delete copyOfModel.lineshapeData[i].lineParams[j][k].group;
                  }
                }
              }
            }
          }
          const updatedModel = createModelFromParamList(
            mainModel,
            copyOfModel,
            (speqqleId, FE_ID) => {
              return getModelFromFromFilesById(FE_ID, parsedModels, speqqleId);
            },
            sendJsonMessage,
            currentUser
            // generateModelForParams
          );

          // Copy VDF data from the loaded model to the updated model
          if (hasProperty(mainModel, "vdf")) {
            updatedModel.vdfData = mainModel.vdf;
          }

          updateModelFromFile(updatedModel, groupsUpdate);
        } else {
          limitedToast(
            "Uploaded model parameters don't match the current model."
          );
          generateWarningObject(
            "Uploaded model parameters don't match the current model.",
            2,
            setWarnings,
            setNewWarningCount
          );
        }
      }
    } catch (error) {
      recordedErrorLog("ERROR IN PROCESSING FILE: ", error);
      limitedToast("Selected file could not be processed.");
      generateWarningObject(
        "Selected file could not be processed.",
        2,
        setWarnings,
        setNewWarningCount
      );
    } finally {
      if (rfmFiles && rfmFiles.length > 0) {
        loadBinary(model.FE_ID, rfmFiles);
      }

      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    }
  };

  const updateModelFromFile = (modelFromFile, groupsUpdateInc) => {
    try {
      let hasGroups = false;

      for (let i = 0; i < modelFromFile.modelParams.length; i++) {
        const param = modelFromFile.modelParams[i];
        if (hasProperty(param, "group")) {
          hasGroups = true;
          break;
        }
      }

      if (!hasGroups && hasProperty(modelFromFile, "recParams")) {
        for (let i = 0; i < modelFromFile.recParams.length; i++) {
          const paramRow = modelFromFile.recParams[i];

          for (let j = 0; j < paramRow.length; j++) {
            const param = paramRow[j];
            if (hasProperty(param, "group")) {
              hasGroups = true;
              break;
            }
          }
          if (hasGroups) {
            break;
          }
        }
      }

      if (!hasGroups && hasProperty(modelFromFile, "lineshapeData")) {
        for (let i = 0; i < modelFromFile.lineshapeData.length; i++) {
          const lineshapeData = modelFromFile.lineshapeData[i];
          for (let j = 0; j < lineshapeData.lineParams.length; j++) {
            const lineParamRow = lineshapeData.lineParams[j];

            for (let k = 0; k < lineParamRow.length; k++) {
              const param = lineParamRow[k];
              if (hasProperty(param, "group")) {
                hasGroups = true;
                break;
              }
            }
            if (hasGroups) {
              break;
            }
          }
          if (hasGroups) {
            break;
          }
        }
      }

      if (hasGroups) {
        let clashingGroups = {};
        let groupsUpdate = deepCopy(groupsUpdateInc);

        // Now we check what groups are in the loaded model and if there are any clashes with current groups
        // First we check non recuring parameters
        const checkedNonRecParams = collectClashingGroupsFromNonRecParams(
          modelFromFile,
          groupsUpdate,
          groupsUpdate
        );
        clashingGroups = checkedNonRecParams.clashingGroups;
        groupsUpdate = checkedNonRecParams.groupsUpdate;

        // Now with double for we check for recuring parameters
        const checkedRecParams = collectClashingGroupsFromRecParams(
          modelFromFile,
          groupsUpdate,
          clashingGroups,
          groupsUpdate
        );

        clashingGroups = checkedRecParams.clashingGroups;
        groupsUpdate = checkedRecParams.groupsUpdate;

        const clashingGroupNumbers = Object.keys(clashingGroups);

        // We check here if there are any values loaded in that have clashing groups
        if (clashingGroupNumbers.length > 0) {
          // We found that there are clashing groups and we will have to initiate merging procedure
          setClashingModel(modelFromFile);
          setClashingGroups(clashingGroups);
          setUpdatedGroupsWithNoClash(groupsUpdate);
        } else {
          // We have no clashing groups here, so we can proceed with adding the model
          changeModel(modelFromFile, modelFromFile.FE_ID, groupsUpdate);
        }
      } else {
        changeModel(
          modelFromFile,
          modelFromFile.FE_ID,
          localGroups.current,
          true
        );
      }
    } catch (error) {
      recordedErrorLog("Model update from file failure: ", error);
    }
  };

  const handleCloseClashModal = () => {
    setClashModalIsOpen(false);
  };

  function rejectClash() {
    resetClashModal();
  }

  function solveClash(updatedValGroups, uploadedModel) {
    changeModel(uploadedModel, uploadedModel.FE_ID, updatedValGroups);

    resetClashModal();
  }

  function resetClashModal() {
    handleCloseClashModal();
    setClashingModel(null);
    setClashingGroups(null);
    setUpdatedGroupsWithNoClash(null);
  }

  const handleOpenClick = () => {
    // No need to have undo fit/autofit option if we open a new file for model params
    setUndoModels((old) =>
      old.filter((oldModel) => oldModel.FE_ID !== model.FE_ID)
    );

    fileInputRef.current.click();
  };
  // After data chagnes, we turn the flag back off
  // so that if data actually changes when we're not
  // editing it, the page is reset
  useEffect(() => {
    setSkipPageReset(false);
  }, [nonRecRows, recRows]);

  let nonRecCheckboxesToDisplay = nonRecCheckboxes.map((element, index) => {
    return <Checkbox key={element.id + "" + index} data={element} />;
  });

  function sortTable(headerName) {
    try {
      if (
        headerName !== "Model" &&
        typeof recRows[0][headerName] === "number"
      ) {
        const status = checkSortOrder(recRows, headerName);

        let updatedRows = deepCopy(recRows);
        switch (status) {
          case "unsorted":
            sortAscending(updatedRows, headerName);
            break;
          case "descending":
            sortAscending(updatedRows, headerName);
            break;
          case "ascending":
            sortDescending(updatedRows, headerName);
            break;
          default:
            break;
        }

        setRecRows(updatedRows);
      }
    } catch (error) {
      recordedErrorLog("Table sorting has failed: ", error);
    }
  }

  function generateSelectedVDFgrid(vdfData) {
    const generatedList = [];
    if (hasProperty(vdfData, "gridData")) {
      for (let i = 0; i < vdfData.gridData.dataids.length; i++) {
        const dataId = vdfData.gridData.dataids[i];
        const sourceEn = vdfData.gridData.source_every_n[i];
        const foundFile = uploadedFiles.find((file) => file.ID === dataId);

        // Only add the entry if the file still exists in uploadedFiles
        if (foundFile) {
          generatedList.push({
            file: foundFile,
            redFactor: sourceEn,
          });
        }
      }
    }
    if (hasProperty(vdfData, "gridlinear")) {
      for (let i = 0; i < vdfData.gridlinear.npoints.length; i++) {
        const points = vdfData.gridlinear.npoints[i];
        const min = vdfData.gridlinear.min[i];
        const max = vdfData.gridlinear.max[i];
        generatedList.push({
          linear: {
            npoints: points,
            min: min,
            max: max,
          },
        });
      }
    }
    return generatedList;
  }

  const handleVdfCreated = () => {
    setVdfFixAllOpen(true);
  };

  const handleVdfRemoved = () => {
    // Create a copy of the model
    let modelCopy = deepCopy(model);

    // Remove VDF data
    if (hasProperty(modelCopy, "vdfData")) {
      delete modelCopy.vdfData;
    }
    // Update model in parent state using same pattern as parameter updates
    changeModel(modelCopy, model.FE_ID);
  };

  const onVdfFixAllConfirm = () => {
    handleFixAll();
    setVdfFixAllOpen(false);
  };

  const handleModelExchange = (
    currentSubModelId,
    modelToExchangeWithId,
    isRec
  ) => {
    try {
      if (currentSubModelId === modelToExchangeWithId) {
        return;
      }

      let modelCopy = deepCopy(model);
      let allModelsCopy = deepCopy(modelData);
      const currentSubModel = getModelById(
        currentSubModelId,
        modelCopy.subModels
      );
      const modelToExchange = getModelById(
        modelToExchangeWithId,
        allModelsCopy
      );

      if (isRec) {
        modelCopy = updateRecParamsAndRowsForParams(
          modelCopy,
          currentSubModelId,
          modelToExchangeWithId,
          modelToExchange.displayName
        );
      } else {
        modelCopy.modelParams = modelCopy.modelParams.map((param) => {
          if (
            param.type === "Model" &&
            param.recuring === 0 &&
            param.FE_ID === currentSubModelId
          ) {
            return {
              ...param,
              FE_ID: modelToExchangeWithId,
            };
          }
          return param;
        });
      }

      if (!modelToExchange.linked) {
        modelToExchange.linked = true;

        allModelsCopy = replaceModelById(
          allModelsCopy,
          modelToExchange,
          modelToExchange.FE_ID
        );
      }

      modelCopy.subModels = replaceModelById(
        modelCopy.subModels,
        modelToExchange,
        currentSubModelId
      );

      allModelsCopy = replaceModelById(
        allModelsCopy,
        modelCopy,
        modelCopy.FE_ID
      );

      if (currentSubModel.linked) {
        allModelsCopy = updateLinksAfterChange(
          currentSubModel.FE_ID,
          allModelsCopy
        );
      } else {
        allModelsCopy.push(currentSubModel);
      }

      setModelData(allModelsCopy);
    } catch (error) {
      recordedErrorLog(
        "Error occured while trying to exchange sub model with another model.",
        error
      );
    }
  };

  function getID() {
    return currentID.current;
  }

  function upID() {
    currentID.current = currentID.current + 1;
    setModelNextID(currentID.current);
  }

  function getAbbrDic() {
    return localAbbrDic.current;
  }

  function setLocalAbbrDic(changedAbbrs) {
    localAbbrDic.current = changedAbbrs;
    setAbbrDic(localAbbrDic.current);
  }

  // const handleExchangeToNewModel = (currentSubModelId, speqqleId) => {
  //   try {
  //     const newModelToChange = generateModel(
  //       speqqleId,
  //       getID,
  //       upID,
  //       getAbbrDic,
  //       setLocalAbbrDic,
  //       allLocalModels,
  //       namingDic,
  //       setNamingDic,
  //       false,
  //       recordedErrorLog
  //     );

  //     let modelCopy = deepCopy(model);
  //     let allModelsCopy = deepCopy(modelData);

  //     modelCopy = updateRecParamsAndRowsForParams(
  //       modelCopy,
  //       currentSubModelId,
  //       newModelToChange.FE_ID,
  //       newModelToChange.displayName
  //     );

  //     const currentSubModel = getModelById(
  //       currentSubModelId,
  //       modelCopy.subModels
  //     );

  //     modelCopy.subModels = replaceModelById(
  //       modelCopy.subModels,
  //       newModelToChange,
  //       currentSubModelId
  //     );

  //     allModelsCopy = replaceModelById(
  //       allModelsCopy,
  //       modelCopy,
  //       modelCopy.FE_ID
  //     );

  //     allModelsCopy.push(currentSubModel);

  //     setModelData(allModelsCopy);
  //   } catch (error) {
  //     recordedErrorLog(
  //       "There was an error exchanging sub model to new model.",
  //       error
  //     );
  //   }
  // };

  return (
    <div
      className="parameterWindow"
      onClick={() => handleWindowClick()}
      style={windowExtraStyle}
      ref={autoFitRef}
      id="model-parameter-window"
    >
      <div
        className={`modelNameAndButtons${isExpanded ? "" : " mini"}`}
        style={{ minWidth: `${modelHeaderMinWidth}px` }}
        id="name-and-buttons-header"
      >
        <div
          className="modelName"
          id="parameter-window-name"
          onClick={() => handleNameClickLinking()}
        >
          {model.displayName}
        </div>
        {isExpanded ? (
          <div>
            <div className="buttonsContainer" id="dropdown-buttons-container">
              <MenuIcon className="settingsIcon" />
              <div className="buttons">
                <div className="iconsRow">
                  <div
                    className="button withIcon"
                    onClick={() => handleSave()}
                    title="Save"
                  >
                    <SaveIcon className="icon" />
                  </div>
                  <div
                    className="button withIcon"
                    onClick={() => handleOpenClick()}
                    title="Load"
                  >
                    <FileOpenIcon className="icon" />
                  </div>
                </div>
                <input
                  ref={fileInputRef}
                  type="file"
                  accept=".spqm,.rfm"
                  onChange={(e) =>
                    handleFileChange(e, ["spqm", "rfm", "RFM"], processFiles)
                  }
                  style={{ display: "none" }}
                />
                {model.vdf ? (
                  <div
                    className="button"
                    ref={vdfButton}
                    onClick={() => setIsVDFOpen(true)}
                  >
                    VDF
                  </div>
                ) : (
                  <></>
                )}
                {model.autoFit ? (
                  <div
                    className="button"
                    onClick={() => handleAutoFit()}
                    id="autofit-button"
                  >
                    AutoFit
                  </div>
                ) : (
                  <></>
                )}
                {undoAvailable ? (
                  <div className="button" onClick={() => handleUndo()}>
                    Undo Fit
                  </div>
                ) : (
                  <></>
                )}
                {isAllFixed.current ? (
                  <div className="button" onClick={() => handleUnfixAll()}>
                    Unfix All
                  </div>
                ) : (
                  <div className="button" onClick={() => handleFixAll()}>
                    Fix All
                  </div>
                )}
                <div className="button" onClick={() => handleExport()}>
                  Export
                </div>
                <AutoFitModal
                  model={model}
                  changeModel={changeModel}
                  reference={autoFitRef}
                  autofitModalIsOpen={autofitModalIsOpen}
                  setAutofitModalIsOpen={setAutofitModalIsOpen}
                />
                {model.vdf ? (
                  <ModalVDF
                    reference={vdfButton}
                    model={model}
                    modalOpen={isVDFOpen}
                    setModalOpen={setIsVDFOpen}
                    hadVDFbefore={hasProperty(model, "vdfData")}
                    preSelectedFiles={
                      hasProperty(model, "vdfData")
                        ? generateSelectedVDFgrid(model.vdfData)
                        : []
                    }
                    preselectedKK={
                      !hasProperty(model, "vdfData") ? true : model.vdfData.kk
                    }
                    preselectedON={
                      !hasProperty(model, "vdfData") ? true : model.vdfData.on
                    }
                    preselectedFitActive={
                      !hasProperty(model, "vdfData")
                        ? true
                        : model.vdfData.fitactive
                    }
                    onVdfCreate={handleVdfCreated}
                    onVdfRemove={handleVdfRemoved}
                  />
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
        {isExpanded ? (
          <CloseFullscreenIcon
            className="sizeIcon"
            onClick={() => {
              setIsExpanded(false);
              changeExpansion(model.FE_ID, false);
            }}
          />
        ) : (
          <OpenInFullIcon
            className="sizeIcon"
            onClick={() => {
              setIsExpanded(true);
              changeExpansion(model.FE_ID, true);
            }}
          />
        )}
        <div className="placeholder" />
        <ConfirmationScreen
          onYes={onVdfFixAllConfirm}
          onNo={() => setVdfFixAllOpen(false)}
          text={"Do you want to fix all the parameters after applying VDF?"}
          open={vdfFixAllOpen}
          setOpen={setVdfFixAllOpen}
          element={autoFitRef}
        />
      </div>
      {isExpanded ? (
        <>
          {/* <hr className="divider" /> */}
          <div className="tablesArea">
            <div className="nonRecuringParams">
              <div className="nonRecParamsTables">
                {nonRecColumns.length > 0 && nonRecRows.length > 0 ? (
                  nonRecColumns.map((columns, index) => {
                    const tableTitle =
                      model.tableTitles && index < model.tableTitles.length
                        ? model.tableTitles[index]
                        : "";
                    return (
                      <ParameterTable
                        key={index}
                        title={tableTitle}
                        tableIndex={index}
                        columns={columns}
                        data={nonRecRows[index]}
                        updateMyData={updateMyData}
                        skipPageReset={skipPageReset}
                        getCellColor={getCellColor}
                        getFontWeight={getFontWeight}
                        setFixed={setFixed}
                        getHeaderTitle={getHeaderTitle}
                        handleTableCellClick={handleTableCellClick}
                        getCheckedValue={getCheckedValue}
                        getColorForCell={getColorForCell}
                        model={model}
                        handleModelExchange={handleModelExchange}
                        // handleExchangeToNewModel={handleExchangeToNewModel}
                      />
                    );
                  })
                ) : (
                  <></>
                )}
              </div>
              <div className="checkboxAndDropdown">
                {nonRecCheckboxesToDisplay}
              </div>
            </div>
            {Object.keys(recTemplate).length > 0 ? (
              <div className="recuringParams" id="recuring-parameter-area">
                <div
                  className="paramsTable"
                  id="recuring-parameter-table-container"
                >
                  <ParameterTable
                    title={getRecurringTableTitle()}
                    columns={recColumns}
                    data={recRows}
                    updateMyData={updateMyData}
                    skipPageReset={skipPageReset}
                    getCellColor={getCellColor}
                    getFontWeight={getFontWeight}
                    setFixed={setFixed}
                    getHeaderTitle={getHeaderTitle}
                    rec={true}
                    handleAddLine={handleAddLine}
                    handleDuplicateLine={handleDuplicateLine}
                    handleLineDelete={handleLineDelete}
                    canDelete={canDelete}
                    handleTableCellClick={handleTableCellClick}
                    getCheckedValue={getCheckedValue}
                    getColorForCell={getColorForCell}
                    sortTable={sortTable}
                    model={model}
                    handleModelExchange={handleModelExchange}
                    // handleExchangeToNewModel={handleExchangeToNewModel}
                  />
                </div>
              </div>
            ) : (
              <></>
            )}
            {hasLineshapeSection ? (
              <div className="lineshapeSection">
                {model.lineshapeData && model.lineshapeData.length > 0 ? (
                  <div className="selectedLineshapes">
                    {model.lineshapeData.map((lineshape, index) => (
                      <div key={index} className="selectedLineshape">
                        {(hasProperty(lineshape, "lineTemplate") && Object.keys(lineshape.lineTemplate).length > 0) ? (
                          <div className="lineshapeParams">
                            <div className="paramsTable">
                              <ParameterTable
                                title={getLineshapeTableTitle(
                                  lineshape.lineshape_id
                                )}
                                columns={getlineshapeCulumnsRows(
                                  lineshape.lineTemplate.params
                                )}
                                data={lineshape.lineTableRows}
                                updateMyData={updateMyData}
                                skipPageReset={skipPageReset}
                                getCellColor={getCellColor}
                                getFontWeight={getFontWeight}
                                setFixed={setFixed}
                                getHeaderTitle={getHeaderTitle}
                                rec={true}
                                lineshape_id={lineshape.lineshape_id}
                                handleAddLine={handleAddLine}
                                handleDuplicateLine={handleDuplicateLine}
                                handleLineDelete={handleLineDelete}
                                canDelete={canDelete}
                                handleTableCellClick={handleTableCellClick}
                                getCheckedValue={getCheckedValue}
                                getColorForCell={getColorForCell}
                                sortTable={sortTable}
                                model={model}
                                handleModelExchange={handleModelExchange}
                                // handleExchangeToNewModel={handleExchangeToNewModel}
                              />
                            </div>
                          </div>
                        ) : (
                          <div className="noLineshapeParams">
                            Generating parameter table...
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                ) : (
                  <></>
                )}
                <div className="lineshapeButtonContainer">
                  <CustomButton
                    text="Add lineshape type"
                    extraClassnames={["lineshapeButton"]}
                    handleClick={handleAddLineshapeType}
                    id="add-lineshape-type-button"
                    reference={lineshapeButtonRef}
                  />
                </div>
              </div>
            ) : (
              <></>
            )}
            {hasVDFSection ? (
              <div className="vdfCheckboxes">
                <div className="title">VDF settings:</div>
                <FormGroup
                  sx={{
                    width: "100%",
                  }}
                  className="checkboxContainer"
                >
                  <FormControlLabel
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        marginLeft: "-5px",
                      },
                    }}
                    control={
                      <MuiCheckbox
                        id="onCheckbox-KK"
                        checked={isKKLocal}
                        onChange={() => {
                          setIsKKLocal((old) => !old);
                        }}
                        sx={{
                          transform: "scale(0.8)",
                        }}
                      />
                    }
                    label={
                      <HtmlTooltip
                        title={
                          <React.Fragment>
                            <div className="customTooltip">
                              Ensures the model with VDF satisfies the
                              Kramers-Kronig relations.
                            </div>
                          </React.Fragment>
                        }
                      >
                        <span>KK</span>
                      </HtmlTooltip>
                    }
                  />
                  <FormControlLabel
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        marginLeft: "-5px",
                      },
                    }}
                    control={
                      <MuiCheckbox
                        checked={isOnLocal}
                        id="onCheckbox-On"
                        onChange={() => {
                          setIsOnLocal((old) => !old);
                        }}
                        sx={{
                          transform: "scale(0.8)",
                        }}
                      />
                    }
                    label={
                      <HtmlTooltip
                        title={
                          <React.Fragment>
                            <div className="customTooltip">
                              Enables/disables the VDF contribution to the model
                              outputs.
                            </div>
                          </React.Fragment>
                        }
                      >
                        <span>ON</span>
                      </HtmlTooltip>
                    }
                  />
                  <FormControlLabel
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        marginLeft: "-5px",
                      },
                    }}
                    control={
                      <MuiCheckbox
                        id="fitActiveCheckbox"
                        checked={!isFitActiveLocal}
                        onChange={() => {
                          setIsFitActiveLocal((old) => !old);
                        }}
                        sx={{
                          transform: "scale(0.8)",
                        }}
                      />
                    }
                    label={
                      <HtmlTooltip
                        title={
                          <React.Fragment>
                            <div className="customTooltip">
                              Fixes all the VDF parameters in the fitting
                              process.
                            </div>
                          </React.Fragment>
                        }
                      >
                        <span>Fixed</span>
                      </HtmlTooltip>
                    }
                  />
                </FormGroup>
              </div>
            ) : (
              <></>
            )}
            {isLinkActive() && !canLink(model.FE_ID, model.speqqleID) ? (
              <div className="overlay" />
            ) : (
              <></>
            )}
            <Modal
              isOpen={clashModalIsOpen}
              onRequestClose={handleCloseClashModal}
              shouldCloseOnOverlayClick={false}
              contentLabel="Clashing Modal"
              appElement={autoFitRef.current}
              id="clashModal"
              style={{
                content: {
                  width: "80vw",
                  height: "60vh",
                  top: `20%`,
                  left: "10%",
                  right: "auto",
                },
                overlay: {
                  zIndex: "8000",
                },
              }}
            >
              <GroupClashWindow
                clashingGroups={clashingGroups}
                clashingModel={clashingModel}
                valueGroups={upadatedGroupsWithNoClash}
                solveClash={solveClash}
                rejectClash={rejectClash}
              />
            </Modal>

            <Modal
              isOpen={isLineshapeModalOpen}
              onRequestClose={handleCloseLineshapeModal}
              shouldCloseOnOverlayClick={true}
              contentLabel="Lineshape Type Selection"
              appElement={lineshapeButtonRef.current}
              id="lineshapeModal"
              style={{
                content: {
                  width: "285px",
                  height: "350px",
                  position: "absolute",
                  top: lineshapeModalPosition.top,
                  left: lineshapeModalPosition.left,
                  transform: "none",
                  backgroundColor: "white",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  padding: "10px",
                },
                overlay: {
                  backgroundColor: "transparent",
                  zIndex: "8000",
                },
              }}
            >
              <div className="modalList">
                {(() => {
                  if (
                    allLocalModels &&
                    allLocalModels.length > 0 &&
                    model.speqqleID !== undefined
                  ) {
                    const matchingModel = allLocalModels.find(
                      (m) => m.speqqle_id === model.speqqleID
                    );

                    if (
                      matchingModel &&
                      matchingModel.shapes &&
                      matchingModel.shapes.length > 0
                    ) {
                      return matchingModel.shapes.map((shape, index) => (
                        <div key={index} className="modalListEntry">
                          <div
                            className="modalListEntryName"
                            onClick={() =>
                              handleLineshapeSelect(shape.speqqle_id)
                            }
                          >
                            {shape.name}
                          </div>
                        </div>
                      ));
                    } else {
                      return (
                        <div className="noShapesMessage">
                          No lineshape types available for this model
                        </div>
                      );
                    }
                  } else {
                    return (
                      <div className="noShapesMessage">
                        No lineshape types available for this model
                      </div>
                    );
                  }
                })()}
              </div>
            </Modal>
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
}

export default ParameterWindow;
