import React from "react";
import ManualContent from "../../components/ManualContent";
import image1 from "../../images/Loop.jpg";

const Overview = () => {
  return (
    <ManualContent title="Operations in a Loop">
      <p>
        <strong>The loop tab is still under development,</strong> but for your
        convenience you do already have access to this function. Please feel
        free to test it out and send us your remarks!
      </p>

      <p>
        With the loop function you can do repeat fitting of many datasets.
        First, you need to load in all the datasets you would like to add to the
        loop. Add the Experiment, and add the first dataset and the Experimental
        output to a graph. Adapt the parameters if the Experiment models and add
        a Chi square (see fit/AddchiSquare.html). Run the fit of the first
        dataset until you are happy with the result. Now you can open the Loop
        tab.
      </p>

      <div className="image-container wide-image">
        <img src={image1} alt="Adding Model Outputs to a Graph" width="800" />
      </div>

      <p>In the loop tab you work from left to right:</p>
      <p>
        Click &quot;Load Files&quot; to select the files you want to fit in a loop. To
        select multiple files, hold the CRTL (Or SHIFT) key. Note that the
        original file, indicated above, is always the first file in the loop.
      </p>

      <p>Your files will be fitted</p>
    </ManualContent>
  );
};

export default Overview;
