import React from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import "./commonComponents.scss";

function CloseButton(props) {
  const { onClose, customId = null } = props;

  return (
    <div className="closeButton" onClick={() => onClose()} id={customId}>
      <HighlightOffIcon className="closeIcon" />
    </div>
  );
}

export default CloseButton;
