import React from "react";
import ManualContent from "../../components/ManualContent";

const Constraints = () => {
  return (
    <ManualContent title="Constraining and Grouping Parameters">
      <p>
        Constraining and grouping parameters are important tools when fitting
        models in Speqqle. Constraints define <strong>hard limits</strong> that
        a parameter cannot exceed during a fit, while grouping allows multiple
        parameters to share the same value. Both tools help guide the
        optimization process but must be used carefully to avoid unintended
        fitting issues.
      </p>

      <h2>Understanding Hard and Soft Limits</h2>

      <p>
        It is important to distinguish between <strong>Hard Limits</strong> and{" "}
        <strong>Soft Limits</strong>:
      </p>

      <ul>
        <li>
          <strong>Hard Limits:</strong> These are absolute constraints—if a
          parameter reaches its Hard Min or Hard Max, it cannot change beyond
          that value.
        </li>
        <li>
          <strong>Soft Limits:</strong> These do not restrict the parameter but
          are instead used for <a href="paramcolors">color-coded warnings</a> to
          alert the user when a parameter reaches an unusual value.
        </li>
      </ul>

      <h2>Why Use Parameter Constraints?</h2>

      <p>
        Setting constraints helps guide the fitting process and prevents
        unrealistic results. Consider the following situations:
      </p>

      <ul>
        <li>
          <strong>Physical Limits:</strong> Some parameters have well-defined
          physical boundaries. For example, layer thickness cannot be negative,
          and refractive indices must be positive.
        </li>
        <li>
          <strong>Preventing Unstable Fits:</strong> If a parameter is free to
          move too far, it can cause instability or lead to non-physical
          solutions.
        </li>
        <li>
          <strong>Speeding Up Convergence:</strong> Limiting a parameter&apos;s
          range can help the algorithm find an optimal solution faster by
          reducing unnecessary exploration.
        </li>
        <li>
          <strong>Fitting Prior Knowledge:</strong> If you have prior knowledge
          from literature or previous measurements, you can restrict parameters
          to realistic values.
        </li>
      </ul>

      <h2>How to Set Parameter Constraints</h2>

      <p>
        Parameter constraints are applied using the{" "}
        <strong>Parameter Sticky Window</strong>. To set constraints for a
        parameter:
      </p>

      <ol>
        <li>
          Click on a parameter in the <strong>Experiments</strong> tab to open
          the Sticky Window.
        </li>
        <li>
          Locate the <strong>Hard Min</strong> and <strong>Hard Max</strong>{" "}
          constraint fields.
        </li>
        <li>Enter the lower and/or upper limits for the parameter.</li>
        <li>
          Confirm your changes by clicking outside the window or pressing{" "}
          <strong>Enter</strong>.
        </li>
      </ol>

      <p>
        <strong>Note:</strong> Once a constraint is set, the parameter{" "}
        <strong>cannot exceed</strong> these values during a fit.
      </p>
      <p>
        <strong>Tip:</strong> Putting constraints on parameters can slow down
        the fitting algorithm. Try only using constraints when necessary for the
        stability of the fit.
      </p>

      <h2>Grouping Parameters</h2>

      <p>
        Grouping parameters allows multiple parameters to be linked, ensuring
        they share the same value throughout the fit. This is useful when you
        know certain parameters should be identical across different models or
        components.
      </p>

      <h3>Why Use Grouping?</h3>

      <ul>
        <li>
          <strong>Ensures consistency:</strong> Forces physically equivalent
          parameters to remain the same across different models.
        </li>
        <li>
          <strong>Reduces the number of free parameters:</strong> Helps simplify
          the fitting process by reducing unnecessary degrees of freedom.
        </li>
        <li>
          <strong>Speeds up convergence:</strong> Reducing parameter complexity
          allows the optimizer to find a solution faster.
        </li>
      </ul>

      <h3>How to Group Parameters</h3>

      <p>
        For the moment you can group parameters in 10 different subgroups. If
        you need more, let us know!
      </p>
      <p>
        To group parameters, use the <strong>Parameter Sticky Window</strong>:
      </p>

      <ol>
        <li>
          Click on a parameter in the <strong>Experiments</strong> tab to open
          the Sticky Window.
        </li>
        <li>
          Locate the <strong>Group</strong> dropdown menu.
        </li>
        <li>Select a group.</li>
        <li>
          Grouped parameters will be assigned a{" "}
          <strong>distinct background color</strong>
          corresponding to their group.
        </li>
      </ol>

      <p>
        To ungroup parameters, click &quot;clear&quot; in the sticky window.
      </p>

      <p>
        <strong>Note:</strong> All parameters in a group will remain identical
        during the fit, but they are not fixed and have no hard limits unless
        this is seperately specified.
      </p>

      <h2>Effects of Grouping Parameters</h2>

      <p>
        While grouping parameters can be beneficial, it is important to consider
        its effects on the fit.
      </p>

      <h3>✅ Benefits of Grouping Parameters</h3>

      <ul>
        <li>
          <strong>Maintains physical consistency:</strong> Useful when
          parameters represent the same physical property in different models.
        </li>
        <li>
          <strong>Reduces overfitting:</strong> Ensures that the optimizer does
          not treat identical parameters as independent.
        </li>
        <li>
          <strong>Improves fit efficiency:</strong> Reducing the number of free
          parameters can speed up convergence.
        </li>
      </ul>

      <h3>⚠️ Risks and Limitations</h3>

      <ul>
        <li>
          <strong>Over-constraining the model:</strong> Grouping parameters
          incorrectly can prevent the fit from adapting to real variations in
          the data.
        </li>
        <li>
          <strong>Potential for bias:</strong> If parameters should be
          independent but are grouped, the model may not capture important
          variations.
        </li>
      </ul>

      <h2>Best Practices for Setting Constraints and Grouping Parameters</h2>

      <p>To effectively manage constraints and grouped parameters:</p>

      <ul>
        <li>
          <strong>Use constraints and grouping selectively:</strong> Only apply
          them when physically justified.
        </li>
        <li>
          <strong>Monitor boundary effects:</strong> If a parameter is stuck at
          a limit, consider relaxing the constraint.
        </li>
        <li>
          <strong>Check the color-coded grouping:</strong> Ensure that only
          relevant parameters are assigned to the same group.
        </li>
        <li>
          <strong>Test grouped and ungrouped fits:</strong> Compare results to
          ensure grouping does not introduce unwanted biases.
        </li>
      </ul>

      <h2>Next Steps</h2>
      <p>
        Now that you understand constraints and grouping, you may want to
        explore:
      </p>

      <ul>
        <li>
          <a href="fixing">Fixing and Freeing Parameters</a>: Learn how to lock
          parameters to fixed values or allow them to vary.
        </li>
        <li>
          <a href="paramcolors">Understanding Parameter Color Codes</a>: Learn
          how color-coded warnings help identify potential parameter issues.
        </li>
      </ul>
    </ManualContent>
  );
};

export default Constraints;
