import React from "react";
import ManualContent from "../../components/ManualContent";
//import profileImage from "../../images/profile.jpg";
//import packagesImage from "../../images/packages.jpg";
//import dashboardImage from "../../images/dashboard.jpg";

const SystemRequirements = () => {
  return (
    <ManualContent title="Speqqle System Requirements">
      <h2>Browser Compatibility</h2>
      <p>
        Speqqle is a web-based application that has been tested on recent
        versions of the following browsers.
      </p>
      <ul>
        <li>Google Chrome</li>
        <li>Mozilla Firefox</li>
        <li>Microsoft Edge</li>
        <li>Safari (MacOS and iOS)</li>
        <li>Opera</li>
      </ul>
      <p>
        If you are using an older browser, some features may not work correctly.
        We recommend updating your browser to the latest version for the best
        experience.
      </p>

      <h2>Internet Connection</h2>
      <p>
        Speqqle is an online application and requires a stable internet
        connection to function properly. We recommend using a broadband or
        fiber-optic connection for the best experience. If you experience slow
        loading times or unexpected errors, check your internet connection and
        try the following.
      </p>
      <ul>
        <li>
          Ensure that you are connected to a stable Wi-Fi or wired network.
        </li>
        <li>Restart your router if you are experiencing connection issues.</li>
        <li>
          Avoid using a VPN or proxy that might block certain web features.
        </li>
        <li>
          Try loading a different website to verify your internet connection.
        </li>
      </ul>

      <h2>Recommended Font Size</h2>
      <p>
        For an optimal viewing experience, we recommend setting the browser font
        size to
        <code>16 pixels</code>, which is the default. If you prefer larger text,
        instead of increasing the font size, we suggest zooming in and out using
        the following shortcuts:
      </p>
      <ul>
        <li>
          Windows and Linux: <kbd>Ctrl</kbd> + <kbd>Mouse Scroll</kbd> or{" "}
          <kbd>Ctrl</kbd> + <kbd>+</kbd> / <kbd>-</kbd>
        </li>
        <li>
          MacOS: <kbd>Cmd</kbd> + <kbd>Mouse Scroll</kbd> or <kbd>Cmd</kbd> +{" "}
          <kbd>+</kbd> / <kbd>-</kbd>
        </li>
      </ul>

      <h2>WebGL Requirement</h2>
      <p>
        Speqqle requires WebGL to function correctly. All modern browsers
        support WebGL by default. However, if you experience any rendering
        issues, check if WebGL is enabled.
      </p>

      <h3>How to Enable WebGL</h3>
      <ul>
        <li>
          <strong>Google Chrome:</strong> Open a new tab and enter{" "}
          <code>chrome://flags</code> in the address bar. Search for{" "}
          <code>WebGL</code> and ensure it is enabled.
        </li>
        <li>
          <strong>Mozilla Firefox:</strong> Type <code>about:config</code> in
          the address bar, search for <code>webgl.disabled</code>, and set it to{" "}
          <code>false</code>.
        </li>
        <li>
          <strong>Microsoft Edge:</strong> Enter <code>edge://flags</code> in
          the address bar and enable <code>WebGL</code>.
        </li>
        <li>
          <strong>Safari:</strong> Go to Safari Preferences, select the Advanced
          tab, and check
          <code>&quot;Show Develop menu in menu bar&quot;</code>. In the Develop
          menu, ensure
          <code>&quot;Enable WebGL&quot;</code> is checked.
        </li>
      </ul>

      <p>
        If WebGL is still not working, ensure that your graphics drivers are
        updated and that hardware acceleration is enabled in your browser
        settings.
      </p>
    </ManualContent>
  );
};

export default SystemRequirements;
