import React, { useState } from "react";
import { Link } from "react-router-dom";

const Sidebar = ({ currentPage, onNavigate, onBackToSpeqqle }) => {
  const [expandedSections, setExpandedSections] = useState(new Set());

  const toggleSection = (section) => {
    setExpandedSections((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(section)) {
        newSet.delete(section);
      } else {
        newSet.add(section);
      }
      return newSet;
    });
  };

  const handleLinkClick = (path) => {
    onNavigate(path);
  };

  return (
    <nav className="sidebar">
      <div className="sidebar-title">Speqqle Manual</div>
      <button className="back-button" onClick={onBackToSpeqqle}>
        Back to Speqqle
      </button>

      <ul>
        <li className="special-link">
          <Link
            to="/manual/welcome"
            onClick={() => handleLinkClick("welcome")}
            className={currentPage === "welcome" ? "active-link" : ""}
          >
            Welcome
          </Link>
        </li>

        {/* General Section */}
        <li className="expandable" onClick={() => toggleSection("general")}>
          General
        </li>
        <ul
          className={`sub-menu ${
            expandedSections.has("general") ? "expanded" : ""
          }`}
        >
          <li>
            <Link
              to="/manual/general/quickstart"
              onClick={() => handleLinkClick("general/quickstart")}
              className={
                currentPage === "general/quickstart" ? "active-link" : ""
              }
            >
              Quick Start
            </Link>
          </li>
          <li>
            <Link
              to="/manual/general/interface"
              onClick={() => handleLinkClick("general/interface")}
              className={
                currentPage === "general/interface" ? "active-link" : ""
              }
            >
              Interface
            </Link>
          </li>
          <li>
            <Link
              to="/manual/general/betaprogram"
              onClick={() => handleLinkClick("general/betaprogram")}
              className={
                currentPage === "general/betaprogram" ? "active-link" : ""
              }
            >
              BETA program
            </Link>
          </li>

          <li>
            <Link
              to="/manual/general/systemrequirements"
              onClick={() => handleLinkClick("general/systemrequirements")}
              className={
                currentPage === "general/systemrequirements" ? "active-link" : ""
              }
            >
              System Requirements
            </Link>
          </li>
        </ul>

        {/* Data Loading Section */}
        <li
          className="expandable"
          onClick={() => toggleSection("data-loading")}
        >
          Data Loading
        </li>
        <ul
          className={`sub-menu ${
            expandedSections.has("data-loading") ? "expanded" : ""
          }`}
        >
          <li>
            <Link
              to="/manual/data-loading/overview"
              onClick={() => handleLinkClick("data-loading/overview")}
              className={
                currentPage === "data-loading/overview" ? "active-link" : ""
              }
            >
              Overview
            </Link>
          </li>
        </ul>

        {/* Experiments Section */}
        <li className="expandable" onClick={() => toggleSection("experiments")}>
          Experiments
        </li>
        <ul
          className={`sub-menu ${
            expandedSections.has("experiments") ? "expanded" : ""
          }`}
        >
          <li>
            <Link
              to="/manual/experiments/overview"
              onClick={() => handleLinkClick("experiments/overview")}
              className={
                currentPage === "experiments/overview" ? "active-link" : ""
              }
            >
              Overview
            </Link>
          </li>
          <li>
            <Link
              to="/manual/experiments/newmodels"
              onClick={() => handleLinkClick("experiments/newmodels")}
              className={
                currentPage === "experiments/newmodels" ? "active-link" : ""
              }
            >
              Add New Model to Experiment
            </Link>
          </li>
          <li>
            <Link
              to="/manual/experiments/loadmodels"
              onClick={() => handleLinkClick("experiments/loadmodels")}
              className={
                currentPage === "experiments/loadmodels" ? "active-link" : ""
              }
            >
              Load Experiments and Models
            </Link>
          </li>
          <li>
            <Link
              to="/manual/experiments/modellist"
              onClick={() => handleLinkClick("experiments/modellist")}
              className={
                currentPage === "experiments/modellist" ? "active-link" : ""
              }
            >
              All Experiments
            </Link>
          </li>
          <li>
            <Link
              to="/manual/experiments/managingmodels"
              onClick={() => handleLinkClick("experiments/managingmodels")}
              className={
                currentPage === "experiments/managingmodels"
                  ? "active-link"
                  : ""
              }
            >
              Managing Main and Submodels
            </Link>
          </li>
        </ul>

        {/* Parameters Section */}
        <li className="expandable" onClick={() => toggleSection("parameters")}>
          Parameters
        </li>
        <ul
          className={`sub-menu ${
            expandedSections.has("parameters") ? "expanded" : ""
          }`}
        >
          <li>
            <Link
              to="/manual/parameters/overview"
              onClick={() => handleLinkClick("parameters/overview")}
              className={
                currentPage === "parameters/overview" ? "active-link" : ""
              }
            >
              Overview
            </Link>
          </li>
          <li>
            <Link
              to="/manual/parameters/editing"
              onClick={() => handleLinkClick("parameters/editing")}
              className={
                currentPage === "parameters/editing" ? "active-link" : ""
              }
            >
              Editing Parameters
            </Link>
          </li>
          <li>
            <Link
              to="/manual/parameters/constraints"
              onClick={() => handleLinkClick("parameters/constraints")}
              className={
                currentPage === "parameters/constraints" ? "active-link" : ""
              }
            >
              Constraints and Groups
            </Link>
          </li>
          <li>
            <Link
              to="/manual/parameters/fixing"
              onClick={() => handleLinkClick("parameters/fixing")}
              className={
                currentPage === "parameters/fixing" ? "active-link" : ""
              }
            >
              Fixing Parameters
            </Link>
          </li>
          <li>
            <Link
              to="/manual/parameters/multilayer"
              onClick={() => handleLinkClick("parameters/multilayer")}
              className={
                currentPage === "parameters/multilayer" ? "active-link" : ""
              }
            >
              Managing Multilayer Structures
            </Link>
          </li>
          <li>
            <Link
              to="/manual/parameters/saving"
              onClick={() => handleLinkClick("parameters/saving")}
              className={
                currentPage === "parameters/saving" ? "active-link" : ""
              }
            >
              Model Saving
            </Link>
          </li>
          <li>
            <Link
              to="/manual/parameters/paramcolors"
              onClick={() => handleLinkClick("parameters/paramcolors")}
              className={
                currentPage === "parameters/paramcolors" ? "active-link" : ""
              }
            >
              Parameter Color Code
            </Link>
          </li>
        </ul>

        {/* Graphs Section */}
        <li className="expandable" onClick={() => toggleSection("graphs")}>
          Graphs
        </li>
        <ul
          className={`sub-menu ${
            expandedSections.has("graphs") ? "expanded" : ""
          }`}
        >
          <li>
            <Link
              to="/manual/graphs/overview"
              onClick={() => handleLinkClick("graphs/overview")}
              className={currentPage === "graphs/overview" ? "active-link" : ""}
            >
              Overview
            </Link>
          </li>
          <li>
            <Link
              to="/manual/graphs/addingdata"
              onClick={() => handleLinkClick("graphs/addingdata")}
              className={
                currentPage === "graphs/addingdata" ? "active-link" : ""
              }
            >
              Add Curves to Graph
            </Link>
          </li>
          <li>
            <Link
              to="/manual/graphs/customization"
              onClick={() => handleLinkClick("graphs/customization")}
              className={
                currentPage === "graphs/customization" ? "active-link" : ""
              }
            >
              Graph Settings
            </Link>
          </li>
          <li>
            <Link
              to="/manual/graphs/saving"
              onClick={() => handleLinkClick("graphs/saving")}
              className={currentPage === "graphs/saving" ? "active-link" : ""}
            >
              Saving Curves from Graph
            </Link>
          </li>
          <li>
            <Link
              to="/manual/graphs/interactive"
              onClick={() => handleLinkClick("graphs/interactive")}
              className={
                currentPage === "graphs/interactive" ? "active-link" : ""
              }
            >
              Updating Datasets via the Legend
            </Link>
          </li>
          <li>
            <Link
              to="/manual/graphs/exporting"
              onClick={() => handleLinkClick("graphs/exporting")}
              className={
                currentPage === "graphs/exporting" ? "active-link" : ""
              }
            >
              Exporting Graphs
            </Link>
          </li>
        </ul>

        {/* Fitting Section */}
        <li className="expandable" onClick={() => toggleSection("fit")}>
          Fitting
        </li>
        <ul
          className={`sub-menu ${
            expandedSections.has("fit") ? "expanded" : ""
          }`}
        >
          <li>
            <Link
              to="/manual/fit/overview"
              onClick={() => handleLinkClick("fit/overview")}
              className={currentPage === "fit/overview" ? "active-link" : ""}
            >
              Overview
            </Link>
          </li>
          <li>
            <Link
              to="/manual/fit/least-sqr"
              onClick={() => handleLinkClick("fit/least-sqr")}
              className={currentPage === "fit/least-sqr" ? "active-link" : ""}
            >
              Least Square Fitting
            </Link>
          </li>
          <li>
            <Link
              to="/manual/fit/vdf-fit"
              onClick={() => handleLinkClick("fit/vdf-fit")}
              className={currentPage === "fit/vdf-fit" ? "active-link" : ""}
            >
              VDF Fit
            </Link>
          </li>
          <li>
            <Link
              to="/manual/fit/kk-trans"
              onClick={() => handleLinkClick("fit/kk-trans")}
              className={currentPage === "fit/kk-trans" ? "active-link" : ""}
            >
              KK transformations
            </Link>
          </li>
        </ul>

        {/* Loop Operations Section */}
        <li className="expandable" onClick={() => toggleSection("loop")}>
          Loop Operations
        </li>
        <ul
          className={`sub-menu ${
            expandedSections.has("loop") ? "expanded" : ""
          }`}
        >
          <li>
            <Link
              to="/manual/loop/overview"
              onClick={() => handleLinkClick("loop/overview")}
              className={currentPage === "loop/overview" ? "active-link" : ""}
            >
              Overview
            </Link>
          </li>
        </ul>

        {/* Special Packages Section */}
        <li className="expandable" onClick={() => toggleSection("packages")}>
          Special Packages
        </li>
        <ul
          className={`sub-menu ${
            expandedSections.has("packages") ? "expanded" : ""
          }`}
        >
          <li>
            <Link
              to="/manual/packages/overview"
              onClick={() => handleLinkClick("packages/overview")}
              className={
                currentPage === "packages/overview" ? "active-link" : ""
              }
            >
              Overview
            </Link>
          </li>
          <li>
            <Link
              to="/manual/packages/speqqlebase"
              onClick={() => handleLinkClick("packages/speqqlebase")}
              className={
                currentPage === "packages/speqqlebase" ? "active-link" : ""
              }
            >
              Speqqle Base
            </Link>
          </li>
        </ul>

        {/* Special Links */}
        <li className="special-link">
          <Link
            to="/manual/troubleshooting/overview"
            onClick={() => handleLinkClick("troubleshooting/overview")}
            className={
              currentPage === "troubleshooting/overview" ? "active-link" : ""
            }
          >
            Troubleshooting
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default Sidebar;
