import React, { useState, useContext, useEffect, useRef } from "react";
// import { DndProvider } from "react-dnd";
// import { HTML5Backend } from "react-dnd-html5-backend";
import DraggableGraphWindow from "./DraggableGraphWindow";
import { DashboardContext } from "../../context/DashboardContext";
import { GraphContext } from "../../context/GraphContext";
import { WebSocketContext } from "../../context/WebSocketContext";
import { GeneralContext } from "../../context/GeneralContext";
import { AuthContext } from "../../context/AuthContext";
import {
  deepCopy,
  getModelById,
  replaceModelById,
} from "../leftSide/Models/modelLogic";
import Modal from "react-modal";
import {
  // containedModelIDsInGraphs,
  // createModelDistPayload,
  createModelList,
  createGraphModelEntry,
  generatePlotData,
  checkModelAndLoad,
} from "./graphLogic";
import {
  isDeepEqual,
  // generateWarningObject,
  updateOrAddCurve,
  produceCurveObject,
  findBestMatchingCurve,
  hasProperty,
  // getRangesForModelsFromGraphs,
  // getReturnedCurveDictionary,
  // replaceModelPlotData,
  getShortName,
  adjustModalPositionAndSize,
  // generateModelsRangesQuantPairsForModelDist,
  // checkBool,
  vwToPixels,
  vhToPixels,
} from "../../utils/helpers";
import {
  // getListOfTermsToUpdate,
  performChi2Message,
  // updatedChi2,
} from "../rightSide/fit/fitLogic";
import { DEFAULT_GRAPH } from "../../utils/constants";
import { processFileWithOptions } from "../leftSide/Files/fileProcessing/processingLogic";
import FitLoaderComponent from "../commonComponents/FitLoaderComponent";
// import FitStopWindow from "./FitStopWindow";
// import LoopStopWindow from "./loop/LoopStopWindow";

function GraphContainer() {
  const {
    uploadedFiles,
    // setUploadedFiles,
    fileID,
    // setFileID,
    modelData,
    setModelData,
    // setWarnings,
    // setNewWarningCount,
    // valueGroups,
    // setValueGroups,
    // fitIteration,
    // setFitIteration,
    allLocalModels,
    chi2ReqAllowed,
    setChi2ReqAllowed,
    chi2QueRef,
    addGraphModalOpen,
    setAddGraphModalOpen,
  } = useContext(DashboardContext);
  const {
    graphs,
    setGraphs,
    graphId,
    setGraphId,
    // updatedModelFE_IDs,
    // setUpdatedModelFE_IDs,
    requestedFitModel,
    // setRequestedFitModel,
    chi2Terms,
    // setChi2Terms,
    selectedChi2Terms,
    isGraphsReady,
    zIndices,
    setZIndices,
  } = useContext(GraphContext);
  const {
    lastJsonMessage,
    sendJsonMessage,
    isFitOngoing,
    isLoopOngoing,
    // setIsFitOngoing,
    retrieveRequestData,
    deleteRequestDataEntry,
  } = useContext(WebSocketContext);
  // const { limitedToast, limitedSucessToast, recordedErrorLog } =
  const { recordedErrorLog } = useContext(GeneralContext);
  const { currentUser } = useContext(AuthContext);
  const [graphModalIsOpenLocal, setGraphModalIsOpenLocal] = useState(false);
  // const [fitModalIsOpen, setFitModalIsOpen] = useState(false);
  const [selectFileList, setSelectFileList] = useState([]);
  const [selectModelList, setSelectModelList] = useState([]);
  const localFileID = useRef(fileID);
  const localGraphID = useRef(graphId);
  const localGraphs = useRef(graphs);
  // const localValueGroups = useRef(valueGroups);
  // const requestedModel = useRef(null);
  // const requestedModelsUpdate = useRef([]);
  const requestedFitModelsLocal = useRef([]);
  // const updatePending = useRef(false);
  // const updateIDWaitList = useRef([]);
  const graphSection = useRef();
  // const fitButton = useRef();
  const [smallWindow, setSmallWindow] = useState({
    height: Math.max(window.innerWidth * 0.3, 220),
    width: Math.max(window.innerWidth * 0.4, 260),
  });
  const [modalPlaceAndSize, setModalPlaceAndSize] = useState({
    top: "0",
    left: "0",
    height: "300px",
    width: "300px",
  });

  const [update, setUpdate] = useState(false);
  // const chiTermsToUpdate = useRef([]);
  const [updateChiTerms, setUpdateChiTerms] = useState(false);

  useEffect(() => {
    localFileID.current = fileID;
  }, [fileID]);

  // We do this chi2 update here instead of in the fit, because it takes a bit of time to unlock the websocket
  // to allow other requests straight after fit.
  useEffect(() => {
    // if (updateChiTerms && chiTermsToUpdate.current.length > 0) {
    if (updateChiTerms) {
      if (selectedChi2Terms.length > 0) {
        const termsToSend = chi2Terms.filter((term) => {
          if (
            selectedChi2Terms.some((id) => id === term.id) &&
            term.weight > 0
          ) {
            return true;
          } else {
            return false;
          }
        });

        if (termsToSend.length > 0) {
          performChi2Message(
            termsToSend,
            modelData,
            uploadedFiles,
            sendJsonMessage,
            currentUser,
            recordedErrorLog,
            chi2ReqAllowed,
            setChi2ReqAllowed,
            chi2QueRef
          );
        }
      }

      // chiTermsToUpdate.current = [];
      setUpdateChiTerms(false);
    }
  }, [updateChiTerms]);

  // Update window sizes when browser window changes
  useEffect(() => {
    const handleResize = () => {
      setSmallWindow({
        height: Math.max(window.innerWidth * 0.3, 220),
        width: Math.max(window.innerWidth * 0.4, 260),
      });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (isGraphsReady && graphs.length === 0) {
      setGraphs([DEFAULT_GRAPH]);
    }
  }, [isGraphsReady]);

  useEffect(() => {
    if (graphId != localGraphID.current) {
      localGraphID.current = graphId;
    }
  }, [graphId]);

  // useEffect(() => {
  //   if (!isDeepEqual(valueGroups, localValueGroups.current)) {
  //     localValueGroups.current = valueGroups;
  //   }
  // }, [valueGroups]);

  useEffect(() => {
    if (addGraphModalOpen) {
      const modalPosition = {
        top: vhToPixels(3),
        right: vwToPixels(15),
        left: "auto",
      };
      const modalSize = { width: 285, height: 350 };

      const adjusted = adjustModalPositionAndSize(modalPosition, modalSize);

      setModalPlaceAndSize(adjusted);
      setGraphModalIsOpenLocal(true);
    }
  }, [addGraphModalOpen]);

  function handleNewModelCurve(jsonMessage, details) {
    try {
      const foundData = jsonMessage.Model.SendCurve.find(
        (curve) =>
          curve.modelid == details.requestDetails.modelId &&
          curve.curves[0].quantity == details.requestDetails.quantity
      );

      if (foundData != undefined) {
        handleModelDistAdd(
          foundData,
          false,
          details.graphDetails.defaultMin,
          details.graphDetails.defaultMax
        );
      } else {
        console.log("DATA NOT FOUND FOR MODEL CURVE");
      }
    } catch (error) {
      recordedErrorLog("Error handling new model curve response: ", error);
    }
  }

  useEffect(() => {
    try {
      if (lastJsonMessage) {
        if (hasProperty(lastJsonMessage, "requestID")) {
          const requestData = retrieveRequestData(lastJsonMessage.requestID);
          if (requestData !== null) {
            switch (requestData.type) {
              case "new-model-curve-with-new-graph":
                handleNewModelCurve(lastJsonMessage, requestData);
                deleteRequestDataEntry(lastJsonMessage.requestID);
                break;
              default:
                break;
            }
          }
        } else {
          throw new Error("Request ID was not attached to the json message.");
        }
      }
    } catch (error) {
      recordedErrorLog(
        "Last Json Message handling error in useEffect: ",
        error
      );
    }
  }, [lastJsonMessage]);

  useEffect(() => {
    requestedFitModelsLocal.current = requestedFitModel;
  }, [requestedFitModel]);

  useEffect(() => {
    localGraphs.current = graphs;
    if (Object.keys(zIndices).length === 0 && localGraphs.current.length > 0) {
      setZIndices(
        deepCopy(localGraphs.current).reduce((acc, win) => {
          acc[win.id] = 0;
          return acc;
        }, {})
      );
    }
    setUpdate(!update);
  }, [graphs]);

  const handleModelDistAdd = (
    data,
    curvesFromModel = false,
    defaultMin,
    defaultMax
  ) => {
    try {
      const { modelid, curves } = data;

      const coordinates = curves[0].coordinates;
      const quantity = curves[0].quantity;

      const modelAdded = getModelById(modelid, modelData);

      let coordsToUse = coordinates;

      if (!curvesFromModel) {
        const curveObj = produceCurveObject(coordinates, quantity);
        modelAdded.curves = updateOrAddCurve(modelAdded.curves, curveObj);

        const newModels = replaceModelById(modelData, modelAdded, modelid);
        setModelData(newModels);

        const foundCurve = findBestMatchingCurve(
          modelAdded.curves,
          defaultMin,
          defaultMax,
          quantity
        );

        if (!foundCurve.bestFound) {
          recordedErrorLog("THE CURVE FOUND DOES NOT FIT THE NEED");
        }

        coordsToUse = foundCurve.curve.curve;
      }

      createGraphEntry(
        coordsToUse,
        modelAdded.displayName,
        null,
        modelid,
        "",
        "",
        null,
        quantity
      );
    } catch (error) {
      recordedErrorLog("Model curve adding failure: ", error);
    }
  };

  const handleCloseGraphModal = () => {
    setAddGraphModalOpen(false);
    setGraphModalIsOpenLocal(false);
  };

  const generateGraphId = () => {
    const newId = localGraphID.current;

    localGraphID.current = localGraphID.current + 1;
    setGraphId(localGraphID.current);
    return newId;
  };

  const deleteGraph = (idToDelete) => {
    try {
      localGraphs.current = localGraphs.current.filter(
        (graph) => graph.id != idToDelete
      );

      setGraphs(localGraphs.current);
    } catch (error) {
      recordedErrorLog("Graph deletion failure: ", error);
    }
  };

  const handleFileSelected = (id) => {
    try {
      const fileForGraph = uploadedFiles.find((file) => file.ID == id);

      const data = Object.prototype.hasOwnProperty.call(
        fileForGraph,
        "dataPoints"
      )
        ? fileForGraph.dataPoints
        : processFileWithOptions(
            fileForGraph.content,
            fileForGraph.options,
            recordedErrorLog
          );

      createGraphEntry(
        data,
        fileForGraph.name,
        fileForGraph.ID,
        null,
        fileForGraph.dataRangeMax,
        fileForGraph.dataRangeMin,
        fileForGraph.edges
      );

      handleCloseGraphModal();
    } catch (error) {
      recordedErrorLog("File addition to graph failure: ", error);
    }
  };

  const createGraphEntry = (
    data,
    name,
    fileID,
    modelID,
    rangeMax,
    rangeMin,
    edges,
    quantity
  ) => {
    try {
      const shortName = getShortName(name);

      const plotData = generatePlotData(
        data,
        rangeMin,
        rangeMax,
        "blue",
        shortName,
        fileID,
        modelID,
        edges,
        quantity
      );

      const layout = {
        xaxis: {
          title: { text: "Wavenumber [cm<sup>-1</sup>]" },
          mirror: "ticks",
          showline: true,
          linecolor: "#000",
          linewidth: 1,
        },
        yaxis: {
          title: { text: "Amplitude" },
          mirror: "ticks",
          showline: true,
          linecolor: "#000",
          linewidth: 1,
        },
        margin: {
          l: 60, // left margin
          r: 5, // right margin
          b: 35, // bottom margin
          t: 20, // top margin
          pad: 0, // padding between the plotting area and the axis labels
        },
        autosize: true,
        width: smallWindow.width - 2,
        height: smallWindow.height - 38,
        // TRANSITION CAUSES ISSUES WITH RERENDERING AND PRODUCES VISUAL BUGS
        // transition: {
        //   duration: 500,
        //   easing: "cubic-in-out",
        // },
        paper_bgcolor: "#edf7ff",
        legend: {
          x: 1,
          y: 1,
          xanchor: "right", // anchors the legend's right side to the x position
          yanchor: "top", // anchors the legend's top to the y position
          bgcolor: "rgba(255,255,255,0.8)", // semi-transparent white background
        },
      };

      if (fileID !== null) {
        layout.xaxis.range = [rangeMin, rangeMax];
      }

      // localFileID.current = localFileID.current + 1;

      // setFileID(localFileID.current);

      const localGraphId = generateGraphId();
      const multipliers = divideWithRemainder(localGraphId, 5);
      const position = {
        x: multipliers.remainder * 100,
        y: multipliers.integer * 100,
      };

      localGraphs.current = [
        ...localGraphs.current,
        {
          type: "standard",
          id: localGraphId,
          position: position,
          title: name,
          plotData: plotData,
          layout: layout,
          containedFiles: fileID != null ? [fileID] : [],
          containedModels:
            modelID != null ? [{ modelId: modelID, quantity: quantity }] : [],
        },
      ];

      setGraphs(localGraphs.current);

      // This line to updates the zIndex state
      setZIndices((prevZIndices) => {
        return {
          ...prevZIndices,
          [localGraphId]: Object.keys(prevZIndices).length,
        };
      });
    } catch (error) {
      recordedErrorLog("Graph entry creation failure: ", error);
    }
  };

  function handleModelClick(modelId, quantity) {
    try {
      const foundModel = getModelById(modelId, modelData);

      const defaultModel = allLocalModels.find(
        (defMod) => defMod.speqqle_id === foundModel.speqqleID
      );

      let defaultMin = -1000;
      let defaultMax = 1000;

      if (defaultModel !== undefined) {
        defaultMin = defaultModel.default_curve_min;
        defaultMax = defaultModel.default_curve_max;
      }

      // const potentialModelQuantitySet =
      checkModelAndLoad(
        foundModel,
        handleModelDistAdd,
        sendJsonMessage,
        defaultMin,
        defaultMax,
        quantity,
        currentUser,
        {
          type: "new-model-curve-with-new-graph",
          graphDetails: { defaultMin, defaultMax },
        }
      );

      // if (potentialModelQuantitySet !== null) {
      //   requestedModel.current = potentialModelQuantitySet;
      // }
      handleCloseGraphModal();
    } catch (error) {
      recordedErrorLog("Model click handler failure: ", error);
    }
  }

  useEffect(() => {
    if (modelData.length > 0) {
      setSelectModelList(
        createModelList(handleModelClick, modelData, createGraphModelEntry)
      );
    }
    if (modelData.length === 0) {
      setSelectModelList([]);
    }
  }, [modelData]);

  useEffect(() => {
    try {
      if (uploadedFiles.length > 0) {
        const fileList = uploadedFiles.map((file) => {
          return (
            <div
              key={file.ID}
              className="fileEntry"
              id="file-dist-entry"
              onClick={() => handleFileSelected(file.ID)}
            >
              {file.name}
            </div>
          );
        });

        setSelectFileList(fileList);
      } else {
        setSelectFileList([]);
      }

      let graphsCopy = deepCopy(localGraphs.current);
      let updateNeeded = false;

      for (let j = 0; j < graphs.length; j++) {
        const graph = graphsCopy[j];

        for (let i = 0; i < graph.containedFiles.length; i++) {
          const fileId = graph.containedFiles[i];

          const uploadedFile = uploadedFiles.find((file) => file.ID == fileId);

          if (uploadedFile != undefined) {
            const fileData = Object.prototype.hasOwnProperty.call(
              uploadedFile,
              "dataPoints"
            )
              ? uploadedFile.dataPoints
              : processFileWithOptions(
                  uploadedFile.content,
                  uploadedFile.options,
                  recordedErrorLog
                );

            const currentPlots = graph.plotData.filter(
              (plot) => plot.fileId == fileId
            );

            const plotColor = currentPlots[0].line.color;
            const plotName = uploadedFile.name;

            const newPlotData = generatePlotData(
              fileData,
              uploadedFile.dataRangeMin,
              uploadedFile.dataRangeMax,
              plotColor,
              plotName,
              fileId,
              null,
              uploadedFile.edges,
              null
            );

            if (!isDeepEqual(currentPlots, newPlotData)) {
              graphsCopy[j].plotData = graphsCopy[j].plotData.filter(
                (plot) => plot.fileId != fileId
              );
              for (let i = 0; i < newPlotData.length; i++) {
                graphsCopy[j].plotData.push({
                  ...newPlotData[i],
                  line: currentPlots[0].line,
                  mode: currentPlots[0].mode,
                  type: currentPlots[0].type,
                });
              }
              updateNeeded = true;
            }
          }
        }
      }

      if (updateNeeded) {
        setGraphs(graphsCopy);
      }
    } catch (error) {
      recordedErrorLog(
        "Applying uploaded files update in useEffect failure: ",
        error
      );
    }
  }, [uploadedFiles]);

  function divideWithRemainder(number, divisor) {
    const remainder = number % divisor;
    const integer = Math.floor(number / divisor);
    return { remainder, integer };
  }

  const reorderZIndices = (currentZIndices, activeId) => {
    try {
      // Extract the current z-index of the active graph
      const activeZIndex = currentZIndices[activeId];

      // Create a new object to store the updated z-indices
      const newZIndices = {};

      // Assign z-indices to other graphs, decrementing their index if it's higher than the active graph's index
      Object.entries(currentZIndices).forEach(([id, zIndex]) => {
        if (id !== activeId) {
          newZIndices[id] = zIndex < activeZIndex ? zIndex : zIndex - 1;
        }
      });

      // Assign the highest z-index to the active graph
      const maxZIndex = Object.keys(currentZIndices).length - 1;
      newZIndices[activeId] = maxZIndex;

      return newZIndices;
    } catch (error) {
      recordedErrorLog("Z indiced reorder failure: ", error);
    }
  };

  const updateZIndex = (id) => {
    setZIndices((prevZIndices) => {
      return reorderZIndices(prevZIndices, id);
    });
  };

  const onDragEnd = () => {
    // nothing for now
    // We can also have onDragStart if we need to
  };

  return (
    <div className="graphSection" ref={graphSection}>
      <div className="graphWindowBar">
        <Modal
          isOpen={graphModalIsOpenLocal}
          onRequestClose={handleCloseGraphModal}
          shouldCloseOnOverlayClick={true}
          contentLabel="Model Modal"
          appElement={graphSection.current}
          style={{
            content: {
              width: modalPlaceAndSize.width,
              height: modalPlaceAndSize.height,
              top: modalPlaceAndSize.top,
              left: modalPlaceAndSize.left,
              right: modalPlaceAndSize.right,
            },
            overlay: {
              backgroundColor: "transparent",
              zIndex: "5000",
            },
          }}
        >
          <div className="distributionsModal">
            <div className="fileDistributions">
              <div className="fileDistTitle">From Files:</div>
              <div className="fileDistList" id="file-dist-list-from-add-graph">
                {selectFileList.length > 0
                  ? selectFileList
                  : "No Files Uploaded"}
              </div>
            </div>
            <hr />
            <div className="modelDistributions">
              <div className="modelDistTitle">From Models:</div>
              {isFitOngoing || isLoopOngoing ? (
                <div
                  style={{
                    position: "relative",
                    height: "100%",
                    width: "100%",
                  }}
                >
                  <FitLoaderComponent scale={50} />
                </div>
              ) : (
                <div
                  className="modelDistList"
                  id="model-dist-list-from-add-graph"
                >
                  {selectModelList.length > 0
                    ? selectModelList
                    : "No Models Added"}
                </div>
              )}
            </div>
          </div>
        </Modal>
      </div>
      {/* Adding this check to make sure that context is loaded in, so that we don't try to created random graphs with
      bad data */}
      {isGraphsReady ? (
        // <DndProvider backend={HTML5Backend}>
        <div
          style={{
            height: "80vh",
            position: "relative",
          }}
        >
          {localGraphs.current.map((graph) => {
            return (
              <DraggableGraphWindow
                key={graph.id}
                id={graph.id}
                initialPosition={graph.position}
                initialData={graph.plotData}
                initialLayout={graph.layout}
                initialSize={
                  hasProperty(graph, "size") ? graph.size : smallWindow
                }
                zIndex={
                  zIndices[graph.id] !== undefined ? zIndices[graph.id] : 0
                }
                onDragStart={updateZIndex}
                onGraphClick={updateZIndex}
                onDragEnd={onDragEnd}
                title={graph.title}
                type={hasProperty(graph, "type") ? graph.type : "standard"}
                initialGraph={graph}
                deleteGraph={deleteGraph}
              />
            );
          })}
        </div>
        // </DndProvider>
      ) : (
        <></>
      )}
    </div>
  );
}

export default GraphContainer;
