import React from "react";
import ManualContent from "../../components/ManualContent";

const Editing = () => {
  return (
    <ManualContent title="Editing Parameters">
      <p>
        Editing a parameter in a model means changing its value to match
        experimental data or improve the accuracy of the fit.
      </p>

      <h2>Initializing Model Parameters</h2>
      <p>
        After adding a new model via the <strong>Experiments</strong> tab, the
        model parameters need to be initialized. Some Experiments, such as the{" "}
        <strong>Raman model</strong>, have autofit functions that provide
        automatic initialization. However, most models require manual parameter
        initialization.
      </p>

      <p>
        During the initial phase, most parameters are set to a default value,
        usually <strong>0</strong>. Models with{" "}
        <strong>recurring parameters</strong> start with a single entry, and the
        user must manually add more entries as needed. The output of an
        uninitialized model is often a simple flat line at zero.
      </p>

      <h2>Understanding Parameter Tables</h2>
      <p>Many models contain multiple parameter tables:</p>
      <ul>
        <li>
          <strong>Fixed parameter tables:</strong> These include instrument or
          setup parameters where the number of parameters is predefined.
        </li>
        <li>
          <strong>Recurring parameter tables:</strong> These contain optical
          response parameters and allow the user to add multiple entries.
        </li>
      </ul>

      <h3>Managing Recurring Parameter Tables</h3>
      <p>For tables with recurring parameters, you can:</p>
      <ul>
        <li>
          <strong>Add new entries:</strong> Click the{" "}
          <strong>green (+) icon</strong> to insert a new row.
        </li>
        <li>
          <strong>Copy an existing entry:</strong> Click the{" "}
          <strong>copy icon</strong> to duplicate a row.
        </li>
        <li>
          <strong>Remove an entry:</strong> Click the{" "}
          <strong>red (X) icon</strong> to delete a row.
        </li>
      </ul>

      <p>
        For models where the order of the rows in the recurring table does not
        affect the model outputs, you can sort them automatically based on one
        of the parameters by clicking on the corresponding column title in the
        table header.
      </p>

      <h2>What is the Parameter Sticky Window?</h2>

      <p>
        The <strong>Parameter Sticky Window</strong> is a floating dialog box
        that opens when you click on any parameter in a model. This window
        allows you to:
      </p>

      <ul>
        <li>Edit the parameter value manually.</li>
        <li>Use a slider for fine-tuned adjustments.</li>
        <li>Set constraints to limit parameter ranges.</li>
        <li>Link the parameter to other parameters for correlated fitting.</li>
      </ul>

      <p>
        If the Sticky Window opens in an inconvenient location on your screen,
        you can reposition it by dragging the <strong>top blue header</strong>
        {""} and dropping it in a new location—just make sure not to place it
        behind a graph!
      </p>

      <h2>How to Edit Parameter Values</h2>
      <p>To modify a parameter value:</p>
      <ol>
        <li>Click inside the parameter cell.</li>
        <li>
          Choose one of the following input methods:
          <ul>
            <li>
              <strong>Direct input:</strong> Type the new value directly into
              the cell.
            </li>
            <li>
              <strong>Parameter input box:</strong> Enter the value in the{" "}
              <strong>&quot;Value&quot;</strong> field in the parameter sticky
              window.
            </li>
            <li>
              <strong>Slider adjustment:</strong> Use the slider in the sticky
              window to change the value interactively.
            </li>
          </ul>
        </li>
      </ol>

      <h2>Next Steps</h2>
      <p>
        Once you&apos;ve adjusted your parameters, explore other related topics:
      </p>

      <ul>
        <li>
          <a href="constraints">Setting Parameter Constraints</a>: Define limits
          to prevent unrealistic values or link parameters across multiple
          models.
        </li>
        <li>
          <a href="fixing">Fixing and Freeing Parameters</a>: Lock or unlock
          parameters during the fitting process.
        </li>
        <li>
          <a href="multilayer">Managing Multilayer Structures</a>: Add, remove,
          or modify material layers in multilayer models.
        </li>
        <li>
          <a href="saving">Saving and Loading Models</a>: Save your model
          settings for reuse in future analyses.
        </li>
        <li>
          <a href="paramcolors">Understanding Parameter Color Codes</a>: Learn
          what each color represents and when a parameter needs attention.
        </li>
      </ul>
    </ManualContent>
  );
};

export default Editing;
