import React from "react";
import ManualContent from "../../components/ManualContent";
import experimentSubmodelLoadedImage from "../../images/experiment_submodel_loaded.jpg";
import linkSubmodelToMainImage from "../../images/experiment_link_submodel_tomain.jpg";
import linkSubmodelToMainLinkImage from "../../images/experiment_link_submodel_tomain_link.jpg";
import linkSubmodelToMainSelectImage from "../../images/experiment_link_submodel_tomain_select.jpg";
import linkSubmodelToMainDoneImage from "../../images/experiment_link_submodel_tomain_done.jpg";

const LoadModels = () => {
  return (
    <ManualContent title="Loading Experiments or Submodels">
      <p>
        Speqqle allows you to save and load entire <strong>Experiments</strong>{" "}
        or individual <strong>Submodels</strong> for later use or sharing with
        colleagues and collaborators. This section explains how to load models
        from your computer, manage their connections, and use them in new or
        existing Experiments.
      </p>

      <h2>Why Save and Load Experiments and Submodels?</h2>
      <p>Saving and loading Experiments and Submodels helps you:</p>
      <ul>
        <li>
          <strong>Reuse experimental setups:</strong> Avoid repetitive work when
          working with similar samples.
        </li>
        <li>
          <strong>Collaborate effectively:</strong> Share models with colleagues
          or students.
        </li>
        <li>
          <strong>Maintain consistency:</strong> Ensure parameter consistency
          across related experiments.
        </li>
      </ul>

      <h2>How to Load an Experiment or Submodel</h2>
      <p>
        Follow these steps to load an Experiment or Submodel into the Experiment
        window:
      </p>

      <ol>
        <li>
          Open the <strong>Experiments</strong> tab in the Speqqle interface.
        </li>
        <li>
          Click the <strong>&quot;Load Model&quot;</strong> button.
        </li>
        <li>
          Select the file from your computer. Speqqle supports the{" "}
          <strong>.spqm</strong> file format.
        </li>
        <li>
          After selection, click <strong>&quot;Open&quot;</strong>.
        </li>
        <li>
          The Experiment or Submodel will appear in the Experiment window.
        </li>
      </ol>
      <p>
        <strong>Note:</strong> The loading functionality supports the loading of
        Speqqle models as well as old RefFIT models. However, we do not
        guarantee a faultless conversion from RefFIT to Speqqle models. We
        therefore recommend that you carefully check any loaded RefFIT models
        and save them as Speqqle models for future use.
      </p>

      <h2>Understanding Experiment Types</h2>
      <p>
        When working with Experiments in Speqqle, it&apos;s important to
        distinguish between:
      </p>

      <ul>
        <li>
          <strong>Complete Experiments:</strong> These contain the full
          experimental structure, including all necessary Submodels. When you
          load a complete Experiment, all its associated Submodels are
          automatically included.
        </li>

        <li>
          <strong>Submodels:</strong> These define specific material properties,
          such as refractive index and dielectric function. Submodels do not
          contain information about the sample structure or measurement settings
          and must be linked to a main Experiment.
        </li>
      </ul>

      <div className="image-container wide-image">
        <img
          src={experimentSubmodelLoadedImage}
          alt="Loaded Submodel"
          className="workflow-image wide-workflow-image"
        />
        <p className="image-caption wide">
          This image shows a loaded <strong>Experiment</strong> consting of a
          main model with two Submodels:{" "}
          <strong>&quot;Material_thinfilm&quot;</strong> and{" "}
          <strong>&quot;Substrate&quot;</strong>. These Submodels are indented,
          indicating their hierarchical connection to the main model, together
          forming the entire Experiment.
          <br />
          <br />
          Additionally, the material model <strong>&quot;SiC&quot;</strong> is
          listed at the bottom without indentation. This indicates that it was
          loaded independently and is <strong>not yet linked</strong> to the
          main model.
          <br />
          <br />
        </p>
      </div>

      <h2>Adding and Linking Submodels to Main Models</h2>

      <p>
        If you load a submodel independently, you will need to manually link it
        to a main model so that it is included in the Experiment. A submodel
        without a linked main model cannot be used for fitting or simulating
        experimental quantities such as reflectivity.
      </p>

      <p>To link a submodel to a main model:</p>
      <ol>
        <li>
          Hover over the main model that you want to link to the submodel.
        </li>
        <li>
          A plus (+), paperclip, and cross (X) icon will appear. Click the{" "}
          <strong>plus (+) icon</strong> to add a new submodel to the main
          model.
        </li>
        <li>
          Next, hover over the new submodel you want to replace with the loaded
          submodel. A paperclip and a cross (X) icon will appear. Click the{" "}
          <strong>paperclip icon</strong>, and a window will open displaying a
          list of all submodels.
        </li>
        <li>
          Select the submodel (<strong>SiC</strong> in this example) to replace
          the placeholder submodel.
        </li>
        <li>
          Once you have replaced the placeholder submodel with the loaded
          submodel, you can remove the original loaded submodel by clicking the{" "}
          <strong>red cross (X) icon</strong>.
        </li>
        <li>
          To learn how to change the order of submodels, visit the{" "}
          <a href="../parameters/multilayer">Multilayer Parameters</a> section.
        </li>
      </ol>

      <div className="image-grid">
        <div className="image-container">
          <img
            src={linkSubmodelToMainImage}
            alt="Linking Submodels"
            className="workflow-image"
          />
          <p className="image-caption">
            1. Hover over the main model, then click the plus (+) icon to add a
            new submodel.
          </p>
        </div>
        <div className="image-container">
          <img
            src={linkSubmodelToMainLinkImage}
            alt="Linking Submodels"
            className="workflow-image"
          />
          <p className="image-caption">
            2. Hover over the new submodel &quot;Material_1&quot; and click the
            paperclip icon.
          </p>
        </div>
        <div className="image-container">
          <img
            src={linkSubmodelToMainSelectImage}
            alt="Selecting a Submodel"
            className="workflow-image"
          />
          <p className="image-caption">
            3. &quot;Material_1&quot; is replaced with &quot;SiC,&quot; the
            previously loaded submodel.
          </p>
        </div>
        <div className="image-container">
          <img
            src={linkSubmodelToMainDoneImage}
            alt="Final Linked Submodel"
            className="workflow-image"
          />
          <p className="image-caption">
            4. &quot;SiC&quot; is now a submodel of &quot;R/T/Ellips_2.&quot;
            The previously loaded &quot;SiC&quot; submodel was removed by
            clicking the red cross (X) icon.
          </p>
        </div>
      </div>

      <p>
        For more detailed instructions on model management, see the{" "}
        <a href="managingmodels">Managing Models</a> section.
      </p>

      <h2>Troubleshooting Model Loading Issues</h2>
      <p>
        If you encounter problems when loading models, consider the following:
      </p>

      <ul>
        <li>
          <strong>File Format:</strong> Ensure the file extension is correct (
          <strong>.spqm</strong>).
        </li>
        <li>
          <strong>Version Compatibility:</strong> Older models (from before
          February 2025) might not be compatible with the current version of
          Speqqle.
        </li>
        <li>
          <strong>File Integrity:</strong> Check if the file is corrupted or
          incomplete.
        </li>
      </ul>

      <p>
        If these steps do not resolve the issue, please use the
        <a href="../profile/feedback">Feedback Form</a> to contact us.
      </p>

      <h2>Next Steps</h2>
      <p>Once your Experiments and Submodels are loaded, you may want to:</p>

      <ul>
        <li>
          <a href="modellist">View the Experiment List</a>
        </li>
        <li>
          <a href="managingmodels">Link, Rename, or Remove Models</a>
        </li>
        <li>
          <a href="newmodels">Add Additional Experiments</a>
        </li>
        <li>
          <a href="../graphs/overview">
            Start plotting your data and Experiment curves
          </a>
        </li>
        <li>
          <a href="../parameters/overview">
            Initialize and adapt the parameters in the models
          </a>
        </li>
      </ul>
    </ManualContent>
  );
};

export default LoadModels;
