import React, { useEffect, useState, useRef, useContext } from "react";
import Modal from "react-modal";
import {
  adjustModalPositionAndSize,
  arrayBufferToBase64,
  filterCurveDataToRange,
  generateWarningObject,
  getShortName,
  handleFileChange,
  hasProperty,
  isDeepEqual,
  segmentsIntoSingleCurve,
  simpleReadFile,
  splitCurveIntoSegmentsByEdges,
} from "../../../utils/helpers";
import { Button, TextField } from "@mui/material";
import { DashboardContext } from "../../../context/DashboardContext";
import { WebSocketContext } from "../../../context/WebSocketContext";
import { AuthContext } from "../../../context/AuthContext";
import { GeneralContext } from "../../../context/GeneralContext";
import deleteIcon from "../../../res/icons/delete.png";
import AddIcon from "@mui/icons-material/Add";
// import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import "./parameters.scss";
import { createModelFitPayloadParams } from "../../middle/graphLogic";
import { processFileWithOptions } from "../../leftSide/Files/fileProcessing/processingLogic";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import HtmlTooltip from "../../commonComponents/HtmlTooltip";
import CloseButton from "../../commonComponents/CloseButton";
import { deepCopy } from "../../leftSide/Models/modelLogic";

function ModalVDF(props) {
  const {
    reference,
    model,
    modalOpen,
    setModalOpen,
    hadVDFbefore = false,
    preSelectedFiles = [],
    preselectedKK,
    preselectedON,
    preselectedFitActive,
    onVdfCreate,
    onVdfRemove,
  } = props;
  const { uploadedFiles, setWarnings, setNewWarningCount } =
    useContext(DashboardContext);
  const { sendJsonMessage } = useContext(WebSocketContext);
  const { currentUser } = useContext(AuthContext);
  const { limitedToast, recordedErrorLog } = useContext(GeneralContext);
  const [isOn, setIsOn] = useState(preselectedON);
  const [isKK, setIsKK] = useState(preselectedKK);
  const [isFitActive, setIsFitActive] = useState(preselectedFitActive);
  //const [updatedName, setUpdatedName] = useState(hadVDFbefore);
  //const [changesMade, setChangesMade] = useState(false);
  //const [gridChangesMade, setGridChangesMade] = useState(false);
  const [selectFileModalOpen, setSelectFileModalOpen] = useState(false);
  const [viewGridModalOpen, setViewGridModalOpen] = useState(false);
  const vdfModalRef = useRef();
  const vdfLoadRef = useRef(null);
  const [selectedFileList, setSelectedFileList] = useState(preSelectedFiles);
  const [selectableFiles, setSelectableFiles] = useState([]);
  const [newLinearNPoints, setNewLinearNPoints] = useState("");
  const [newLinearMin, setNewLinearMin] = useState("");
  const [newLinearMax, setNewLinearMax] = useState("");

  const [modalPlaceAndSize, setModalPlaceAndSize] = useState({
    top: "0",
    left: "0",
    height: "300px",
    width: "300px",
  });

  const [gridModalPlaceAndSize, setGridModalPlaceAndSize] = useState({
    top: "0",
    left: "0",
    height: "300px",
    width: "300px",
  });

  const [viewGridModalPlaceAndSize, setViewGridModalPlaceAndSize] = useState({
    top: "0",
    left: "0",
    height: "400px",
    width: "300px",
  });

  useEffect(() => {
    try {
      // First, handle the selectable files list
      if (uploadedFiles && uploadedFiles.length > 0) {
        const availableFiles = [];

        for (let i = 0; i < uploadedFiles.length; i++) {
          const file = uploadedFiles[i];

          const isAlreadySelected = selectedFileList.some((selectedFile) => {
            if (hasProperty(selectedFile, "linear")) {
              return false;
            }
            return selectedFile.file.ID === file.ID;
          });

          if (!isAlreadySelected) {
            availableFiles.push(file);
          }
        }

        setSelectableFiles(availableFiles);
      }

      if (selectedFileList.length > 0) {
        // First filter out files that no longer exist, then update names
        let selectedFilesToUpdate = deepCopy(selectedFileList);
        // setSelectedFileList(
        //   selectedFilesToUpdate => {
        // Step 1: Filter out files that no longer exist
        const filteredFiles = selectedFilesToUpdate.filter((selectedFile) => {
          // Always keep linear grid entries
          if (hasProperty(selectedFile, "linear")) {
            return true;
          }

          // If there are no uploaded files, only keep linear entries
          if (!uploadedFiles || uploadedFiles.length === 0) {
            return false;
          }

          // For regular file entries, check if they still exist in uploadedFiles
          const uploadedFileIDs = uploadedFiles.map((file) => file.ID);
          return uploadedFileIDs.includes(selectedFile.file.ID);
        });

        // Step 2: Update names of remaining files if needed
        selectedFilesToUpdate = filteredFiles.map((selectedFile) => {
          // Skip linear grid entries
          if (hasProperty(selectedFile, "linear")) {
            return selectedFile;
          }

          // Find matching uploaded file to check name
          const matchingUploadedFile = uploadedFiles.find(
            (file) => file.ID === selectedFile.file.ID
          );

          // If matching file found and name has changed, update it
          if (
            matchingUploadedFile &&
            matchingUploadedFile.name !== selectedFile.file.name
          ) {
            return {
              ...selectedFile,
              file: {
                ...selectedFile.file,
                name: matchingUploadedFile.name,
              },
            };
          }

          // Return the original entry if no name update needed
          return selectedFile;
        });

        if (!isDeepEqual(selectedFilesToUpdate, selectedFileList)) {
          setSelectedFileList(selectedFilesToUpdate);
        }
        // });
      }
    } catch (error) {
      recordedErrorLog("Error in selected/uploaded files useEffect: ", error);
    }
  }, [selectedFileList, uploadedFiles]);

  useEffect(() => {
    try {
      if (modalOpen) {
        const modalPosition = {
          top:
            reference.current != undefined
              ? reference.current.getBoundingClientRect().top
              : 0,
          left:
            reference.current != undefined
              ? reference.current.getBoundingClientRect().left
              : 0,
          right: "auto",
        };
        const modalSize = { width: 470, height: 380 };

        const adjusted = adjustModalPositionAndSize(modalPosition, modalSize);

        setModalPlaceAndSize(adjusted);
      }
    } catch (error) {
      recordedErrorLog("VDF modal open checker useEffect has failed: ", error);
    }
  }, [modalOpen]);

  useEffect(() => {
    try {
      if (selectFileModalOpen) {
        const modalPosition = {
          top:
            vdfModalRef.current != undefined
              ? vdfModalRef.current.getBoundingClientRect().top + 200
              : 0,
          left:
            vdfModalRef.current != undefined
              ? vdfModalRef.current.getBoundingClientRect().left
              : 0,
          right: "auto",
        };
        const modalSize = { width: 470, height: 380 };

        const adjusted = adjustModalPositionAndSize(modalPosition, modalSize);

        setGridModalPlaceAndSize(adjusted);
      }
    } catch (error) {
      recordedErrorLog(
        "VDF File Select modal open checker useEffect has failed: ",
        error
      );
    }
  }, [selectFileModalOpen]);

  useEffect(() => {
    try {
      if (viewGridModalOpen && vdfModalRef.current) {
        const modalPosition = {
          top: vdfModalRef.current.getBoundingClientRect().top + 50,
          left: vdfModalRef.current.getBoundingClientRect().left + 50,
          right: "auto",
        };
        const modalSize = { width: 350, height: 400 };

        const adjusted = adjustModalPositionAndSize(modalPosition, modalSize);

        setViewGridModalPlaceAndSize(adjusted);
      }
    } catch (error) {
      recordedErrorLog("View Grid modal position adjustment failed: ", error);
    }
  }, [viewGridModalOpen]);

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleCloseFileSelectModal = () => {
    setSelectFileModalOpen(false);
  };

  const handleCloseViewGridModal = () => {
    setViewGridModalOpen(false);
  };

  const handleCreateVDF = () => {
    try {
      const modelParams = createModelFitPayloadParams([model]);

      const vdfParams = {
        modelid: model.FE_ID,
        kk: isKK,
        on: isOn,
        fitactive: isFitActive,
      };

      const dataIds = [];
      const sourceN = [];
      const points = [];
      const min = [];
      const max = [];

      for (let i = 0; i < selectedFileList.length; i++) {
        const entry = selectedFileList[i];
        if (hasProperty(entry, "linear")) {
          points.push(parseInt(entry.linear.npoints));
          min.push(parseInt(entry.linear.min));
          max.push(parseInt(entry.linear.max));
        } else {
          dataIds.push(entry.file.ID);
          sourceN.push(parseInt(entry.redFactor));
        }
      }

      if (dataIds.length > 0) {
        vdfParams.griddata = {
          dataids: dataIds,
          source_every_n: sourceN,
        };
      }

      if (points.length > 0) {
        vdfParams.gridlinear = {
          npoints: points,
          min: min,
          max: max,
        };
      }

      let payload = {
        User: currentUser.id,
        Model: {
          SendModel: modelParams,
          Vdf: [vdfParams],
        },
      };

      let fileDataPayload = [];

      for (let i = 0; i < selectedFileList.length; i++) {
        if (hasProperty(selectedFileList[i], "linear")) {
          // We skip non file entriess
          continue;
        }
        const file = selectedFileList[i].file;

        let data = Object.prototype.hasOwnProperty.call(file, "dataPoints")
          ? file.dataPoints
          : processFileWithOptions(
              file.content,
              file.options,
              recordedErrorLog
            );

        data = filterCurveDataToRange(
          data,
          file.dataRangeMin,
          file.dataRangeMax
        );

        data = segmentsIntoSingleCurve(
          splitCurveIntoSegmentsByEdges(data, file.edges)
        );

        let range = {};
        range = { ...range, min: file.dataRangeMin != "" ? file.dataRangeMin : -1000, max: file.dataRangeMax != "" ? file.dataRangeMax : 1000, };

        const filteredCutParams = file.edges.filter(
          (param) =>
            (param.min !== undefined && param.min !== "") ||
            (param.max !== undefined && param.max !== "")
        );

        fileDataPayload.push({
          dataid: file.ID,
          data: data,
          range: range,
          edges: filteredCutParams,
        });
      }
      payload.Data = {
        SendData: fileDataPayload,
      };

      let requestedVdfToSet = [];

      if (hasProperty(vdfParams, "griddata")) {
        requestedVdfToSet = [
          ...requestedVdfToSet,
          { id: model.FE_ID, gridData: vdfParams.griddata },
        ];
      }

      if (hasProperty(vdfParams, "gridlinear")) {
        requestedVdfToSet = [
          ...requestedVdfToSet,
          { id: model.FE_ID, gridlinear: vdfParams.gridlinear },
        ];
      }
      onVdfCreate();

      sendJsonMessage(payload, {
        type: "vdf-request",
        requestedVdfs: requestedVdfToSet,
      });
      setModalOpen(false);
      //setUpdatedName(true);
      //setChangesMade(false);
      //setGridChangesMade(false);
    } catch (error) {
      recordedErrorLog("Error in VDF creation handler: ", error);
    }
  };

  const handleFileDelete = (index) => {
    try {
      //setUpdatedName(false);
      //setChangesMade(true);

      setSelectedFileList((oldList) => {
        // Create a new array without the item at the specified index
        const newList = oldList.filter((_, i) => i !== index);
        return newList;
      });
    } catch (error) {
      recordedErrorLog("Error deleting VDF entry: ", error);
    }
  };

  const handleFileSelect = (file) => {
    //setUpdatedName(false);
    //setChangesMade(true);

    setSelectedFileList((old) => [...old, { file: file, redFactor: 2 }]);
    handleCloseFileSelectModal();
  };

  const handleRedFactChange = (e, file) => {
    try {
      const value = e.target.value;
      if (value > 0) {
        //setUpdatedName(false);
        //setChangesMade(true);
        //setGridChangesMade(true);
        setSelectedFileList((oldList) => {
          const updatedList = oldList.map((entry) => {
            if (hasProperty(entry, "linear")) {
              return entry;
            }
            if (entry.file.ID === file.file.ID) {
              return { ...entry, redFactor: value };
            } else {
              return entry;
            }
          });

          return updatedList;
        });
      }
    } catch (error) {
      recordedErrorLog("Error in red fact change handler: ", error);
    }
  };

  async function processFiles(files) {
    try {
      const rfmData = await Promise.all(
        files.map((file) => simpleReadFile(file))
      );

      const Vdf = [];
      const requestedVdfs = [];

      // Handle RFM file processing logic here
      rfmData.forEach((fileData) => {
        const base64Content = arrayBufferToBase64(fileData.content);
        Vdf.push({
          modelid: model.FE_ID,
          binary: base64Content,
        });
        requestedVdfs.push({ id: model.FE_ID });
      });

      const payload = {
        User: currentUser.id,
        Model: {
          Vdf: Vdf,
        },
      };
      sendJsonMessage(payload, {
        type: "binary-vdf-load",
        requestedVdfs: requestedVdfs,
      });
      setModalOpen(false);
    } catch (error) {
      recordedErrorLog("ERROR RFM FILE PROCESS: ", error);
      limitedToast("Selected RFM file could not be processed.");
      generateWarningObject(
        "Selected RFM file could not be processed.",
        2,
        setWarnings,
        setNewWarningCount
      );
    } finally {
      if (vdfLoadRef.current) {
        vdfLoadRef.current.value = "";
      }
    }
  }

  const handleNpointChange = (e) => {
    const value = e.target.value;

    if (value === "") {
      setNewLinearNPoints("");
    } else if (value > 1) {
      setNewLinearNPoints(parseInt(value));
    } else {
      setNewLinearNPoints(1);
    }
  };

  const handleLinearMinChange = (e) => {
    const value = e.target.value;

    if (value === "") {
      setNewLinearMin("");
    } else {
      setNewLinearMin(value);
    }
  };

  const handleLinearMaxChange = (e) => {
    const value = e.target.value;

    if (value === "") {
      setNewLinearMax("");
    } else {
      setNewLinearMax(value);
    }
  };

  const handleAddLinearGrid = () => {
    setSelectedFileList((old) => [
      ...old,
      {
        linear: {
          npoints: newLinearNPoints,
          min: newLinearMin,
          max: newLinearMax,
        },
      },
    ]);
    setNewLinearNPoints("");
    setNewLinearMin("");
    setNewLinearMax("");
    //setUpdatedName(false);
    //setChangesMade(true);
    handleCloseFileSelectModal();
    //setGridChangesMade(true);
  };

  const handleRemoveVDF = () => {
    // Reset all state to initial values
    setIsKK(true);
    setIsOn(true);
    setIsFitActive(true);
    //setUpdatedName(false);
    //setChangesMade(false);
    //setGridChangesMade(false);
    setSelectedFileList([]);
    if (onVdfRemove) {
      onVdfRemove();
    }
  };

  return (
    <Modal
      isOpen={modalOpen}
      onRequestClose={handleCloseModal}
      shouldCloseOnOverlayClick={true}
      contentLabel="VDF Modal"
      appElement={reference.current}
      style={{
        content: {
          width: modalPlaceAndSize.width,
          height: modalPlaceAndSize.height,
          top: modalPlaceAndSize.top,
          left: modalPlaceAndSize.left,
          right: modalPlaceAndSize.right,
        },
        overlay: {
          backgroundColor: "transparent",
          zIndex: "9900",
        },
      }}
    >
      <div className="vdfModal" ref={vdfModalRef}>
        <div className="modalHeader">
          <div className="headerLeft">
            <div className="title">Current Grid:</div>
            {hasProperty(model, "vdfData") &&
              hasProperty(model.vdfData, "enodes") && (
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  className="createVDFButton"
                  onClick={() => setViewGridModalOpen(true)}
                  disabled={
                    !hasProperty(model.vdfData, "enodes") ||
                    model.vdfData.enodes.length === 0
                  }
                >
                  View Grid
                </Button>
              )}
          </div>
          <div className="deleteGraphButton">
            <CloseButton onClose={handleCloseModal} />
            {/* <HighlightOffIcon
              className="deleteGraphIcon"
              onClick={handleCloseModal}
            /> */}
          </div>
        </div>
        <div className="optionsArea">
          {!hasProperty(model, "vdfData") && (
            <div className="noGridMessage">No Grid</div>
          )}
          {hasProperty(model, "vdfData") && (
            <div className="enodesSection">
              <div className="sectionTitle">Grid Summary:</div>
              <div className="enodesInfo">
                <div className="enodesDetail">
                  <span className="value">
                    {hasProperty(model.vdfData, "enodes")
                      ? model.vdfData.enodes.length
                      : 0}{" "}
                    points, Min:{" "}
                    {hasProperty(model.vdfData, "enodes")
                      ? model.vdfData.enodes[0].W
                      : 0}
                    , Max:{" "}
                    {hasProperty(model.vdfData, "enodes")
                      ? model.vdfData.enodes[model.vdfData.enodes.length - 1].W
                      : 0}
                  </span>
                </div>
              </div>
            </div>
          )}
          {/*<div className="checkboxes">
            <FormGroup
              sx={{
                width: "150px",
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isKK}
                    onChange={() => {
                      if (hadVDFbefore && !gridChangesMade) {
                        setUpdatedName(true);
                      }
                      setChangesMade(true);
                      setIsKK((old) => !old);
                    }}
                  />
                }
                label={"KK"}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isOn}
                    onChange={() => {
                      if (hadVDFbefore && !gridChangesMade) {
                        setUpdatedName(true);
                      }
                      setChangesMade(true);
                      setIsOn((old) => !old);
                    }}
                  />
                }
                label={"ON"}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isFitActive}
                    onChange={() => {
                      if (hadVDFbefore && !gridChangesMade) {
                        setUpdatedName(true);
                      }
                      setChangesMade(true);
                      setIsFitActive((old) => !old);
                    }}
                  />
                }
                label={"Fit Active"}
              />
            </FormGroup>
          </div> */}
          <div className="gridDefineArea">
            <div className="gridTitle">New Grid:</div>
            {selectedFileList.length > 0 ? (
              <>
                {/* Display linear files first */}
                {selectedFileList.some((file) =>
                  hasProperty(file, "linear")
                ) && (
                  <div className="linearFilesSection">
                    {selectedFileList.map((file, index) => {
                      if (hasProperty(file, "linear")) {
                        return (
                          <div className="linearEntry" key={`${index}`}>
                            <div className="details">
                              <div className="title">Linear Grid:</div>
                              <div className="number" title="Number of points">
                                {file.linear.npoints}
                              </div>
                              <div className="number" title="min">
                                {file.linear.min}
                              </div>
                              <div className="number" title="max">
                                {file.linear.max}
                              </div>
                            </div>
                            <div className="reductionFactor" />
                            <div className="deleteArea">
                              <img
                                src={deleteIcon}
                                alt="Delete VDF file"
                                className="deleteButton"
                                onClick={() => handleFileDelete(index)}
                              />
                            </div>
                          </div>
                        );
                      }
                      return null;
                    })}
                  </div>
                )}

                {/* Display regular files with headers if they exist */}
                {selectedFileList.some(
                  (file) => !hasProperty(file, "linear")
                ) && (
                  <>
                    <div className="listHeader">
                      <div className="name">File Name:</div>
                      <div className="reduction">Reduction factor:</div>
                      <div className="del" />
                    </div>
                    <div className="selectedFileList">
                      {selectedFileList.map((file, index) => {
                        if (!hasProperty(file, "linear")) {
                          return (
                            <div
                              className="vdfFileEntry"
                              key={`${file.file.ID}|${index}`}
                            >
                              <div className="fileName">
                                {file.file.name.length > 25 ? (
                                  <HtmlTooltip
                                    title={
                                      <React.Fragment>
                                        <div className="customTooltip">
                                          {file.file.name}
                                        </div>
                                      </React.Fragment>
                                    }
                                  >
                                    <span>
                                      {getShortName(file.file.name, 25)}
                                    </span>
                                  </HtmlTooltip>
                                ) : (
                                  file.file.name
                                )}
                              </div>
                              <div className="reductionFactor">
                                <input
                                  id="reduction-factor"
                                  value={file.redFactor}
                                  type="number"
                                  onChange={(e) => handleRedFactChange(e, file)}
                                />
                              </div>
                              <div className="deleteArea">
                                <img
                                  src={deleteIcon}
                                  alt="Delete VDF file"
                                  className="deleteButton"
                                  onClick={() => handleFileDelete(index)}
                                />
                              </div>
                            </div>
                          );
                        }
                        return null;
                      })}
                    </div>
                  </>
                )}
              </>
            ) : (
              <div className="noGridMessage">No Preselected Grid</div>
            )}
            <div className="addFileButtonArea">
              <div
                className="addFileButton"
                id="add-chi2-term-button-model"
                onClick={() => setSelectFileModalOpen(true)}
              >
                <AddIcon />
                <div className="addText">Add Grid Entry</div>
              </div>
            </div>
          </div>
        </div>
        <div className="createArea">
          <div className="buttonGroup">
            <Button
              id="create-vdf-button"
              variant="contained"
              color="primary"
              className="createVDFButton"
              onClick={() => handleCreateVDF()}
              disabled={selectedFileList.length === 0 && isOn}
            >
              {`${hadVDFbefore ? "Re-Create" : "Create"} VDF`}
            </Button>
            {hadVDFbefore && (
              <Button
                id="remove-vdf-button"
                variant="contained"
                color="primary"
                className="removeVDFButton"
                onClick={() => handleRemoveVDF()}
              >
                Remove VDF
              </Button>
            )}
          </div>
          <FileOpenIcon
            className="loadVdfIcon"
            onClick={() => vdfLoadRef.current.click()}
          />
          <input
            ref={vdfLoadRef}
            type="file"
            accept=".rfv,.RFV"
            onChange={(e) => handleFileChange(e, ["rfv", "RFV"], processFiles)}
            style={{ display: "none" }}
          />
        </div>
        <Modal
          isOpen={selectFileModalOpen}
          onRequestClose={handleCloseFileSelectModal}
          shouldCloseOnOverlayClick={true}
          id="file-choice-list-modal"
          contentLabel="VDF Grid File Select Modal"
          appElement={vdfModalRef.current}
          style={{
            content: {
              width: gridModalPlaceAndSize.width,
              height: gridModalPlaceAndSize.height,
              top: gridModalPlaceAndSize.top,
              left: gridModalPlaceAndSize.left,
              right: gridModalPlaceAndSize.right,
            },
            overlay: {
              zIndex: "9999",
              backgroundColor: "transparent",
            },
          }}
        >
          <div className="selectFilesModal">
            <div className="modalHeader">
              <div className="title">Grid Based on Datapoints in File:</div>
              <div className="closeVdfModalButtonContainer">
                <CloseButton onClose={handleCloseFileSelectModal} />
                {/* <HighlightOffIcon
                  className="deleteGraphIcon"
                  onClick={handleCloseFileSelectModal}
                /> */}
              </div>
            </div>
            <div className="filesSection">
              {selectableFiles.map((file) => (
                <div
                  key={file.ID}
                  className="selectableFileEntry"
                  onClick={() => handleFileSelect(file)}
                >
                  {file.name.length > 30 ? (
                    <HtmlTooltip
                      title={
                        <React.Fragment>
                          <div className="customTooltip">{file.name}</div>
                        </React.Fragment>
                      }
                    >
                      <span>{getShortName(file.name, 30)}</span>
                    </HtmlTooltip>
                  ) : (
                    file.name
                  )}
                </div>
              ))}
            </div>
            <div className="linearEntry">
              <div className="title">Linear Grid:</div>
              <div className="entryFields">
                <TextField
                  label="N-Points"
                  variant="standard"
                  value={newLinearNPoints}
                  type="number"
                  className="entry"
                  onChange={(e) => handleNpointChange(e)}
                />
                <TextField
                  label="Min"
                  variant="standard"
                  value={newLinearMin}
                  type="number"
                  className="entry"
                  onChange={(e) => handleLinearMinChange(e)}
                />
                <TextField
                  label="Max"
                  variant="standard"
                  value={newLinearMax}
                  type="number"
                  className="entry"
                  onChange={(e) => handleLinearMaxChange(e)}
                />
              </div>
              <div className="buttonArea">
                <Button
                  variant="contained"
                  className="createVDFButton"
                  onClick={() => handleAddLinearGrid()}
                  disabled={
                    !newLinearNPoints ||
                    newLinearNPoints < 1 ||
                    newLinearMin === "" ||
                    newLinearMax === "" ||
                    parseFloat(newLinearMin) >= parseFloat(newLinearMax)
                  }
                >
                  Add Linear Grid
                </Button>
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          isOpen={viewGridModalOpen}
          onRequestClose={handleCloseViewGridModal}
          shouldCloseOnOverlayClick={true}
          contentLabel="VDF Grid Values"
          appElement={vdfModalRef.current}
          style={{
            content: {
              width: viewGridModalPlaceAndSize.width,
              height: viewGridModalPlaceAndSize.height,
              top: viewGridModalPlaceAndSize.top,
              left: viewGridModalPlaceAndSize.left,
              right: viewGridModalPlaceAndSize.right,
            },
            overlay: {
              zIndex: "9999",
              backgroundColor: "transparent",
            },
          }}
        >
          <div className="viewGridModal">
            <div className="modalHeader">
              <div className="title">Grid Points</div>
              <div className="deleteGraphButton">
                <CloseButton onClose={handleCloseViewGridModal} />
                {/* <HighlightOffIcon
                  className="deleteGraphIcon"
                  onClick={handleCloseViewGridModal}
                /> */}
              </div>
            </div>
            <div className="gridPointsContainer">
              {hasProperty(model, "vdfData") &&
                hasProperty(model.vdfData, "enodes") &&
                model.vdfData.enodes.map((node, index) => (
                  <div key={index} className="gridPoint">
                    <span className="index">{index + 1}:</span>
                    <span className="value">{node.W}</span>
                  </div>
                ))}
            </div>
          </div>
        </Modal>
      </div>
    </Modal>
  );
}

export default ModalVDF;
