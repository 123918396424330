import React, { useState, useContext, useRef, useEffect } from "react";
import { styled } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import SettingsIcon from "@mui/icons-material/Settings";
import NotificationsIcon from "@mui/icons-material/Notifications";
import logo from "../../res/Logos/speqqle.png";
// import { Button } from "@mui/material";
// import { Button, Select } from "@mui/material";
// import Menu from "@mui/material/Menu";
// import MenuItem from "@mui/material/MenuItem";
// import { Link } from "react-router-dom";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import alert from "../../res/icons/notification.png";
// import close from "../../res/icons/delete.png";
import { DashboardContext } from "../../context/DashboardContext";
import { AuthContext } from "../../context/AuthContext";
import { GraphContext } from "../../context/GraphContext";
import { WebSocketContext } from "../../context/WebSocketContext";
import { generateWarningList } from "./actionBarLogic";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import "./appBar.scss";
import {
  adjustModalPositionAndSize,
  downloadStringTxtFile,
  generateWarningObject,
  handleFileChange,
  hasProperty,
  simpleReadFile,
  vhToPixels,
} from "../../utils/helpers";
// import units from "../../utils/units";
import { GeneralContext } from "../../context/GeneralContext";
import TextLoader from "../commonComponents/TextLoader";
import ConfirmationScreen from "../commonComponents/ConfirmationScreen";
import {
  createModelFromParamList,
  deepCopy,
  generateModel,
} from "../leftSide/Models/modelLogic";
import { createModelFitPayloadParams } from "../middle/graphLogic";
import CloseButton from "../commonComponents/CloseButton";
import CustomButton from "../commonComponents/CustomButton";

//AppBar style
const AppBarStyle = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: 1000,
  toolbar: theme.mixins.toolbar,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const AppBar = () => {
  const {
    resetDashboardContext,
    warnings,
    setWarnings,
    newWarningCount,
    setNewWarningCount,
    getDashboardVariables,
    setDashboardVariables,
    setDemoOpen,
    demoOpen,
    setAddGraphModalOpen,
    loopOpen,
    setLoopOpen,
    outputsOpen,
    setOutputsOpen,
    loopPaused,
    allLocalModels,
    setAllLocalModels,
    setFitIteration,
    setLoopPaused,
    setPauseOnNextLoop,
  } = useContext(DashboardContext);
  const { limitedToast, recordedErrorLog } = useContext(GeneralContext);
  const { resetAuth, currentUser } = useContext(AuthContext);
  const { resetGraphContext, getGraphVariables, setGraphVariables } =
    useContext(GraphContext);
  const {
    resetWebSocketContext,
    isFitOngoing,
    isLoopOngoing,
    lastJsonMessage,
    sendJsonMessage,
    retrieveRequestData,
    deleteRequestDataEntry,
    setIsFitOngoing,
    clearQueues,
    clearMessage,
    manualReconnect,
  } = useContext(WebSocketContext);
  const navigate = useNavigate();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalPlaceAndSize, setModalPlaceAndSize] = useState({
    top: "0",
    left: "0",
    height: "300px",
    width: "300px",
  });
  const [settingModalOpen, setSettingModalOpen] = useState(false);
  // const [xUnit, setXUnit] = useState(units[0]);
  const warningsRef = useRef(null);
  let warningsToDisplay = null;
  const fileInputRef = useRef(null);
  const settingsRef = useRef(null);
  const feedbackRef = useRef(null);
  const sessionOpenButtonRef = useRef(null);
  const [feedbackConfirmOpen, setFeedbackConfirmOpen] = useState(false);
  const [openSessionConfirm, setOpenSessionConfirm] = useState(false);
  const [retrievedSessionData, setRetrievedSessionData] = useState(null);
  const allModelsRef = useRef(null);
  //Logout menu toggle function anchor
  // const [anchorEl, setAnchorEl] = useState(null);
  // const open = Boolean(anchorEl);

  useEffect(() => {
    try {
      if (lastJsonMessage) {
        if (hasProperty(lastJsonMessage, "requestID")) {
          const requestData = retrieveRequestData(lastJsonMessage.requestID);
          if (requestData !== null) {
            switch (requestData.type) {
              case "get-model-list-app-bar":
                handleModelListReponse(lastJsonMessage);
                deleteRequestDataEntry(lastJsonMessage.requestID);
                break;
              case "get-model-specs-app-bar":
                handleModelSpecsReponse(lastJsonMessage);
                deleteRequestDataEntry(lastJsonMessage.requestID);
                break;

              default:
                break;
            }
          }
        } else {
          throw new Error("Request ID was not attached to the json message.");
        }
      }
    } catch (error) {
      recordedErrorLog(
        "Last Json message handling failure in useEffect: ",
        error
      );
    }
  }, [lastJsonMessage]);

  function handleModelListReponse(jsonMessage) {
    try {
      const listOfSpeqqleIDs = jsonMessage.Model.ModelList.map(
        (entry) => entry.speqqle_id
      );
      sendJsonMessage(
        {
          User: currentUser.id,
          Model: { GetModelSpecs: listOfSpeqqleIDs },
        },
        { type: "get-model-specs-app-bar" }
      );
      allModelsRef.current = jsonMessage.Model.ModelList;
    } catch (error) {
      recordedErrorLog("Error handling model LIST response: ", error);
    }
  }

  function handleModelSpecsReponse(jsonMessage) {
    try {
      let allModelsCopy = deepCopy(allModelsRef.current);
      allModelsCopy = allModelsCopy.map((model) => {
        const selectedDetails = jsonMessage.Model.ModelSpecs.find(
          (details) => details.SpeqqleModelID == model.speqqle_id
        );
        
        // Create the base model object
        let updatedModel = {
          ...model,
          params: selectedDetails.Parameters,
          outputs: selectedDetails.Outputs,
        };
        
        // Add shapes only if the list is not empty
        if (selectedDetails.Shapes && selectedDetails.Shapes.length > 0) {
          updatedModel.shapes = selectedDetails.Shapes;
        }
        
        return updatedModel;
      });
      allModelsRef.current = allModelsCopy;
      setAllLocalModels(allModelsCopy);
      updateSessionFromFile(retrievedSessionData, allModelsCopy);
    } catch (error) {
      recordedErrorLog("Error handling model SPECS response: ", error);
    }
  }

  //Logout menu toggle open function
  const handleSettingsClick = () => {
    // setAnchorEl(event.currentTarget);
    setSettingModalOpen(true);
  };

  // //Logout menu toggle close function
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  //Logout menu toggle close function
  const handleLogout = () => {
    resetDashboardContext();
    resetAuth();
    resetGraphContext();
    resetWebSocketContext();
    sessionStorage.clear();
    // handleClose();
    navigate("/");
  };

  const handleSessionClear = () => {
    resetDashboardContext();
    // // resetAuth();
    resetGraphContext();
    resetWebSocketContext();
    // sessionStorage.clear();
    // handleClose();
    window.location.reload();
  };

  const handleCloseModal = () => {
    setModalIsOpen(false);
  };

  const handleCloseSettingsModal = () => {
    setSettingModalOpen(false);
  };

  function handleWarningIconClick() {
    try {
      setNewWarningCount(0);
      setModalIsOpen(!modalIsOpen);

      const modalPosition = { top: vhToPixels(2), right: 280, left: "auto" };
      const modalSize = { width: 370, height: 400 };

      const adjusted = adjustModalPositionAndSize(modalPosition, modalSize);

      setModalPlaceAndSize(adjusted);
    } catch (error) {
      recordedErrorLog("Warning icon click handler has failed: ", error);
    }
  }

  function handleDelete(indexToDelete) {
    try {
      const warningTemp = [...warnings].filter(
        (warning, index) => index != indexToDelete
      );
      if (warningTemp.length < 1) {
        setModalIsOpen(false);
      }
      setWarnings(warningTemp);
    } catch (error) {
      recordedErrorLog("Delete handler failure: ", error);
    }
  }

  function handleClearAll() {
    setWarnings([]);
    setModalIsOpen(false);
  }

  function handleUserIconClick() {
    navigate("/profile");
  }

  warningsToDisplay = generateWarningList(warnings, handleDelete);

  function generateFileName() {
    try {
      const now = new Date();
      const year = now.getFullYear();
      const month = (now.getMonth() + 1).toString().padStart(2, "0");
      const day = now.getDate().toString().padStart(2, "0");
      const hours = now.getHours().toString().padStart(2, "0");
      const minutes = now.getMinutes().toString().padStart(2, "0");
      const seconds = now.getSeconds().toString().padStart(2, "0");

      // Constructing the filename string
      const fileName = `Session - ${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      return fileName;
    } catch (error) {
      recordedErrorLog("File name generatio failed: ", error);
      return "Session";
    }
  }

  const upgradeModelData = (data) => {
    try {
      const addSimplified = (model) => {
        let modelCopy = deepCopy(model);
        if (model.subModels.length > 0) {
          const updatedSubModels = model.subModels.map((subMod) => {
            return addSimplified(subMod);
          });

          modelCopy.subModels = updatedSubModels;
        }

        const simplifiedModel = createModelFitPayloadParams([model])[0];

        modelCopy.simplifiedParams = simplifiedModel.parameters;

        return modelCopy;
      };

      const updatedData = data.map((modelFromData) => {
        const updatedModel = addSimplified(modelFromData);

        return updatedModel;
      });

      return updatedData;
    } catch (error) {
      recordedErrorLog("Model data upgrading error: ", error);
      return data;
    }
  };

  const handleSessionSaveStart = () => {
    try {
      const dashboardVariables = getDashboardVariables();

      let updatedModelDataFromDashboard = upgradeModelData(
        dashboardVariables.modelData
      );

      dashboardVariables.modelData = updatedModelDataFromDashboard;

      const graphVariables = getGraphVariables();
      const combinedVariables = {
        dashboard: dashboardVariables,
        graph: graphVariables,
      };

      const variablesAsText = JSON.stringify(combinedVariables);

      const textToSave = "session\n" + variablesAsText;

      const generatedName = generateFileName();

      const newName = prompt("Name your session: ", generatedName);
      if (newName !== null) {
        downloadStringTxtFile(textToSave, newName, ".spqs");
      } else {
        downloadStringTxtFile(textToSave, generatedName, ".spqs");
      }
    } catch (error) {
      recordedErrorLog("Saving handler failure: ", error);
    }
  };

  function convertSessionFileToVariables(fileContent) {
    try {
      // Split the content by newline to differentiate the 'session' prefix from the JSON string
      const lines = fileContent.split("\n");

      // Check if the first line is 'session'
      if (lines[0].trim() === "session") {
        // Remove the first line
        lines.shift();

        // Join the remaining lines back in case the JSON itself contained newlines
        const jsonString = lines.join("\n");

        // Parse the JSON string back into an object
        try {
          const obj = JSON.parse(jsonString);
          return obj;
        } catch (error) {
          recordedErrorLog("Error parsing JSON from file content", error);
          return null;
        }
      } else {
        recordedErrorLog("File does not start with 'session'");
        return null;
      }
    } catch (error) {
      recordedErrorLog("Session file to variables conversion failed: ", error);
    }
  }

  const handleOpen = () => {
    setOpenSessionConfirm(true);
  };

  const handleConfirmOpen = () => {
    if (isFitOngoing) {
      sendJsonMessage(
        {
          User: currentUser.id,
          stopfit: true,
        },
        false
      );
      setIsFitOngoing(false);
      setFitIteration(null);
    }

    if (isLoopOngoing) {
      sendJsonMessage(
        {
          User: currentUser.id,
          stop_task: true,
        },
        false
      );

      setLoopPaused(false);
      setPauseOnNextLoop(false);
    }

    try {
      clearQueues();
      clearMessage();
      manualReconnect();
    } catch (error) {
      recordedErrorLog("Error resetting previous session variables: ", error);
    }

    fileInputRef.current.click();
    setOpenSessionConfirm(false);
  };

  const updateModelsToLatest = (models, allModels) => {
    try {
      let modelsCopy = deepCopy(models);

      const modelDict = {};
      const idChangeDict = {};

      const getSubMod = (FE_ID) => {
        return modelDict[FE_ID];
      };

      const update = (model) => {
        if (model.subModels.length > 0) {
          model.subModels = model.subModels.map((subMod) => update(subMod));
        }

        const generatedTemplateModel = generateModel(
          model.speqqleID,
          () => {
            return model.FE_ID;
          },
          () => {},
          () => {
            return {};
          },
          () => {},
          allModels,
          {},
          () => {},
          false,
          recordedErrorLog
        );

        const updatedModel = createModelFromParamList(
          { parameters: model.simplifiedParams },
          generatedTemplateModel,
          (_, FE_ID, placedWithID) => {
            if (placedWithID !== undefined) {
              idChangeDict[placedWithID] = FE_ID;
            }
            return getSubMod(FE_ID);
          },
          sendJsonMessage,
          currentUser
        );

        const updatedSubModels = model.subModels.map((subMod) => {
          let modelToReturn = {
            ...modelDict[subMod.FE_ID],
            FE_ID: subMod.FE_ID,
            displayName: subMod.displayName,
            curves: subMod.curves,
            linked: subMod.linked,
          };

          if (hasProperty(subMod, "vdfData")) {
            modelToReturn = { ...modelToReturn, vdfData: subMod.vdfData };
          }
          if (hasProperty(subMod, "lineshapeData")) {
            modelToReturn = { ...modelToReturn, lineshapeData: subMod.lineshapeData };
          }
          return modelToReturn;
        });

        const combinedModel = {
          ...updatedModel,
          FE_ID: model.FE_ID,
          displayName: model.displayName,
          subModels: updatedSubModels,
          curves: model.curves,
          linked: model.linked,
        };

        for (let i = 0; i < combinedModel.recParams.length; i++) {
          const recRow = combinedModel.recParams[i];

          for (let j = 0; j < recRow.length; j++) {
            const recParam = recRow[j];
            if (
              hasProperty(recParam, "FE_ID") &&
              hasProperty(idChangeDict, combinedModel.recParams[i][j].FE_ID)
            ) {
              combinedModel.recParams[i][j].FE_ID =
                idChangeDict[combinedModel.recParams[i][j].FE_ID];
            }
          }
        }

        if (hasProperty(model, "vdfData")) {
          combinedModel.vdfData = model.vdfData;
        }
        if (hasProperty(model, "lineshapeData")) {
          combinedModel.lineshapeData = model.lineshapeData;
        }

        modelDict[model.FE_ID] = combinedModel;

        return combinedModel;
      };

      modelsCopy = modelsCopy.map((modelToUpdate) => {
        return update(modelToUpdate);
      });

      return modelsCopy;
    } catch (error) {
      limitedToast("Error loading updating session models.");
      recordedErrorLog("Error loading updating session models: ", error);

      return null;
    }
  };

  const updateSessionFromFile = (retrievedData, allModels) => {
    try {
      const retrievedDataCopy = deepCopy(retrievedData);
      const modelDataCopy = deepCopy(retrievedDataCopy.dashboard.modelData);

      const updatedModels = updateModelsToLatest(modelDataCopy, allModels);

      if (updatedModels !== null) {
        retrievedDataCopy.dashboard.modelData = updatedModels;
      }

      retrievedDataCopy.dashboard.allLocalModels = allModels;
      setDashboardVariables(retrievedDataCopy.dashboard);
      setGraphVariables(retrievedDataCopy.graph);
    } catch (error) {
      recordedErrorLog(
        "Fatal error loading in session, please contact support.",
        error
      );
    } finally {
      setRetrievedSessionData(null);
    }
  };

  const processFiles = async (files) => {
    try {
      const filesData = await Promise.all(
        files.map((file) => simpleReadFile(file))
      );
      const retrievedVariables = convertSessionFileToVariables(
        filesData[0].content
      );

      if (
        hasProperty(retrievedVariables, "dashboard") &&
        hasProperty(retrievedVariables, "graph")
      ) {
        if (allLocalModels.length > 0) {
          updateSessionFromFile(retrievedVariables, allLocalModels);
        } else {
          // We need to know what models should look like from BE, so if there are no models loaded, we need to do
          // that first.
          setRetrievedSessionData(retrievedVariables);
          sendJsonMessage(
            { User: currentUser.id, Model: { GetModelList: [] } },
            { type: "get-model-list-app-bar" }
          );
        }
      } else {
        throw new Error("Could not process the file");
      }
    } catch (error) {
      console.log("ERROR FILE PROCESS: ", error);
      limitedToast("Error loading file.");
      generateWarningObject(
        "Selected file could not be processed",
        2,
        setWarnings,
        setNewWarningCount
      );
    } finally {
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    }
  };

  // const handleUnitChange = (e) => {
  //   try {
  //     const value = e.target.value;
  //     const unit = units.filter((u) => u.value === value)[0];
  //     setXUnit(unit);
  //   } catch (error) {
  //     recordedErrorLog("Unit change handler failure: ", error);
  //   }
  // };

  const handleDemo = () => {
    setDemoOpen((old) => !old);
  };

  const handleAddGrahpClick = () => {
    setAddGraphModalOpen((old) => !old);

    if (!outputsOpen) {
      if (loopOpen) {
        setLoopOpen(false);
      }
      setOutputsOpen(true);
    }
  };

  const handleFeedbackClick = () => {
    setFeedbackConfirmOpen(true);
    // navigate("/profile/contact");
  };

  // const handleFeedbackConfirmation = (wantsSession) => {
  //   if (wantsSession) {
  //     window.sessionStorage.setItem("addSession", JSON.stringify(true));
  //   } else {
  //     window.sessionStorage.setItem("addSession", JSON.stringify(false));
  //   }
  //   navigate("/profile/contact");
  // };

  return (
    <AppBarStyle
      position="fixed"
      sx={{
        backgroundColor: "#d7e7f5",
        borderBottom: "1px solid #2D446A",
      }}
    >
      <Toolbar sx={{ flexGrow: 1 }} className="topBarContainer">
        <div className="logoPlusButtons">
          <img src={logo} alt="Speqqle logo" className="appBarLogo" />
          <HelpOutlineIcon
            className="toAlphaManual"
            onClick={() => {
              navigate("/manual");
            }}
          />
          <CustomButton
            text="Feedback"
            extraClassnames={["whiteAppBarButton"]}
            handleClick={handleFeedbackClick}
            reference={feedbackRef}
          />
          {/* <Button
            variant="contained"
            sx={{
              backgroundColor: "white",
              borderColor: "#145d9d",
              borderStyle: "solid",
              borderWidth: 2,
              color: "#145d9d",
              ":hover": {
                color: "white",
                backgroundColor: "#145d9d",
              },
              fontSize: "0.8em",
              marginLeft: "10px",
            }}
            size="small"
            onClick={() => handleFeedbackClick()}
            // ref={feedbackRef}
          >
            Feedback
          </Button> */}
        </div>

        {isFitOngoing || isLoopOngoing ? (
          <TextLoader
            text={
              loopPaused
                ? "Loop paused, action needed."
                : `Processing ${
                    isLoopOngoing ? "loop" : "fit"
                  }, functionality reduced.`
            }
          />
        ) : (
          <></>
        )}

        <div className="buttonsAndMenuContainer">
          {warnings.length > 0 ? (
            <div
              className="alertsContainer"
              onClick={() => handleWarningIconClick()}
            >
              {newWarningCount > 0 ? (
                <div className="newWarnCount">
                  {newWarningCount > 9 ? "9+" : newWarningCount}
                </div>
              ) : (
                <></>
              )}
              <img
                src={alert}
                alt="Alert Icon"
                className="alertIcon"
                ref={warningsRef}
              />
            </div>
          ) : (
            <></>
          )}

          <Modal
            isOpen={modalIsOpen}
            onRequestClose={handleCloseModal}
            shouldCloseOnOverlayClick={false}
            contentLabel="Warning Modal"
            appElement={warningsRef.current}
            overlayClassName="warningModalOverlay"
            style={{
              content: {
                width: modalPlaceAndSize.width,
                height: modalPlaceAndSize.height,
                top: modalPlaceAndSize.top,
                right: modalPlaceAndSize.right,
                left: modalPlaceAndSize.left,
                zIndex: "9999",
              },
            }}
          >
            <div className="warningList">
              {warningsToDisplay}
              <div className="closeButtonContainer">
                <CloseButton onClose={handleCloseModal} />
              </div>
              {/* <img
                src={close}
                alt="Close Button"
                className="closeIcon"
                onClick={() => handleCloseModal()}
              /> */}
              <CustomButton
                text="Clear All"
                extraClassnames={["warningClear"]}
                handleClick={handleClearAll}
              />
              {/* <div className="clearAllButton" onClick={() => handleClearAll()}>
                Clear All
              </div> */}
            </div>
          </Modal>
          <CustomButton
            text="Add Graph"
            extraClassnames={[]}
            handleClick={handleAddGrahpClick}
            id="add-graph-button"
          />
          {/* <Button
            variant="contained"
            sx={{
              backgroundColor: "#145d9d",
              borderColor: "#145d9d",
              borderStyle: "solid",
              borderWidth: 2,
              color: "white",
              ":hover": {
                color: "white",
                backgroundColor: "#145d9d",
              },
              fontSize: "0.8em",
              marginRight: "4vw",
            }}
            size="small"
            onClick={() => handleAddGrahpClick()}
            id="add-graph-button"
          >
            Add Graph
          </Button> */}
          <CustomButton
            text="Demo"
            extraClassnames={
              demoOpen ? ["demoOpenButton"] : ["whiteAppBarButton"]
            }
            handleClick={handleDemo}
            id="demo-switch"
          />
          {/* <Button
            variant="contained"
            sx={{
              backgroundColor: demoOpen ? "#145d9d" : "white",
              borderColor: "#145d9d",
              borderStyle: "solid",
              borderWidth: 2,
              color: demoOpen ? "white" : "#145d9d",
              marginRight: 1,
              ":hover": {
                color: "white",
                backgroundColor: "#145d9d",
              },
              fontSize: "0.8em",
            }}
            size="small"
            onClick={() => handleDemo()}
            id="demo-switch"
          >
            Demo
          </Button> */}
          <CustomButton
            text="Open"
            extraClassnames={["whiteAppBarButton"]}
            handleClick={handleOpen}
            reference={sessionOpenButtonRef}
          />
          {/* <Button
            variant="contained"
            sx={{
              backgroundColor: "white",
              borderColor: "#145d9d",
              borderStyle: "solid",
              borderWidth: 2,
              color: "#145d9d",
              marginRight: 1,
              ":hover": {
                color: "white",
                backgroundColor: "#145d9d",
              },
              fontSize: "0.8em",
            }}
            size="small"
            onClick={() => handleOpen()}
            ref={sessionOpenButtonRef}
          >
            Open
          </Button> */}
          <input
            ref={fileInputRef}
            type="file"
            accept=".spqs"
            onChange={(e) => handleFileChange(e, ["spqs"], processFiles)}
            style={{ display: "none" }}
          />
          <CustomButton
            text="Save"
            extraClassnames={["whiteAppBarButton"]}
            handleClick={handleSessionSaveStart}
          />
          {/* <Button
            variant="contained"
            sx={{
              backgroundColor: "white",
              borderColor: "#145d9d",
              borderStyle: "solid",
              borderWidth: 2,
              color: "#145d9d",
              ":hover": {
                color: "white",
                backgroundColor: "#145d9d",
              },
              fontSize: "0.8em",
            }}
            size="small"
            onClick={() => handleSessionSaveStart()}
          >
            Save
          </Button> */}
          <IconButton
            color="inherit"
            id="app-settings-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={() => handleSettingsClick()}
            ref={settingsRef}
          >
            <SettingsIcon sx={{ color: "#2D446A", fontSize: 30 }}>
              <NotificationsIcon />
            </SettingsIcon>
          </IconButton>
          {/* <ConfirmationScreen
            onYes={() => handleFeedbackConfirmation(true)}
            onNo={() => handleFeedbackConfirmation(false)}
            text={"Do you want to attach your session with feedback?"}
            open={feedbackConfirmOpen}
            setOpen={setFeedbackConfirmOpen}
            element={feedbackRef}
          /> */}
          <ConfirmationScreen
            onYes={handleConfirmOpen}
            onNo={() => setOpenSessionConfirm(false)}
            text={
              "Opening new session file will RESET current session. This is irreversible. Are you sure you want to continue?"
            }
            open={openSessionConfirm}
            setOpen={setOpenSessionConfirm}
            element={sessionOpenButtonRef}
          />
          <Modal
            isOpen={settingModalOpen}
            onRequestClose={handleCloseSettingsModal}
            shouldCloseOnOverlayClick={true}
            appElement={settingsRef.current}
            id="appSettingsWindow"
            style={{
              content: {
                width: "300px",
                height: "300px",
                top: "3vw",
                right: "3vw",
                left: "auto",
                zIndex: "9999",
              },
              overlay: {
                zIndex: "9999",
              },
            }}
          >
            <div className="settingsModal">
              <div className="settings">
                <div className="title">General App settings:</div>
                <div className="setting">
                  <div className="unitSelectorLabel">x-data unit: </div>
                  <div className="xUnitSelectContainer" title="In development">
                    <div>
                      cm<sup>-1</sup>
                    </div>
                    {/* <Select
                      MenuProps={{
                        container: () =>
                          document.getElementById("appSettingsWindow"),
                      }}
                      value={xUnit.value}
                      onChange={handleUnitChange}
                      className="x_unit_select"
                      disabled
                    >
                      {units.map(({ name, value }) => (
                        <MenuItem key={name} value={value}>
                          {name}
                        </MenuItem>
                      ))}
                    </Select> */}
                  </div>
                </div>
              </div>
              <div className="buttons">
                <CustomButton
                  text="Clear Session"
                  variant="outlined"
                  extraClassnames={["clearVariant"]}
                  handleClick={handleSessionClear}
                />
                <CustomButton
                  text="Logout"
                  variant="outlined"
                  extraClassnames={["clearVariant"]}
                  handleClick={handleLogout}
                  id="logout-button"
                />
                {/* <Button
                  variant="outlined"
                  onClick={() => handleSessionClear()}
                  id="logout-button"
                >
                  Clear Session
                </Button> */}
                {/* <Button
                  variant="outlined"
                  onClick={() => handleLogout()}
                  id="logout-button"
                >
                  Logout
                </Button> */}
              </div>
            </div>
          </Modal>
          <Modal
            isOpen={feedbackConfirmOpen}
            onRequestClose={() => setFeedbackConfirmOpen(false)}
            shouldCloseOnOverlayClick={true}
            appElement={feedbackRef.current}
            style={{
              content: {
                width: "350px",
                height: "160px",
                top: "3vw",
                right: "auto",
                left: "10vw",
                zIndex: "9999",
              },
              overlay: {
                zIndex: "9999",
              },
            }}
          >
            <div className="feedbackModal">
              <div className="text">
                Please write to us at{" "}
                <a href="mailto:contact@speqqle.com">contact@speqqle.com</a>
              </div>
              <div className="subText">
                Attach relevant screenshots and session data if possible.
              </div>
            </div>
          </Modal>
          {/* Logout menu */}
          {/* <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <Link to="/" style={{ textDecorationLine: "none" }}>
              <MenuItem
                onClick={() => handleLogout()}
                sx={{ color: "#145d9d" }}
              >
                Logout
              </MenuItem>
            </Link>
          </Menu> */}
          <AccountCircleIcon
            className="userIcon"
            id="user-profile-home-icon"
            onClick={() => handleUserIconClick()}
          />
        </div>
      </Toolbar>
    </AppBarStyle>
  );
};

export default AppBar;
