import React, { useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import { GeneralContext } from "../context/GeneralContext";
import "./feedback.scss";
import { api } from "../utils/authActions";
import { useNavigate } from "react-router-dom";

function Feedback() {
  const navigate = useNavigate();
  const { authToken } = useContext(AuthContext);
  const { recordedErrorLog } = useContext(GeneralContext);

  // Map form field names to more readable labels
  const fieldLabelMap = {
    name: "Name (Optional)",
    email: "Email Address",
    company: "Company/Institution Name",
    experience:
      "On a scale of 1 to 5, how would you rate your overall experience?",
    three_words: "What three words best describe your experience?",
    primary_use:
      "What was your primary use case or goal? Did it meet your expectations?",
    experience_upload: "Data uploading",
    experience_manip: "Data manipulation such as cutting the datarange",
    experience_new_model: "Opening a new model to fit the data",
    experience_add_datasets: "Adding datasets and model curves to graphs",
    experience_params: "Changing parameters of the models",
    experience_multilayer: "Building up a multilayer model",
    experience_fitting:
      "Fitting (including initializing the fit and changing fit options)",
    experience_vdf: "Performing a VDF",
    experience_saving:
      "Saving models, graphs, output curves and the entire session",
    most_useful:
      "Which features or functionalities did you find most useful? Why?",
    difficult_features:
      "Were there any features you found difficult to use or unnecessary? Please explain.",
    issue1: "Issue 1",
    priority1: "Priority (Issue 1)",
    impact1: "Impact (Issue 1)",
    issue2: "Issue 2 (Optional)",
    priority2: "Priority (Issue 2)",
    impact2: "Impact (Issue 2)",
    issue3: "Issue 3 (Optional)",
    priority3: "Priority (Issue 3)",
    impact3: "Impact (Issue 3)",
    additional_issues: "Additional Bugs/Issues (Optional)",
    change_one:
      "If you could change or add one thing about/to Speqqle, what would it be? Why?",
    pitch_idea: "Pitch your improvement idea to the team",
    free_feedback:
      "Imagine you're advising a new user. What would you tell them to watch out for and suggest they do?",
    "New-models":
      "Which spectroscopy or other models do you need most urgently that are not yet available?",
    largest_impact:
      "Which issue do you think will affect the largest number of users?",
    critical_issue:
      "Which issue is most critical for ensuring a smooth experience?",
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.target;

    // Basic required-field validation
    const requiredFields = form.querySelectorAll("[required]");
    for (const field of requiredFields) {
      if (!field.value.trim()) {
        field.focus();
        alert(`Please fill out the required field: ${field.name || field.id}`);
        return;
      }
    }

    // Gather the original form data
    const originalFormData = new FormData(form);

    // Build a readable multiline string
    let allFeedback = "";
    for (const [fieldName, value] of originalFormData.entries()) {
      // If we have a mapping for this field, use it; otherwise use fieldName
      const label = fieldLabelMap[fieldName] || fieldName;
      allFeedback += `${label}: ${value}\n`;
    }

    // Prepare JSON payload for the endpoint
    const payload = {
      email_title: "FE Feedback",
      email_content: allFeedback,
    };

    // Send JSON payload
    api
      .sendMessage(authToken, payload)
      .then((response) => {
        if (response.status === 200) {
          alert("Feedback submitted successfully!");
          form.reset();
          setTimeout(() => {
            navigate("/profile");
          }, 500);
        } else {
          alert("Something went wrong while submitting feedback!");
        }
      })
      .catch((err) => {
        alert("Something went wrong while submitting feedback!");
        recordedErrorLog("Error from feedback form submit: ", err);
      });
  };

  return (
    <div className="feedbackPage">
      <form onSubmit={handleSubmit}>
        <h1>Speqqle Beta Testing Feedback Form</h1>

        <div className="form-group">
          <label htmlFor="name">Name (Optional):</label>
          <input type="text" id="name" name="name" />
        </div>

        <div className="form-group">
          <label htmlFor="email">
            Email Address: <span style={{ color: "red" }}>*</span>
          </label>
          <input type="email" id="email" name="email" required />
        </div>

        <div className="form-group">
          <label htmlFor="company">
            Company/Institution Name: <span style={{ color: "red" }}>*</span>
          </label>
          <input type="text" id="company" name="company" required />
        </div>

        <h2>Overall Experience</h2>
        <div className="form-group">
          <label>
            On a scale of 1 to 5, how would you rate your overall experience?{" "}
            <span style={{ color: "red" }}>*</span>
          </label>
          <div className="rating">
            <label>
              <input type="radio" name="experience" value="1" required /> 1
            </label>
            <label>
              <input type="radio" name="experience" value="2" /> 2
            </label>
            <label>
              <input type="radio" name="experience" value="3" /> 3
            </label>
            <label>
              <input type="radio" name="experience" value="4" /> 4
            </label>
            <label>
              <input type="radio" name="experience" value="5" /> 5
            </label>
          </div>
        </div>

        <div className="form-group">
          <label htmlFor="three-words">
            What three words best describe your experience?{" "}
            <span style={{ color: "red" }}>*</span>
          </label>
          <input type="text" id="three-words" name="three_words" required />
        </div>

        <div className="form-group">
          <label htmlFor="primary-use">
            What was your primary use case or goal? Did it meet your
            expectations? <span style={{ color: "red" }}>*</span>
          </label>
          <textarea id="primary-use" name="primary_use" rows="4" required />
        </div>

        <h2>Specific Feedback on Features</h2>
        <div className="form-group">
          <label>
            On a scale of 1 to 5 (1 being worst, 5 being best), how would you
            rate your experience with the following features of Speqqle?
          </label>
          <br />
          <label>
            Data uploading <span style={{ color: "red" }}>*</span>
          </label>
          <div className="rating">
            <label>
              <input type="radio" name="experience_upload" value="1" required />{" "}
              1
            </label>
            <label>
              <input type="radio" name="experience_upload" value="2" /> 2
            </label>
            <label>
              <input type="radio" name="experience_upload" value="3" /> 3
            </label>
            <label>
              <input type="radio" name="experience_upload" value="4" /> 4
            </label>
            <label>
              <input type="radio" name="experience_upload" value="5" /> 5
            </label>
            <label>
              <input type="radio" name="experience_upload" value="6" /> Did not
              test
            </label>
          </div>

          <br />
          <label>
            Data manipulation such as cutting the datarange{" "}
            <span style={{ color: "red" }}>*</span>
          </label>
          <div className="rating">
            <label>
              <input type="radio" name="experience_manip" value="1" required />{" "}
              1
            </label>
            <label>
              <input type="radio" name="experience_manip" value="2" /> 2
            </label>
            <label>
              <input type="radio" name="experience_manip" value="3" /> 3
            </label>
            <label>
              <input type="radio" name="experience_manip" value="4" /> 4
            </label>
            <label>
              <input type="radio" name="experience_manip" value="5" /> 5
            </label>
            <label>
              <input type="radio" name="experience_manip" value="6" /> Did not
              test
            </label>
          </div>

          <br />
          <label>
            Opening a new model to fit the data{" "}
            <span style={{ color: "red" }}>*</span>
          </label>
          <div className="rating">
            <label>
              <input
                type="radio"
                name="experience_new_model"
                value="1"
                required
              />{" "}
              1
            </label>
            <label>
              <input type="radio" name="experience_new_model" value="2" /> 2
            </label>
            <label>
              <input type="radio" name="experience_new_model" value="3" /> 3
            </label>
            <label>
              <input type="radio" name="experience_new_model" value="4" /> 4
            </label>
            <label>
              <input type="radio" name="experience_new_model" value="5" /> 5
            </label>
            <label>
              <input type="radio" name="experience_new_model" value="6" /> Did
              not test
            </label>
          </div>

          <br />
          <label>
            Adding datasets and model curves to graphs{" "}
            <span style={{ color: "red" }}>*</span>
          </label>
          <div className="rating">
            <label>
              <input
                type="radio"
                name="experience_add_datasets"
                value="1"
                required
              />{" "}
              1
            </label>
            <label>
              <input type="radio" name="experience_add_datasets" value="2" /> 2
            </label>
            <label>
              <input type="radio" name="experience_add_datasets" value="3" /> 3
            </label>
            <label>
              <input type="radio" name="experience_add_datasets" value="4" /> 4
            </label>
            <label>
              <input type="radio" name="experience_add_datasets" value="5" /> 5
            </label>
            <label>
              <input type="radio" name="experience_add_datasets" value="6" />{" "}
              Did not test
            </label>
          </div>

          <br />
          <label>
            Changing parameters of the models{" "}
            <span style={{ color: "red" }}>*</span>
          </label>
          <div className="rating">
            <label>
              <input type="radio" name="experience_params" value="1" required />{" "}
              1
            </label>
            <label>
              <input type="radio" name="experience_params" value="2" /> 2
            </label>
            <label>
              <input type="radio" name="experience_params" value="3" /> 3
            </label>
            <label>
              <input type="radio" name="experience_params" value="4" /> 4
            </label>
            <label>
              <input type="radio" name="experience_params" value="5" /> 5
            </label>
            <label>
              <input type="radio" name="experience_params" value="6" /> Did not
              test
            </label>
          </div>

          <br />
          <label>
            Building up a multilayer model{" "}
            <span style={{ color: "red" }}>*</span>
          </label>
          <div className="rating">
            <label>
              <input
                type="radio"
                name="experience_multilayer"
                value="1"
                required
              />{" "}
              1
            </label>
            <label>
              <input type="radio" name="experience_multilayer" value="2" /> 2
            </label>
            <label>
              <input type="radio" name="experience_multilayer" value="3" /> 3
            </label>
            <label>
              <input type="radio" name="experience_multilayer" value="4" /> 4
            </label>
            <label>
              <input type="radio" name="experience_multilayer" value="5" /> 5
            </label>
            <label>
              <input type="radio" name="experience_multilayer" value="6" /> Did
              not test
            </label>
          </div>

          <br />
          <label>
            Fitting (including initializing the fit and changing fit options){" "}
            <span style={{ color: "red" }}>*</span>
          </label>
          <div className="rating">
            <label>
              <input
                type="radio"
                name="experience_fitting"
                value="1"
                required
              />{" "}
              1
            </label>
            <label>
              <input type="radio" name="experience_fitting" value="2" /> 2
            </label>
            <label>
              <input type="radio" name="experience_fitting" value="3" /> 3
            </label>
            <label>
              <input type="radio" name="experience_fitting" value="4" /> 4
            </label>
            <label>
              <input type="radio" name="experience_fitting" value="5" /> 5
            </label>
            <label>
              <input type="radio" name="experience_fitting" value="6" /> Did not
              test
            </label>
          </div>

          <br />
          <label>
            Performing a VDF <span style={{ color: "red" }}>*</span>
          </label>
          <div className="rating">
            <label>
              <input type="radio" name="experience_vdf" value="1" required /> 1
            </label>
            <label>
              <input type="radio" name="experience_vdf" value="2" /> 2
            </label>
            <label>
              <input type="radio" name="experience_vdf" value="3" /> 3
            </label>
            <label>
              <input type="radio" name="experience_vdf" value="4" /> 4
            </label>
            <label>
              <input type="radio" name="experience_vdf" value="5" /> 5
            </label>
            <label>
              <input type="radio" name="experience_vdf" value="6" /> Did not
              test
            </label>
          </div>

          <br />
          <label>
            Saving models, graphs, output curves and the entire session{" "}
            <span style={{ color: "red" }}>*</span>
          </label>
          <div className="rating">
            <label>
              <input type="radio" name="experience_saving" value="1" required />{" "}
              1
            </label>
            <label>
              <input type="radio" name="experience_saving" value="2" /> 2
            </label>
            <label>
              <input type="radio" name="experience_saving" value="3" /> 3
            </label>
            <label>
              <input type="radio" name="experience_saving" value="4" /> 4
            </label>
            <label>
              <input type="radio" name="experience_saving" value="5" /> 5
            </label>
            <label>
              <input type="radio" name="experience_saving" value="6" /> Did not
              test
            </label>
          </div>
        </div>

        <div className="form-group">
          <label htmlFor="most-useful">
            Which features or functionalities did you find most useful? Why?{" "}
            <span style={{ color: "red" }}>*</span>
          </label>
          <br />
          <textarea id="most-useful" name="most_useful" rows="4" required />
        </div>

        <div className="form-group">
          <label htmlFor="difficult-features">
            Were there any features you found difficult to use or unnecessary?
            Please explain. <span style={{ color: "red" }}>*</span>
          </label>
          <br />
          <textarea
            id="difficult-features"
            name="difficult_features"
            rows="4"
            required
          />
        </div>

        <h2>Prioritization of Issues and Bugs</h2>

        {/* Issue 1 */}
        <div className="form-group">
          <label htmlFor="issue1">
            Issue 1: <span style={{ color: "red" }}>*</span>
          </label>
          <br />
          <textarea
            id="issue1"
            name="issue1"
            rows="3"
            placeholder="Describe the issue..."
            required
          />
          <label htmlFor="priority1">
            Priority: <span style={{ color: "red" }}>*</span>
          </label>
          <select id="priority1" name="priority1" required defaultValue="">
            <option value="" disabled>
              Select priority
            </option>
            <option value="urgent">Urgent</option>
            <option value="high">High</option>
            <option value="medium">Medium</option>
            <option value="low">Low</option>
          </select>
          <label htmlFor="impact1">
            Impact: <span style={{ color: "red" }}>*</span>
          </label>
          <select id="impact1" name="impact1" required defaultValue="">
            <option value="" disabled>
              Select impact
            </option>
            <option value="critical">Critical</option>
            <option value="high">High</option>
            <option value="medium">Medium</option>
            <option value="low">Low</option>
          </select>
        </div>

        {/* Issue 2 */}
        <div className="form-group">
          <label htmlFor="issue2">Issue 2 (Optional):</label>
          <br />
          <textarea
            id="issue2"
            name="issue2"
            rows="3"
            placeholder="Describe the issue..."
          />
          <label htmlFor="priority2">Priority:</label>
          <select id="priority2" name="priority2" defaultValue="">
            <option value="" disabled>
              Select priority
            </option>
            <option value="urgent">Urgent</option>
            <option value="high">High</option>
            <option value="medium">Medium</option>
            <option value="low">Low</option>
          </select>
          <label htmlFor="impact2">Impact:</label>
          <select id="impact2" name="impact2" defaultValue="">
            <option value="" disabled>
              Select impact
            </option>
            <option value="critical">Critical</option>
            <option value="high">High</option>
            <option value="medium">Medium</option>
            <option value="low">Low</option>
          </select>
        </div>

        {/* Issue 3 */}
        <div className="form-group">
          <label htmlFor="issue3">Issue 3 (Optional):</label>
          <br />
          <textarea
            id="issue3"
            name="issue3"
            rows="3"
            placeholder="Describe the issue..."
          />
          <label htmlFor="priority3">Priority:</label>
          <select id="priority3" name="priority3" defaultValue="">
            <option value="" disabled>
              Select priority
            </option>
            <option value="urgent">Urgent</option>
            <option value="high">High</option>
            <option value="medium">Medium</option>
            <option value="low">Low</option>
          </select>
          <label htmlFor="impact3">Impact:</label>
          <select id="impact3" name="impact3" defaultValue="">
            <option value="" disabled>
              Select impact
            </option>
            <option value="critical">Critical</option>
            <option value="high">High</option>
            <option value="medium">Medium</option>
            <option value="low">Low</option>
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="additional-issues">
            Additional Bugs/Issues (Optional):
          </label>
          <br />
          <textarea
            id="additional-issues"
            name="additional_issues"
            rows="6"
            placeholder="Describe any other issues you encountered..."
          />
        </div>

        <h2>Improvement Suggestions</h2>
        <div className="form-group">
          <label htmlFor="change-one">
            If you could change or add one thing about/to Speqqle, what would it
            be? Why? <span style={{ color: "red" }}>*</span>
          </label>
          <br />
          <textarea id="change-one" name="change_one" rows="4" required />
        </div>

        <div className="form-group">
          <label htmlFor="pitch-idea">
            Pitch your improvement idea to the team:{" "}
            <span style={{ color: "red" }}>*</span>
          </label>
          <br />
          <textarea id="pitch-idea" name="pitch_idea" rows="4" required />
        </div>

        <h2>Free Feedback</h2>
        <div className="form-group">
          <label htmlFor="free-feedback">
            Imagine you&apos;re advising a new user. What would you tell them to
            watch out for and suggest they do to effectively use the platform?{" "}
            <span style={{ color: "red" }}>*</span>
          </label>
          <br />
          <textarea id="free-feedback" name="free_feedback" rows="6" required />
        </div>

        <h2>New models</h2>
        <div className="form-group">
          <label htmlFor="New-models">
            Which spectroscopy or other models do you need most urgently that
            are not yet available? <span style={{ color: "red" }}>*</span>
          </label>
          <br />
          <textarea id="New-models" name="New-models" rows="6" required />
        </div>

        <h2>About Impact</h2>
        <div className="form-group">
          <label htmlFor="largest-impact">
            Which issue do you think will affect the largest number of users?{" "}
            <span style={{ color: "red" }}>*</span>
          </label>
          <br />
          <textarea
            id="largest-impact"
            name="largest_impact"
            rows="4"
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="critical-issue">
            Which issue is most critical for ensuring a smooth experience?{" "}
            <span style={{ color: "red" }}>*</span>
          </label>
          <br />
          <textarea
            id="critical-issue"
            name="critical_issue"
            rows="4"
            required
          />
        </div>

        <button type="submit">Submit Feedback</button>
      </form>
    </div>
  );
}

export default Feedback;
