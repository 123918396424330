import React, { useState } from "react";
import axios from "axios";

function CrashPage(props) {
  const { text, originalError } = props;
  const [reportSuccess, setReportSuccess] = useState(false);
  const [reportSent, setReportSent] = useState(false);
  const [includeSessionData, setIncludeSessionData] = useState(false);

  function authHeaders(token) {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  }

  async function sendErrorMessage(baseUrl, token, data) {
    return axios.post(
      `${baseUrl}/api/v1/users/contact_admin/`,
      data,
      authHeaders(token)
    );
  }

  const formatDate = () => {
    try {
      const date = new Date();
      const pad = (n) => (n < 10 ? "0" + n : n);

      const day = pad(date.getDate());
      const month = pad(date.getMonth() + 1); // Months are zero-based
      const year = date.getFullYear();
      const hours = pad(date.getHours());
      const minutes = pad(date.getMinutes());

      return `${day}/${month}/${year} ${hours}:${minutes}`;
    } catch (_) {
      return "unretrieved";
    }
  };

  function serializeError(error) {
    try {
      if (error instanceof Error) {
        return {
          name: error.name,
          message: error.message,
          stack: error.stack,
          ...error,
        };
      }
      // if it's not an Error instance, just return it as is
      return error;
    } catch (e) {
      console.error("Serialization failed: ", e);
      return "Error serialization failed";
    }
  }

  function handleErrorReport() {
    try {
      let baseUrl = "";
      if (window.sessionStorage.getItem("url") !== null) {
        baseUrl = JSON.parse(window.sessionStorage.getItem("url"));
      } else if (window.location.hostname === "app.speqqle.com") {
        baseUrl = "https://beprod1.speqqle.com";
      } else {
        baseUrl = "https://belab.speqqle.com";
      }

      const authTokenSession = JSON.parse(
        window.sessionStorage.getItem("authToken")
      );

      const sessionErrorLog = window.sessionStorage.getItem("errorLog");
      const currentUserSession = window.sessionStorage.getItem("currentUser");

      let currentUserID = "unretrieved";

      if (currentUserSession !== null) {
        try {
          const parsedUser = JSON.parse(currentUserSession);
          if (Object.prototype.hasOwnProperty.call(parsedUser, "id")) {
            currentUserID = parsedUser.id;
          }
        } catch (_) {
          console.log("Current user parsing error.");
        }
      }

      // Only proceed if we have errorLog & authToken
      if (sessionErrorLog !== null && authTokenSession !== null) {
        const currentUrl = window.location.href;
        let constructedEmail = `ERROR REPORT FROM:\n
        USER ID: ${currentUserID}\n
        TIME: ${formatDate()}\n
        ORIGIN: ${currentUrl}\n
        ERROR THAT CAUSED CRASH PAGE: ${JSON.stringify(
          serializeError(originalError)
        )}\n
        ERROR LOGS: ${sessionErrorLog}`;

        // Append session data if the user checked the box
        if (includeSessionData) {
          let sessionDataString = "";
          // Gather all keys from sessionStorage
          for (let i = 0; i < window.sessionStorage.length; i++) {
            const key = window.sessionStorage.key(i);
            // Optionally skip certain keys, e.g. "errorLog", "authToken", etc.
            // if you don't want them re-included
            if (key === "authToken") continue;

            const value = window.sessionStorage.getItem(key);
            sessionDataString += `session[${key}]: ${value}\n`;
          }
          if (sessionDataString) {
            constructedEmail += `\n--- SESSION DATA ATTACHED ---\n${sessionDataString}`;
          }
        }

        const messagePayload = {
          email_title: "FE ERROR REPORT",
          email_content: constructedEmail,
        };

        sendErrorMessage(baseUrl, authTokenSession, messagePayload)
          .then(() => {
            setReportSuccess(true);
            setReportSent(true);
          })
          .catch(() => {
            setReportSuccess(false);
            setReportSent(true);
          });
      } else {
        // If we don't have the needed session data or token, fail gracefully
        setReportSuccess(false);
        setReportSent(true);
      }
    } catch (_) {
      // If something else breaks, also set report as sent but failed
      setReportSuccess(false);
      setReportSent(true);
    }
  }

  return (
    <div
      style={{
        width: "95vw",
        height: "95vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "1.5em",
      }}
    >
      <div style={{ padding: "10vh 10vw", textWrap: "wrap" }}>
        {text}&nbsp;Please try refreshing the page or closing and opening the
        page again.
        <br />
        <br />
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <input
            type="checkbox"
            id="includeSessionData"
            checked={includeSessionData}
            onChange={(e) => setIncludeSessionData(e.target.checked)}
          />
          <label
            htmlFor="includeSessionData"
            style={{ marginLeft: "6px", cursor: "pointer" }}
          >
            <strong>Send session data with the report</strong>
          </label>
          <div
            style={{
              fontSize: "0.7em",
              marginTop: "5px",
              color: "#555",
              maxWidth: "500px",
            }}
          >
            Attaching your session data will send your models, files, and all
            other relevant information with this report. This greatly helps us
            identify and fix issues faster.
          </div>
        </div>
        <div>You can do an easy automatic report by clicking this button:</div>
        <button
          style={{
            fontSize: "0.8em",
            marginTop: "20px",
            marginBottom: "10px",
            padding: "8px 10px",
            backgroundColor: "#3b4b95",
            color: "white",
            borderRadius: "10px",
            cursor: "pointer",
          }}
          onClick={() => handleErrorReport()}
        >
          Send Report
        </button>
        <br />
        <br />
        {reportSent && !reportSuccess ? (
          <>
            Automatic reporting failed. If you can, please report this to
            support manually.
            <br />
            Support email: contact@speqqle.com
          </>
        ) : null}
        {reportSent && reportSuccess ? (
          <div
            style={{
              color: "#139429",
            }}
          >
            Automatic report sent successfully!
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default CrashPage;
