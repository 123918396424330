import React, { useState, useContext, useEffect, useRef } from "react";
import UserDetail from "./UserDetail";
import { AuthContext } from "../../context/AuthContext";
import { GeneralContext } from "../../context/GeneralContext";
import UserData from "./UserData";
import { api } from "../../utils/authActions";
import Package from "./Package";
import { Button } from "@mui/material";
import Modal from "react-modal";
import { getPackageFromList } from "./adminLogic";
import ChangesCheck from "./ChangesCheck";
import "./adminComponents.scss";
import ConfirmationScreen from "../commonComponents/ConfirmationScreen";
import toast from "react-hot-toast";
import {
  adjustModalPositionAndSize,
  vhToPixels,
  vwToPixels,
} from "../../utils/helpers";

function EditUser(props) {
  const { userData, handleUserDelete } = props;
  const { authToken, packages, currentUser } = useContext(AuthContext);
  const { recordedErrorLog } = useContext(GeneralContext);
  const [fullName, setFullName] = useState(userData ? userData.full_name : "");
  const [email, setEmail] = useState(userData ? userData.email : "");
  const [newsLetter, setNewsletter] = useState(
    userData ? userData.newsletter : false
  );
  const [activePacks, setActivePacks] = useState([]);
  const [changesList, setChangesList] = useState([]);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [modalPlaceAndSize, setModalPlaceAndSize] = useState({
    top: "0",
    left: "0",
    height: "300px",
    width: "300px",
  });
  const [isDelUserOpen, setIsDelUserOpen] = useState(false);
  const [isAdmin, setIsAdmin] = useState(
    userData ? userData.is_superuser : false
  );
  const detailRef = useRef();
  const dangerZoneRef = useRef();

  useEffect(() => {
    api
      .getUserPackages(authToken, userData.id)
      .then((response) => {
        setActivePacks(response.data);
      })
      .catch((err) => {
        recordedErrorLog(
          "THERE WAS A PROBLEM GETTING SELECTED USER PACKAGES: ",
          err
        );
        toast.error(
          "Error getting user packages. Check console for more info."
        );
      });
  }, []);

  useEffect(() => {
    setChangesList(produceChangeList());
  }, [email, newsLetter, fullName, isAdmin]);

  const startUpdate = () => {
    try {
      const modalPosition = {
        top: vhToPixels(35),
        right: "auto",
        left: vwToPixels(50),
      };
      const modalSize = { width: 550, height: 650 };

      const adjusted = adjustModalPositionAndSize(modalPosition, modalSize);

      setModalPlaceAndSize(adjusted);
      setIsConfirmOpen(true);
    } catch (error) {
      recordedErrorLog("Update starting has failed: ", error);
    }
  };

  const handleCloseConfirmModal = () => {
    setIsConfirmOpen(false);
  };

  const produceChangeList = () => {
    try {
      const list = [];

      if (email != null && email !== userData.email) {
        list.push({
          title: "Email",
          prev: userData.email,
          new: email,
        });
      }
      if (fullName != null && fullName !== userData.full_name) {
        list.push({
          title: "Full Name",
          prev: userData.full_name,
          new: fullName,
        });
      }
      if (newsLetter != null && newsLetter !== userData.newsletter) {
        list.push({
          title: "Newsletter",
          prev: userData.newsletter ? "Accepted" : "Not Accepted",
          new: newsLetter ? "Accepted" : "Not Accepted",
        });
      }
      if (isAdmin != null && isAdmin !== userData.is_superuser) {
        list.push({
          title: "Elavated Rights",
          prev: userData.is_superuser ? "Admin" : "Not Admin",
          new: isAdmin ? "Admin" : "Not Admin",
        });
      }

      return list;
    } catch (error) {
      recordedErrorLog(
        "Changes list production has encountered a failure: ",
        error
      );
      return [];
    }
  };

  const reverseChanges = () => {
    try {
      setEmail(userData.email);
      setFullName(userData.full_name);
      setNewsletter(userData.newsletter);
      setIsConfirmOpen(false);
    } catch (error) {
      recordedErrorLog("Changes reversal has failed: ", error);
    }
  };

  const approveChanges = () => {
    try {
      const payload = {
        ...userData,
        full_name: fullName,
        email: email,
        newsletter: newsLetter,
        is_superuser: isAdmin,
      };

      api
        .updateUser(authToken, payload, userData.id)
        .then(() => {
          //function can receive 'response' here if needed
          setChangesList([]);
        })
        .catch((err) => {
          console.log("ERROR ON UPDATE: ", err);
          toast.error(
            "Error updating user details. Check console for more info."
          );
        });

      setIsConfirmOpen(false);
    } catch (error) {
      recordedErrorLog("Changes approval has failed: ", error);
    }
  };

  function getCurrentDate() {
    try {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are 0-based in JS
      const day = String(today.getDate()).padStart(2, "0");

      return `${year}-${month}-${day}`;
    } catch (error) {
      recordedErrorLog("Current date getter has failed: ", error);
      return "";
    }
  }

  function getDatePlusDaysMonths(days, months) {
    try {
      const today = new Date();

      today.setMonth(today.getMonth() + months);
      today.setDate(today.getDate() + days);

      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, "0");
      const day = String(today.getDate()).padStart(2, "0");

      return `${year}-${month}-${day}`;
    } catch (error) {
      recordedErrorLog("Date plus days getter has failed: ", error);
      return "";
    }
  }

  const handlePackAdd = (id, days, months) => {
    try {
      const endDate = getDatePlusDaysMonths(days, months);

      const payload = {
        start: getCurrentDate(),
        end: endDate,
        authorised_by: currentUser.id,
        user_id: userData.id,
        package_id: id,
      };

      api
        .addPackToUser(authToken, payload)
        .then((response) => {
          setActivePacks((old) => [...old, response.data]);
        })
        .catch((err) => {
          recordedErrorLog("ERROR ADDING PACKAGE: ", err);
          toast.error(
            "Error adding package to the user. Check console for more info."
          );
        });
    } catch (error) {
      recordedErrorLog("Package adder handler has failed: ", error);
    }
  };

  const confirmUserDelete = () => {
    try {
      setIsConfirmOpen(false);
      api
        .deleteUser(authToken, userData.id)
        .then((response) => {
          console.log("User delete success: ", response);
        })
        .catch((err) => {
          recordedErrorLog("ERROR deleting user: ", err);
          toast.error("Error deleting the user. Check console for more info.");
        });

      // This is kept outside of user delete enpoint handling, because BE can delete the user and still return an error,
      // so in this case it would break the UI.
      handleUserDelete(userData.id);
    } catch (error) {
      recordedErrorLog("User delete confirmation has failed: ", error);
    }
  };

  return (
    <div className="editUser">
      <div className="sectionTitle">Details:</div>
      <div className="detailSection" ref={detailRef}>
        <div className="generalDetails">
          <div className="subSectionTitle">General:</div>
          <UserData
            fullName={fullName}
            setFullName={setFullName}
            email={email}
            setEmail={setEmail}
            emailVerified={userData ? userData.email_confirmed : false}
            newsLetter={newsLetter}
            setNewsletter={setNewsletter}
            dateCreated={userData ? userData.created_on : ""}
          />
        </div>
        <div className="adminDetails">
          <div className="subSectionTitle">Admin:</div>
          <UserDetail
            title={"Elavated Rights:"}
            value={isAdmin}
            setValue={setIsAdmin}
            checkbox
            checkboxLabel="Admin"
          />
        </div>
      </div>
      <Button
        variant="contained"
        className="updateButton"
        onClick={() => startUpdate()}
        disabled={changesList === undefined || changesList.length < 1}
      >
        Confirm Changes
      </Button>
      <Modal
        isOpen={isConfirmOpen}
        onRequestClose={handleCloseConfirmModal}
        shouldCloseOnOverlayClick={true}
        contentLabel="Fit Modal"
        appElement={detailRef.current}
        style={{
          content: {
            top: modalPlaceAndSize.top,
            left: modalPlaceAndSize.left,
            right: modalPlaceAndSize.right,
            transform: `translate(-50%, -35%)`,
            width: modalPlaceAndSize.width,
            height: modalPlaceAndSize.height,
          },
          overlay: {
            backgroundColor: "transparent",
            zIndex: "100",
          },
        }}
      >
        <ChangesCheck
          changesList={changesList}
          approveChanges={approveChanges}
          reverseChanges={reverseChanges}
        />
      </Modal>
      <div className="divider" />
      <div className="sectionTitle" ref={dangerZoneRef}>
        Danger Zone:
      </div>
      <Button
        variant="contained"
        className="deleteUserButton"
        onClick={() => setIsDelUserOpen(true)}
      >
        Delete User
      </Button>
      <ConfirmationScreen
        onYes={confirmUserDelete}
        onNo={() => setIsDelUserOpen(false)}
        text={"Are you sure you want to DELETE this user?"}
        open={isDelUserOpen}
        setOpen={setIsDelUserOpen}
        element={dangerZoneRef}
      />
      <div className="divider" />
      <div className="packageSection">
        <div className="sectionTitle">Packages:</div>
        <div className="availablePackages">
          <div className="packagesContainer">
            <div className="subSectionTitle">Active:</div>
            <div className="packages">
              {activePacks.length >= 1 ? (
                activePacks.map((pack) => {
                  const foundPack = getPackageFromList(
                    packages,
                    pack.package_id
                  );
                  return (
                    <Package
                      key={pack.id}
                      title={foundPack.name}
                      description={foundPack.description}
                      price={foundPack.cost}
                      active
                      activeTill={pack.end}
                      id={pack.id}
                    />
                  );
                })
              ) : (
                <div className="noPacks">No active packages</div>
              )}
            </div>
          </div>
          <div className="packagesContainer">
            <div className="subSectionTitle">Available:</div>
            <div className="packages">
              {packages.map((pack) => {
                return (
                  <Package
                    key={pack.id}
                    title={pack.name}
                    description={pack.description}
                    price={pack.cost}
                    length={pack.months}
                    id={pack.id}
                    admin
                    handleAdd={handlePackAdd}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditUser;
