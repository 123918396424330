import React from "react";
import ManualContent from "../../components/ManualContent";
import "./ParamColors.css";

const ParamColors = () => {
  return (
    <ManualContent title="Parameter Color Codes">
      <p>
        In Speqqle, different colors and text styles are used in the{" "}
        <strong>Parameters</strong> tab to indicate the status of each
        parameter. These visual cues help users quickly assess whether a
        parameter is free, fixed, outside expected limits, or grouped.
      </p>

      <h2>Understanding Parameter Colors and Text Styles</h2>

      <p>
        The table below provides an overview of how different parameters are
        visually represented:
      </p>

      <table className="color-code-table">
        <thead>
          <tr>
            <th>Appearance</th>
            <th>Meaning</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <span className="param-free">Black value</span>
            </td>
            <td>
              The parameter is <strong>free</strong> and will be optimized
              during the fit.
            </td>
          </tr>
          <tr>
            <td>
              <span className="param-warning">Orange value</span>
            </td>
            <td>
              The parameter is{" "}
              <strong>outside of predefined soft limits</strong>. This does not
              constrain the parameter but serves as a warning that the value may
              be unphysical (e.g., an unexpected refractive index or thickness).
            </td>
          </tr>
          <tr>
            <td>
              <span className="param-fixed">Bold value (orange or black)</span>
            </td>
            <td>
              The parameter is <strong>fixed</strong> and excluded from the fit.
              The fitting algorithm will not modify its value.
            </td>
          </tr>
          <tr>
            <td>
              <span className="param-grouped">Colored background</span>
            </td>
            <td>
              The parameter is <strong>grouped</strong> with others, meaning it
              shares the same value as other parameters in its subgroup. The
              background color indicates the assigned parameter group.
            </td>
          </tr>
        </tbody>
      </table>

      <p>
        A parameter can be grouped (
        <span className="param-grouped">Colored background</span>), fixed (
        <strong>Bold</strong>) and outside of the soft limits (
        <span className="param-warning">Orange</span>) at the same time.
      </p>

      <h2>How to Interpret Soft Limit Warnings</h2>

      <p>
        When a parameter is displayed in <strong>orange text</strong>, it
        indicates that the value is outside a predefined soft limit. This does
        not affect the fitting process but serves as a cautionary flag.
      </p>

      <p>Soft limits can indicate potential issues such as:</p>

      <ul>
        <li>A material property falling outside expected physical values.</li>
        <li>
          A parameter drifting too far from typical experimental conditions.
        </li>
        <li>A numerical instability that may require user intervention.</li>
      </ul>

      <p>
        If a parameter is orange, check its value carefully and consider
        adjusting constraints if necessary.
      </p>

      <h2>Managing Fixed and Grouped Parameters</h2>

      <p>
        To <strong>fix a parameter</strong> (displayed in bold text):
      </p>

      <ol>
        <li>
          Click the parameter to open the{" "}
          <strong>Parameter Sticky Window</strong>.
        </li>
        <li>
          Check the <strong>Fix</strong> box to prevent the parameter from
          changing during the fit.
        </li>
      </ol>

      <p>
        To <strong>group parameters</strong> (displayed with a colored
        background):
      </p>

      <ol>
        <li>
          Click the parameter to open the{" "}
          <strong>Parameter Sticky Window</strong>.
        </li>
        <li>
          Use the <strong>Group</strong> dropdown menu to assign it to a
          parameter group.
        </li>
        <li>
          All parameters in the same group will be linked and share a common
          value.
        </li>
      </ol>

      <h2>Next Steps</h2>

      <p>
        Now that you understand parameter color codes, explore other related
        topics:
      </p>

      <ul>
        <li>
          <a href="constraints">Constraining and Grouping Parameters</a>: Learn
          how to apply hard limits and link parameters.
        </li>
        <li>
          <a href="fixing">Fixing and Freeing Parameters</a>: Understand when to
          lock parameters and when to allow them to vary.
        </li>
        <li>
          <a href="editing">Editing Parameter Values</a>: Learn how to modify
          parameter values using direct input and sliders.
        </li>
      </ul>
    </ManualContent>
  );
};

export default ParamColors;
