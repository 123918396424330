import React, { useState, useContext, useEffect, useRef } from "react";
// import { Button, TextField } from "@mui/material";
import { TextField } from "@mui/material";
import { DashboardContext } from "../../../context/DashboardContext";
import { GraphContext } from "../../../context/GraphContext";
import { WebSocketContext } from "../../../context/WebSocketContext";
import { GeneralContext } from "../../../context/GeneralContext";
import { AuthContext } from "../../../context/AuthContext";
import X2Chip from "./X2Chip";
import "./fit.scss";
import HtmlTooltip from "../../commonComponents/HtmlTooltip";
import {
  generateModelsRangesQuantPairsForModelDist,
  generateWarningObject,
  getAllIdsForModelsAndSubmodels,
  getRangesForModelsFromGraphs,
  getShortName,
  hasProperty,
  toScientificNotation,
} from "../../../utils/helpers";
import {
  createModelDistPayload,
  createModelList,
  generateFitPayload,
} from "../../middle/graphLogic";
import {
  deepCopy,
  getModelsByIds,
  replaceModelById,
} from "../../leftSide/Models/modelLogic";
import {
  createFitModelEntry,
  // getTotalActiveParams,
  getTotalChi2Terms,
  performChi2Message,
} from "./fitLogic";
import { styled } from "@mui/system";
import BlockingOverlay from "../../commonComponents/BlockingOverlay";
import CustomButton from "../../commonComponents/CustomButton";

// Create a custom style hook
const CustomTextField = styled(TextField)(() => ({
  "& .MuiInputBase-input": {
    padding: "1px 6px", // Adjust padding here as per your requirement
  },
}));

function FitOptions(props) {
  const { closeModal } = props;
  const {
    uploadedFiles,
    modelData,
    setModelData,
    setWarnings,
    setNewWarningCount,
    setUndoModels,
    maxIteration,
    setMaxIteration,
    iterationRefreshCount,
    setIterationRefreshCount,
    maxRunTime,
    setMaxRunTime,
    chiVal,
    setChiVal,
    totalActiveParams,
    setTotalActiveParams,
    chi2ReqAllowed,
    setChi2ReqAllowed,
    chi2QueRef,
    undoModels,
    fitUndoAvailable,
    setFitUndoAvailable,
  } = useContext(DashboardContext);
  const {
    graphs,
    chi2Terms,
    setChi2Terms,
    chi2TermID,
    setChi2TermID,
    selectedChi2Terms,
    setSelectedChi2Terms,
    setRequestedFitModel,
    setUpdatedModelFE_IDs,
  } = useContext(GraphContext);
  const { sendJsonMessage, setIsFitOngoing, isFitOngoing, isLoopOngoing } =
    useContext(WebSocketContext);
  const { limitedToast, recordedErrorLog } = useContext(GeneralContext);
  const { currentUser } = useContext(AuthContext);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedModelQuantSet, setSelectedModelQuantSet] = useState(null);
  const [isAddOverlayVisible, setIsAddOverlayVisible] = useState(false);
  const [isOptOverlayVisible, setIsOptOverlayVisible] = useState(false);
  const [optionsErr, setOptionsErr] = useState(false);

  const localChi2Terms = useRef(chi2Terms);
  const updateFromTermAdd = useRef(false);
  // const [totalActiveParams, setTotalActiveParams] = useState(0);

  useEffect(() => {
    localChi2Terms.current = chi2Terms;
  }, [chi2Terms]);

  useEffect(() => {
    if (
      localChi2Terms.current &&
      localChi2Terms.current.length > 0 &&
      selectedChi2Terms.length > 0 &&
      selectedChi2Terms.some((termNum) => {
        if (
          chi2Terms.length > 0 &&
          chi2Terms.find((term) => term.id === termNum).weight > 0
        ) {
          return true;
        }
        return false;
      })
    ) {
      // We do this check to not send double chi2 request to the BE when adding new chi2 term (that also changes
      // selected chi2 term variable)
      if (!updateFromTermAdd.current) {
        const termsToSend = chi2Terms.filter((term) => {
          if (
            selectedChi2Terms.some((id) => id === term.id) &&
            term.weight > 0
          ) {
            return true;
          } else {
            return false;
          }
        });

        if (termsToSend.length > 0) {
          performChi2Message(
            termsToSend,
            modelData,
            uploadedFiles,
            sendJsonMessage,
            currentUser,
            recordedErrorLog,
            chi2ReqAllowed,
            setChi2ReqAllowed,
            chi2QueRef
          );
        }
      } else {
        updateFromTermAdd.current = false;
      }
    } else {
      setTotalActiveParams(0);
    }
  }, [selectedChi2Terms]);

  function handleFileEntryClick(ID, name) {
    setSelectedFile({ ID: ID, name: name });
  }

  function handleModelEntryClick(ID, name, qname, quantity) {
    setSelectedModelQuantSet({
      modelId: ID,
      modelName: name,
      quantityName: qname,
      quantity: quantity,
    });
  }

  function handleTermAdd() {
    try {
      if (selectedFile != null && selectedModelQuantSet != null) {
        const newTerm = {
          fileId: selectedFile.ID,
          fileName: selectedFile.name,
          modelId: selectedModelQuantSet.modelId,
          modelName: selectedModelQuantSet.modelName,
          quantityName: selectedModelQuantSet.quantityName,
          quantity: selectedModelQuantSet.quantity,
          weight: 1,
          chi2: null,
          id: chi2TermID,
          activeParams: null,
        };
        setChi2Terms([newTerm, ...chi2Terms]);
        // Newly created terms are automatically selected
        setSelectedChi2Terms((old) => [...old, chi2TermID]);

        setChi2TermID((old) => old + 1);
        setSelectedFile(null);
        setSelectedModelQuantSet(null);
        updateFromTermAdd.current = true;
        const termsToSend = chi2Terms.filter((term) => {
          if (
            selectedChi2Terms.some((id) => id === term.id) &&
            term.weight > 0
          ) {
            return true;
          } else {
            return false;
          }
        });

        performChi2Message(
          [newTerm, ...termsToSend],
          modelData,
          uploadedFiles,
          sendJsonMessage,
          currentUser,
          recordedErrorLog,
          chi2ReqAllowed,
          setChi2ReqAllowed,
          chi2QueRef
        );
      } else {
        limitedToast("You must select File AND Model to create a Chi2 Term.");
        generateWarningObject(
          "You must select File AND Model to create a Chi2 Term.",
          0,
          setWarnings,
          setNewWarningCount
        );
      }
    } catch (error) {
      recordedErrorLog("Term add handler failure: ", error);
    }
  }

  // This function updates selected chi2 term list when invoked (click on term in list)
  function handleTermClick(event, clickedTermId) {
    try {
      if (
        "className" in event.target &&
        event.target.className.includes("MuiInputBase-input")
      ) {
        return;
      }

      if (selectedChi2Terms.some((id) => id === clickedTermId)) {
        const updatedSelection = selectedChi2Terms.filter(
          (id) => id !== clickedTermId
        );
        setSelectedChi2Terms(updatedSelection);
      } else {
        if (event.target.className !== "deleteOverlay")
          setSelectedChi2Terms((old) => [...old, clickedTermId]);
      }
    } catch (error) {
      recordedErrorLog("Term click handler has failed: ", error);
    }
  }

  function handleTermWeightChange(index, weight) {
    try {
      const newTerms = deepCopy(chi2Terms);
      newTerms[index] = { ...newTerms[index], weight: weight };

      setChi2Terms(newTerms);
    } catch (error) {
      recordedErrorLog("Term weight handler failure: ", error);
    }
  }

  function handleDeleteChip(index) {
    try {
      const newTerms = deepCopy(chi2Terms);
      newTerms.splice(index, 1);

      setChi2Terms(newTerms);
    } catch (error) {
      recordedErrorLog("Chip deletion has failed: ", error);
    }
  }

  function handleFit() {
    try {
      if (selectedChi2Terms.length > 0) {
        // Both Chi2 option values in last row must be filled, if only one is filled, we need to produce error
        if (
          (chiVal.n === "" && chiVal.val !== "") ||
          (chiVal.n !== "" && chiVal.val === "")
        ) {
          // PRODUCE ERROR HERE !
          limitedToast(
            "Last row of Fit options needs to be not filled at all, or fully filled."
          );
          generateWarningObject(
            "Last row of Fit options needs to be not filled at all, or fully filled.",
            1,
            setWarnings,
            setNewWarningCount
          );
          setOptionsErr(true);
          setIsOptOverlayVisible(true);
        } else {
          setOptionsErr(false);
          // Only selected terms are sent to 'Fit'
          const selectedTerms = chi2Terms.filter((term) =>
            selectedChi2Terms.includes(term.id)
          );

          const termsModels = [
            ...new Set(selectedTerms.map((term) => term.modelId)),
          ];

          const allRelevantModelIds = getAllIdsForModelsAndSubmodels(
            termsModels,
            modelData
          );

          const rangesForModels = getRangesForModelsFromGraphs(graphs);

          const parametersForCurvePayload =
            generateModelsRangesQuantPairsForModelDist(
              allRelevantModelIds,
              rangesForModels,
              modelData
            );

          const modelDistPayload = createModelDistPayload(
            parametersForCurvePayload.models,
            rangesForModels,
            parametersForCurvePayload.quantityPairs,
            currentUser
          );

          let payload = generateFitPayload(
            uploadedFiles,
            modelData,
            selectedTerms,
            maxIteration,
            maxRunTime,
            chiVal,
            iterationRefreshCount,
            currentUser,
            modelDistPayload.Model.GetModelCurve,
            recordedErrorLog
          );

          const modelsForUndo = getModelsByIds(termsModels, modelData);
          setUndoModels(modelsForUndo);
          setFitUndoAvailable(true);

          const termsModelQuantSets = chi2Terms.map((term) => {
            return { modelId: term.modelId, quantity: term.quantity };
          });

          setRequestedFitModel((old) => {
            const oldUpdated = [...old, ...termsModelQuantSets];
            const sentCurveRequests = modelDistPayload.Model.GetModelCurve;
            for (let i = 0; i < sentCurveRequests.length; i++) {
              const modelSent = sentCurveRequests[i];
              if (
                !oldUpdated.some(
                  (idQuantPair) =>
                    idQuantPair.modelId === modelSent.modelid &&
                    idQuantPair.quantity === modelSent.quantity
                )
              ) {
                oldUpdated.push({
                  modelId: modelSent.modelid,
                  quantity: modelSent.quantity,
                });
              }
            }
            return oldUpdated;
          });

          const updatedTerms = chi2Terms.map((term) => {
            if (allRelevantModelIds.some((id) => id === term.modelId)) {
              return {
                ...term,
                needsUpdate: true,
              };
            } else {
              return term;
            }
          });

          setChi2Terms(updatedTerms);
          setIsFitOngoing(true);
          sendJsonMessage(payload, { type: "fit-message" });
          closeModal();
        }
      }
    } catch (error) {
      recordedErrorLog("Fit handler has failed: ", error);
    }
  }

  // function createFitModelEntry(model, depth, indexArray) {
  //   const shortName = getShortName(model.displayName);
  //   return (
  //     <div
  //       key={indexArray + "|" + model.FE_ID}
  //       style={{ marginLeft: `${depth * 5}px` }}
  //       className={
  //         selectedModel != null && selectedModel.ID === model.FE_ID
  //           ? "listItem selected"
  //           : "listItem"
  //       }
  //       onClick={() => handleModelEntryClick(model.FE_ID, shortName)}
  //     >
  //       {shortName}
  //     </div>
  //   );
  // }

  const handleChiValChange = (val, key) => {
    const valToWrite = val >= 0 ? val : 0;
    setChiVal((old) => {
      return { ...old, [key]: valToWrite };
    });
  };

  const toggleAddOverlay = () => {
    setIsAddOverlayVisible((old) => !old);
  };

  const toggleOptOverlay = () => {
    setIsOptOverlayVisible((old) => !old);
  };

  const sanitiseOptionVal = (val, float = false) => {
    if (val === "") {
      return "";
    }

    if (float) {
      return parseFloat(val);
    } else {
      return parseInt(val);
    }
  };

  const handleUndoAllFit = () => {
    try {
      let newUndoModels = deepCopy(undoModels);
      let newModels = deepCopy(modelData);
      const revertedIDs = [];
      for (let i = 0; i < chi2Terms.length; i++) {
        const term = chi2Terms[i];
        const foundUndoModel = newUndoModels.find(
          (undoModel) => undoModel.FE_ID === term.modelId
        );

        if (foundUndoModel !== undefined) {
          newModels = replaceModelById(newModels, foundUndoModel, term.modelId);

          revertedIDs.push(foundUndoModel.FE_ID);

          newUndoModels = newUndoModels.filter(
            (undoModel) => undoModel.FE_ID !== foundUndoModel.FE_ID
          );
        }
      }

      const allIDsToUpdate = getAllIdsForModelsAndSubmodels(
        revertedIDs,
        newModels
      );
      setUpdatedModelFE_IDs(allIDsToUpdate);

      setFitUndoAvailable(false);
      setUndoModels(newUndoModels);
      setModelData(newModels);
    } catch (error) {
      recordedErrorLog("Undo all Fits handler failure: ", error);
    }
  };

  return (
    <div className="fitOptions" id="fit-options-window">
      <div className="choicesAndTerms">
        <div className="definedTerms">
          <div className="termsHeader">
            <div className="title">
              Defined Χ<sup>2</sup> Terms:
            </div>
            <CustomButton
              text="Undo Fit"
              extraClassnames={[]}
              disabled={!fitUndoAvailable}
              handleClick={handleUndoAllFit}
            />
            {/* <Button
              variant="contained"
              size="small"
              sx={{ m: 1 }}
              onClick={() => handleUndoAllFit()}
              className="undoFitButton"
              disabled={!fitUndoAvailable}
            >
              Undo Fit
            </Button> */}
          </div>
          {chi2Terms.length > 0 ? (
            <div className="termsContainer">
              {chi2Terms.map((term, index) => (
                <X2Chip
                  key={term.id}
                  fileName={term.fileName}
                  modelName={term.modelName}
                  quantityName={term.quantityName}
                  weightInitial={term.weight}
                  chi2={term.chi2}
                  index={index}
                  handleDeleteChip={handleDeleteChip}
                  handleTermWeightChange={handleTermWeightChange}
                  handleClick={handleTermClick}
                  id={term.id}
                  activeParams={term.activeParams}
                  needsUpdate={
                    hasProperty(term, "needsUpdate") ? term.needsUpdate : false
                  }
                />
              ))}
            </div>
          ) : (
            <div className="noTermsChosen">
              No Χ<sup>2</sup> Terms have been defined
            </div>
          )}
          {chi2Terms.length > 0 ? (
            <div className="totalsContainer">
              <div className="total">
                Total Χ<sup>2</sup>:&nbsp;
                {toScientificNotation(
                  getTotalChi2Terms(chi2Terms, selectedChi2Terms),
                  3
                )}
              </div>
              <div className="total">
                Total Free Parameters (FP):&nbsp;
                {toScientificNotation(totalActiveParams, 3)}
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      {isAddOverlayVisible ? (
        <div className={`addOverlay`} id="add-chi2-term-overlay">
          <div className="choicesSection">
            <div className="choiceLists">
              <div className="choiceListContainer">
                <div className="choiceListTitle">Choose File:</div>
                <div>
                  <div className="choiceList" id="file-choice-list">
                    {uploadedFiles.map((file) => {
                      const shortName = getShortName(file.name, 18);
                      return (
                        <div
                          className={
                            selectedFile != null && selectedFile.ID === file.ID
                              ? "listItem selected"
                              : "listItem"
                          }
                          key={file.ID}
                          onClick={() =>
                            handleFileEntryClick(file.ID, shortName)
                          }
                          id={`list-item-${shortName}`}
                        >
                          {file.name.length > 18 ? (
                            <HtmlTooltip
                              title={
                                <React.Fragment>
                                  <div className="customTooltip">
                                    {file.name}
                                  </div>
                                </React.Fragment>
                              }
                            >
                              <span>{shortName}</span>
                            </HtmlTooltip>
                          ) : (
                            shortName
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="choiceListContainer">
                <div className="choiceListTitle">Choose Model:</div>
                <div className="choiceList" id="model-choice-list">
                  {createModelList(
                    handleModelEntryClick,
                    modelData,
                    createFitModelEntry,
                    selectedModelQuantSet
                  )}
                </div>
              </div>
            </div>

            {/* <div className="addTermSection">
              <div
                variant="contained"
                size="small"
                sx={{ m: 1 }}
                onClick={() => handleTermAdd()}
                className="addButton"
              >
                Add Χ<sup>2</sup> Term
              </div>
            </div> */}
          </div>
          <div className="overlayCloseContainer">
            <CustomButton
              text={
                <>
                  Add Χ<sup>2</sup>&nbsp;Term
                </>
              }
              extraClassnames={["addChi2InOverlay"]}
              handleClick={handleTermAdd}
              id="add-chi2-term-overlay-button"
            />
            <CustomButton
              text="Close"
              extraClassnames={[]}
              handleClick={toggleAddOverlay}
              id="close-chi2-term-overlay-button"
            />
            {/* <Button
              variant="contained"
              size="small"
              sx={{ m: 1 }}
              onClick={() => handleTermAdd()}
              className="addButton"
              id="add-chi2-term-overlay-button"
            >
              Add Χ<sup>2</sup>&nbsp;Term
            </Button>
            <Button
              variant="contained"
              size="small"
              sx={{ m: 1 }}
              onClick={toggleAddOverlay}
              className="closeOverlayButton"
              id="close-chi2-term-overlay-button"
            >
              Close
            </Button> */}
          </div>
        </div>
      ) : (
        <></>
      )}
      {isOptOverlayVisible ? (
        <div className={`addOverlay options`}>
          <div className="termOptionsSection">
            <div className="optionTitle">Fit Options:</div>
            <div className="option">
              <div className="text">Max # interations:</div>
              <CustomTextField
                value={maxIteration}
                onChange={(e) =>
                  e.target.value >= 0
                    ? setMaxIteration(sanitiseOptionVal(e.target.value))
                    : setMaxIteration(0)
                }
                className={`optionEntry`}
                size="small"
                type="number"
              />
            </div>
            <div className="option">
              <div className="text">Update display after every&nbsp;</div>
              <CustomTextField
                value={iterationRefreshCount}
                onChange={(e) =>
                  e.target.value >= 0
                    ? setIterationRefreshCount(
                        sanitiseOptionVal(e.target.value)
                      )
                    : setIterationRefreshCount(0)
                }
                className={`optionEntry`}
                size="small"
                type="number"
              />
              <div className="text">&nbsp;iteration(s)</div>
            </div>
            <div className="option">
              <div className="text">Max running time:</div>
              <CustomTextField
                value={maxRunTime}
                onChange={(e) =>
                  e.target.value >= 0
                    ? setMaxRunTime(sanitiseOptionVal(e.target.value))
                    : setMaxRunTime(0)
                }
                className="optionEntry"
                size="small"
                type="number"
              />
              <div className="text">&nbsp;seconds</div>
            </div>
            <div className="option">
              <div className="text">
                Reducing Χ<sup>2</sup>during{" "}
              </div>
              <CustomTextField
                value={chiVal.n}
                onChange={(e) =>
                  handleChiValChange(sanitiseOptionVal(e.target.value), "n")
                }
                className="optionEntry"
                size="small"
                type="number"
                error={optionsErr}
              />
              <div className="text">&nbsp;iterations below&nbsp;</div>
              <CustomTextField
                value={chiVal.val}
                onChange={(e) =>
                  handleChiValChange(
                    sanitiseOptionVal(e.target.value, true),
                    "val"
                  )
                }
                className="optionEntry"
                size="small"
                type="number"
                error={optionsErr}
              />
            </div>
          </div>
          <div className="overlayCloseContainer">
            <CustomButton
              text="Close"
              extraClassnames={[]}
              handleClick={toggleOptOverlay}
            />
            {/* <Button
              variant="contained"
              size="small"
              sx={{ m: 1 }}
              onClick={toggleOptOverlay}
              className="closeOverlayButton"
            >
              Close
            </Button> */}
          </div>
        </div>
      ) : (
        <></>
      )}
      <div className="fitButtonSection">
        <CustomButton
          text="Options"
          extraClassnames={["fitVersion"]}
          handleClick={toggleOptOverlay}
          disabled={isFitOngoing || isLoopOngoing}
        />
        {/* <Button
          variant="contained"
          size="small"
          sx={{ m: 1 }}
          onClick={() => toggleOptOverlay()}
          className="fitButton"
          disabled={isFitOngoing || isLoopOngoing}
        >
          Options
        </Button> */}
        <CustomButton
          text="Fit"
          extraClassnames={["fitVersion"]}
          handleClick={handleFit}
          disabled={
            selectedChi2Terms.length < 1 ||
            !selectedChi2Terms.some((termNum) => {
              if (
                chi2Terms.length > 0 &&
                chi2Terms.find((term) => term.id === termNum).weight > 0
              ) {
                return true;
              }
              return false;
            }) ||
            isFitOngoing ||
            isLoopOngoing ||
            totalActiveParams === 0
          }
          id="fit-button"
        />
        {/* <Button
          disabled={
            selectedChi2Terms.length < 1 ||
            !selectedChi2Terms.some((termNum) => {
              if (
                chi2Terms.length > 0 &&
                chi2Terms.find((term) => term.id === termNum).weight > 0
              ) {
                return true;
              }
              return false;
            }) ||
            isFitOngoing ||
            isLoopOngoing ||
            totalActiveParams === 0
          }
          variant="contained"
          size="small"
          sx={{ m: 1 }}
          onClick={() => handleFit()}
          className="fitButton"
          id="fit-button"
        >
          Fit
        </Button> */}
        <CustomButton
          text={
            <>
              Add Χ<sup>2</sup>&nbsp;Term
            </>
          }
          extraClassnames={["fitVersion"]}
          handleClick={toggleAddOverlay}
          disabled={isFitOngoing || isLoopOngoing}
          id="add-chi2-term-button"
        />
        {/* <Button
          variant="contained"
          size="small"
          sx={{ m: 1 }}
          onClick={() => toggleAddOverlay()}
          className="fitButton"
          id="add-chi2-term-button"
          disabled={isFitOngoing || isLoopOngoing}
        >
          Add Χ<sup>2</sup>&nbsp;Term
        </Button> */}
      </div>
      {isFitOngoing || isLoopOngoing ? (
        <BlockingOverlay />
      ) : (
        // <FitLoaderComponent text={"Fit is being processed. Please wait."} />
        <></>
      )}
    </div>
  );
}

export default FitOptions;
