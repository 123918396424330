import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, Routes, Route } from "react-router-dom";
import Sidebar from "./components/Sidebar";
import Welcome from "./pages/Welcome";
import { MathJaxContext } from "better-react-mathjax";

// General
import QuickStart from "./pages/general/QuickStart";
import Interface from "./pages/general/Interface";
import BetaProgram from "./pages/general/BetaProgram";
import SystemRequirements from "./pages/general/SystemRequirements";

// Data Loading
import DataLoadingOverview from "./pages/data-loading/Overview";

// Experiments
import ExperimentsOverview from "./pages/experiments/Overview";
import ModelList from "./pages/experiments/ModelList";
import ManagingModels from "./pages/experiments/ManagingModels";
import NewModels from "./pages/experiments/NewModels";
import LoadModels from "./pages/experiments/LoadModels";

// Parameters
import ParametersOverview from "./pages/parameters/Overview";
import EditingParameters from "./pages/parameters/Editing";
import Constraints from "./pages/parameters/Constraints";
import Relationships from "./pages/parameters/Relationships";
// import Validation from './pages/parameters/Validation';
import Fixing from "./pages/parameters/Fixing";
import Multilayer from "./pages/parameters/Multilayer";
import ParamColors from "./pages/parameters/ParamColors";
import ParameterSaving from "./pages/parameters/Saving";

// Graphs
import GraphsOverview from "./pages/graphs/Overview";
import AddingData from "./pages/graphs/AddingData";
import Customization from "./pages/graphs/Customization";
import Interactive from "./pages/graphs/Interactive";
import GraphSaving from "./pages/graphs/Saving";
import Exporting from "./pages/graphs/Exporting";

// Fitting
import FittingOverview from "./pages/fit/Overview";
import LeastSqr from "./pages/fit/LeastSqr";
import VdfFit from "./pages/fit/VdfFit";
import KKtrans from "./pages/fit/KKtrans";

// Loop Operations
import LoopOverview from "./pages/loop/Overview";

// Special Packages
import PackagesOverview from "./pages/packages/Overview";
import SpeqqleBase from "./pages/packages/SpeqqleBase";

// Special Pages
import TroubleshootingOverview from "./pages/troubleshooting/Overview";

import "./styles/manual.scss";

const mathJaxConfig = {
  loader: { load: ["input/tex", "output/chtml"] },
  tex: {
    inlineMath: [["\\(", "\\)"]], // Enables proper inline math rendering
    displayMath: [["\\[", "\\]"]], // Block equations use double brackets
    processEscapes: true, // Allows backslashes to work properly in JSX
    processEnvironments: true, // Ensures proper handling of equation environments
  },
  options: {
    escapeLatex: false, // Prevents escaping of backslashes
  },
};


const Manual = () => {
  const [currentPage, setCurrentPage] = useState("welcome");
  const pageTitle = "Welcome";
  const navigate = useNavigate();
  const location = useLocation();

  // Separate useEffect for page title updates
  useEffect(() => {
    const path = location.pathname.split("/").pop() || "welcome";
    setCurrentPage(path);
    document.title = `${pageTitle} - Speqqle Manual`;
  }, [location, pageTitle]);

  // Separate useEffect for scroll behavior
  useEffect(() => {
    const contentArea = document.querySelector(".content-area");
    if (contentArea) {
      setTimeout(() => {
        contentArea.scrollTop = 0;
      }, 0);
    }
  }, [location.pathname]);

  const handleNavigate = (path) => {
    setCurrentPage(path);
    // Remove /manual prefix if present
    const relativePath = path.replace(/^\/manual\//, "");
    navigate(relativePath);
  };

  const handleBackToSpeqqle = () => {
    // Get the last known Speqqle page or default to profile
    // const speqqleURL = localStorage.getItem('lastSpeqqlePage') || 'https://app.speqqle.com/profile';
    // window.location.href = speqqleURL;
    navigate("/profile");
  };

  return (
    <MathJaxContext config={mathJaxConfig}>
    <div className="manual-page">
      <div className="container">
        <Sidebar
          currentPage={currentPage}
          onNavigate={handleNavigate}
          onBackToSpeqqle={handleBackToSpeqqle}
        />
        <div className="main-content">
          <div className="top-header" id="page-title">
            {pageTitle}
          </div>
          <div className="content-area">
            <Routes>
              {/* Welcome */}
              <Route path="/" element={<Welcome />} />
              <Route path="welcome" element={<Welcome />} />

              {/* General */}
              <Route path="general/quickstart" element={<QuickStart />} />
              <Route path="general/interface" element={<Interface />} />
              <Route path="general/betaprogram" element={<BetaProgram />} />
              <Route path="general/systemrequirements" element={<SystemRequirements />} />

              {/* Data Loading */}
              <Route
                path="data-loading/overview"
                element={<DataLoadingOverview />}
              />
              {/* Experiments */}
              <Route
                path="experiments/overview"
                element={<ExperimentsOverview />}
              />
              <Route path="experiments/modellist" element={<ModelList />} />
              <Route
                path="experiments/managingmodels"
                element={<ManagingModels />}
              />
              <Route path="experiments/newmodels" element={<NewModels />} />
              <Route path="experiments/loadmodels" element={<LoadModels />} />

              {/* Parameters */}
              <Route
                path="parameters/overview"
                element={<ParametersOverview />}
              />
              <Route
                path="parameters/editing"
                element={<EditingParameters />}
              />
              <Route path="parameters/constraints" element={<Constraints />} />
              <Route
                path="parameters/relationships"
                element={<Relationships />}
              />
              {/* <Route path="parameters/validation" element={<Validation />} /> */}
              <Route path="parameters/fixing" element={<Fixing />} />
              <Route path="parameters/multilayer" element={<Multilayer />} />
              <Route path="parameters/paramcolors" element={<ParamColors />} />
              <Route path="parameters/saving" element={<ParameterSaving />} />

              {/* Graphs */}
              <Route path="graphs/overview" element={<GraphsOverview />} />
              <Route path="graphs/addingdata" element={<AddingData />} />
              <Route path="graphs/customization" element={<Customization />} />
              <Route path="graphs/interactive" element={<Interactive />} />
              <Route path="graphs/saving" element={<GraphSaving />} />
              <Route path="graphs/exporting" element={<Exporting />} />

              {/* Fitting */}
              <Route path="fit/overview" element={<FittingOverview />} />
              <Route path="fit/least-sqr" element={<LeastSqr />} />
              <Route path="fit/vdf-fit" element={<VdfFit />} />
              <Route path="fit/kk-trans" element={<KKtrans />} />

              {/* Loop Operations */}
              <Route path="loop/overview" element={<LoopOverview />} />

              {/* Special Packages */}
              <Route path="packages/overview" element={<PackagesOverview />} />
              <Route path="packages/speqqlebase" element={<SpeqqleBase />} />

              {/* Special Pages */}
              <Route
                path="troubleshooting/overview"
                element={<TroubleshootingOverview />}
              />
            </Routes>
          </div>
        </div>
      </div>
    </div>
    </MathJaxContext>
  );
};

export default Manual;
